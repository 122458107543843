import PropTypes from 'prop-types';
import React, { Component } from 'react';
import componentClassnames from './styles/ProgressBar.scss';
import classNames from 'classnames';

export default class ProgressBar extends Component {
    static propTypes = {
        currentProgress: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]),
        maxProgress: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
        fillColor: PropTypes.oneOf([
            'green',
            'indigo',
            'red',
            'yellow',
            'blue',
            'gray',
            'orange',
            'teal',
        ]),
        fillWithGray: PropTypes.bool,
        style: PropTypes.object,
        topElement: PropTypes.element,
        bottomElement: PropTypes.element,
        height: PropTypes.number,
        maxColor: PropTypes.oneOf([
            'green',
            'indigo',
            'red',
            'yellow',
            'blue',
            'gray',
            'orange',
            'teal',
        ]),
    }

    static defaultProps = {
        currentProgress: 0,
        maxProgress: 10,
        fillWithGray: false,
        fillColor: '',
        style: {},
        topElement: '',
        bottomElement: '',
        height: '',
        maxColor: '',
    }

    constructor(props) {
        super(props);
        this.state = {};
    }

    getColorValue() {
        let threshold = this.props.maxProgress / 3;
        let colorValue = 0;
        while (this.props.currentProgress > threshold) {
            colorValue++;
            threshold += this.props.maxProgress / 3;
        }
        return colorValue;
    }

    render() {
        const colorValue = this.getColorValue();
        const { fillWithGray, fillColor, style, height, bottomElement, topElement, maxColor } = this.props;
        let cssClass = {
            [componentClassnames['Progress__bar--green']]: colorValue !== 0 && colorValue !== 1,
            [componentClassnames['Progress__bar--red']]: colorValue === 0,
            [componentClassnames['Progress__bar--orange']]: colorValue === 1,
        };

        if (fillColor || fillWithGray) {
            cssClass = {
                [componentClassnames['Progress__bar--gray']]: fillWithGray && !fillColor,
                [componentClassnames['Progress__bar--green']]: fillColor === 'green',
                [componentClassnames['Progress__bar--indigo']]: fillColor === 'indigo',
                [componentClassnames['Progress__bar--red']]: fillColor === 'red',
                [componentClassnames['Progress__bar--yellow']]: fillColor === 'yellow',
                [componentClassnames['Progress__bar--blue']]: fillColor === 'blue',
                [componentClassnames['Progress__bar--gray']]: fillColor === 'gray',
                [componentClassnames['Progress__bar--orange']]: fillColor === 'orange',
                [componentClassnames['Progress__bar--teal']]: fillColor === 'teal',
            };
        }

        if (!!maxColor.length && (this.props.currentProgress > this.props.maxProgress)) {
            cssClass = {
                [componentClassnames['Progress__bar--green']]: maxColor === 'green',
                [componentClassnames['Progress__bar--indigo']]: maxColor === 'indigo',
                [componentClassnames['Progress__bar--red']]: maxColor === 'red',
                [componentClassnames['Progress__bar--yellow']]: maxColor === 'yellow',
                [componentClassnames['Progress__bar--blue']]: maxColor === 'blue',
                [componentClassnames['Progress__bar--gray']]: maxColor === 'gray',
                [componentClassnames['Progress__bar--orange']]: maxColor === 'orange',
                [componentClassnames['Progress__bar--teal']]: maxColor === 'teal',
            };
        }
        const customStyle = {
            ...style,
            height: height
        }

        return (
            <div>
                {topElement && <div>{topElement}</div>}
                <div className={componentClassnames.Progress} style={ customStyle }>
                <div
                    className={classNames(componentClassnames.Progress__bar, cssClass)}
                    style={{ width: `${(this.props.currentProgress / this.props.maxProgress) * 100}%` }} />
                </div>
                {bottomElement && <div>{bottomElement}</div>}
            </div>
        );
    }
}
