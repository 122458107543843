import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SkinlessPopover from '../../../SkinlessPopover';
import { v4 as uuid } from 'uuid';

class Popover extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    closePopover() {
        this.setState({ isOpen: false });
    }

    // need to set state of isOpen after mount to avoid
    // "Cannot read property 'jquery' of null" console
    // error from tether.js
    componentDidMount() {
        if (this.props.isOpen) {
            this.setState({ isOpen: true });
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.isOpen !== this.state.isOpen) {
            this.setState({ isOpen: newProps.isOpen });
        }
    }

    render() {
        let attachment = '';
        let targetAttachment = '';

        switch (this.props.position) {
            case 'top':
                attachment = 'bottom center';
                targetAttachment = 'top center';
                break;
            case 'right':
                attachment = 'middle left';
                targetAttachment = 'middle right';
                break;
            case 'bottom':
                attachment = 'top center';
                targetAttachment = 'bottom center';
                break;
            case 'bottom-left':
                attachment = 'top left';
                targetAttachment = 'bottom left';
                break;
            case 'left':
                attachment = 'middle right';
                targetAttachment = 'middle left';
                break;
        }
        return (
            <SkinlessPopover {...this.props} isOpen={this.state.isOpen} attachment={attachment} targetAttachment={targetAttachment}>
                {
                    React.Children.map(this.props.children, (c, index) => {
                        return React.cloneElement(c, {
                            key: c.key || uuid(),
                        });
                    })
                }
            </SkinlessPopover>
        );
    }
}

Popover.propTypes = {
    position: PropTypes.oneOf(['top', 'right', 'bottom', 'bottom-left', 'left']),
    constrainTo: PropTypes.oneOf(['window', 'scrollParent']),
    isOpen: PropTypes.bool,
    showOnEvent: PropTypes.oneOf(['click', 'hover']),
    hoverOnChild: PropTypes.bool,
};

Popover.defaultProps = {
    position: 'right',
    constrainTo: 'window',
    isOpen: false,
    showOnEvent: 'click',
    hoverOnChild: false,
};

export default Popover;
