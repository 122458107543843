import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon';
import css from './styles.scss';

const InsightsChip = ({
  id,
  currentPrice,
  onClick,
  payRange,
  messageStates,
}) => {
  let message = messageStates.zero;
  let icon = 'moneyOff';
  let color = 'gray';

  const validData = payRange.low !== 0 && payRange.high !== 0 && currentPrice !== 0;

  if (validData && currentPrice < payRange.low) {
    message = messageStates.low;
    icon = 'trendingDown';
    color = 'yellow';
  } else if (validData && currentPrice > payRange.high) {
    message = messageStates.high;
    icon = 'trendingUp';
    color = 'green';
  } else if (validData) {
    message = messageStates.normal;
    icon = 'trendingFlat';
    color = 'blue';
  }

  return (
    <div
      className={classNames([
        css['MSI__insights-chip'],
        css[`MSI__insights-chip-${color}`],
      ])}
      onClick={onClick}
      onKeyPress={onClick}
      id={id}
      role="button"
      tabIndex="0"
    >
      <div className={css['MSI__insights-holder']}>
        <Icon name={icon} size={16} />
        <span className={css['MSI__insights-chip-label']}>
          {message}
        </span>
      </div>
    </div>
  );
};

InsightsChip.propTypes = {
  currentPrice: PropTypes.number,
  id: PropTypes.string,
  onClick: PropTypes.func,
  payRange: PropTypes.shape({
    low: PropTypes.number,
    high: PropTypes.number,
  }),
  messageStates: PropTypes.shape({
    zero: PropTypes.string,
    low: PropTypes.string,
    normal: PropTypes.string,
    high: PropTypes.string,
  }),
};

InsightsChip.defaultProps = {
  currentPrice: 0,
  id: 'insights-chip',
  onClick: () => {},
  payRange: {
    low: 0,
    high: 0,
  },
  messageStates: {
    zero: 'Rate comparison unavailable',
    low: 'Below standard rate',
    normal: 'Above standard rate',
    high: 'Standard rate',
  },
};

export default InsightsChip;
