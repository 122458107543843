import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import css from './styles.scss';
import Button from '../legacy/Construction/Button';
import IconButton from '../IconButton';
import ActionsMenu from '../ActionsMenu';
import {
  getItemByProp,
  getButtonsndActionMenuFromSplitProps,
} from './utils';
import wrapEventHandler, { ARG_ORDER } from '../utils/wrapEventHandler';

class MassActionBarRenderer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      availableItemsWidth: null,
      haveMeasurements: false,
    };

    this.MassActionBarRef = React.createRef();

    this.MassActionBarItemsWrapperRef = [];
    this.MassActionBarItemsRef = [];
    this.actionsMenu = null;
    this.actionsMenuWidth = 0;

    this.setRef = this.setRef.bind(this);
    this.handleResize = debounce(this.handleResize.bind(this), 50);
  }

  componentDidMount() {
    this.ensureMeasurementsAndNavItemProps();
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps, prevState) {
    const { renderIconButton } = this.props;
    const { haveMeasurements } = this.state;

    if (prevState.haveMeasurements !== haveMeasurements
      || prevProps.renderIconButton !== renderIconButton) {
      this.getAndSetAvailableItemsWidth();
    }
  }

  componentWillUnmount() {
    this.clearMeasurementsAndNavItemInfo();
    window.removeEventListener('resize', this.handleResize);
  }

  getAndSetAvailableItemsWidth() {
    const getAvailableItemWidth = this.getAvailableMassActionBarItemWidth();

    if (getAvailableItemWidth) {
      this.setState({
        availableItemsWidth: getAvailableItemWidth,
      });
    }
  }

  handleResize() {
    this.getAndSetAvailableItemsWidth();
  }

  getMassActionBarItemWidths() {
    const itemsWidths = [];
    const itemsRefs = this.MassActionBarItemsWrapperRef;

    itemsRefs.forEach((item) => {
      if (item != null) {
        // add one for sub-pixels
        const width = item ? Math.ceil(item.offsetWidth) + 1 : 0;
        itemsWidths.push(width);
      }
    });

    return itemsWidths;
  }

  getActionsMenuWidth() {
    // eslint-disable-next-line react/no-find-dom-node, max-len
    return this.actionsMenu != null ? Math.ceil(ReactDOM.findDOMNode(this.actionsMenu).offsetWidth) + 1 : 0;
  }

  setMeasurements() {
    this.massActionBarItemsWidths = this.getMassActionBarItemWidths();
    this.actionsMenuWidth = this.getActionsMenuWidth();
  }

  setRef(node, attr, index) {
    if (node && index != null && this[attr]) {
      this[attr][index] = node;
    } else if (node) {
      this[attr] = node;
    }
  }

  getAvailableMassActionBarItemWidth() {
    if (this.MassActionBarRef && this.massActionBarItemsWidths) {
      const componentWidth = this.MassActionBarRef.current.offsetWidth;

      let itemWidthsSum = 0;

      this.massActionBarItemsWidths.forEach((width) => {
        itemWidthsSum += width;
      });

      let availableMassActionBarItemsWidth = componentWidth;

      if (itemWidthsSum > componentWidth) {
        availableMassActionBarItemsWidth = componentWidth - this.actionsMenuWidth;
      }
      return availableMassActionBarItemsWidth;
    }

    return 0;
  }

  ensureMeasurementsAndNavItemProps() {
    const { haveMeasurements } = this.state;

    if (!haveMeasurements) {
      this.setMeasurements();

      this.setState({
        haveMeasurements: true,
        availableItemsWidth: this.getAvailableMassActionBarItemWidth(),
      });
    }
  }

  clearMeasurementsAndNavItemInfo() {
    this.massActionBarItemsWidths = null;
    this.actionsMenuWidth = null;

    this.setState({ haveMeasurements: false });
  }

  getPropsSplit() {
    const children = this.MassActionBarItemsRef;
    const buttonArray = [];
    const actionMenuArray = [];

    const { renderIconButton } = this.props;

    const { availableItemsWidth } = this.state;

    let itemsWidth = 0;

    const propToFetch = renderIconButton ? 'title' : 'label';

    children.forEach((child, i) => {
      if (child !== null) {
        const childProp = child.props[propToFetch];

        itemsWidth += this.massActionBarItemsWidths[i];

        if (availableItemsWidth > itemsWidth) {
          buttonArray.push(getItemByProp(children, childProp, propToFetch));
        } else {
          actionMenuArray.push(getItemByProp(children, childProp, propToFetch));
        }
      }
    });

    return { buttonArray, actionMenuArray };
  }

  renderMeasured() {
    const { renderIconButton, closeOnSelection } = this.props;

    const splitProps = this.getPropsSplit();
    const actionsMenuComponent = getButtonsndActionMenuFromSplitProps(splitProps, renderIconButton, closeOnSelection);

    return actionsMenuComponent;
  }

  renderUnMeasured() {
    const { actions, renderIconButton } = this.props;

    return (
      <>
        {
          actions.map((action, i) => (
            <div
              ref={wrapEventHandler(this.setRef, ['MassActionBarItemsWrapperRef', i], ARG_ORDER.ARGS_FIRST, this)}
              className={css.MassActionBarItem}
              key={action.label}
            >
              {
                renderIconButton
                  ? (
                    <IconButton
                      ref={wrapEventHandler(this.setRef, ['MassActionBarItemsRef', i], ARG_ORDER.ARGS_FIRST, this)}
                      title={action.label}
                      name={action.icon}
                      onClick={action.onClick}
                      disabled={action.disabled}
                      color="light"
                    />
                  )
                  : (
                    <Button
                      ref={wrapEventHandler(this.setRef, ['MassActionBarItemsRef', i], ARG_ORDER.ARGS_FIRST, this)}
                      label={action.label}
                      icon={action.icon}
                      onClick={action.onClick}
                      disabled={action.disabled}
                      disableTitleCasing={action.disableTitleCasing}
                      type="primary"
                      shouldUseIconComponent
                    />
                  )
              }
            </div>
          ))
        }
        <div
          className={css.MassActionBarItem}
          key={`renderUnMeasured_MassActionBar${renderIconButton ? 'iconButton' : 'Button'}`}
        >
          <ActionsMenu
            ref={wrapEventHandler(this.setRef, ['actionsMenu'], ARG_ORDER.ARGS_FIRST, this)}
            label="More"
            type="primary"
            disableAutoWidth
            menuItems={actions}
          />
        </div>
      </>
    );
  }

  render() {
    const { haveMeasurements } = this.state;

    return (
      <div
        className={css.MassActionBar}
      >
        <div
          ref={this.MassActionBarRef}
          className={css.MassActionBar__inner}
        >
          {!haveMeasurements ? this.renderUnMeasured() : this.renderMeasured()}
        </div>
      </div>
    );
  }
}

MassActionBarRenderer.propTypes = {
  closeOnSelection: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  })).isRequired,
  renderIconButton: PropTypes.bool,
};

MassActionBarRenderer.defaultProps = {
  renderIconButton: false,
  closeOnSelection: false,
};

export default MassActionBarRenderer;
