import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import classNames from 'classnames';
import classes from './styles/PinnedMenuTypeAhead.scss';
import {
    Group,
    Label,
    Search,
} from '..//Field/Field.jsx';
import { scroller } from 'react-scroll';

@CSSModules(classes)
export default class PinnedMenuTypeAhead extends Component {
    static propTypes = {
        fields: PropTypes.array,
        selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        shouldAnimate: PropTypes.bool,
    };

    static defaultProps = {
        fields: [],
        shouldAnimate: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            value: '',
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        event.preventDefault();

        const { target: { value } } = event;
        this.setState({ value });
    }

    getTypeAheadSearchResult(input, arr) {
        const lowerCaseInput = input.toLowerCase();

        return arr.filter(({ label }) => label.toLowerCase().indexOf(lowerCaseInput) !== -1);
    }

    componentDidMount() {
        if (this.props.shouldAnimate) {
            scroller.scrollTo(`field_${this.props.selected}`, {
                to: `field_${this.props.selected}`,
                offset: 0,
                isDynamic: true,
                smooth: true,
                duration: 500,
                containerId: 'PinnedMenuTypeAheadContent',
            });
        }
    }

    render() {
        const searchResult = this.getTypeAheadSearchResult(this.state.value, this.props.fields);

        return (
            <div styleName="PinnedMenuTypeAhead">
                <div styleName="PinnedMenuTypeAhead__Search">
                    <label className="u-srOnly" htmlFor="searchCategory">Search Category</label>

                    <Group
                        id="searchCategory">
                        <Label>Label Text</Label>
                        <Search
                            placeholder="Search for category..."
                            value={this.state.value}
                            onChange={this.handleChange} />
                    </Group>
                </div>
                <div styleName="PinnedMenuTypeAhead__Content" id="PinnedMenuTypeAheadContent">
                    {this.props.children(searchResult)}
                </div>
            </div>
        );
    }
}
