export default function isValidDate(str) {
  const regex = /(?:^|\s)(\d{1,2}):(\d{2}) (AM|PM)$/g;
  const initialMatch = regex.test(str);
  let isValid = true;

  // return if null/empty/undefined (null is valid)
  if (!str) {
    return isValid;
  }

  if (!initialMatch) {
    isValid = false;
  }

  if (initialMatch) {
    str.replace(regex, (match, p1, p2) => {
      if (parseInt(p1, 10) > 23) {
        isValid = false;
      }

      if (parseInt(p2, 10) > 59) {
        isValid = false;
      }
    });
  }

  return isValid;
}
