
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import classNames from 'classnames';
import classes from './styles/Action.scss';

export default class Action extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const actionClassnames = classNames([classes.Action], {
            'u-resetButton': this.props.action,
        });
        if (this.props.action) {
            return (
                <button onClick={e => this.props.action(e)} className={actionClassnames}>{this.props.actionText}</button>
            );
        }

        return (
            <a href={this.props.actionUrl} target={this.props.actionTarget} className={actionClassnames}>{this.props.actionText}</a>
        );
    }
}

Action.propTypes = {
    actionUrl: PropTypes.string,
    actionText: PropTypes.string,
    action: PropTypes.func,
    actionTarget: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
};

Action.defaultProps = {
    actionUrl: '#',
    action: null,
    actionText: '',
    actionTarget: '_self',
};

Action.contextTypes = {
    notificationBandStyles: PropTypes.object,
};
