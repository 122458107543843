import { atom, selector } from 'recoil';
import requestPathQuery from './request-path.query';

interface Column {
  can_sort?: boolean;
  group?: string;
  icon?: string;
  id: string;
  label?: string;
  order?: number;
  selected?: boolean;
  sort_dir?: string;
}

interface FilterOption {
  label: string;
  value: string;
}

interface Filter {
  id: string;
  name?: string;
  type?: 'multi-select' | 'boolean' | 'date' | 'date-time' | 'typeahead' | 'slider' | 'date-range' | 'advanced-date-range' | 'phone' | 'text' | 'number';
  section?: number;
  options?: FilterOption[];
}

interface ColumnGroup {
  group?: string;
  items?: Column[];
}

interface Metadata {
  total: number;
  page: number;
  pages: number;
  per_page: number;
  columns: string;
  view: string;
  sort: string;
  order: 'asc' | 'desc';
  list: string;
  available_columns?: ColumnGroup[];
  available_filters?: Filter[];
}

interface List {
  id: string;
  label?: string;
  count?: number;
}

export interface ApiResponse {
  metadata: Metadata;
  lists: List[];
  results: any[];
}

export const dataVersionState = atom<number>({
  key: 'dataVersion',
  default: 1,
});

const requestQuery = selector<ApiResponse | null>({
  key: 'requestQuery',
  get: async ({ get }) => {
    get(dataVersionState); // forces refreshes when this increments, re-queries all data

    const url = get(requestPathQuery);

    const resp = await fetch(
      url,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        credentials: 'same-origin',
        cache: 'no-cache',
        mode: 'cors',
      },
    );

    if (!resp.ok || resp.status !== 200) {
      // eslint-disable-next-line no-console
      console.error(
        `[DataGrid]: ${resp.status} response: ${resp.statusText}: ${await resp.text() || '(non-parseable response)'}`,
      );
      return null;
    }

    const apiResponse = (await resp.json()) as ApiResponse;

    // @todo save preferences and reconcile with server response

    return apiResponse;
  },
});

export default requestQuery;
