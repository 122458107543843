import React from 'react';
import PropTypes from 'prop-types';
import {
  Option,
  OptionsGroup,
  OptionsLegend,
} from '../../index';
import { actionPropTypes } from '../Radio';

const AbstractOptions = ({
  expandWhenChecked,
  inputType,
  legend,
  legendHidden,
  onChange,
  options,
  optgroup,
  optionsWithGroup,
  value,
  viewType,
  boxView,
}) => {
  const optgroups = [];
  Array.prototype.forEach.call(optionsWithGroup, (element, i) => {
    optgroups.push(
      <OptionsLegend
        key={`optgroup-legend-${i}`}
        screenReaderOnly={legendHidden}
        optgroup
        disabled={element.disabled}
      >
        {element.label}
      </OptionsLegend>,
    );
    element.options.forEach((option) => {
      optgroups.push(
        <Option
          inlineFlex
          disabled={element.disabled || option.disabled}
          key={`${element.label}${option.value}`}
          secondaryContent={option.secondaryContent}
          tertiaryAction={option.tertiaryAction}
          value={option.value}
          dataTestid={option.dataTestid || option.value}
          optgroup
          boxView={boxView}
        >
          {option.label}
        </Option>,
      );
    });
  });

  return (
    <OptionsGroup
      expandWhenChecked={expandWhenChecked}
      inputType={inputType}
      onChange={onChange}
      value={value}
      viewType={viewType}
      boxView={boxView}
    >
      <OptionsLegend screenReaderOnly={legendHidden}>{legend}</OptionsLegend>
      {
        optgroup
          ? optgroups
          : options.map((option) => (
            <Option
              inlineFlex
              disabled={option.disabled}
              key={option.value}
              secondaryContent={option.secondaryContent}
              tertiaryAction={option.tertiaryAction}
              value={option.value}
              dataTestid={option.dataTestid || option.value}
              boxView={boxView}
            >
              {option.label}
            </Option>
          ))
      }
    </OptionsGroup>
  );
};

AbstractOptions.propTypes = {
  expandWhenChecked: PropTypes.bool,
  inputType: PropTypes.oneOf(['radio', 'checkbox']).isRequired,
  legend: PropTypes.string.isRequired,
  legendHidden: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    secondaryContent: PropTypes.node,
    tertiaryAction: PropTypes.arrayOf(actionPropTypes),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dataTestid: PropTypes.string,
  })),
  optgroup: PropTypes.bool,
  optionsWithGroup: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      secondaryContent: PropTypes.node,
      tertiaryAction: PropTypes.arrayOf(actionPropTypes),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      dataTestid: PropTypes.string,
    })),
  })),
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    PropTypes.string,
    PropTypes.number,
  ]),
  viewType: PropTypes.oneOf(['horizontal', 'vertical']),
  boxView: PropTypes.bool,
};

AbstractOptions.defaultProps = {
  expandWhenChecked: false,
  legendHidden: false,
  onChange: () => { },
  options: [],
  optgroup: false,
  optionsWithGroup: [],
  value: [],
  viewType: 'vertical',
  boxView: false,
};

export default AbstractOptions;
