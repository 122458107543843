import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CSSModules from 'react-css-modules';
import classes from './Tabs/styles/Tabs.scss';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import Expander from './Expander.jsx';
import MediaQuery from 'react-media';
import tokens from '../../utils/tokens.json';
import maxWidthMediaQuery, { minWidthMediaQuery } from '../../utils/maxWidthMediaQuery.js';
import Menu from '../../Menu';
import Caret from '../../Caret';
import Tab from './Tabs/Tab.jsx';
import TabPanel from './Tabs/TabPanel.jsx';

class Tabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: props.selectedIndex,
        };
        this.handleTabClick = this.handleTabClick.bind(this);
    }

    getChildContext() {
        return {
            tabsStyles: classes,
        };
    }

    handleTabClick(e, index) {
        const self = this;
        // prevent click event and state change if click tab is already active
        if (index !== this.state.selectedIndex) {
            // for internally managed state (not just props driven)
            if (!this.props.disableStateManagement) {
                this.setState({ selectedIndex: index }, () => {
                    self.props.onTabClick(index);
                });
            // for props driven just do click event
            } else {
                self.props.onTabClick(index);
            }
        }
    }

    getTabListItems() {
        let items = [];
        const children = this.props.children;

        React.Children.map(children, (child, index) => {
            const itemClasses = classNames({
                [classes.Tabs__item]: true,
                [classes['Tabs__item--active']]: index === this.state.selectedIndex,
                'js-nav-item': true,
            });
            const itemInnerClasses = classNames({
                [classes.Tabs__item__inner]: true,
                [classes['Tabs__item__inner--active']]: index === this.state.selectedIndex,
            });
            if (child.type) {
                if (child.type === Tab) {
                    items.push(<li className={itemClasses} data-testid={child.props['nw-id']} key={`tabId + ${index}`} onClick={e => this.handleTabClick(e, index)}><div className={itemInnerClasses}>{child.props.label}</div></li>);
                }
            }
        });
        return items;
    }

    getMobileNavItems() {
        const children = this.props.children;

        return React.Children.map(children, (child, index) => ({
            'data-testid': child.props['nw-id'],
            key: `tabId${index}`,
            onClick: e => this.handleTabClick(e, index),
            label: child.props.label,
        }));
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.selectedIndex !== this.state.selectedIndex) {
            this.setState({ selectedIndex: nextProps.selectedIndex });
        }
    }

    getInnerContent(child) {
        return (
            <div className={classNames({
                [classes['Tabs__panel__inner--isFlush']]: child.props.isFlush,
            })}>{child}</div>
        );
    }

    getCurrentTabPanel() {
        const children = this.props.children;
        return (
            <div>
                {React.Children.map(children, (child, index) => {
                    if (this.props.disableAnimations && index === this.state.selectedIndex) {
                        return this.getInnerContent(this.props.children[index]);
                    }
                    return (
                        <TransitionGroup component="div">
                            {index === this.state.selectedIndex &&
                                <Expander>
                                    {this.getInnerContent(this.props.children[index])}
                                </Expander>
                            }
                        </TransitionGroup>
                    );
                }
                )}
            </div>
        );
    }

    renderMobileMenu() {
        const menuItems = this.getMobileNavItems();
        return (
            <div className={classes.Tabs__menu}>
                <Menu
                    disableAutoWidth
                    menuItems={menuItems}
                    trigger={(props, state) => (
                        <div className={classes.Tabs__menuInner}>
                            <Caret
                                label={menuItems[this.state.selectedIndex].label}
                                isOpen={state.isOpen} />
                        </div>
                    )} />
            </div>
        );
    }

    render() {
        return (
            <div>
                <MediaQuery query={maxWidthMediaQuery(this.props.breakPoint)}>
                    {this.renderMobileMenu()}
                </MediaQuery>
                <MediaQuery query={minWidthMediaQuery(this.props.breakPoint)}>
                    <ul styleName="Tabs__tabList">
                        {this.getTabListItems()}
                    </ul>
                </MediaQuery>
                <TabPanel>{this.getCurrentTabPanel()}</TabPanel>
            </div>
        );
    }
}

Tabs.propTypes = {
    selectedIndex: PropTypes.number,
    onTabClick: PropTypes.func,
    disableStateManagement: PropTypes.bool,
    disableAnimations: PropTypes.bool,
    breakPoint: PropTypes.string,
};

Tabs.defaultProps = {
    selectedIndex: 0,
    onTabClick: () => {},
    disableStateManagement: false,
    disableAnimations: false,
    breakPoint: tokens.ScreenSizeLg,
};

Tabs.childContextTypes = {
    tabsStyles: PropTypes.object,
};

export default CSSModules(Tabs, classes);

export {
    Tab,
    TabPanel,
};
