import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CSSModules from 'react-css-modules';
import componentClassnames from './styles/TinyBand.scss';

export default class TinyBand extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = { };
    }

    render() {
        const rootClasses = classNames(componentClassnames.TinyBand, {
            [componentClassnames['TinyBand--clickable']]: this.props.onClick,
            [componentClassnames['TinyBand--status']]: this.context.isInStatusBand,
            [componentClassnames[`TinyBand--${this.props.type}`]]: this.props.type,
        });
        return (
            <div onClick={this.props.onClick} className={rootClasses}>{this.props.body || this.props.children}</div>
        );
    }
}

TinyBand.propTypes = {
    type: PropTypes.oneOf(['danger', 'warning', 'default', 'success', 'info', 'secondary', 'orange80']),
    onClick: PropTypes.func,
};

TinyBand.defaultProps = {
    type: 'danger',
    onClick: null,
};

TinyBand.contextTypes = {
    isInStatusBand: PropTypes.bool,
};

