import { selector } from 'recoil';
import { Tab } from './DataGrid';
import prefsState from './prefs.state';
import propsState from './props.state';

/**
 * Returns the active tab in the following order:
 *
 * 1) As defined in their preferences
 * 2) As defaulted in props
 * 3) First available tab
 *
 * @return {Tab}
 */
const activeTabQuery = selector<Tab | null>({
  key: 'activeTab',
  get: ({ get }) => {
    const allPrefs = get(prefsState);
    const allProps = get(propsState);

    if (!allPrefs || !allProps) {
      return null;
    }

    // first: preference tab
    const prefTab = allPrefs.defaultTab
      ? allProps.tabs.find(({ id }) => id === allPrefs.defaultTab)
      : undefined;
    if (prefTab) {
      return prefTab;
    }

    // second: tab from <DataGrid> props
    const propsTab = allProps.defaultTab
      ? allProps.tabs.find(({ id }) => id === allProps.defaultTab)
      : undefined;
    if (propsTab) {
      return propsTab;
    }

    const [first] = allProps.tabs;

    // final: first tab in array
    if (!first) {
      return null;
    }

    return first;
  },
});

export default activeTabQuery;
