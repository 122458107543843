import React from 'react';
import PropTypes from 'prop-types';
import filesize from 'filesize';
import {
  BodyText,
  Icon,
  IconButton,
  Media,
  Median,
  MedianAlpha,
  MedianOmega,
  Truncate,
  LoaderIndicator,
} from '../index';

import css from './File.scss';

const File = ({
  name, size, type, thumbnail, onDelete, percentUploaded, showThumbnail,
}) => {
  const partial = percentUploaded < 1;
  const displayThumb = showThumbnail && thumbnail && type?.includes?.('image/');

  return (
    <div className={css.File__container}>
      <Media
        verticalAlign={displayThumb ? 'middle' : 'top'}
        element={partial ? (
          <LoaderIndicator />
        ) : (
          <>
            {!displayThumb && <Icon name="document" size={32} />}
            {displayThumb
              && (
              <img
                src={thumbnail}
                alt="Preview"
                className={css.File__thumbnail}
                // Revoke data uri after image is loaded
                // eslint-disable-next-line react/jsx-no-bind
                onLoad={() => { URL.revokeObjectURL(thumbnail); }}
              />
              )}
          </>
        )}
        body={(
          <Median verticalAlign="middle" noWrap>
            <MedianAlpha>
              <BodyText
                tag="div"
                styleLevel="mdSemiBold"
              >
                <Truncate
                  wordBreak="break-all"
                  lineHeight="1.5em"
                  maxLines={1}
                  text={name}
                />
              </BodyText>
              <BodyText
                tag="div"
                styleLevel="sm"
              >
                {partial && (
                  <span>
                    {Number(parseInt(filesize(size), 10) * percentUploaded)?.toFixed(2)}
                    {' of '}
                  </span>
                )}
                {filesize(size)}
              </BodyText>
            </MedianAlpha>
            <MedianOmega>
              {partial ? (
                <button
                  className={css.File__closePartial}
                  type="submit"
                  onClick={onDelete}
                >
                  <Icon
                    block
                    name="cancelSolid"
                    title={`Delete ${name}`}
                  />
                </button>
              ) : (
                <IconButton
                  onClick={onDelete}
                  name="deleteHollow"
                  title={`Delete ${name}`}
                />
              )}
            </MedianOmega>
          </Median>
        )}
      />
    </div>
  );
};

File.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  type: PropTypes.string,
  thumbnail: PropTypes.string,
  showThumbnail: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  percentUploaded: PropTypes.number,
};

File.defaultProps = {
  type: '',
  thumbnail: '',
  showThumbnail: false,
  percentUploaded: 1,
};

export default File;
