
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import classes from './styles/Median.scss';

export default class Median extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={classes.Median}>{this.props.children}</div>
        );
    }
}
