import React from 'react';
import AbstractInput from '../AbstractInput';
import protectedProps from '../AbstractInput/protectedProps';

/*
^                                       # Start of expression
(?!\b(\d)\1+-(\d)\1+-(\d)\1+\b)         # Don't allow all matching digits for every field
(?!123-45-6789|219-09-9999|078-05-1120) # Don't allow "123-45-6789", "219-09-9999" or "078-05-1120"
(?!666|000|9\d{2})\d{3}                 # Don't allow the SSN to begin with 666, 000 or anything between 900-999
-                                       # A dash (separating Area and Group numbers)
(?!00)\d{2}                             # Don't allow the Group Number to be "00"
-                                       # Another dash (separating Group and Serial numbers)
(?!0{4})\d{4}                           # Don't allow last four digits to be "0000"
$                                       # End of expression
*/
export const SSN_REGEX = /^(?!\b(\d)\1+-(\d)\1+-(\d)\1+\b)(?!123-45-6789|219-09-9999|078-05-1120)(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}/;

export const DEFAULT_ERROR = {
  type: 'error',
  message: 'Social Security Number is invalid',
};

export const internalValidation = (event) => SSN_REGEX.test(event.target.value) || DEFAULT_ERROR;

const SocialSecurityNumberInput = (initialProps) => {
  const props = {
    ...initialProps,
    format: '999-99-9999',
    internalValidation,
  };

  return (<AbstractInput {...props} />);
};

SocialSecurityNumberInput.defaultProps = {
  label: 'Social Security Number',
};

export default protectedProps(SocialSecurityNumberInput);
