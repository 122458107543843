import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './styles.scss';

const IconButtons = ({
  children,
  isFlush,
}) => (
  <div
    className={classNames({
      [css['IconButtons--flush']]: isFlush,
    })}
  >
    {children}
  </div>
);

IconButtons.propTypes = {
  children: PropTypes.node,
  isFlush: PropTypes.bool,
};

IconButtons.defaultProps = {
  children: null,
  isFlush: false,
};

export default IconButtons;
