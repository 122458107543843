export const getFileIconNameByExtension = (extension: string): string => {
  const mappings = {
    pdf: "pdfFile",
    csv: "csvFile",
    txt: "textFile",
    heic: "image",
    heif: "image",
    mp3: "audioFile",
    mp4: "videoFile",
    avi: "videoFile",
    mov: "videoFile",
    m4v: "videoFile",
    mkv: "videoFile",
    flv: "videoFile",
    wmv: "videoFile",
    mpeg: "videoFile",
    mpg: "videoFile",
    ogv: "videoFile",
    "3gp": "videoFile",
    webm: "videoFile",
    zip: "zipFile",
    rar: "zipFile",
    tar: "zipFile",
    "7z": "zipFile",
    bz2: "zipFile",
    gz: "zipFile",
  };
  const lowerCaseExtension = extension?.toLowerCase() || '';
  const matchingKey = Object.keys(mappings).find(
    (key) => key === lowerCaseExtension
  );

  // Return the corresponding value or 'textFile' as default type if no match is found
  return matchingKey ? mappings[matchingKey] : "textFile";
};

export const getNonImageIconNameByMimeType = (mimeType: string): string => {
  const mappings = {
    pdf: 'pdfFile',
    csv: 'csvFile',
    audio: 'audioFile',
    video: 'videoFile',
    zip: 'zipFile',
    'x-rar': 'zipFile',
    'x-tar': 'zipFile',
    'x-7z-compressed': 'zipFile',
    'x-bzip2': 'zipFile',
    'x-gzip': 'zipFile',
    image: 'image',
  };

  const lowerCaseMimeType = mimeType?.toLowerCase() || '';
  const matchingKey = Object.keys(mappings).find((key) => lowerCaseMimeType.includes(key));

  // Return the corresponding value or 'textFile' as default type if no match is found
  return matchingKey ? mappings[matchingKey] : 'textFile';
};
