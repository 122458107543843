import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './Legend.scss';

const Legend = ({ children, screenReaderOnly, disabled }) => (
  <legend className={classNames(css.Legend, {
    [css['Legend--srOnly']]: screenReaderOnly,
    [css['Legend--disabled']]: disabled,
  })}
  >
    {children}
  </legend>
);

Legend.propTypes = {
  screenReaderOnly: PropTypes.bool,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

Legend.defaultProps = {
  screenReaderOnly: false,
  disabled: false,
};

export default Legend;
