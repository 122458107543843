
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import classNames from 'classnames';

export default class PanelBoxSidebar extends Component {
    constructor(props) {
        super(props);
        this.state = { };
    }

    render() {
        const styles = this.context.panelStyles;
        const rootClasses = classNames(
            [styles.Panel__bd__col],
            [styles['Panel__bd__col--sidebar']],
            {
                [styles.Panel__bd__col]: this.context.panelPosition === 'static',
            }
        );
        const containerClasses = classNames([styles.Panel__bd__col__container],
            {
                [styles['u-static']]: this.context.panelPosition === 'static',
            }
        );
        const innerClasses = classNames({
            [styles.Panel__bd__col__container__inner]: true,
            [styles['u-static']]: this.context.panelPosition === 'static',
        });
        return (
            <div className={rootClasses}>
                <div className={containerClasses}>
                    {this.props.header ?
                        <div className={[styles.Panel__bd__col__container__hdr]}>{this.props.header}</div>
                        :
                        null
                    }
                    <div className={innerClasses}>{this.props.children}</div>
                </div>
            </div>
        );
    }
}

PanelBoxSidebar.propTypes = {

};

PanelBoxSidebar.defaultProps = {

};

PanelBoxSidebar.contextTypes = {
    panelStyles: PropTypes.object,
    panelPosition: PropTypes.oneOf(['static', 'absolute']),
};
