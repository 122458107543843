import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import classNames from 'classnames';
import styles from './styles.scss';

class FlashAlert extends Component {
  componentWillEnter(callback) {
    if (document) {
      const el = this.node;
      TweenMax.fromTo( // eslint-disable-line
        el,
        0.2,
        { y: 25, opacity: 0 },
        { y: 0, opacity: 1, onComplete: callback },
      );
    }
  }

  componentWillLeave(callback) {
    if (document) {
      const el = this.node;
      TweenMax.fromTo( // eslint-disable-line
        el,
        0.1,
        { y: 0, opacity: 1 },
        { y: 25, opacity: 0, onComplete: callback },
      );
    }
  }

  render() {
    const {
      alertType,
      icon,
    } = this.props;
    const containerClasses = classNames({
      [styles.FlashAlert]: true,
      [styles['FlashAlert--danger']]: alertType === 'danger',
      [styles['FlashAlert--info']]: alertType === 'info',
      [styles['FlashAlert--success']]: alertType === 'success',
      [styles['FlashAlert--warning']]: alertType === 'warning',
    });

    const iconContainerClasses = classNames({
      [styles.FlashAlert__icon]: true,
      [styles['FlashAlert__icon--danger']]: alertType === 'danger',
      [styles['FlashAlert__icon--info']]: alertType === 'info',
      [styles['FlashAlert__icon--success']]: alertType === 'success',
      [styles['FlashAlert__icon--warning']]: alertType === 'warning',
    });

    const iconHolderClasses = classNames({
      [styles.FlashAlert__icon__elem]: true,
    });
    const iconInnerClasses = classNames(icon, {
      [styles.FlashAlert__icon__elem__inner]: true,
      [styles['FlashAlert__icon__elem__inner--spin']]:
        icon === 'icon-glyphish-refresh',
    });

    /* eslint-disable */
    return (
      <div className={containerClasses} ref={(node) => { this.node = node; }}>
        <div className={iconContainerClasses}>
          <span className={iconHolderClasses}>
            <span className={iconInnerClasses} />
          </span>
        </div>
        <div styleName="FlashAlert__bd">
          <div styleName="FlashAlert__hdg">{this.props.heading}</div>
          <div dangerouslySetInnerHTML={{ __html: this.props.body }} />
        </div>
      </div>
    );
    /* eslint-disable */
  }
}

FlashAlert.propTypes = {
  alertType: PropTypes.oneOf(['success', 'info', 'danger', 'warning']),
  icon: PropTypes.string,
};

FlashAlert.defaultProps = {
  alertType: 'danger',
  icon: 'icon-x',
};

export default CSSModules(FlashAlert, styles);
