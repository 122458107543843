import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import Svg from '../Svg';
import ExpanderAnimation from '../ExpanderAnimation';
import css from './styles.scss';
import Icon from '../Icon';
import { actionPropTypes } from '../v2/Radio';
import { Button, ButtonGroup } from '..';

/* eslint-disable jsx-a11y/label-has-for */

const Option = ({
  checked,
  children,
  disabled,
  expandWhenChecked,
  indeterminate,
  inlineFlex,
  labelBold,
  labelHidden,
  onChange,
  secondaryContent,
  tertiaryAction,
  type,
  value,
  optgroup,
  dataTestid,
  completedWhenChecked = false,
  labelStyle = {},
  boxView = false,
}) => (
  <div
    className={classNames(css.Option, {
      [css['Option--marginMd']]: optgroup,
      [css['Option--boxView']]: boxView,
      [css['Option--boxView__active']]: boxView && checked,
    })}
    {...(boxView ? { onClick: () => onChange({ target: { checked: true } }) } : {})}
  >
    <label
      className={classNames(css.Option__label, {
        [css['Option__label--inlineFlex']]: inlineFlex,
      })}
      style={labelStyle}
    >
      <div
        className={css.Option__item}
      >
        {completedWhenChecked
          ? (
            <span className={css.Option__success}>
              <Icon
                name="completeSolid"
                size={22}
                block
              />
            </span>
          )
          : (
            <>
              <Svg
                indeterminate={indeterminate}
                checked={checked}
                disabled={disabled}
                type={type}
              />
              <input
                disabled={disabled}
                onChange={onChange}
                value={value}
                className={css.Option__input}
                type={type}
                checked={checked}
                data-testid={dataTestid}
              />
            </>
          )}
      </div>
      { children && (
        <div
          className={classNames(css.Option__item, {
            [css['Option__item--srOnly']]: labelHidden,
          })}
        >
          <div className={classNames(css.Option__text, {
            [css['Option__text--disabled']]: disabled,
            [css['Option__text--bold']]: labelBold,
          })}
          >
            {children}
          </div>
        </div>
      )}
      {tertiaryAction.length > 0 && (
        <ButtonGroup>
          {tertiaryAction.map((action) => (
            <Button
              key={`tertiaryAction-${action.label || 'default'}`}
              {...action}
            />
          ))}
        </ButtonGroup>
      )}
    </label>
    {(secondaryContent && !expandWhenChecked)
      && <div className={css.Option__secondary}>{secondaryContent}</div>}
    {(secondaryContent && expandWhenChecked)
      && (
        <TransitionGroup component="div">
          {checked
            && (
              <ExpanderAnimation>
                <div className={css.Option__secondary}>{secondaryContent}</div>
              </ExpanderAnimation>
            )}
        </TransitionGroup>
      )}
  </div>
);
/* eslint-enable jsx-a11y/label-has-for */
Option.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  expandWhenChecked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  inlineFlex: PropTypes.bool,
  labelBold: PropTypes.bool,
  labelHidden: PropTypes.bool,
  onChange: PropTypes.func,
  secondaryContent: PropTypes.node,
  tertiaryAction: PropTypes.arrayOf(actionPropTypes),
  type: PropTypes.oneOf(['radio', 'checkbox']),
  dataTestid: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  optgroup: PropTypes.bool,
  completedWhenChecked: PropTypes.bool,
  labelStyle: PropTypes.object,
  boxView: PropTypes.bool,
};

Option.defaultProps = {
  type: 'checkbox',
  checked: false,
  disabled: false,
  expandWhenChecked: false,
  indeterminate: false,
  inlineFlex: false,
  labelBold: false,
  labelHidden: false,
  onChange: () => {},
  secondaryContent: '',
  tertiaryAction: [],
  optgroup: false,
  dataTestid: 'check-opt',
  completedWhenChecked: false,
  labelStyle: {},
  boxView: false,
};

export default Option;
