import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { onlyUpdateForKeys } from 'recompose';
import css from './Link.scss';

const Link = onlyUpdateForKeys(['children'])(({
  children,
  href,
  onClick,
  target,
}) => {
  const linkClassNames = classNames([css.Link]);
  const Tag = `${href ? 'a' : 'button'}`;
  const noop = () => {};

  return (
    <Tag
      type={!href ? 'button' : undefined}
      target={((target === '_blank') ? '_blank' : '')}
      href={href}
      onClick={onClick || noop}
      className={linkClassNames}
    >
      {children}
    </Tag>
  );
});

Link.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
};

Link.defaultProps = {
  href: null,
  onClick: null,
  target: '_self',
};

export default Link;
