import { Props } from './DataGrid';
import { PreferenceFactory, Preferences } from './preferences.factory';

const version = 1;

const preferenceDefault: PreferenceFactory = {
  load: (props: Props): Preferences => ({
    version,
    tabs: props.tabs.map(({
      id,
    }) => ({
      id,
    })),
  }),
};

export default preferenceDefault;
