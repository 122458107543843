import React from 'react';
import ReactPhoneInput from 'react-phone-number-input';
import TextInput from '../TextInput/TextInput';
import phoneMetaData from '../../../../config/phone-metadata.min.json';

const countries = Object.keys(phoneMetaData.countries);

const PhoneInput = ({ onChange, ...props }) => (
  <ReactPhoneInput
    {...props}
    country="US"
    maskChar=" "
    limitMaxLength
    eventResponseAsValue
    countries={countries}
    mask="(999) 999-9999"
    showCountrySelect={false}
    inputComponent={TextInput}
    onChange={(value = '') => onChange({
      target: {
        value: value.replace(/\(|\)|\ |-/g, ''),
      },
    })}
  />
);

export default PhoneInput;
