
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import CSSModules from 'react-css-modules';

export default class Heading extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const headingStyle = this.props.headingInline ? { display: 'inline' } : {};

        return (
            <div style={headingStyle}><strong>{this.props.children}</strong></div>
        );
    }
}

Heading.propTypes = {
    headingInline: PropTypes.bool,
};

Heading.defaultProps = {
    headingInline: false,
};
