import React from 'react';

const SelectedValueDefault = props => (
    <div className="Select-value">
        <span
            id={props.id}
            className="Select-value-label"
            role="option"
            aria-selected="true">
            {props.children}
        </span>
    </div>
);

export default SelectedValueDefault;
