import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import Expander from '../Expander.jsx';
import HorizontalList from '../../../HorizontalList';
import HorizontalListItem from '../../../HorizontalListItem';
import componentClassnames from './styles/NotificationExpander.scss';
import TinyBand from './TinyBand';
import NotificationBand from './Root';

export default class NotificationExpander extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isExpanded: false,
        };
    }

    getBannerWithIndicator(child) {
        const iconClasses = classNames(componentClassnames.NotificationExpander__icon, {
            [componentClassnames['NotificationExpander__icon--isExpanded']]: this.props.expandUp ? !this.props.expanded : this.props.expanded,
        });
        const body = (
            <HorizontalList
                spacing={1}>
                <HorizontalListItem>{child.props.body}</HorizontalListItem>
                <HorizontalListItem><span className={iconClasses} /></HorizontalListItem>
            </HorizontalList>

        );
        const childWithIndicator = React.cloneElement(child, {
            body,
        });
        return childWithIndicator;
    }

    getChildren() {
        const children = this.props.children;
        const decoratedChildren = []; // sorry for dark variable name
        const self = this;
        const bannerClassnames = classNames(componentClassnames.NotificationExpander__banner, {
            [componentClassnames['NotificationExpander__banner--isExpanded']]: this.props.expandUp ? !this.props.expanded : this.props.expanded,
        });
        React.Children.forEach(children, (child, i) => {
            let decoratedChild = null;
            if (child && child.type) {
                if (child.type === TinyBand) {
                    decoratedChild = (
                        <div onClick={self.props.onBannerClick} key={i} className={bannerClassnames}>{self.getBannerWithIndicator(child)}</div>
                    );
                }
                if (child.type === NotificationBand) {
                    decoratedChild = (
                        <TransitionGroup key={i} component="div">
                            {self.props.expanded &&
                                <Expander>
                                    {child}
                                </Expander>
                            }
                        </TransitionGroup>
                    );
                }
                decoratedChildren.push(decoratedChild);
            }
        });
        return decoratedChildren;
    }

    render() {
        return (
            <div>{this.getChildren()}</div>
        );
    }
}

NotificationExpander.propTypes = {
    expanded: PropTypes.bool,
    expandUp: PropTypes.bool,
};

NotificationExpander.defaultProps = {
    expanded: false,
    expandUp: false,
};
