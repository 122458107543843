import React from 'react';
import PropTypes from 'prop-types';
import AbstractContainer from '../AbstractContainer';

const Body = ({
  children,
  disablePadding,
}) => (
  <AbstractContainer
    flexGrow
    fullHeight
    scroll
    disablePadding={disablePadding}
  >
    {children}
  </AbstractContainer>
);

Body.propTypes = {
  children: PropTypes.node.isRequired,
  disablePadding: PropTypes.bool,
};

Body.defaultProps = {
  disablePadding: false,
};

export default Body;
