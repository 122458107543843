import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { iconList } from '@fieldnation/font/components';
import textContent from 'react-addons-text-content';
import Item from './Item';
import css from './styles.scss';

const IconActions = ({ actions, hasSpacing }) => {
  const iconActionsClasses = classNames(
    css.IconActions,
    {
      [css['IconActions--hasSpacing']]: hasSpacing,
    },
  );

  return (
    <ul className={iconActionsClasses}>
      {actions.map((action) => (
        <li
          key={textContent(action.label)}
        >
          <Item
            {...action}
            hasExtraSpacing={hasSpacing}
          />
        </li>
      ))}
    </ul>
  );
};

IconActions.propTypes = {
  hasSpacing: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.node,
    href: PropTypes.string,
    icon: PropTypes.oneOf(iconList),
    onClick: PropTypes.func,
    target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
    disabled: PropTypes.bool,
  })).isRequired,
};

IconActions.defaultProps = {
  hasSpacing: false,
};

export default IconActions;
