import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InputMask from 'react-input-mask';
import classes from './TextInput.scss';
import { omit } from 'lodash/fp';

const omitProps = omit([
    'internalValidation',
    'eventResponseAsValue',
    'hasAppend',
    'hasDisableTextColor',
    'hasPrepend',
    'hasIconLeft',
    'hasIconRight',
    'onValid',
    'applyFocus',
    'fieldValidation',
]);

export default class TextInput extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        eventResponseAsValue: PropTypes.bool, // This is needed for integration w/ phone input
        hasAppend: PropTypes.bool,
        hasPrepend: PropTypes.bool,
        hasIconLeft: PropTypes.bool,
        hasIconRight: PropTypes.bool,
        mask: PropTypes.string,
        maskChar: PropTypes.string,
        name: PropTypes.string,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onKeyUp: PropTypes.func,
        placeholder: PropTypes.string,
        type: PropTypes.oneOf(['text', 'email', 'search', 'phone', 'number', 'password', 'tel']),
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        applyFocus: PropTypes.bool,
        fieldValidation: PropTypes.oneOf(['success', 'error', '']),
    };

    static defaultProps = {
        disabled: false,
        eventResponseAsValue: false,
        hasAppend: false,
        hasPrepend: false,
        mask: null,
        maskChar: undefined,
        name: '',
        onBlur: () => { },
        onChange: () => { },
        onKeyUp: () => { },
        placeholder: '',
        type: 'text',
        value: '',
        applyFocus: false,
        fieldValidation: '',
    };

    static contextTypes = {
        fieldId: PropTypes.string,
        fieldValidation: PropTypes.oneOf(['', 'error', 'success']),
    };

    applyFocus() {
        const { applyFocus } = this.props;

        if (applyFocus) {
            this.focus();
        }
    }

    componentDidMount() {
        this.applyFocus();
    }

    componentDidUpdate() {
        this.applyFocus();
    }

    focus = () => this.input.focus();

    render() {
        if (this.context.fieldId) {
            const { props, context } = this;
            const {
                mask,
                maskChar,
                ...restProps
            } = props;

            const componentProps = {
                className: classNames(classes.TextInput__elem, {
                    [classes['TextInput__elem--error']]: props.fieldValidation === 'error',
                    [classes['TextInput__elem--hasPrepend']]: props.hasPrepend,
                    [classes['TextInput__elem--hasAppend']]: props.hasAppend,
                    [classes['TextInput__elem--hasIconLeft']]: props.hasIconLeft,
                    [classes['TextInput__elem--hasIconRight']]: props.hasIconRight,
                }),
                id: context.fieldId,
                onChange: props.eventResponseAsValue ?
                    ({ target: { value = '' } = {} }) => props.onChange(value) :
                    props.onChange,
            };

            if (mask || mask === '') {
                return (
                    <InputMask
                        {...omitProps(restProps)}
                        {...componentProps}
                        mask={mask}
                        maskChar={maskChar}
                        inputRef={this.storeInputRef}
                    />
                );
            }
            return (
                <input ref={this.storeInputRef} {...omitProps(restProps)} {...componentProps} />
            );
        }
        return (<div />);
    }

    storeInputRef = ref => this.input = ref;
}
