import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from '../styles/Root.scss';

const Root = ({children, inline, minWidth}) => (
  <div className={classNames([css.Root], {
      [css['Root--inline']]: inline,
  })} style={minWidth ? { minWidth } : {}}>{children}</div>
);

Root.propTypes = {
  children: PropTypes.node.isRequired,
  inline: PropTypes.bool,
  minWidth: PropTypes.string,
};

Root.defaultProps = {
  inline: false,
  minWidth: undefined,
};

export default Root;
