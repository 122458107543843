import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Button,
  ButtonBar,
  Heading,
} from '../index';
import css from './styles.scss';

const TooltipContent = ({ header, body, footerActions }) => {
  const getFooter = (actions) => actions && (
    <ButtonBar menuDisabled>
      {actions.map((action) => (
        <Button
          key={`footerAction-${action.label}`}
          href={action.href}
          target={action.target}
          onClick={action.onClick}
          label={action.label}
          type={action.type}
          disabled={action.disabled}
        />
      ))}
    </ButtonBar>
  );

  return (
    <div className={classNames(css.TooltipContent)}>
      {header
        && (
        <div className={css.TooltipContent__hdr}>
          <Heading
            tag="h3"
            styleLevel="h6"
          >
            {header}
          </Heading>
        </div>
        )}
      <div>
        {body}
      </div>
      {(footerActions && footerActions.length > 0)
        && (
        <div className={css.TooltipContent__ftr}>
          {getFooter(footerActions)}
        </div>
        )}
    </div>
  );
};

TooltipContent.propTypes = {
  header: PropTypes.node,
  body: PropTypes.node,
  footerActions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({
      href: PropTypes.string,
      target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
      onClick: PropTypes.func,
      label: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['primary', 'secondary']),
      disabled: PropTypes.bool,
    })),
    PropTypes.func,
  ]),
};

TooltipContent.defaultProps = {
  header: undefined,
  body: undefined,
  footerActions: undefined,
};

export default TooltipContent;
