import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../IconButton';
import HorizontalList from '../HorizontalList';
import HorizontalListItem from '../HorizontalListItem';

const Pagination = ({
  currentPage,
  totalPages,
  onNext,
  disableNext,
  onPrev,
  disablePrev,
}) => (
  <HorizontalList
    spacing={3}
  >
    {currentPage && totalPages
      && (
      <HorizontalListItem>
        <span>
          Page
          {' '}
          {currentPage}
          {' '}
          of
          {' '}
          {totalPages}
        </span>
      </HorizontalListItem>
      )}
    <HorizontalListItem>
      <HorizontalList>
        <HorizontalListItem>
          <IconButton
            disabled={disablePrev}
            onClick={onPrev && onPrev}
            name="previous"
          />
        </HorizontalListItem>
        <HorizontalListItem>
          <IconButton
            disabled={disableNext}
            onClick={onNext && onNext}
            name="next"
          />
        </HorizontalListItem>
      </HorizontalList>
    </HorizontalListItem>
  </HorizontalList>
);

Pagination.propTypes = {
  currentPage: PropTypes.number,
  disableNext: PropTypes.bool,
  disablePrev: PropTypes.bool,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  totalPages: PropTypes.number,
};

Pagination.defaultProps = {
  currentPage: null,
  disableNext: false,
  disablePrev: false,
  onNext: () => {},
  onPrev: () => {},
  totalPages: null,
};

export default Pagination;
