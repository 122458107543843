import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { filtersPropTypes } from './ToolbarPropTypes';
import SkinlessPopover from '../SkinlessPopover';
import MoreMenuItems from './MoreMenuItems';
import css from './MoreMenu.scss';

const MoreMenu = (props) => {
  const {
    trigger,
    filters,
    onApplyFilter,
    onRemoveFilter,
  } = props;

  return (
    <SkinlessPopover
      classes={{
        element: classNames(
          css.MoreMenu__menuWrapper,
        ),
      }}
      showOnEvent="click"
      targetAttachment="top left"
      otherChildrenClasses="filter-edit-panel"
    >
      {trigger}
      <div className={css.MoreMenu__menu}>
        <MoreMenuItems
          filters={filters}
          onApplyFilter={onApplyFilter}
          onRemoveFilter={onRemoveFilter}
        />
      </div>
    </SkinlessPopover>
  );
};

MoreMenu.propTypes = {
  trigger: PropTypes.node.isRequired,
  filters: filtersPropTypes,
  onApplyFilter: PropTypes.func.isRequired,
  onRemoveFilter: PropTypes.func.isRequired,
};

MoreMenu.defaultProps = {
  filters: [],
};

export default MoreMenu;
