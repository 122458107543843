import React, { Component } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';

export default class TimeDuration extends Component {
    static propTypes = {
        seconds: PropTypes.number,
    };

    static defaultProps = {
        seconds: 0,
    };

    constructor(props) {
        super(props);
    }

    convertSecondIntoTimeDuration() {
        let totalSeconds = parseInt(this.props.seconds, 10);
        const timeStr = [];

        const months = Math.floor(totalSeconds / (3600 * 24 * 30));
        if (months > 0) timeStr.push(`${months} month${months > 1 ? 's ' : ''}`);

        totalSeconds %= (3600 * 24 * 30);
        const days = Math.floor(totalSeconds / (3600 * 24));
        if (days > 0) timeStr.push(`${days} day${days > 1 ? 's ' : ''}`);

        totalSeconds %= (3600 * 24);
        const hours = Math.floor(totalSeconds / 3600);
        if (hours > 0) timeStr.push(`${hours} hour${hours > 1 ? 's ' : ''}`);

        totalSeconds %= 3600;
        const minutes = Math.floor(totalSeconds / 60);
        if (minutes > 0) timeStr.push(`${minutes} minute${minutes > 1 ? 's ' : ''}`);

        return timeStr.join(' ');
    }

    render() {
        return <span>{this.convertSecondIntoTimeDuration()}</span>;
    }
}
