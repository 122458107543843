import React from 'react';
import PropTypes from 'prop-types';
import Group from '../Group/Group';
import Label from '../Label/Label';
import HelpText from '../HelpText/HelpText';
import CreditCardInput from '../CreditCardInput';
import withDeprecation from '../../../../utils/hoc/withDeprecation';

/**
 * @deprecated: Instead use a CreditCardInput directly with custom validation messaging parameters.
 */
const CreditCardField = ({ label, value, onChange, validation, error }) => {
  return (
    <Group validation={validation}>
      <Label>{label}</Label>
      <CreditCardInput value={value} onChange={onChange} />
      {error && <HelpText>{error}</HelpText>}
    </Group>
  );
}

CreditCardField.defaultProps = {
  label: 'Credit Card Number',
  validation: '',
};

CreditCardField.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  validation: PropTypes.oneOf(['', 'error']),
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
};

export default withDeprecation(
  CreditCardField,
  'Use CreditCardInput directly',
);
