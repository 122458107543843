/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import classes from './Notification.scss';
import Icon from '../../Icon';
import Truncate from '../../Truncate';
import Menu from '../../Menu';
import getDateTime from './util';
import NotificationType from './NotificationType';

const Notification = ({
  notification, onClick, readNotification, deleteNotification, isRead,
}) => {
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    setShowMessage(notification?.work_order?.available_status !== 'No longer available');
  }, [notification?.work_order?.available_status]);

  function getCaseNotificationTitle(title = 'Message', caseId = '') {
    return (
      <div>
        {title}
        <span className={classes.WOId}>
          {' \u00B7 '}
          Case
          {' '}
          {caseId}
        </span>
      </div>
    );
  }

  function notificationClicked() {
    if (!onClick || notification?.work_order?.available_status === 'No longer available') return;
    onClick();
  }

  const getTitle = (item) => {
    if (Object.values(NotificationType).includes(item?.notification_type) && item?.case_id) {
      return getCaseNotificationTitle(item?.title, item?.case_id);
    }

    if (!item?.work_order?.id) { return item?.title; }
    return (
      <div>
        {item?.title}
        {(item?.work_order?.available_status && item?.work_order?.available_status !== 'No longer available') ? (
          <span className={classes.AvailableStatus}>
            {' \u00B7 '}
            {item?.work_order?.available_status}
          </span>
        ) : (
          <span className={classes.WOId}>
            {' \u00B7 '}
            WO
            {' '}
            {item?.work_order?.id}
          </span>
        )}
      </div>
    );
  };

  function showDetails(e) {
    e.stopPropagation();
    setShowMessage(true);
  }

  const getNotificationText = () => {
    const availableStatus = notification?.work_order?.available_status;
    return (
      <div>
        {availableStatus === 'No longer available' && (
        <span className={classes.NoLongerStatus}>
          {`${availableStatus}. `}
        </span>
        )}
        {showMessage ? notification?.message
          : <span onClick={showDetails} className={classes.ShowDetails}>Show details</span>}
      </div>
    );
  };

  return (
    <div className={classes.ItemContent}>
      <div className={classes.ReadUnReadArea}>
        <div className={classes.Dot}>
          <div className={!isRead ? classes.Unread : classes.Read} />
        </div>
      </div>
      <section className={classes.ItemArea} onClick={notificationClicked}>
        <div className={classes.ThumbnailArea}>
          <div className={classes.User}>
            <div
              className={classes.Thumb}
              style={{
                backgroundImage: `url(${notification?.author?.thumbnail})`,
              }}
            />
          </div>
        </div>
        <div className={classes.MessageArea}>
          <div className={classes.MessageAreaHeader}>
            <div className={classes.Title}>
              <Truncate maxLines={1} text={getTitle(notification)} />
            </div>
          </div>
          <div className={classes.ItemText}>
            {getNotificationText()}
          </div>
        </div>
      </section>
      <div className={classes.ActionArea}>
        <div className={classes.Timestamp}>
          {getDateTime(notification?.created?.utc)}
        </div>
        <div className={classes.Action}>
          <Menu
            minWidth="160"
            alignToRight
            closeOnSelection
            menuItems={[
              isRead ? {} : {
                label: 'Mark as read',
                icon: 'check',
                onClick: () => { readNotification(notification); },
              },
              {
                label: 'Delete',
                icon: 'deleteSolid',
                onClick: () => { deleteNotification(notification); },
              },
            ]}
            trigger={(
              <div>
                <Icon name="moreHoriz" />
              </div>
                    )}
          />
        </div>
      </div>
    </div>
  );
};
Notification.propTypes = {
  notification: PropTypes.object,
  onClick: PropTypes.func,
  readNotification: PropTypes.func,
  deleteNotification: PropTypes.func,
  isRead: PropTypes.bool,
};

Notification.defaultProps = {
  notification: {},
  onClick: () => {},
  readNotification: () => {},
  deleteNotification: () => {},
  isRead: false,
};
export default Notification;
