import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import ClassNames from 'classnames';
import css from './FilterSearch.scss';
import Search from '../Search';
import { filtersPropTypes } from './ToolbarPropTypes';
import wrapEventHandler, { ARG_ORDER } from '../utils/wrapEventHandler';

class FilterSearch extends React.Component {
  constructor(props) {
    super(props);

    const { filters } = props;

    this.state = {
      search: '',
      filters,
    };
    this.guardHandler = this.guardHandler.bind(this);
    this.searchHandler = this.searchHandler.bind(this);
  }

  guardHandler(filter, event) {
    if (event.key === 'Enter') {
      const { onClick } = this.props;
      onClick(filter);
    }
  }

  searchHandler(event) {
    const { target } = event;
    this.setState({ search: target.value });
  }

  render() {
    const { onClick, filterSections } = this.props;
    const {
      search = '',
      filters,
    } = this.state;

    const availableFilters = filters
      .filter((filter) => (search.length
        ? filter.name.toLowerCase().includes((search).toLowerCase()) : true))
      .reduce((acc, current) => {
        const { section = 0 } = current;
        if (acc[section]) {
          acc[section].push(current);
        } else {
          acc[section] = [current];
        }
        return acc;
      }, [])
      .map((section, index) => {
        const sectionName = filterSections?.find?.((fs) => fs?.id === index);
        return (
          <div
            key={uuid()}
            className={css.FilterSearch__filterSection}
          >
            {
              sectionName
              && (
                <div className={css.FilterSearch__filterSection__Name}>
                  {sectionName.name}
                </div>
              )
            }
            {
              section.map((filter) => (
                <div
                  key={`filterOption__${filter.id}`}
                  className={ClassNames(css.FilterSearch__filterOption, {
                    [css.FilterSearch__filterOption__Section__Name__Space]:
                      Boolean(sectionName),
                  })}
                  onClick={wrapEventHandler(onClick, [filter], ARG_ORDER.EVENT_LAST)}
                  onKeyUp={wrapEventHandler(this.guardHandler, [filter], ARG_ORDER.EVENT_LAST)}
                  tabIndex="0"
                  role="button"
                >
                  {filter.name}
                </div>
              ))
            }
          </div>
        );
      });

    return (
      <div className={css.FilterSearch__filterPanel}>
        <div className={css.FilterSearch__filterSearch}>
          <Search
            disableAutoComplete
            disableCloseIconEvent
            placeholder="Find Filter..."
            onChange={wrapEventHandler(this.searchHandler, [], ARG_ORDER.EVENT_FIRST, this)}
            value={search}
            iconDirection="right"
            onClearSearch={wrapEventHandler(this.setState, [{ search: '' }], ARG_ORDER.OMIT, this)}
            applyFocus
          />
        </div>
        <div className={css.FilterSearch__filterOptions}>
          {
            availableFilters.length
              ? availableFilters
              : (
                <div className={css.FilterSearch__filtersEmpty}>
                  {search.length ? 'No filters match your search!' : 'No filters'}
                </div>
              )
          }
        </div>
      </div>
    );
  }
}

FilterSearch.propTypes = {
  filters: filtersPropTypes.isRequired,
  onClick: PropTypes.func,
  filterSections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
};

FilterSearch.defaultProps = {
  onClick: () => { },
  filterSections: [],
};

export default FilterSearch;
