export enum ARG_ORDER {
  EVENT_FIRST = 0,
  ARGS_FIRST = 0,
  EVENT_LAST = 1,
  ARGS_LAST = 1,
  OMIT_ARGS = 2,
  OMIT = 2,
}

/**
 * Allows composition of an event handler function with params.
 * Use in place of `onClick` and `onChange` handlers in React JSX
 * Here is how they usually appear with an arrow function in JSX (bad):
 *  <MyComponent onClick={(e) => this.handleEvent(e, param1, param2)} />
 *
 * Convert using `wrapEventHandler`:
 * const proxyHandler = wrapEventHandler(this.handleEvent, [param1, param2], false, this);
 *  <MyComponent onClick={proxyHandler} />
 *
 * When the onClick handler is activated, the event will pass through.
 * Note, `this` context is optional.
 */
const wrapEventHandler = (
  fn: any,
  params: Array<any>,
  argOrder: ARG_ORDER | boolean,
  thisArg?: any,
) => (arg1: any, arg2?: any, arg3?: any) => {
  let allParams: Array<any> = [];
  const newParams = [arg1];
  if (arg2 != null) {
    newParams.push(arg2);
  }
  if (arg3 != null) {
    newParams.push(arg3);
  }
  switch (argOrder) {
    case ARG_ORDER.ARGS_LAST:
    case ARG_ORDER.EVENT_LAST:
    case true:
      allParams = [...params, ...newParams];
      break;
    case ARG_ORDER.OMIT_ARGS:
    case ARG_ORDER.OMIT:
      allParams = [...params];
      break;
    case ARG_ORDER.ARGS_FIRST:
    case ARG_ORDER.EVENT_FIRST:
    case false:
    default:
      allParams = [...newParams, ...params];
  }
  if (thisArg) {
    return fn.apply(thisArg, allParams);
  }
  return fn(...allParams);
};

export default wrapEventHandler;
