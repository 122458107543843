
import React, { Component } from 'react';
import Heading from '../../../Heading';

export default class PanelHeading extends Component {
    render() {
        return (
            <Heading
                tag="h1"
                styleLevel="h2"
            >
                {this.props.children}
            </Heading>
        );
h2}
}
