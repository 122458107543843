import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { IconButton, Tooltip } from '..';
import TooltipContainer from '../TooltipComponent';
import { slideType } from './types';
import wrapEventHandler, { ARG_ORDER } from '../utils/wrapEventHandler';
import Css from './styles.scss';

export default class Pagination extends PureComponent {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    onPageChange: PropTypes.func,
    pageLimit: PropTypes.number,
    pages: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      slides: PropTypes.arrayOf(slideType),
    })),
    perPage: PropTypes.oneOf([1, 4, 16]),
    totalPages: PropTypes.number,
  }

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    onPageChange: () => {},
    pageLimit: 8,
    pages: [],
    perPage: 1,
    totalPages: 1,
  }

  constructor(props) {
    super(props);
    const {
      onPageChange = () => {},
      pages = [],
      totalPages = 1,
    } = props;
    const currentPage = (pages.find(
      (page) => page.slides.find((slide) => slide.selected),
    ) || {}).id || 1;
    onPageChange({ page: this.getPage({ currentPage, pages }) });
    this.state = {
      currentPage,
      firstPage: 1,
      lastPage: totalPages,
    };
    this.execAction = this.execAction.bind(this);
  }

  execAction(action, args) {
    const { onPageChange } = this.props;

    // eslint-disable-next-line default-case
    switch (action) {
      case 'page':
        this.setState({ currentPage: args.page.id }, onPageChange({ page: args.page }));
        break;
      case 'previous':
        this.setState({ currentPage: this.getPrevPage({ currentPage: args.currentPage }) },
          () => onPageChange({
            page: this.getPage({ currentPage: this.getPrevPage({ currentPage: args.currentPage }), pages: args.pages }),
          }));
        break;
      case 'next':
        this.setState(
          { currentPage: this.getNextPage({ currentPage: args.currentPage }) },
          () => onPageChange({
            page: this.getPage({ currentPage: this.getNextPage({ currentPage: args.currentPage }), pages: args.pages }),
          }),
        );
        break;
    }
  }

  getAfterPages = ({
    currentPage, lastPage, pageLimit, pages,
  }) => pages.slice(
    this.getEndPage({ currentPage, pageLimit }), lastPage - 1,
  )

  getActivePages = ({ currentPage, pageLimit, pages }) => pages.slice(
    this.getStartPage({ currentPage, pageLimit }),
    this.getEndPage({ currentPage, pageLimit }),
  )

  getBeforePages = ({
    currentPage, firstPage, pageLimit, pages,
  }) => pages.slice(
    firstPage, this.getStartPage({ currentPage, pageLimit }),
  )

  getDottedLinks = ({
    currentPage, onPageChange, pages, perPage,
  }) => (
    <Tooltip
      position="top"
      showOnEvent="click"
    >
      <button
        className={ClassNames({
          [Css.Carousel__Pagination__Links__Link]: true,
          [Css['Carousel__Pagination__Links__Link--IsDotted']]: true,
        })}
        type="button"
      >
        {' '}
      </button>
      <TooltipContainer verticalScrollHeight={20}>
        <div className={Css['Carousel__Pagination__Links--IsInDot']}>
          {pages.map((page) => this.getLink({
            currentPage, isInDot: true, onPageChange, page, perPage,
          }))}
        </div>
      </TooltipContainer>
    </Tooltip>
  )

  getEndPage = ({ currentPage, pageLimit }) => [
    currentPage % pageLimit === 0
      ? currentPage
      : Math.ceil(currentPage / pageLimit) * pageLimit,
  ].find((i) => i)

  getLink = ({
    currentPage, isInDot, page, perPage,
  }) => {
    const slide = page?.slides?.find(({ color }) => !!color);
    return (
      <button
        className={ClassNames({
          [Css.Carousel__Pagination__Links__Link]: true,
          [Css['Carousel__Pagination__Links__Link--IsInDot']]: isInDot,
          [Css['Carousel__Pagination__Links__Link--Active']]:
            page.id === currentPage,
          [Css[`Carousel__Pagination__Links__Link--BgColor--${slide?.color}`]]:
            perPage === 1 && !!slide?.color,
        })}
        key={page.id}
        onClick={wrapEventHandler(this.execAction, ['page', { page }], ARG_ORDER.OMIT, this)}
        type="button"
      >
        {page.id}
      </button>
    );
  }

  getNextPage = ({ currentPage }) => currentPage + 1

  getPage = ({ currentPage, pages }) => pages.find((page) => page.id === currentPage)

  getPrevPage = ({ currentPage }) => currentPage - 1

  getStartPage = ({ currentPage, pageLimit }) => [
    currentPage % pageLimit === 0
      ? currentPage - pageLimit
      : Math.floor(currentPage / pageLimit) * pageLimit,
  ].find((i) => i >= 0)

  isFirstPage = ({ currentPage, firstPage }) => currentPage === firstPage

  isLastPage = ({ currentPage, lastPage }) => currentPage === lastPage

  render() {
    const {
      currentPage,
      firstPage,
      lastPage,
    } = this.state;
    const {
      onPageChange,
      pageLimit,
      pages,
      perPage,
    } = this.props;
    const beforePages = this.getBeforePages({
      currentPage, firstPage, pageLimit, pages,
    });
    const activePages = this.getActivePages({ currentPage, pageLimit, pages });
    const afterPages = this.getAfterPages({
      currentPage, lastPage, pageLimit, pages,
    });
    return (
      <div className={Css.Carousel__Pagination}>
        <IconButton
          disabled={this.isFirstPage({ currentPage, firstPage })}
          name="previous"
          onClick={wrapEventHandler(this.execAction, ['previous', { currentPage, pages }], ARG_ORDER.OMIT, this)}
        />
        <div className={Css.Carousel__Pagination__Links}>
          {currentPage > pageLimit && this.getLink({
            currentPage,
            isInDot: false,
            onPageChange,
            page: this.getPage({ currentPage: firstPage, pages }),
            perPage,
          })}
          {beforePages.length > 0 && this.getDottedLinks({
            currentPage,
            onPageChange,
            pages: beforePages,
            perPage,
          })}
          {activePages.map((page) => this.getLink({
            currentPage,
            isInDot: false,
            onPageChange,
            page,
            perPage,
          }))}
          {afterPages.length > 0 && this.getDottedLinks({
            currentPage,
            onPageChange,
            pages: afterPages,
            perPage,
          })}
          {currentPage <= this.getStartPage({ currentPage: lastPage, pageLimit }) && this.getLink({
            currentPage,
            isInDot: false,
            onPageChange,
            page: this.getPage({ currentPage: lastPage, pages }),
            perPage,
          })}
        </div>
        <IconButton
          disabled={this.isLastPage({ currentPage, lastPage })}
          name="next"
          onClick={wrapEventHandler(this.execAction, ['next', { currentPage, pages }], ARG_ORDER.OMIT, this)}
        />
      </div>
    );
  }
}
