import React from 'react';
import PropTypes from 'prop-types';
import ActiveFilter from './ActiveFilter';
import { filterPropTypes } from './ToolbarPropTypes';

const MoreMenuItem = (props) => {
  const {
    filter,
    onApplyFilter,
    onRemoveFilter,
  } = props;
  return (
    <ActiveFilter
      openImmediately={false}
      attachment="top right"
      targetAttachment="top left"
      onRemoveFilter={onRemoveFilter}
      filter={filter}
      onSubmit={onApplyFilter}
      type="list"
    />
  );
};

MoreMenuItem.propTypes = {
  filter: filterPropTypes.isRequired,
  onApplyFilter: PropTypes.func.isRequired,
  onRemoveFilter: PropTypes.func.isRequired,
};

export default MoreMenuItem;
