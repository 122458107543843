import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BigCalendar from 'react-big-calendar';
// Drag-n-drop will be enabled when ui is updated to react 16.3
// import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import moment from 'moment';
import classNames from 'classnames';
import get from 'lodash/get';
import Toolbar from './Toolbar';
import Tooltip from './Tooltip';
import TooltipContent from '../TooltipContent';
import getElementOffsetFromDocument from '../utils/getElementOffsetFromDocument';
import './styles.global.scss';
import IconActions from '../IconActions';
import ActionsWrapper from './ActionsWrapper';
import CustomEvent from './CustomEvent';
import css from './styles.scss';

const momentLocalizer = BigCalendar.momentLocalizer(moment);
const defaultComponents = {
  toolbar: Toolbar,
};

const PLACE_HOLDER_EVENT_ID = 'PLACE_HOLDER_EVENT_ID';

const eventPropGetter = (event) => {
  const { type, color = 'blue-100' } = event;

  const classes = classNames({
    [css[`Calendar__event--${color}`]]: type === 'unavailable' || type === 'unconfirmed' || type === 'confirmed',
    [css['Calendar__event--unavailable']]: type === 'unavailable',
    [css['Calendar__event--placeholder']]: type === 'placeholder',
    [css['Calendar__event--unconfirmed']]: type === 'unconfirmed',
    [css[`Calendar__event--unconfirmed--${color}`]]: ['purple', 'red'].includes(color) && type === 'unconfirmed',
  });
  return {
    className: classes,
    style: {},
  };
};

const realEvents = (event) => event.id !== PLACE_HOLDER_EVENT_ID;

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: props.events,
      tooltip: {
        content: '',
        isOpen: false,
        top: 0,
        left: 0,
      },
    };
    this.onSelectSlot = this.onSelectSlot.bind(this);
    this.onSelectEvent = this.onSelectEvent.bind(this);
    this.hideTooltip = this.hideTooltip.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { events } = this.props;
    if (events !== nextProps.events) {
      this.setState({ events: nextProps.events });
    }
  }

  componentWillUnmount() {
    if (this.showTooltipTimer) {
      clearTimeout(this.showTooltipTimer);
    }
  }

  hideTooltip() {
    const placeholderFound = (events) => (
      events.find((event) => event.id === PLACE_HOLDER_EVENT_ID)
    );
    this.setState((state) => ({
      events: placeholderFound(state.events)
        ? [...state.events.filter((event) => realEvents(event))]
        : [...state.events],
      tooltip: {
        isOpen: false,
        content: null,
        top: 0,
        left: 0,
        position: undefined,
        showClose: false,
        showOverlay: false,
      },
    }));
  }

  showTooltip(content, top, left, width, height, position, callback, showClose, showOverlay) {
    // use timer to avoid race condition with hide tooltip
    this.showTooltipTimer = setTimeout(() => {
      this.setState({
        tooltip: {
          content,
          top,
          left,
          width,
          height,
          isOpen: true,
          position,
          showClose,
          showOverlay,
        },
      });
      if (callback && typeof callback === 'function') {
        callback();
      }
    }, 50);
  }

  showPlaceholderEvent(calendarEvent) {
    const {
      resourceIdAccessor,
    } = this.props;
    this.setState((state) => ({
      events: [
        ...state.events.filter((event) => realEvents(event)),
        {
          start: calendarEvent.start,
          end: calendarEvent.end,
          id: PLACE_HOLDER_EVENT_ID,
          [resourceIdAccessor]: get(calendarEvent, `${resourceIdAccessor}`, 0),
          type: 'placeholder',
        },
      ],
    }));
  }

  onSelectSlot(calendarEvent) {
    const {
      onSelectSlot,
      onSelectSlotActions,
      hideTooltipOverlay,
    } = this.props;
    // proxy through standard prop
    if (typeof onSelectSlot === 'function') {
      onSelectSlot(calendarEvent);
    }

    if (onSelectSlotActions.length !== 0) {
      const { box, bounds } = calendarEvent;
      const rect = box || bounds;
      if (rect) {
        // callback needs to happen async from showTooltip timer
        const callback = () => { this.showPlaceholderEvent(calendarEvent); };
        this.showTooltip(
          (
            <ActionsWrapper>
              <IconActions
                actions={onSelectSlotActions.map((action) => ({
                  onClick: (event) => {
                    this.hideTooltip();
                    action.onClick(event, calendarEvent);
                  },
                  label: action.label,
                  icon: action.icon,
                }))}
              />
            </ActionsWrapper>
          ),
          rect.top || rect.y,
          rect.left || rect.x,
          0,
          0,
          'top',
          callback,
          false,
          !hideTooltipOverlay,
        );
      }
    }
  }

  onSelectEvent(calendarEvent, e) {
    const { onSelectEvent, onSelectEventDisplay } = this.props;
    // proxy through standard prop
    if (typeof onSelectEvent === 'function') {
      onSelectEvent(calendarEvent, e);
    }
    if (onSelectEventDisplay && get(calendarEvent, 'type') !== 'placeholder') {
      const $target = e.currentTarget;
      const width = $target.offsetWidth;
      const height = $target.offsetHeight;
      const offset = getElementOffsetFromDocument($target);
      this.showTooltip(
        <TooltipContent
          header={onSelectEventDisplay.header(calendarEvent)}
          body={onSelectEventDisplay.body(calendarEvent)}
          footerActions={onSelectEventDisplay.footerActions(calendarEvent)}
        />,
        offset.top,
        offset.left,
        width,
        height,
        'right',
        undefined,
        true,
        false,
      );
    }
  }

  render() {
    const { props } = this;
    const {
      localizer,
      formats,
      components,
      scrollHeight,
      ...remainingProps
    } = props;

    const {
      events,
    } = this.state;

    const {
      tooltip: {
        top,
        left,
        width,
        height,
        position,
        isOpen,
        content,
        showClose,
        showOverlay,
      },
    } = this.state;
    return (
      <div>
        {isOpen
          && (
            <Tooltip
              position={position}
              onDismiss={this.hideTooltip}
              top={top}
              left={left}
              width={width}
              height={height}
              showClose={showClose}
              showOverlay={showOverlay}
            >
              {content}
            </Tooltip>
          )}
        <BigCalendar
          formats={{
            weekdayFormat: 'dddd',
            dateFormat: 'D',
            dayFormat: 'ddd',
            timeGutterFormat: 'h A',
            dayHeaderFormat: 'dddd, MMMM D, YYYY',
            dayRangeHeaderFormat: ({ start, end }, culture, local) => {
              const startDate = new Date(start);
              const endDate = new Date(end);
              // span across year
              if (startDate.getFullYear() !== endDate.getFullYear()) {
                return (
                  `${local.format(start, 'MMMM D, YYYY', culture)} - ${local.format(end, 'MMMM D, YYYY', culture)}`
                );
                // span across month
              } if (startDate.getMonth() !== endDate.getMonth()) {
                return (
                  `${local.format(start, 'MMMM D', culture)} - ${local.format(end, 'MMMM D, YYYY', culture)}`
                );
              }
              // same month
              return (
                `${local.format(start, 'MMMM D', culture)} - ${local.format(end, 'D, YYYY', culture)}`
              );
            },
            ...formats,
          }}
          localizer={momentLocalizer}
          step={30}
          timeslots={2}
          eventPropGetter={eventPropGetter}
          {...remainingProps}
          events={events}
          onSelectSlot={this.onSelectSlot}
          onSelectEvent={this.onSelectEvent}
          {...(scrollHeight && { style: { height: scrollHeight } })}
          className={classNames('fn-calendar', {
            'fn-calendar-selectable': remainingProps.selectable,
            'fn-time-gutter-style': remainingProps.timeGutterStyle,
          })}
          components={{
            event: CustomEvent,
            ...defaultComponents,
            ...components,
          }}
        />
      </div>
    );
  }
}

Calendar.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
  formats: PropTypes.objectOf(PropTypes.oneOf([
    PropTypes.string,
    PropTypes.object,
  ])),
  onSelectEvent: PropTypes.func,
  onSelectEventDisplay: PropTypes.shape({
    header: PropTypes.func,
    body: PropTypes.func,
    footerActions: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({
        href: PropTypes.string,
        target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
        onClick: PropTypes.func,
        label: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['primary', 'secondary']),
        disabled: PropTypes.bool,
      })),
      PropTypes.func,
    ]),
  }),
  onSelectSlot: PropTypes.func,
  onSelectSlotActions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    icon: PropTypes.string,
  })),
  resourceIdAccessor: PropTypes.string,
  views: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ])),
  hideTooltipOverlay: PropTypes.bool,
  timeGutterStyle: PropTypes.bool,
  localizer: PropTypes.func,
  components: PropTypes.node,
  scrollHeight: PropTypes.number,
};

Calendar.defaultProps = {
  formats: {},
  onSelectEvent: undefined,
  onSelectEventDisplay: undefined,
  onSelectSlot: undefined,
  onSelectSlotActions: [],
  resourceIdAccessor: undefined,
  views: ['day', 'week', 'month'],
  hideTooltipOverlay: false,
  timeGutterStyle: false,
  localizer: undefined,
  components: undefined,
  scrollHeight: undefined,
};

// Calendar.withDragAndDrop = withDragAndDrop;
Calendar.Views = BigCalendar.Views;

export default Calendar;
