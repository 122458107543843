import React from 'react';
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';

export default function handle(props) {
    const { value, dragging, index, ...restProps } = props;
    return (
        <Tooltip
            prefixCls="rc-slider-tooltip"
            overlay={value}
            visible={dragging}
            placement="top"
            key={index}>
            <Slider.Handle value={value} {...restProps} />
        </Tooltip>
    );
}
