import React from 'react';
import PropTypes from 'prop-types';
import classes from './InputBox.scss';
import Icon from '../../Icon';
import Truncate from '../../Truncate';
import { getNonImageIconNameByMimeType } from '../../utils/getAttachmentIconName';

const InputBox = ({
  value,
  onChange,
  onSubmit,
  disabled,
  onKeyDown,
  attachmentEnabled,
  attachments,
  onChangeAttachments,
  onRemoveAttachment,
  showCharCount,
  characterLimit,
  inputPlaceholder,
}) => {
  function handleChange(event) {
    const inputValue = event.target.value;
    if (showCharCount && inputValue.length > characterLimit) {
      return;
    }
    onChange(inputValue);
  }

  function handleKeyDown(event) {
    onKeyDown(event);
  }

  function handleClick() {
    if (!value.trim()) return;
    onSubmit();
  }

  const charCount = showCharCount ? `${value.length}/${characterLimit}` : '';
  const imageAttachments = [];
  const nonImageAttachments = [];
  attachments.forEach(
    (attachment) => {
      if (typeof attachment.mimeType !== 'string') return;
      if ([
        'image/jpeg',
        'image/png',
        'image/gif',
        'image/webp',
        'image/bmp',
        'image/svg+xml',
      ].includes(attachment.mimeType)) {
        imageAttachments.push(attachment);
      } else {
        nonImageAttachments.push(attachment);
      }
    },
  );

  return (
    <div className={classes.MainMessage}>
      <div className={classes.Form}>
        <textarea
          className={`${classes.Input} ${showCharCount ? classes.InputWithCharCount : ''} ${attachmentEnabled ? classes.InputAttachmentSpacing : ''}`}
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder={inputPlaceholder || 'Message'}
          disabled={disabled}
          rows={2}
        />
        {(attachmentEnabled && attachments?.length > 0) && (
          <div className={classes.AttachmentScroll}>
            <div className={classes.AttachmentImageContainer}>
              {imageAttachments.map((attachment) => (
                <div className={classes.AttachmentBlock}>
                  <img className={classes.AttachmentImage} src={attachment.src} alt={attachment.name} />
                  <div
                    className={classes.AttachmentRemoveImageIcon}
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                    onClick={() => onRemoveAttachment(attachment)}
                  >
                    <Icon name="cancelSolid" size={22} />
                  </div>
                </div>
              ))}
            </div>
            {(nonImageAttachments.length > 0) && (
              <div className={classes.AttachmentNonImageContainer}>
                {nonImageAttachments.map((attachment) => (
                  <div className={classes.AttachmentNonImage}>
                    <div className={classes.AttachmentName}>
                      <div className={`Attachment_Icon--${getNonImageIconNameByMimeType(attachment?.mimeType || '')}`}>
                        <Icon name={getNonImageIconNameByMimeType(attachment?.mimeType || '')} color="red" size={24} />
                      </div>
                      <div className={classes.AttachmentNameContainer}>
                        <Truncate text={attachment.name} maxLines={1} wordBreak="break-all" />
                      </div>
                    </div>
                    <div
                      className={classes.AttachmentRemoveNonImageIcon}
                      role="button"
                      tabIndex={0}
                      onKeyDown={() => {}}
                      onClick={() => onRemoveAttachment(attachment)}
                    >
                      <Icon name="cancelSolid" size={22} />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      <div className={`${classes.ButtonContainer} ${attachmentEnabled ? '' : classes.SendPad}`}>
        {showCharCount && (
          <div className={classes.MessageCharCount}>{charCount}</div>
        )}
        <div className={attachmentEnabled ? classes.InputButtonWrapper : ''}>
          <div className={`${classes.IconFlex} ${showCharCount ? classes.IconWithCharCount : ''}`}>
            {attachmentEnabled && (
              <div
                className={`${classes.Icon} ${showCharCount ? classes.IconWithCharCount : ''}`}
              >
                <label className={classes.AttachmentLabel}>
                  <input
                    type="file"
                    multiple
                    onChange={onChangeAttachments}
                    accept="*"
                  />
                  <Icon
                    name="attachment"
                    size={26}
                  />
                </label>
              </div>
            )}
            <div
              role="button"
              tabIndex={0}
              onClick={handleClick}
              onKeyDown={handleKeyDown}
              className={`${classes.Icon} ${showCharCount ? classes.IconWithCharCount : ''}`}
            >
              <Icon name="send" size={24} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

InputBox.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onKeyDown: PropTypes.func,
  attachmentEnabled: PropTypes.bool,
  attachments: PropTypes.array,
  onChangeAttachments: PropTypes.func,
  onRemoveAttachment: PropTypes.func,
  showCharCount: PropTypes.bool,
  characterLimit: PropTypes.number,
  inputPlaceholder: PropTypes.string,
};

InputBox.defaultProps = {
  value: '',
  disabled: false,
  onChange: () => {},
  onSubmit: () => {},
  onKeyDown: () => {},
  attachmentEnabled: false,
  attachments: [],
  onChangeAttachments: () => {},
  onRemoveAttachment: () => {},
  showCharCount: false,
  characterLimit: 0,
  inputPlaceholder: '',
};

export default InputBox;
