import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import classes from './styles.scss';
import getKeyFromChildContent from '../utils/getKeyFromChildContent';

class Table extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      tableBodyWidth: 'auto',
    };

    this.tableBody = null;
    this.tableHead = null;
    this.tableFooter = React.createRef();
    this.scrollLeft = 0; // this isn't kept in state because scroll is set directly

    this.onScroll = this.onScroll.bind(this);
    this.updateScrollPosition = this.updateScrollPosition.bind(this);
    this.setScrollBarWidth = this.setScrollBarWidth.bind(this);
  }

  componentDidMount() {
    this.setScrollBarWidth();

    // Update width when window size changes
    window.addEventListener('resize', this.setScrollBarWidth);
  }

  componentDidUpdate() {
    this.updateScrollPosition();
    this.setScrollBarWidth();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setScrollBarWidth);
  }

  getChildren() {
    const getInnerChildrenFromParent = (parent) => {
      const { children } = parent.props;

      return React.cloneElement(
        parent,
        {
          children: React.Children.map(children, (child, i) => (
            React.cloneElement(
              child,
              {
                isNotFirstRow: i !== 0,
                applyDistinctBg: !child.props.applyDistinctBg ? false : (i % 2) === 0 && child.props.highlightColor === '',
              },
            )
          )),
        },
      );
    };

    const { children } = this.props;
    const { onScroll } = this;

    return React.Children.map(children, (child) => {
      if (child && child.type) {
        if (child.type.name) {
          const { displayName } = child.type;

          if (displayName === 'TableBody' || displayName === 'TableHead') {
            return React.cloneElement(
              displayName === 'TableBody' ? getInnerChildrenFromParent(child) : child,
              {
                ref: (node) => {
                  if (displayName === 'TableBody') {
                    this.tableBody = node;
                  } else {
                    this.tableHead = node;
                  }
                },
                key: getKeyFromChildContent(child),
                onScroll: displayName === 'TableBody' ? onScroll : undefined,
              },
            );
          }
        }
      }
      return child;
    });
  }

  setScrollBarWidth() {
    if (this.tableBody) {
      const tbody = get(this.tableBody, 'tbody.current');

      if (tbody) {
        const widthPixels = tbody.scrollWidth;

        const { tableBodyWidth } = this.state;

        const width = widthPixels ? `${widthPixels}px` : 'auto';

        if (width !== tableBodyWidth) {
          this.setState({ tableBodyWidth: width });
        }
      }
    }
  }

  updateScrollPosition() {
    this.tableFooter.current.scrollLeft = this.scrollLeft;

    const tbody = get(this.tableBody, 'tbody.current');
    tbody.scrollLeft = this.scrollLeft;

    const thead = get(this.tableHead, 'thead.current');
    thead.scrollLeft = this.scrollLeft;
  }

  onScroll({ currentTarget }) {
    if (currentTarget) {
      const { scrollLeft } = currentTarget;

      if (scrollLeft !== this.scrollLeft) {
        this.scrollLeft = scrollLeft;

        if (currentTarget !== this.tableFooter.current) {
          this.tableFooter.current.scrollLeft = scrollLeft;
        }

        const tbody = get(this.tableBody, 'tbody.current');

        if (currentTarget !== tbody) {
          tbody.scrollLeft = scrollLeft;
        }

        const thead = get(this.tableHead, 'thead.current');

        if (currentTarget !== thead) {
          thead.scrollLeft = scrollLeft;
        }
      }
    }
  }

  render() {
    const {
      tableBodyWidth,
    } = this.state;

    const { onScroll } = this;
    const { isFooterFixedAtBottom } = this.props;

    return (
      <div className={classes.Table}>
        <table
          className={classes.Table__table}
          style={{ paddingBottom: isFooterFixedAtBottom ? '4rem' : 0 }}
        >
          {this.getChildren()}
        </table>
        <div
          ref={this.tableFooter}
          onScroll={onScroll}
          className={classes.Table__footer}
          style={{ bottom: isFooterFixedAtBottom ? '5.5rem' : 0 }}
        >
          <div className={classes.Table__footerInner} style={{ width: tableBodyWidth }}>{' '}</div>
        </div>
      </div>
    );
  }
}

Table.propTypes = {
  children: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.node,
  ])),
  isFooterFixedAtBottom: PropTypes.bool,
};

Table.defaultProps = {
  children: null,
  isFooterFixedAtBottom: false,
};

export default Table;
