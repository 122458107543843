import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import css from './NotificationBand.scss';
import {
  Median,
  MedianAlpha,
  MedianOmega,
} from '../../index';
import Icon from '../../Icon';
import IconButton from '../../IconButton';
import Button from '../../legacy/Construction/Button';

const icons = {
  info: {
    name: 'infoHollow',
    class: css['NotificationBand__Icon--Info'],
  },
  danger: {
    name: 'alertHollow',
    class: css['NotificationBand__Icon--Danger'],
  },

  warning: {
    name: 'warningRectangle',
    class: css['NotificationBand__Icon--Warning'],
  },
  help: {
    name: 'helpHollow',
    class: css['NotificationBand__Icon--Help'],
  },
};

class NotificationBand extends React.PureComponent {
  render() {
    const {
      type,
      primaryText,
      secondaryText,
      onDismiss,
      noWrap,
      actions,
    } = this.props;

    const {
      name: iconName,
      class: iconClass,
    } = icons[type];

    return (
      <div
        className={classnames(css.NotificationBand__container, {
          [css['NotificationBand--Danger']]: type === 'danger',
          [css['NotificationBand--Warning']]: type === 'warning',
          [css['NotificationBand--Info']]: type === 'info',
          [css['NotificationBand--Help']]: type === 'help',
        })}
      >
        <Median verticalFlush noWrap={noWrap}>
          <MedianAlpha>
            <div className={css.NotificationBand__Section}>
              <div className={classnames(css.NotificationBand__Icon, iconClass)}>
                <Icon size={32} name={iconName} />
              </div>
              <div className={css.NotificationBand__Text}>
                <div className={css.NotificationBand__PrimaryText}>{primaryText}</div>
                {secondaryText && <div>{secondaryText}</div>}
              </div>
            </div>
          </MedianAlpha>
          <MedianOmega>
            <div className={css.NotificationBand__Section}>
              {actions.map((action, idx) => (
                <div
                  key={`notificationBand__${action.label}`}
                  className={classnames({ [css.NotificationBand__ActionButton]: idx > 0 })}
                >
                  <Button
                    type={action.type}
                    href={action.href}
                    target="_blank"
                    onClick={action.onClick}
                    disableTitleCasing={action.disableTitleCasing}
                  >
                    {action.label}
                  </Button>
                </div>
              ))}
              {onDismiss && (
                <div className={css.NotificationBand__Dismiss}>
                  <IconButton name="close" onClick={onDismiss} />
                </div>
              )}
            </div>
          </MedianOmega>
        </Median>
      </div>
    );
  }
}

NotificationBand.propTypes = {
  onDismiss: PropTypes.func,
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.oneOf(['primary', 'secondary', 'flat']),
    href: PropTypes.string,
    onClick: PropTypes.func,
    disableTitleCasing: PropTypes.bool,
  })),
  type: PropTypes.oneOf(['info', 'danger', 'warning', 'danger2']),
  noWrap: PropTypes.bool,
};

NotificationBand.defaultProps = {
  onDismiss: undefined,
  actions: [],
  secondaryText: '',
  type: 'info',
  noWrap: false,
};

export default NotificationBand;
