import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import hash from 'object-hash';
import pick from 'lodash/pick';
import css from './Toolbar.scss';
import IconButton from '../IconButton';
import Button from '../legacy/Construction/Button';
import HorizontalList from '../HorizontalList';
import HorizontalListItem from '../HorizontalListItem';
import Median from '../Median';
import MedianAlpha from '../MedianAlpha';
import Icon from '../Icon';
import Search from '../Search';
import SkinlessPopover from '../SkinlessPopover';
import FilterSearch from './FilterSearch';
import FilterSave from './FilterSave';
import ActiveFilters from './ActiveFilters';
import {
  filtersPropTypes,
  filterPropTypes,
  actionButtonPropTypes,
} from './ToolbarPropTypes';
import SavedFilters from './SavedFilters';
import { MedianOmega, Menu } from '..';
import BodyText from '../BodyText';
import applyTruncation from '../utils/truncate';
import DeleteSavedFilterModal from '../v2/Modal';
import wrapEventHandler, { ARG_ORDER } from '../utils/wrapEventHandler';

/**
 * A `Toolbar` to handle search, filter and view changes
 */
export default class Toolbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displaySaveButton: props.activeFilters.length > 0 && isEmpty(props.savedFilter),
      settingSavedFilters: false,
      isOpen: false,
      aboutToDeleteFilter: {},
      searchExpanded: false,
      incompleteFilter: {},
    };

    this.iconMap = {
      card: 'cards',
      list: 'table',
      map: 'map',
    };

    this.timerHandle = 0;

    this.toolbarSectionRef = React.createRef();
    this.bookmarkSectionRef = React.createRef();
    this.filterAddButtonRef = React.createRef();
    this.filterSaveButtonRef = React.createRef();
    this.viewSwitcherSectionRef = React.createRef();
    this.exportButtonRef = React.createRef();

    this.handleOnDeleteSavedFilter = this.handleOnDeleteSavedFilter.bind(this);
    this.handleSearchEvent = this.handleSearchEvent.bind(this);
  }

  componentDidMount() {
    const { savedFilter, searchValue, alwaysExpandedSearch } = this.props;
    if (savedFilter) {
      this.setState({
        displaySaveButton: false,
        searchExpanded: (searchValue.length > 0) || alwaysExpandedSearch,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // PID-4550 - guard against rerendering when props and state are unchanged
    if (hash.sha1(this.state) !== hash.sha1(nextState)) {
      return true;
    }
    if (hash.sha1(this.props) !== hash.sha1(nextProps)) {
      return true;
    }
    return false;
  }

  componentWillUnmount = () => {
    if (this.timerHandle) {
      clearTimeout(this.timerHandle);
      this.timerHandle = 0;
    }
  };

  getSwitcherItem(viewType, label) {
    const {
      activeView,
      onViewChange,
    } = this.props;

    const icon = this.iconMap[viewType];

    return (
      <div
        role="button"
        tabIndex={0}
        className={classNames(css.Toolbar__viewSwitcherItem, {
          [css['Toolbar__viewSwitcherItem--active']]: activeView === viewType,
        })}
        onClick={wrapEventHandler(onViewChange, [viewType], ARG_ORDER.EVENT_LAST)}
        onKeyUp={wrapEventHandler(onViewChange, [viewType], ARG_ORDER.EVENT_LAST)}
      >
        <div className={classNames(css.Toolbar__viewSwitcherIcon)}>
          <span className={classNames('', {
            [css['Toolbar__viewSwitcherIcon--active']]: activeView === viewType,
            [css['Toolbar__viewSwitcherIcon--inactive']]: activeView !== viewType,
          })}
          >
            <Icon name={icon} />
          </span>
        </div>
        <span className={css.Toolbar__viewSwitcherLabel}>{label}</span>
      </div>
    );
  }

  getExportOptions = () => {
    const { exportOptionsMenuItems } = this.props;
    const action = {
      menuType: 'list',
      menuItems: exportOptionsMenuItems,
    };

    const limitProps = (props) => (pick(props, [
      'href',
      'target',
      'onClick',
      'label',
      'disabled',
    ]));
    return (
      <Menu
        minWidth="250"
        trigger={<IconButton name="download" />}
        menuItems={action.menuItems.map((item) => limitProps(item))}
        closeOnSelection
      />
    );
  }

  handleSearchEvent(event) {
    const { onSearch } = this.props;
    if (event.key === 'Enter') {
      onSearch(event.currentTarget.value);
    }
  }

  handleOnDeleteSavedFilter(filter) {
    this.setState({
      isOpen: true,
      aboutToDeleteFilter: filter,
    });
  }

  handleModalDeleteClick(filter) {
    const { onDeleteSavedFilter } = this.props;

    onDeleteSavedFilter(filter);

    this.setState({
      isOpen: false,
    });
  }

  resetSavedFilters = () => this.setState({ settingSavedFilters: false });

  toggleSearchExpanded = (searchExpanded) => {
    this.setState({
      searchExpanded,
    });
  };

  onRemoveFilter = (filter) => {
    const { incompleteFilter } = this.state;
    if (filter.id === incompleteFilter.id) {
      this.setState({ incompleteFilter: {} });
    } else {
      const { onRemoveFilter, activeFilters } = this.props;
      const newActiveFilters = activeFilters.filter((f) => f.id !== filter.id);
      if (onRemoveFilter) {
        onRemoveFilter(filter, [...newActiveFilters]);
      }
    }

    this.timerHandle = setTimeout(() => {
      this.setState({
        displaySaveButton: true,
      });
    }, 100);
  };

  onApplyFilter = (filter) => {
    if ((filter.value && filter.value.length) || (filter.value && !isEmpty(filter.value))) {
      const { onApplyFilter, activeFilters, isSingleFilter } = this.props;
      const existingFilterIdx = activeFilters.findIndex(({ id }) => filter.id === id);
      let newActiveFilters = [...activeFilters];
      if (existingFilterIdx >= 0 && !isSingleFilter) {
        newActiveFilters = newActiveFilters.map((item) => (item.id === filter.id ? { ...item, value: filter.value } : item));
      } else {
        newActiveFilters = [filter, ...newActiveFilters];
      }

      this.setState(
        {
          displaySaveButton: true,
          incompleteFilter: {},
        },
        onApplyFilter ? () => onApplyFilter(filter, newActiveFilters) : () => {},
      );
    }
  };

  onSaveFilters = (data) => {
    const { onSaveFilters, activeFilters } = this.props;

    onSaveFilters({
      ...data,
      filters: cloneDeep(activeFilters),
    });

    this.setState({
      displaySaveButton: false,
    });
  };

  onClickSavedFilter = (savedFilter) => {
    const { onClickSavedFilter } = this.props;
    this.setState({
      settingSavedFilters: true,
      displaySaveButton: false,
    },
    () => onClickSavedFilter(savedFilter));
  };

  onAddFilter = (f) => {
    const { onAddFilter } = this.props;
    this.setState({ incompleteFilter: f }, () => onAddFilter(f));
  }

  render() {
    const {
      activeView,
      views,
      savedFilters,
      onClickSavedFilter,
      activeFilters,
      filters,
      savedFilter,
      onClearSavedFilters,
      onSearchChange,
      onClearSearch,
      searchValue,
      disableSearch,
      searchPlaceholder,
      actionButton,
      alwaysExpandedSearch,
      showExportButton,
      showExportOptions,
      onExportButtonClick,
      searchWidthFitPlaceholderText,
      hideBorderRadius,
      filterSections,
      onFilterOptionsFetched,
      disableSaveFilter,
      filterButtonLabel,
      isSingleFilter,
      disabledFilterButton,
    } = this.props;

    const {
      displaySaveButton,
      settingSavedFilters,
      isOpen,
      aboutToDeleteFilter,
      searchExpanded,
      incompleteFilter,
    } = this.state;

    if (settingSavedFilters) {
      this.resetSavedFilters();
    }

    const allActiveFilters = isEmpty(incompleteFilter)
      ? activeFilters
      : [incompleteFilter, ...activeFilters];

    return (
      <div
        className={css.Toolbar}
        ref={this.toolbarSectionRef}
        style={hideBorderRadius ? { boxShadow: 'none' } : {}}
      >
        {!disableSearch
        && (
          <div
            className={
              classNames([
                css.Toolbar__item, css.Toolbar__search,
                css[searchExpanded ? 'Toolbar__search--expanded' : 'Toolbar__search--collapsed'],
              ])
            }
          >
            <Search
              collapsible
              disableAutoComplete
              iconDirection="right"
              onChange={onSearchChange}
              onClearSearch={onClearSearch}
              onKeyUp={wrapEventHandler(this.handleSearchEvent, [], ARG_ORDER.EVENT_FIRST, this)}
              placeholder={searchPlaceholder}
              searchExpanded={searchExpanded || alwaysExpandedSearch}
              searchWidthFitPlaceholderText={searchWidthFitPlaceholderText}
              toggleSearchExpanded={this.toggleSearchExpanded}
              value={searchValue}
            />
          </div>
        )}
        {
          !disableSaveFilter
          && filters.length > 0
          && onClickSavedFilter
          && (
            <div className={css.Toolbar__item} ref={this.bookmarkSectionRef}>
              <div className={css.Toolbar__itemInner}>
                <SkinlessPopover
                  attachment="top left"
                  targetAttachment="bottom left"
                  testId="saved-filter-pop"
                >
                  {
                    !savedFilters.length || !savedFilter.name
                      ? (
                        <IconButton
                          onClick={onClickSavedFilter}
                          name="bookmark"
                        />
                      )
                      : (
                        <div className={css.Toolbar__savedFilter}>
                          <div className={css.Toolbar__savedFilterName}>
                            <BodyText
                              styleLevel="mdSemiBold"
                            >
                              {
                                `${applyTruncation(savedFilter.name, true, displaySaveButton ? 19 : 20)}
                                 ${displaySaveButton ? '*' : ''}`
                              }
                            </BodyText>
                          </div>
                          <div>
                            <Icon
                              block
                              name="dropDown"
                              size={16}
                            />
                          </div>
                        </div>
                      )
                   }
                  <div>
                    <SavedFilters
                      filters={savedFilters}
                      activeSavedFilter={savedFilter.name}
                      onClickSavedFilter={this.onClickSavedFilter}
                      onDeleteSavedFilter={this.handleOnDeleteSavedFilter}
                      onClearFilters={onClearSavedFilters}
                    />
                  </div>
                </SkinlessPopover>
              </div>
            </div>
          )
        }
        <div className={classNames([css.Toolbar__item, css['Toolbar__item--main']])}>
          <div className={classNames([css.Toolbar__itemInner, css['Toolbar__itemInner--main']])}>
            <Median noWrap verticalFlush>
              <MedianAlpha verticalFlush>
                <HorizontalList noWrap spacing={2}>
                  {filters.length && !isSingleFilter
                  && (
                    <HorizontalListItem>
                      <div ref={this.filterAddButtonRef}>
                        <SkinlessPopover
                          targetAttachment="bottom left"
                          testId="add-filter-pop"
                        >
                          <Button
                            type="link"
                            label="Add Filter"
                            vAlign="middle"
                            icon="addHollow"
                            shouldUseIconComponent
                            applyFixedHeight
                          />
                          <FilterSearch
                            filters={
                              filters
                                .filter((f) => !activeFilters.find((i) => i.id === f.id))
                                .map((f) => {
                                  // We need to change name and options based on props.
                                  // We have a use case that based on country filter we need to show corresponding state and zip filters
                                  const { filters: propFilters } = this.props;
                                  const propFilter = propFilters.find((i) => i.id === f.id);
                                  return {
                                    ...f,
                                    name: propFilter ? propFilter.name : f.name,
                                    options: propFilter ? propFilter.options : f.options,
                                  };
                                })
                            }
                            onClick={this.onAddFilter}
                            filterSections={filterSections}
                          />
                        </SkinlessPopover>
                      </div>
                    </HorizontalListItem>
                  )}
                  {filters.length && isSingleFilter
                  && (
                    <HorizontalListItem>
                      <div ref={this.filterAddButtonRef}>
                        <Button
                          type="link"
                          label={filterButtonLabel || 'Add Filter'}
                          vAlign="middle"
                          icon="addHollow"
                          shouldUseIconComponent
                          applyFixedHeight
                          onClick={wrapEventHandler(this.onAddFilter, [filters?.[0]], ARG_ORDER.EVENT_LAST)}
                          disabled={Boolean(allActiveFilters.length) || disabledFilterButton}
                        />
                      </div>
                    </HorizontalListItem>
                  )}
                  {
                    allActiveFilters.length && (
                      <HorizontalListItem>
                        <ActiveFilters
                          onRemoveFilter={this.onRemoveFilter}
                          filters={allActiveFilters}
                          onApplyFilter={this.onApplyFilter}
                          toolbarSectionRef={this.toolbarSectionRef}
                          searchSectionWidth={
                            searchExpanded
                              ? parseFloat(css.searchInputExpandedWidth) * 10 // Converting 20rem to 200.
                              : parseFloat(css.searchInputCollapsedWidth) * 10
                          }
                          bookmarkSectionRef={this.bookmarkSectionRef}
                          filterAddButtonRef={this.filterAddButtonRef}
                          filterSaveButtonRef={this.filterSaveButtonRef}
                          viewSwitcherSectionRef={this.viewSwitcherSectionRef}
                          exportButtonRef={this.exportButtonRef}
                          openImmediately={!settingSavedFilters}
                          onFilterOptionsFetched={onFilterOptionsFetched}
                          isSingleFilter={isSingleFilter}
                        />
                      </HorizontalListItem>
                    )
                  }
                </HorizontalList>
              </MedianAlpha>
              {!disableSaveFilter && displaySaveButton && activeFilters.length > 0 && (
                <MedianOmega
                  leftFlush
                >
                  <div ref={this.filterSaveButtonRef}>
                    <FilterSave
                      onFilterSaveDone={this.onSaveFilters}
                      hasFilterChanged={displaySaveButton && !isEmpty(savedFilter)}
                      savedFilters={savedFilters}
                      currentSavedFilter={savedFilter.name || undefined}
                    />
                  </div>
                </MedianOmega>
              )}
            </Median>
          </div>
        </div>
        {actionButton && actionButton.id && (
          <div style={{ borderLeft: 0 }} className={css.Toolbar__item}>
            <div className={css.Toolbar__itemInner}>
              <Button
                key={actionButton.id}
                onClick={actionButton.onClick}
                label={actionButton.label}
                type={actionButton.type}
                disabled={actionButton.disabled}
                active={actionButton.active}
                title={actionButton.title}
                disableTitleCasing={actionButton.disableTitleCasing}
              />
            </div>
          </div>
        )}
        {(views.length > 1 && activeView) && (
          <div className={css.Toolbar__item} ref={this.viewSwitcherSectionRef}>
            <div className={css.Toolbar__itemInner}>
              <SkinlessPopover
                attachment="top right"
                targetAttachment="bottom right"
              >
                <IconButton
                  name={this.iconMap[activeView]}
                />
                <div className={css.Toolbar__viewSwitcherContainer}>
                  {
                    views.includes('card') && this.getSwitcherItem('card', 'Card')
                  }
                  {
                    views.includes('list') && this.getSwitcherItem('list', 'List')
                  }
                  {
                    views.includes('map') && this.getSwitcherItem('map', 'Map')
                  }
                </div>
              </SkinlessPopover>
            </div>
          </div>
        )}
        {showExportButton && (
          <div className={css.Toolbar__item} ref={this.exportButtonRef}>
            <div className={css.Toolbar__itemInner}>
              <IconButton
                name="download"
                onClick={onExportButtonClick}
              />
            </div>
          </div>
        )}
        {showExportOptions && (
          <div className={css.Toolbar__item}>
            <div className={css.Toolbar__itemInner} data-testid="exportOptions">
              {this.getExportOptions()}
            </div>
          </div>
        )}
        <DeleteSavedFilterModal
          isOpen={isOpen}
          header="Delete Saved Filter?"
          onClose={wrapEventHandler(this.setState, [{ isOpen: false }], ARG_ORDER.OMIT, this)}
          footerActions={[
            {
              label: 'Cancel',
              type: 'text',
              onClick: () => this.setState({ isOpen: false }),
            },
            {
              label: 'Delete',
              type: 'danger',
              onClick: () => this.handleModalDeleteClick(aboutToDeleteFilter),
            },
          ]}
        >
          This will remove
          {' '}
          {aboutToDeleteFilter.name}
          {' '}
          from your list of saved filters.
        </DeleteSavedFilterModal>
      </div>
    );
  }
}

Toolbar.propTypes = {
  /** An array of active filters */
  activeFilters: filtersPropTypes,
  /** The currently active view */
  activeView: PropTypes.oneOf(['card', 'list', 'map']),
  /** Removes the search bar from the Toolbar */
  disableSearch: PropTypes.bool,
  /** An array of filters to choose from */
  filters: filtersPropTypes,
  /** Callback called when a filter is added */
  onAddFilter: PropTypes.func,
  /** Callback called when a filter is applied */
  onApplyFilter: PropTypes.func,
  /** Callback called when saved filters are cleared */
  onClearSavedFilters: PropTypes.func,
  /** Callback called when the search text is cleared */
  onClearSearch: PropTypes.func,
  /** Callback called when a saved filter is clicked */
  onClickSavedFilter: PropTypes.func,
  /** Callback called when a saved filter is deleted */
  onDeleteSavedFilter: PropTypes.func,
  /** Callback called when a filter is removed */
  onRemoveFilter: PropTypes.func,
  /** Callback called when save filter is clicked */
  onSaveFilters: PropTypes.func,
  /** Callback called when pressing 'enter' key in search field */
  onSearch: PropTypes.func,
  /** Callback called when the search text changes */
  onSearchChange: PropTypes.func,
  /** Callback called when the view type changes */
  onViewChange: PropTypes.func,
  /** An array of saved filters */
  savedFilters: filtersPropTypes,
  /** Active saved filter */
  savedFilter: filterPropTypes,
  /** Search placeholder */
  searchPlaceholder: PropTypes.string,
  /** The search value */
  searchValue: PropTypes.string,
  /** An array of filter sections to display filter section names */
  filterSections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  /** An array of views to select. Must be a subset of the default */
  views: PropTypes.arrayOf(PropTypes.oneOf(['card', 'list', 'map'])),
  /** An additional action button you can add that will live on the far right of the toolbar */
  actionButton: actionButtonPropTypes,
  /** Makes the search box always expanded */
  alwaysExpandedSearch: PropTypes.bool,
  /** Shows an export button directly in the Toolbar */
  showExportButton: PropTypes.bool,
  showExportOptions: PropTypes.bool,
  exportOptionsMenuItems: PropTypes.arrayOf(PropTypes.shape({
    disabled: PropTypes.bool,
    href: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.string,
    target: PropTypes.string,
  })),
  /** On click handler for export button */
  onExportButtonClick: PropTypes.func,
  /** Makes the search bar width fit the entire placeholder text when expanded */
  searchWidthFitPlaceholderText: PropTypes.bool,
  /** Hide the box shawdow/border around the toolbar */
  hideBorderRadius: PropTypes.bool,
  onFilterOptionsFetched: PropTypes.func,
  disableSaveFilter: PropTypes.bool,
  isSingleFilter: PropTypes.bool,
  filterButtonLabel: PropTypes.string,
  disabledFilterButton: PropTypes.bool,
};

Toolbar.defaultProps = {
  activeFilters: [],
  activeView: 'list',
  disableSearch: false,
  filters: [],
  onAddFilter: () => {},
  onApplyFilter: () => {},
  onClearSavedFilters: () => {},
  onClearSearch: () => {},
  onClickSavedFilter: () => {},
  onDeleteSavedFilter: () => {},
  onRemoveFilter: () => {},
  onSaveFilters: () => {},
  onSearch: () => {},
  onSearchChange: () => {},
  onViewChange: () => {},
  savedFilters: [],
  savedFilter: {},
  searchPlaceholder: 'Search...',
  searchValue: '',
  filterSections: [],
  views: ['card', 'list', 'map'],
  actionButton: {},
  alwaysExpandedSearch: false,
  showExportButton: false,
  showExportOptions: false,
  exportOptionsMenuItems: [],
  onExportButtonClick: () => {},
  searchWidthFitPlaceholderText: false,
  hideBorderRadius: false,
  onFilterOptionsFetched: () => {},
  disableSaveFilter: false,
  isSingleFilter: false,
  filterButtonLabel: '',
  disabledFilterButton: false,
};
