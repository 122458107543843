
export default function bytesToText(bytes, decimals = 1) {
    if (bytes == 0) {
        return '(empty)';
    }

    const k = 1000; // or 1024 for binary
    const dm = decimals + 1 || 3;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}
