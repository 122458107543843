import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import classes from './styles.scss';
import getKeyFromChildContent from '../utils/getKeyFromChildContent';
import { checkChildren, checkProps } from '../utils/comparison';

class TableRow extends Component {
  shouldComponentUpdate(nextProps) {
    const {
      children: currentChildren,
      ...restCurrentProps
    } = this.props;

    const {
      children: nextChildren,
      ...restNextProps
    } = nextProps;

    // check if children and props are equal
    if (checkChildren(currentChildren, nextChildren)
      && checkProps(restCurrentProps, restNextProps)) {
      return false;
    }
    return true;
  }

  getChildren() {
    const {
      children,
      isNotFirstRow,
      highlightColor,
      applyDistinctBg,
      tableWidth,
      totalColumnWidth,
      lastColumnStretch,
    } = this.props;

    let expandableWidth = 0;

    if (tableWidth > 0 && tableWidth > totalColumnWidth) {
      expandableWidth = tableWidth - totalColumnWidth;
    }

    const childrenArray = (React.Children.toArray(children) || []).filter((f) => f);
    const childrenCount = childrenArray.length;
    const hasInlineActionsColumn = childrenArray.find((i) => i.props && i.props.id === 'inline_actions');
    const decrement = hasInlineActionsColumn && childrenCount > 1 ? 2 : 1;
    let index = 0;
    return React.Children.map(children, (child) => {
      let enhancedChild = null;

      if (child && get(child, 'type', '')) {
        const { displayName } = child.type;
        const isLastColumn = index === (childrenCount - decrement);

        let lastColumnWidth = child.props.width;
        if (isLastColumn && lastColumnStretch) {
          lastColumnWidth = expandableWidth + child.props.width;
        }

        if (displayName === 'TableColumnHeader') {
          enhancedChild = React.cloneElement(
            child,
            {
              key: getKeyFromChildContent(child),
              isLastColumn,
              expandableWidth,
              lastColumnWidth,
            },
          );
          index += 1;
        }

        if (displayName === 'TableCell') {
          enhancedChild = React.cloneElement(
            child,
            {
              key: getKeyFromChildContent(child),
              isNotFirstRow,
              highlightColor,
              applyDistinctBg,
              isLastColumn,
              expandableWidth,
              lastColumnWidth,
            },
          );
          index += 1;
        }
      }

      return enhancedChild;
    });
  }

  render() {
    const {
      isNotFirstRow,
      highlightColor,
      applyDistinctBg,
      applyRowBorder,
      totalColumnWidth,
      tableWidth,
    } = this.props;
    return (
      <tr
        className={classNames([classes.TableRow], {
          [classes['TableRow--notFirst']]: isNotFirstRow,
          [classes['TableRow--info']]: highlightColor === 'info',
          [classes['TableRow--warning']]: highlightColor === 'warning',
          [classes['TableRow--danger']]: highlightColor === 'danger',
          [classes['TableRow--neutral']]: highlightColor === 'nuetral' || highlightColor === 'neutral',
          [classes['TableRow--selected']]: highlightColor === 'selected',
          [classes['TableRow--distinctBg']]: applyDistinctBg,
          [classes['TableRow--border']]: applyRowBorder,
          [classes['TableRow--maxContent']]: tableWidth < totalColumnWidth,
        })}
      >
        {this.getChildren()}
      </tr>
    );
  }
}

TableRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  isNotFirstRow: PropTypes.bool,
  highlightColor: PropTypes.oneOf([
    '',
    'info',
    'warning',
    'danger',
    'nuetral',
    'neutral',
    'selected',
    'success',
    'alt',
  ]),
  applyDistinctBg: PropTypes.bool,
  tableWidth: PropTypes.number,
  totalColumnWidth: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  applyRowBorder: PropTypes.bool,
  lastColumnStretch: PropTypes.bool,
};

TableRow.defaultProps = {
  children: null,
  isNotFirstRow: false,
  highlightColor: '',
  applyDistinctBg: false,
  tableWidth: 0,
  totalColumnWidth: 0,
  applyRowBorder: false,
  lastColumnStretch: true,
};

export default TableRow;
