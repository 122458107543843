import isSticky from './isSticky';

const getStickyPosition = (sticky) => {
  if (!isSticky(sticky)) {
    return {};
  }
  const { offset } = sticky;

  return {
    [sticky.side]: `${(offset && !Number.isNaN(parseInt(offset, 10))) ? offset : 0}px`,
  };
};

export default getStickyPosition;
