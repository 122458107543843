import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CSSModules from 'react-css-modules';
import styles from './Status/styles/Status.scss';

const Status = ({
    children,
    title,
    size,
    type,
    isLoading,
    inlineFlex,
}) => {
    const statusClasses = classNames({
        [styles.Status]: true,
        [styles['Status--isLoading']]: isLoading,
        [styles['Status--sm']]: size === 'sm',
        [styles['Status--success']]: type === 'success',
        [styles['Status--info']]: type === 'info',
        [styles['Status--danger']]: type === 'danger',
        [styles['Status--active']]: type === 'active',
        [styles['Status--warning']]: type === 'warning',
        [styles['Status--light']]: type === 'light',
        [styles['Status--progress']]: type === 'progress',
        [styles['Status--inlineFlex']]: inlineFlex,
    });

    return (
        <span title={title} className={statusClasses}>
            <span styleName='Status__inner'>
                {children}
            </span>
        </span>
    );
};

Status.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    type: PropTypes.oneOf(['default', 'success', 'info', 'danger', 'active', 'warning', 'light', 'progress']),
    size: PropTypes.oneOf(['', 'sm']),
    isLoading: PropTypes.bool,
    inlineFlex: PropTypes.bool,
};

Status.defaultProps = {
    type: 'default',
    title: '',
    size: '',
    isLoading: false,
    inlineFlex: false,
};

export default CSSModules(Status, styles);
