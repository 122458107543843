import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import classes from './Icon.scss';
import IconComponent from '../../../../Icon';

export default class Icon extends PureComponent {
    static propTypes = {
        icon: PropTypes.oneOf(['dollar', 'search']),
        disabled: PropTypes.bool,
        onClick: PropTypes.func,
        orientation: PropTypes.oneOf(['right', 'left']).isRequired,
    }

    static defaultProps = {
        disabled: false,
    }

    static contextTypes = {
        fieldValidation: PropTypes.oneOf(['', 'error', 'success']),
    }

    constructor(props, context) {
        super(props, context);
        this.state = {
            fieldValidation: '',
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const VALIDATION_KEY = 'fieldValidation';
        if (nextContext[VALIDATION_KEY] !== this.state[VALIDATION_KEY]) {
            this.setState({
                fieldValidation: nextContext[VALIDATION_KEY],
            });
        }
    }

    render() {
        const Tag = `${(this.props.onClick ? 'button' : 'span')}`;
        const { orientation, onClick } = this.props;
        return (
            <Tag
                onClick={onClick}
                className={classNames(classes.Icon, {
                    [classes['Icon--left']]: orientation === 'left',
                    [classes['Icon--right']]: orientation === 'right',
                })}>
                <IconComponent
                    block
                    size={24}
                    name={this.props.icon} />
            </Tag>
        );
    }
}
