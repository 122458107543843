import React from 'react';
import PropTypes from 'prop-types';

const TitleBarHOC = (TitleBar) => {
  class TitleBarBridge extends React.Component {
    componentDidMount() {
      const {
        primaryAction, secondaryAction, menu, tertiaryComponent,
      } = this.props;
      const hasPrimaryAction = Boolean(Object.keys(primaryAction).length);
      const hasSecondaryAction = Boolean(Object.keys(secondaryAction).length);
      const hasMenu = Boolean(Object.keys(menu)) && Boolean(menu.actions);

      if (hasPrimaryAction || hasSecondaryAction || hasMenu || tertiaryComponent !== null) {
        // eslint-disable-next-line
        console.warn('Deprecated props are being used and will be unsupoorted after 03/10/2020, please see the updated prop structure for <TitleBar> in https://fieldnation.design/#/title-bar'); // eslint-disable-line no-console
      }
    }

    render() {
      const {
        primaryAction, secondaryAction, menu, tertiaryComponent, title, actions, content,
      } = this.props;
      const hasActions = Boolean(Object.keys(actions).length);
      const hasPrimaryAction = Boolean(Object.keys(primaryAction).length);
      const hasSecondaryAction = Boolean(Object.keys(secondaryAction).length);
      const hasMenu = Boolean(Object.keys(menu)) && Boolean(menu.actions);

      const mappedActions = [
        hasPrimaryAction ? { ...primaryAction, type: primaryAction.type || 'primary' } : null,
        hasSecondaryAction ? { ...secondaryAction, type: secondaryAction.type || 'secondary' } : null,
        hasMenu ? { label: menu.label, menuItems: menu.actions, menuType: 'button' } : null,
      ].filter((i) => i);

      const mappedContent = tertiaryComponent || content;

      return (
        <TitleBar
          title={title}
          content={mappedContent}
          actions={hasActions ? actions : mappedActions}
        />
      );
    }
  }

  TitleBarBridge.propTypes = {
    title: PropTypes.string.isRequired,
    primaryAction: PropTypes.shape({
      href: PropTypes.string,
      target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
      onClick: PropTypes.func,
      label: PropTypes.string,
      type: PropTypes.oneOf(['primary', 'secondary']),
      disabled: PropTypes.bool,
    }),
    secondaryAction: PropTypes.shape({
      href: PropTypes.string,
      target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
      onClick: PropTypes.func,
      label: PropTypes.string,
      type: PropTypes.oneOf(['primary', 'secondary']),
      disabled: PropTypes.bool,
    }),
    menu: PropTypes.shape({
      label: PropTypes.string,
      actions: PropTypes.arrayOf(PropTypes.shape({
        href: PropTypes.string,
        target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
        onClick: PropTypes.func,
        label: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['primary', 'secondary']),
        disabled: PropTypes.bool,
      })),
    }),
    actions: PropTypes.arrayOf(PropTypes.shape({
      href: PropTypes.string,
      target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
      onClick: PropTypes.func,
      label: PropTypes.string,
      type: PropTypes.oneOf(['primary', 'secondary', 'link', 'buttonLink']),
      disabled: PropTypes.bool,
      menuType: PropTypes.oneOf(['button', 'list']),
      menuItems: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        icon: PropTypes.string,
        disabled: PropTypes.bool,
        onClick: PropTypes.func,
        href: PropTypes.string,
        target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
      })),
    })),
    tertiaryComponent: PropTypes.element,
    content: PropTypes.element,
  };

  TitleBarBridge.defaultProps = {
    secondaryAction: {},
    primaryAction: {},
    menu: {},
    tertiaryComponent: null,
    actions: [],
    content: null,
  };

  return TitleBarBridge;
};

export default TitleBarHOC;
