import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import css from './styles.scss';
// eslint-disable-next-line import/no-cycle
import Menu from '../Menu';
import IconButton from '../IconButton';
import BodyText from '../BodyText';
import Icon from '../Icon';
import Chip from '../Chip';
import Tooltip from '../Tooltip';
import TooltipComponent from '../TooltipComponent';

const mapStatus = {
  default: 'none',
  info: 'info',
  warning: 'warning',
  critical: 'critical',
  positive: 'approved',
  negative: 'denied',
  strikethrough: 'none',
};

const getChip = (status, data, onChipClick, tooltip, active) => {
  const chip = (
    <Chip
      id="List Item"
      key={uuid()}
      active={active}
      type={mapStatus[status]}
      value={data}
      hideCloseButton
      textStrikethrough={status === 'negative' || status === 'strikethrough'}
      size="sm"
      shape="oval"
      onClick={onChipClick}
    />
  );

  return tooltip ? (
    <Tooltip position="left" showOnEvent="click">
      <div>
        {chip}
      </div>
      <TooltipComponent>
        {tooltip}
      </TooltipComponent>
    </Tooltip>
  ) : chip;
};

const GroupCardListItem = ({
  label,
  icon,
  iconColor,
  dottedLine,
  status,
  actions,
  type,
  data,
  border,
  tooltip,
  onChipClick,
  active,
  hideExpander,
  spacing,
  middleContent,
}) => {
  const iconColorStyle = iconColor ? {
    color: iconColor,
  } : {};
  const jsx = [
    ['default', 'strikethrough'].includes(status) && !active ? data : getChip(status, data, onChipClick, tooltip, active),
  ].map((i) => i);

  return (
    <div className={classNames([css.GroupCardListItem__container], {
      [css[`GroupCardListItem__container--${type}`]]: type,
      [css['GroupCardListItem--borderBottom']]: (border === 'both' || border === 'bottom'),
      [css['GroupCardListItem--borderTop']]: (border === 'both' || border === 'top'),
    })}
    >
      <div className={classNames(css['GroupCardListItem--leftChild'], {
        [css['GroupCardListItem--marginZero']]: spacing === 'none',
        [css['GroupCardListItem--marginSm']]: spacing === 'sm',
        [css['GroupCardListItem--marginSmPlus']]: spacing === 'sm+',
      })}
      >
        <div className={css.GroupCardListItem__container}>
          <div title={(label && typeof label !== 'object') ? label : ''} className={css['GroupCardListItem--innerLeftChild']}>
            { icon
              ? (
                <BodyText styleLevel="md">
                  <span
                    className={css['GroupCardListItem--labelIcon']}
                    style={{
                      ...iconColorStyle,
                      verticalAlign: 'middle',
                    }}
                  >
                    <Icon name={icon} size={16} />
                  </span>
                  {label}
                </BodyText>
              )
              : label}
          </div>
          {(middleContent)
            && (
            <div className={css['GroupCardListItem--middleContent']}>
              {middleContent}
            </div>
            )}
          {(!middleContent)
            && (
            <div className={classNames([css['GroupCardListItem--innerMiddleChild']], {
              [css['GroupCardListItem--dotted']]: dottedLine,
            })}
            />
            )}
          <div className={classNames([css['GroupCardListItem--innerRightChild']], {
            [css['GroupCardListItem--rightChild--strikethrough']]: status === 'strikethrough',
          })}
          >
            {jsx}
          </div>
        </div>
      </div>
      {hideExpander === false && (
        <div className={css['GroupCardListItem--rightChild']}>
          {actions && actions.length > 0
            && (
              <Menu
                disableAutoWidth
                trigger={(
                  <div className={css['Table__inlineActions--position']}>
                    <IconButton name="moreVert" />
                  </div>
                )}
                closeOnSelection
                menuItems={actions}
                alignToRight
              />
            )}
        </div>
      )}
    </div>
  );
};

GroupCardListItem.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  dottedLine: PropTypes.bool,
  status: PropTypes.oneOf(['default', 'info', 'warning', 'critical', 'positive', 'negative', 'strikethrough']),
  actions: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string,
    onClick: PropTypes.func,
    label: PropTypes.string,
    type: PropTypes.oneOf(['primary', 'secondary', 'critical', 'text']),
    disabled: PropTypes.bool,
    icon: PropTypes.string,
  })),
  type: PropTypes.oneOf(['default', 'info', 'warning', 'critical']),
  data: PropTypes.string.isRequired,
  border: PropTypes.oneOf(['top', 'bottom', 'both', 'none']),
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChipClick: PropTypes.func,
  active: PropTypes.bool,
  hideExpander: PropTypes.bool,
  spacing: PropTypes.oneOf(['none', 'sm', 'sm+', 'md']),
  middleContent: PropTypes.node,
};

GroupCardListItem.defaultProps = {
  dottedLine: false,
  status: 'default',
  actions: [],
  type: 'default',
  border: 'both',
  tooltip: '',
  icon: '',
  iconColor: '',
  onChipClick: () => {},
  active: false,
  hideExpander: false,
  spacing: 'md',
  middleContent: '',
};

export default GroupCardListItem;
