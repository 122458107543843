
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import classNames from 'classnames';

export default class IconAsideIcon extends Component {
    constructor(props) {
        super(props);
        this.state = { };
    }

    render() {
        const styles = this.context.iconAsideStyles;
        return (
            <span className={[styles.IconAside__iconContainer__dot]} />
        );
    }
}

IconAsideIcon.contextTypes = {
    iconAsideStyles: PropTypes.object,
};
