import isFinite from 'lodash/isFinite';

/**
 * Base options for the currencyFormatter
 * @param forceDecimal boolean - set to 'true' to force display of trailing zeros '.00'
 */
export const currencyOptions = {
  forceDecimal: false,
};

/**
 * Generate currency locale string from number
 * @param value  {number}   value to generate currency from
 * @returns      {string}   currency locale string
 */
export default function currencyFormatter(value, options) {
    const parsedValue = parseFloat(value);
    const opts = { ...currencyOptions, ...options };

    if (!isFinite(parsedValue)) {
        return value;
    }
    const localeValue = parseFloat(parsedValue.toFixed(2)).toLocaleString(
      'en-US', {
          style: 'currency',
          currency: 'USD',
          currencyDisplay: 'symbol',
      }
    );
    return (opts.forceDecimal) ? localeValue : localeValue.replace('.00', '');
}
