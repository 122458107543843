import React from 'react';
import { Column } from './DataGrid';

type Formatter = (value: string | number) => React.ReactNode;

const createComponent = (
  property: string,
  withEmpty?: React.ReactNode,
  formatter?: Formatter,
) => (row: any) => {
  const value = row?.[property] || '';

  if (!value && withEmpty) {
    return <span className="text-muted">{withEmpty}</span>;
  }

  if (formatter) {
    return formatter(value || '');
  }

  if (!value) {
    return null;
  }

  return value;
};

interface Options {
  id: string;
  property: string;
  label?: string;
  defaultWidth?: number;
  defaultSortDirection?: 'asc' | 'desc';
  withDefault?: boolean;
  withTruncation?: boolean;
  withSort?: boolean;
  withEmpty?: React.ReactNode;
  formatter?: Formatter;
}

export default ({
  id,
  label,
  property,
  defaultSortDirection,
  withDefault = false,
  defaultWidth = 180,
  withTruncation = true,
  withSort = false,
  withEmpty,
  formatter,
}: Options) => {
  const column: Column = {
    id,
    label: label || id,
    render: createComponent(property, withEmpty, formatter),
    defaultWidth,
    withDefault,
    withSort,
    withTruncation,
    defaultSortDirection,
  };

  return column;
};
