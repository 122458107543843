import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames';
import elementClass from 'element-class';
import PanelSection from './PanelSection';
import PanelAnimation from './PanelAnimation';
import PanelDrawer from './PanelDrawer';
import PanelDrawerAnimation from './PanelDrawerAnimation';
import PanelBox from './PanelBox';
import PanelBoxHeader from './PanelBoxHeader';
import PanelHeading from './PanelHeading';
import PanelBoxClose from './PanelBoxClose';
import PanelBoxBody from './PanelBoxBody';
import PanelBoxSidebar from './PanelBoxSidebar';
import PanelBoxMain from './PanelBoxMain';
import SaveBar from './SaveBar';
import SaveBarAnimation from './SaveBarAnimation';

ReactModal.defaultStyles = {
    overlay: {
    // position          : 'fixed',
    // top               : 0,
    // left              : 0,
    // right             : 0,
    // bottom            : 0,
    // backgroundColor   : 'transparent'
    },
    content: {
    // position                   : 'absolute',
    // left                       : '0',
    // right                      : '0',
    // bottom                     : '0',
    // background                 : '#fff',
    // overflow                   : 'auto',
    // WebkitOverflowScrolling    : 'touch',
    // outline                    : 'none',
    // padding                    : '0'
    },
};

export default class Panel extends Component {
    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
    }

    setBodyClassFromProps() {
        if (this.props.isOpen) {
            elementClass(document.body).add('ReactModal__Body--panelOpen');
        } else {
            elementClass(document.body).remove('ReactModal__Body--panelOpen');
        }
    }

    componentDidMount() {
        if (document) {
            this.setBodyClassFromProps();
        }
    }

    componentWillUnmount() {
        if (document) {
            elementClass(document.body).remove('ReactModal__Body--panelOpen');
        }
    }

    componentDidUpdate() {
        this.setBodyClassFromProps();
    }

    closeModal(e) {
        this.setState({ open: false }, () => {
            if (this.props.onClickClose) {
                this.props.onClickClose(e);
            }
        });
    }

    render() {
        return (
            <ReactModal
                {...this.props}
                closeTimeoutMS={250}
                overlayClassName="ReactModal__Overlay--panel js-panelOverlay"
                className={classNames('ReactModal__Content--panel')}
                onRequestClose={this.closeModal}
                contentLabel="Modal"
                appElement={document.querySelector('.c-page__body')}>
                <PanelBox position="absolute" style={this.props.panelStyles}>
                    {!this.props.disableHeader ? <PanelBoxHeader
                        backgroundColor={this.props.headerBackgroundColor}>
                            {this.props.onClickClose ?
                                <PanelBoxClose onClick={this.closeModal} /> : <div/>
                            }
                            {this.props.header &&
                                (this.props.header)
                            }
                    </PanelBoxHeader> : null}
                    <PanelBoxBody>
                        {this.props.sidebar ?
                            <PanelBoxSidebar>{this.props.sidebar}</PanelBoxSidebar>
                            :
                            null
                        }
                        <PanelBoxMain
                            header={this.props.bodyHeader}
                            footer={this.props.bodyFooter}
                            bodyFooterFlush={this.props.bodyFooterFlush}
                            backgroundColor={this.props.contentBackgroundColor} >
                            {this.props.children}
                        </PanelBoxMain>
                    </PanelBoxBody>
                </PanelBox>
            </ReactModal>
        );
    }
}

Panel.propTypes = {
    contentBackgroundColor: PropTypes.oneOf(['white', 'grey']),
    isOpen: PropTypes.bool,
    headerBackgroundColor: PropTypes.oneOf(['white', 'danger', 'success']),
    panelStyles: PropTypes.object,
    disableHeader: PropTypes.bool,
};

Panel.defaultProps = {
    contentBackgroundColor: 'white',
    isOpen: true,
    headerBackgroundColor: 'white',
    panelStyles: {},
    disableHeader: false,
};

export {
    PanelAnimation,
    PanelBox,
    PanelBoxBody,
    PanelBoxClose,
    PanelBoxHeader,
    PanelBoxMain,
    PanelBoxSidebar,
    PanelDrawer,
    PanelDrawerAnimation,
    PanelHeading,
    PanelSection,
    SaveBar,
    SaveBarAnimation,
};
