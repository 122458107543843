import { selector } from 'recoil';
import { stringify } from 'query-string';
import tabPropsQuery from './tab-props.query';
import tabPrefsQuery from './tab-prefs.query';
import { activeColumnIdsQuery } from './active-columns.query';
import activeTabQuery from './active-tab.query';
import activePageQuery from './active-page.query';
import activeViewQuery from './active-view.query';
import activeSortQuery from './active-sort.query';
import activePerPageQuery from './active-per-page.query';
import searchTextQuery from './search-text.query';
import prefsState from './prefs.state';

/**
 * Builds the GET query-string for the API request to the server for the listing.
 * This is generated after processing the preferences and current state as the
 * query string generally includes things like:
 *
 * 1) The sorted column and direction
 * 2) The columns requested for return
 * 3) Any filters, or removing of filters
 * 4) Page and items per page
 * 5) Active tab
 *
 * @returns {string}
 */
const requestPathQuery = selector<string>({
  key: 'requestPath',
  get: ({ get }) => {
    const props = get(tabPropsQuery);
    const prefs = get(tabPrefsQuery);
    const columns = get(activeColumnIdsQuery);
    const tab = get(activeTabQuery);
    const page = get(activePageQuery);
    const perPage = get(activePerPageQuery);
    const view = get(activeViewQuery);
    const sort = get(activeSortQuery);
    const search = get(searchTextQuery);
    const allPrefs = get(prefsState);

    if (!prefs || !props || !allPrefs) {
      return '';
    }

    const { filters = [] } = allPrefs;
    const { url } = props;

    const q = stringify(
      {
        list: tab?.id || '',
        view,
        page,
        per_page: perPage,
        columns: columns.join(','),
        sort: sort ? sort.id : undefined,
        order: sort ? sort.sortDirection : undefined,
        f_: '1',
        f_search: search || undefined,
        ...(filters.reduce((acc, { id, value }) => {
          acc[`f_${id}`] = value;
          return acc;
        }, {})),
      },
      { arrayFormat: 'bracket' },
    );

    return `${url}?${q}`;
  },
});

export default requestPathQuery;
