import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import classes from './TextArea.scss';
import { omit } from 'lodash/fp';

const omitProps = omit([
    'style',
]);

export default class TextArea extends PureComponent {
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.string,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onKeyUp: PropTypes.func,
        height: PropTypes.oneOf(['', 'sm']),
    }

    static defaultProps = {
        name: '',
        value: '',
        onChange: () => {},
        onBlur: () => {},
        onKeyUp: () => {},
        height: '',
    }

    static contextTypes = {
        fieldId: PropTypes.string.isRequired,
        fieldValidation: PropTypes.oneOf(['', 'error', 'success']),
    }

    constructor(props, context) {
        super(props, context);
        this.state = {
            fieldValidation: '',
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const VALIDATION_KEY = 'fieldValidation';
        if (nextContext[VALIDATION_KEY] !== this.state[VALIDATION_KEY]) {
            this.setState({
                fieldValidation: nextContext[VALIDATION_KEY],
            });
        }
    }

    render() {
        return (
            <textarea
                {...omitProps(this.props)}
                className={classNames(classes.TextArea, {
                    [classes['TextArea--error']]: this.state.fieldValidation === 'error',
                    [classes['TextArea--sm']]: this.props.height === 'sm',
                })}
                id={this.context.fieldId}
                name={this.props.name}
                placeholder={this.props.placeholder}
                value={this.props.value}
                onChange={this.props.onChange}
                onBlur={this.props.onBlur}
                onKeyUp={this.props.onKeyUp}
                disabled={this.props.disabled} />
        );
    }
}
