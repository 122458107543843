import remsToPixels from './remsToPixels';

export default function queryBuilder(containerSizes) {
  function sortNumbers(a, b) {
    return a - b;
  }

  const sizes = containerSizes.sort(sortNumbers);
  const query = {};

  sizes.forEach((sz, i) => {
    // if zero not included, add to beginning
    if (i === 0) {
      const offset = sz === 0 ? 1 : 0;
      query[0] = { maxWidth: remsToPixels(`${sizes[i + offset]}rem`) - 1 };
    }
    query[sz] = { minWidth: remsToPixels(`${sz}rem`) };
    if (i !== sizes.length - 1) {
      query[sz].maxWidth = remsToPixels(`${sizes[i + 1]}rem`) - 1;
    }
  });

  return sizes.length ? query : null;
}
