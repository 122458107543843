import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import Expander from '../legacy/Construction/Expander';
import {
  IconButton, Heading, BodyText, Icon, ProgressBar,
} from '..';
import css from './styles.scss';

// eslint-disable-next-line react/prop-types
const DefaultBadge = ({ children }) => (<span className={css.GroupCardAccordion__badge}>{children}</span>);

export default class GroupCardAccordion extends PureComponent {
  constructor(props) {
    super(props);
    const { expand } = this.props;
    this.state = {
      expanded: expand,
    };
    this.onWholeRowClick = this.onWholeRowClick.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      expand,
    } = this.props;

    const {
      expanded,
    } = prevState;

    if (expand !== expanded) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ expanded: expand });
    }
  }

  toggle() {
    const { onCollapse, onExpand } = this.props;
    const { expanded } = this.state;
    this.setState(({ expanded: !expanded }),
      () => {
        if (!expanded) {
          onExpand();
        } else {
          onCollapse();
        }
      });
  }

  getExpander() {
    const {
      showExpandButton,
      expanderHideButtonStyling,
    } = this.props;
    const { expanded } = this.state;

    return (
      <div className={expanderHideButtonStyling
        ? css.GroupCardAccordion__expander : css.GroupCardAccordion__action}
      >
        {showExpandButton && (
        <IconButton
          name={expanded ? 'collapse' : 'expand'}
          onClick={this.toggle}
        />
        )}
      </div>
    );
  }

  onWholeRowClick() {
    const { entireRowIsClickable } = this.props;

    if (!entireRowIsClickable) {
      return;
    }
    this.toggle();
  }

  render() {
    const {
      children,
      type,
      status,
      border,
      label,
      value,
      badge,
      showBadge,
      actAsHeader,
      subHeading,
      clip,
      hideExpander,
      expanderPosition,
      middleContent,
      icon,
      iconSize,
      progress,
      customBadgeRenderer,
      customHeaderContent,
      showHoverEffect,
      subNode,
    } = this.props;

    const { expanded } = this.state;

    function renderHeader() {
      if (actAsHeader) {
        return (
          <div className={css['GroupCardAccordion__labelContent--header']}>
            <Heading styleLevel="h3">{label}</Heading>
          </div>
        );
      }
      if (customBadgeRenderer) {
        const BadgeComponent = customBadgeRenderer;
        return (
          <>
            <Heading tag="span" styleLevel="h4">
              {label}
            </Heading>
            {showBadge && (
              <BadgeComponent>
                {badge}
              </BadgeComponent>
            )}
          </>
        );
      }

      return (
        <div className={css['GroupCardAccordion__labelContent--header']}>
          <Heading styleLevel="h4">
            {label}
            {showBadge && <DefaultBadge>{badge}</DefaultBadge>}
          </Heading>
        </div>
      );
    }

    function renderCustomContent() {
      if (customHeaderContent) {
        return (
          <span>
            {customHeaderContent}
          </span>
        );
      }

      return actAsHeader ? (
        <div className={`${css[`GroupCardAccordion__value--${status}`]}`}>
          <Heading styleLevel="h2">
            {value}
          </Heading>
        </div>
      ) : (
        <div className={`${css.GroupCardAccordion__value} ${css[`GroupCardAccordion__value--${status}`]}`}>
          <Heading styleLevel="h5">
            {value}
          </Heading>
        </div>
      );
    }

    return (
      <div>
        <div className={
          classNames(css.GroupCardAccordion__container, {
            [css['GroupCardAccordion__container--borderTop']]: border === 'top' || border === 'both',
            [css['GroupCardAccordion__container--borderBottom']]: border === 'bottom' || border === 'both',
            [css['GroupCardAccordion__header--info']]: type === 'info',
            [css['GroupCardAccordion__header--warning']]: type === 'warning',
            [css['GroupCardAccordion__header--critical']]: type === 'critical',
            [css['GroupCardAccordion--default']]: type === 'default',
            [css['GroupCardAccordion--clip']]: clip,
            [css['GroupCardAccordion--hover']]: !!showHoverEffect,
          })
          }
        >
          {!hideExpander && expanderPosition === 'left' && this.getExpander()}
          <div className={css.GroupCardAccordion__content} onClick={this.onWholeRowClick} onKeyDown={this.onWholeRowClick} data-testid="groupCardAccordion" role="presentation">
            <div className={classNames(
              css.GroupCardAccordion__container, { [css.GroupCardAccordion__wrap]: customHeaderContent },
            )}
            >
              <div className={css.GroupCardAccordion__label}>
                <div className={classNames(
                  css.GroupCardAccordion__labelContent, { [css.GroupCardAccordion__wrap_label]: customHeaderContent },
                )}
                >
                  {icon && (
                    <span
                      className={(progress && progress.value === progress.max) ? css.IconComplete : css.Icon}
                      style={{ marginRight: 10 }}
                    >
                      <Icon
                        block
                        size={iconSize || 22}
                        name={(progress && progress.value === progress.max) ? 'completeSolid' : `${icon}`}
                      />
                    </span>
                  )}
                  {renderHeader()}
                  { subNode && <div className={css['GroupCardAccordion__labelContent--subNode']}>{subNode}</div>}
                  <div className={css['GroupCardAccordion__labelContent--subHeading']}>
                    <BodyText styleLevel="xs">
                      {subHeading}
                    </BodyText>
                  </div>
                  <div className={css['GroupCardAccordion__labelMiddleContent--header']}>
                    {middleContent}
                  </div>
                </div>
              </div>
              <div>
                {renderCustomContent()}
              </div>
            </div>
          </div>
          {!hideExpander && expanderPosition !== 'left' && this.getExpander()}
        </div>
        {(progress && Number.isInteger(progress.max) && Number.isInteger(progress.value))
          && (
            <ProgressBar
              fillColor="green"
              currentProgress={progress.value}
              maxProgress={progress.max}
            />
          )}
        <TransitionGroup component="div">
          {expanded && (
            <Expander>
              <div className={classNames({
                [css['GroupCardAccordion--info']]: type === 'info',
                [css['GroupCardAccordion--warning']]: type === 'warning',
                [css['GroupCardAccordion--critical']]: type === 'critical',
                [css['GroupCardAccordion--default']]: type === 'default',
              })}
              >
                {children}
              </div>
            </Expander>
          )}
        </TransitionGroup>
      </div>
    );
  }
}

GroupCardAccordion.propTypes = {
  children: PropTypes.node.isRequired,
  status: PropTypes.oneOf(['default', 'positive', 'negative', 'strikethrough', 'pending', 'info']),
  type: PropTypes.oneOf(['default', 'info', 'warning', 'critical']),
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  expand: PropTypes.bool,
  showBadge: PropTypes.bool,
  badge: PropTypes.number,
  onCollapse: PropTypes.func,
  onExpand: PropTypes.func,
  border: PropTypes.oneOf(['top', 'bottom', 'both', 'none']),
  actAsHeader: PropTypes.bool,
  subHeading: PropTypes.string,
  clip: PropTypes.bool,
  hideExpander: PropTypes.bool,
  showExpandButton: PropTypes.bool,
  expanderPosition: PropTypes.oneOf(['left', 'right']),
  expanderHideButtonStyling: PropTypes.bool,
  middleContent: PropTypes.string,
  entireRowIsClickable: PropTypes.bool,
  showHoverEffect: PropTypes.bool,
  icon: PropTypes.string,
  iconSize: PropTypes.number,
  progress: PropTypes.object,
  customBadgeRenderer: PropTypes.node,
  customHeaderContent: PropTypes.node,
  subNode: PropTypes.node,
};

GroupCardAccordion.defaultProps = {
  type: 'default',
  status: 'default',
  expand: false,
  showBadge: true,
  badge: 0,
  border: 'none',
  onCollapse: () => {},
  onExpand: () => {},
  actAsHeader: false,
  subHeading: '',
  clip: false,
  hideExpander: false,
  showExpandButton: true,
  expanderPosition: 'right',
  expanderHideButtonStyling: false,
  middleContent: '',
  entireRowIsClickable: false,
  showHoverEffect: false,
  icon: '',
  iconSize: 18,
  progress: {},
  customBadgeRenderer: undefined,
  customHeaderContent: undefined,
  subNode: undefined,
};
