import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../../../../Icon';
import classes from './Addon.scss';

class Addon extends Component {
    render() {
        const {
            active,
            adjacentToDisabled,
            buttonRef,
            disabled,
            icon,
            indicated,
            label,
            onClick,
            orientation,
        } = this.props;

        return (
            <button
                disabled={disabled}
                ref={buttonRef}
                type="button"
                className={classNames(classes.Addon, {
                    [classes['Addon--active']]: active,
                    [classes['Addon--isLabel']]: label,
                    [classes['Addon--right']]: orientation === 'right',
                    [classes['Addon--left']]: orientation === 'left',
                    [classes['Addon--adjacentToDisabled']]: adjacentToDisabled,
                })}
                onClick={onClick}>
                  {icon &&
                      <span
                        className={classes.Addon__icon}>
                        <Icon
                        block
                        size={24}
                        name={icon}/>
                        <span className={classNames(classes.Addon__indicator, {
                          [classes['Addon__indicator--active']]: indicated,
                        })} />
                      </span>
                  }
                    {label &&
                      label
                    }
            </button>
        );
    }
}

Addon.propTypes = {
    active: PropTypes.bool,
    adjacentToDisabled: PropTypes.bool,
    buttonRef: PropTypes.func,
    disabled: PropTypes.bool,
    icon: PropTypes.oneOf(['filter', 'event', 'time', 'search']),
    label: PropTypes.string,
    orientation: PropTypes.oneOf(['right', 'left', 'middle']),
};

Addon.defaultProps = {
    adjacentToDisabled: false,
    buttonRef: undefined,
    disabled: false,
    label: '',
    orientation: 'before',
};

export default Addon;
