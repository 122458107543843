import React from 'react';
import chunk from 'lodash/chunk';
import classNames from 'classnames';
import BaseMonth from 'react-big-calendar/lib/Month';
import dates from 'react-big-calendar/lib/utils/dates';

export default class Month extends BaseMonth {
  render() {
    const {
      date,
      localizer,
      className,
      monthStyle,
    } = this.props;

    const month = dates.visibleDays(date, localizer);
    const weeks = chunk(month, 7);

    // eslint-disable-next-line no-underscore-dangle
    this._weekCount = weeks.length;
    const { height } = monthStyle;

    return (
      <div className="fn-month-view">
        <div className="rbc-row rbc-month-header">
          {this.renderHeaders(weeks[0])}
        </div>
        <div className="fn-month-content" style={{ height }}>
          <div className={classNames('rbc-month-view', className)}>
            {weeks.map(this.renderWeek)}
            {this.props.popup && this.renderOverlay()}
          </div>
        </div>
      </div>
    );
  }
}
