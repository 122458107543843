import React from 'react';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import { filtersPropTypes } from './ToolbarPropTypes';
import css from './MoreMenuItems.scss';
import MoreMenuItem from './MoreMenuItem';

const MoreMenuItems = (props) => {
  const {
    filters,
    onApplyFilter,
    onRemoveFilter,
  } = props;

  return (
    <div className={css.MoreMenuWrapper}>
      {filters.map((filter) => (
        <MoreMenuItem
          key={`moreMenuItem__${filter.id}`}
          {...pick(filter, [
            'href',
            'onClick',
            'target',
            'active',
            'label',
          ])}
          filter={filter}
          onRemoveFilter={onRemoveFilter}
          onApplyFilter={onApplyFilter}
        />
      ))}
    </div>
  );
};

MoreMenuItems.propTypes = {
  filters: filtersPropTypes,
  onApplyFilter: PropTypes.func.isRequired,
  onRemoveFilter: PropTypes.func.isRequired,
};

MoreMenuItems.defaultProps = {
  filters: [],
};

export default MoreMenuItems;
