const defaultDateRange = [
  {
    label: 'Yesterday',
    value: 'yesterday',
    offset: {
      startDate: {
        dateFns: 'startOfDay',
        sub: {
          days: 1,
        },
      },
      endDate: {
        dateFns: 'endOfDay',
        sub: {
          days: 1,
        },
      },
    },
  },
  {
    label: 'Today',
    value: 'today',
    offset: {
      startDate: {
        dateFns: 'startOfDay',
      },
      endDate: {
        dateFns: 'endOfDay',
      },
    },
  },
  {
    label: 'Next 4 Hours',
    value: 'next-4-hours',
    offset: {
      startDate: {},
      endDate: {
        add: {
          hours: 4,
        },
      },
    },
  },
  {
    label: 'Next 8 Hours',
    value: 'next-8-hours',
    offset: {
      startDate: {},
      endDate: {
        add: {
          hours: 8,
        },
      },
    },
  },
  {
    label: 'Next 12 Hours',
    value: 'next-12-hours',
    offset: {
      startDate: {},
      endDate: {
        add: {
          hours: 12,
        },
      },
    },
  },
  {
    label: 'Tomorrow',
    value: 'tomorrow',
    offset: {
      startDate: {
        dateFns: 'startOfDay',
        add: {
          days: 1,
        },
      },
      endDate: {
        dateFns: 'endOfDay',
        add: {
          days: 1,
        },
      },
    },
  },
  {
    label: 'Last Week',
    value: 'last-week',
    offset: {
      startDate: {
        dateFns: 'startOfWeek',
        sub: {
          weeks: 1,
        },
      },
      endDate: {
        dateFns: 'endOfWeek',
        sub: {
          weeks: 1,
        },
      },
    },
  },
  {
    label: 'This Week',
    value: 'this-week',
    offset: {
      startDate: {
        dateFns: 'startOfWeek',
      },
      endDate: {
        dateFns: 'endOfWeek',
      },
    },
  },
  {
    label: 'Next Week',
    value: 'next-week',
    offset: {
      startDate: {
        dateFns: 'startOfWeek',
        add: {
          weeks: 1,
        },
      },
      endDate: {
        dateFns: 'endOfWeek',
        add: {
          weeks: 1,
        },
      },
    },
  },
  {
    label: 'Last 2 Week',
    value: 'last-2-week',
    offset: {
      startDate: {
        dateFns: 'startOfWeek',
        sub: {
          weeks: 2,
        },
      },
      endDate: {
        dateFns: 'endOfWeek',
        sub: {
          weeks: 1,
        },
      },
    },
  },
  {
    label: 'Next 2 Week',
    value: 'next-2-week',
    offset: {
      startDate: {
        dateFns: 'startOfWeek',
        add: {
          weeks: 1,
        },
      },
      endDate: {
        dateFns: 'endOfWeek',
        add: {
          weeks: 2,
        },
      },
    },
  },
  {
    label: 'Last month',
    value: 'last-month',
    offset: {
      startDate: {
        dateFns: 'startOfMonth',
        sub: {
          months: 1,
        },
      },
      endDate: {
        dateFns: 'endOfMonth',
        sub: {
          months: 1,
        },
      },
    },
  },
  {
    label: 'This month',
    value: 'this-month',
    offset: {
      startDate: {
        dateFns: 'startOfMonth',
      },
      endDate: {
        dateFns: 'endOfMonth',
      },
    },
  },
  {
    label: 'Next month',
    value: 'next-month',
    offset: {
      startDate: {
        dateFns: 'startOfMonth',
        add: {
          months: 1,
        },
      },
      endDate: {
        dateFns: 'endOfMonth',
        add: {
          months: 1,
        },
      },
    },
  },
  {
    label: 'Custom',
    value: 'custom',
    offset: {},
  },
];

const historicDateRange = [
  {
    label: 'Custom',
    value: 'custom',
    offset: {},
  },
  {
    label: 'Last Week',
    value: 'last-week',
    offset: {
      startDate: {
        dateFns: 'startOfWeek',
        sub: {
          weeks: 1,
        },
      },
      endDate: {
        dateFns: 'endOfWeek',
        sub: {
          weeks: 1,
        },
      },
    },
  },
  {
    label: 'This Week',
    value: 'this-week',
    offset: {
      startDate: {
        dateFns: 'startOfWeek',
      },
      endDate: {
        dateFns: 'endOfWeek',
      },
    },
  },
  {
    label: 'Last Month',
    value: 'last-month',
    offset: {
      startDate: {
        dateFns: 'startOfMonth',
        sub: {
          months: 1,
        },
      },
      endDate: {
        dateFns: 'endOfMonth',
        sub: {
          months: 1,
        },
      },
    },
  },
  {
    label: 'This Month',
    value: 'this-month',
    offset: {
      startDate: {
        dateFns: 'startOfMonth',
      },
      endDate: {
        dateFns: 'endOfMonth',
      },
    },
  },
  {
    label: 'Last Year',
    value: 'last-year',
    offset: {
      startDate: {
        dateFns: 'startOfYear',
        sub: {
          years: 1,
        },
      },
      endDate: {
        dateFns: 'endOfYear',
        sub: {
          years: 1,
        },
      },
    },
  },
  {
    label: 'This Year',
    value: 'this-year',
    offset: {
      startDate: {
        dateFns: 'startOfYear',
      },
      endDate: {
        dateFns: 'endOfYear',
      },
    },
  },
];

const pastDateRange = [
  {
    label: 'Yesterday',
    value: 'yesterday',
    offset: {
      startDate: {
        dateFns: 'startOfDay',
        sub: {
          days: 1,
        },
      },
      endDate: {
        dateFns: 'endOfDay',
        sub: {
          days: 1,
        },
      },
    },
  },
  {
    label: 'Today',
    value: 'today',
    offset: {
      startDate: {
        dateFns: 'startOfDay',
      },
      endDate: {
        dateFns: 'endOfDay',
      },
    },
  },
  {
    label: 'Last Week',
    value: 'last-week',
    offset: {
      startDate: {
        dateFns: 'startOfWeek',
        sub: {
          weeks: 1,
        },
      },
      endDate: {
        dateFns: 'endOfWeek',
        sub: {
          weeks: 1,
        },
      },
    },
  },
  {
    label: 'This Week',
    value: 'this-week',
    offset: {
      startDate: {
        dateFns: 'startOfWeek',
      },
      endDate: {
        dateFns: 'endOfWeek',
      },
    },
  },
  {
    label: 'Last 2 Week',
    value: 'last-2-week',
    offset: {
      startDate: {
        dateFns: 'startOfWeek',
        sub: {
          weeks: 2,
        },
      },
      endDate: {
        dateFns: 'endOfWeek',
        sub: {
          weeks: 1,
        },
      },
    },
  },
  {
    label: 'Last month',
    value: 'last-month',
    offset: {
      startDate: {
        dateFns: 'startOfMonth',
        sub: {
          months: 1,
        },
      },
      endDate: {
        dateFns: 'endOfMonth',
        sub: {
          months: 1,
        },
      },
    },
  },
  {
    label: 'This month',
    value: 'this-month',
    offset: {
      startDate: {
        dateFns: 'startOfMonth',
      },
      endDate: {
        dateFns: 'endOfMonth',
      },
    },
  },
  {
    label: 'Custom',
    value: 'custom',
    offset: {},
  },
];

const advancedDateRangeList = {
  default: defaultDateRange,
  historic: historicDateRange,
  past: pastDateRange,
};

export default advancedDateRangeList;
