import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from '../index';
import css from './styles.scss';

const Banner = (props) => {
  const {
    title, message, type, icon,
  } = props;
  const getBannerIcon = () => {
    if (icon) {
      return icon;
    }

    if (type === 'warning') {
      return 'warningRectangle';
    }

    if (type === 'alert') {
      return 'warningSolid';
    }

    if (type === 'success') {
      return 'completeHollow';
    }
    return 'infoHollow';
  };

  const rootClasses = classNames({
    [css.bannerContainer]: true,
    [css['banner--info']]: type === 'info',
    [css['banner--warning']]: type === 'warning',
    [css['banner--alert']]: type === 'alert',
    [css['banner--success']]: type === 'success',
  });

  const iconClasses = classNames({
    [css['icon--info']]: type === 'info',
    [css['icon--warning']]: type === 'warning',
    [css['icon--alert']]: type === 'alert',
    [css['icon--success']]: type === 'success',
  });

  return (
    <div className={rootClasses}>
      <span className={iconClasses}>
        <Icon name={getBannerIcon()} size={22} block />
      </span>
      <span className={css.bannerText}>
        {!!title
            && (
            <div className={css.bannerTitle}>
              {title}
            </div>
            )}
        {!!message
            && (
            <div>
              {message}
            </div>
            )}
      </span>
    </div>
  );
};

Banner.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.oneOf([
    'info',
    'warning',
    'alert',
  ]),
};

Banner.defaultProps = {
  title: '',
  message: '',
  icon: '',
  type: 'info',
};

export default Banner;
