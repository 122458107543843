import React, { PureComponent } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import Slide from './Slide';
import styles from './styles.scss';

export default class Carousel extends PureComponent {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    settings: PropTypes.object,
    slides: PropTypes.array,
    slideStyle: PropTypes.object,
  }

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    settings: {
      dots: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 5000,
      arrows: false,
      infinite: true,
      centerMode: false,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            centerMode: true,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            centerMode: true,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    },
    slides: [],
    slideStyle: {
      marginLeft: 8,
      marginRight: 8,
      backgroundColor: 'white',
      borderRadius: 4,
      borderTop: 'solid',
      borderTopColor: '#268ED9',
      borderTopWidth: 4,
      display: 'flex',
      width: 350,
      height: 180,
      boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)',
    },
  }

  render() {
    const { settings, slides, slideStyle } = this.props;
    return (
      <div className={styles.Carousel}>
        <Slider {...settings}>
          {slides.map((slide) => <Slide slide={slide} key={slide.id} slideStyle={slideStyle} />)}
        </Slider>
      </div>
    );
  }
}
