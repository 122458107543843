import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { iconList, svgIcons } from '@fieldnation/font/components';
import camelCase from 'lodash/camelCase';
import classes from './styles.scss';

export const getIconFromList = (iconName, props) => {
  let elem;
  if (!iconName || iconName === '') {
    return elem;
  }
  if (iconList.includes(iconName)) {
    elem = svgIcons[iconName](props);
  } else if (iconList.includes(camelCase(iconName))) {
    // backwards-compat support of existing kebab-case and snake-case icon names
    elem = svgIcons[camelCase(iconName)](props);
    // eslint-disable-next-line no-console
    console.warn('WARNING: Icon [', iconName, '] has been renamed to [', camelCase(iconName), ']');
  }
  return elem;
};

const Icon = ({
  name,
  size,
  block,
  disablePointerEvent,
  svgProps,
}) => {
  const computedOuterRem = size / 10;
  const iconElem = getIconFromList(name, svgProps);

  return (
    <span
      aria-hidden
      className={classNames([classes.Icon], {
        [classes['Icon--block']]: block,
        [classes['Icon--disablePointerEvent']]: disablePointerEvent,
      })}
      style={{ width: `${computedOuterRem}rem`, height: `${computedOuterRem}rem` }}
    >
      <span className={classNames(classes.Icon__svg)}>
        {(iconElem) && iconElem}
      </span>
    </span>
  );
};

Icon.propTypes = {
  block: PropTypes.bool,
  name: PropTypes.string,
  size: PropTypes.oneOf([14, 16, 18, 22, 24, 32, 48, 64, 72, 96, 124]),
  disablePointerEvent: PropTypes.bool,
  svgProps: PropTypes.object,
};

Icon.defaultProps = {
  name: '',
  block: false,
  size: 24,
  disablePointerEvent: false,
  svgProps: {},
};

export default Icon;
