import React from 'react';
import PropTypes from 'prop-types';
import { iconList } from '@fieldnation/font/components';
import AbstractContainer from '../AbstractContainer';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';

const ContentContainer = ({
  children,
  title,
  titleTag,
  additionalActions,
  footerActions,
  customFooter,
  onClose,
  onBack,
  size,
  noBodyPadding,
  customHeader,
  customSecondaryHeader,
  footerBackgroundColor,
  headerBackgroundColor,
  headerBorderBottom,
  headerVerticalAlign,
  tertiaryFooter,
}) => (
  <AbstractContainer
    flexDirection="column"
    fullHeight
    disablePadding
  >
    <Header
      onBack={onBack}
      onClose={onClose}
      title={title}
      titleTag={titleTag}
      actions={additionalActions}
      backgroundColor={headerBackgroundColor}
      borderBottom={headerBorderBottom}
      custom={customHeader}
      customSecondary={customSecondaryHeader}
      verticalAlign={headerVerticalAlign}
    />
    <Body
      disablePadding={noBodyPadding}
    >
      {children}
    </Body>
    <Footer
      size={size}
      actions={footerActions}
      customFooter={customFooter}
      backgroundColor={footerBackgroundColor}
      tertiary={tertiaryFooter}
    />
  </AbstractContainer>
);

ContentContainer.propTypes = {
  children: PropTypes.node.isRequired,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  /** The semantic HTML element that will be used for the title (options are 'h1'-'h6') */
  titleTag: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
  ]),
  additionalActions: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.oneOf(iconList).isRequired,
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
  })),
  footerActions: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['primary', 'secondary']),
    disabled: PropTypes.bool,
    menuItem: PropTypes.bool,
  })),
  size: PropTypes.oneOf(['sm']),
  customFooter: PropTypes.element,
  customHeader: PropTypes.element,
  customSecondaryHeader: PropTypes.element,
  footerBackgroundColor: PropTypes.oneOf(['default', 'grey20', 'grey30', 'grey40']),
  headerBackgroundColor: PropTypes.oneOf(['default', 'grey20', 'grey30', 'grey40']),
  headerBorderBottom: PropTypes.bool,
  headerVerticalAlign: PropTypes.oneOf(['middle', 'top', 'bottom']),
  tertiaryFooter: PropTypes.shape({
    action: PropTypes.shape({
      disabled: PropTypes.bool,
      icon: PropTypes.string,
      onClick: PropTypes.func,
    }),
    text: PropTypes.string,
  }),
  noBodyPadding: PropTypes.bool,
};

ContentContainer.defaultProps = {
  title: null,
  titleTag: 'h2',
  additionalActions: [],
  footerActions: [],
  onBack: null,
  onClose: null,
  size: null,
  customFooter: null,
  noBodyPadding: false,
  customHeader: null,
  customSecondaryHeader: null,
  footerBackgroundColor: 'default',
  headerBackgroundColor: 'default',
  headerBorderBottom: true,
  headerVerticalAlign: 'middle',
  tertiaryFooter: null,
};

export default ContentContainer;
