import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { iconList } from '@fieldnation/font/components';
import css from './styles.scss';
import Icon from '../Icon';

/*
  Functional component does not support refs. React forwardRef can be used but
  it may require to change in all the places where this component is used and
  needed ref usage. So for minimum impact I'm keeping it class component.
*/

// eslint-disable-next-line react/prefer-stateless-function
class IconButton extends Component {
  render() {
    const {
      active,
      color,
      disabled,
      href,
      name,
      onClick,
      size,
      title,
    } = this.props;

    const Tag = `${href ? 'a' : 'button'}`;

    return (
      <Tag
        href={href}
        type="button"
        disabled={disabled}
        className={classNames(css.IconButton, {
          [css.IconButton__small]: size === 'sm',
          [css['IconButton--active']]: active,
          [css['IconButton--light']]: color === 'light',
          [css['IconButton--fade']]: color === 'fade',
        })}
        onClick={onClick}
        aria-label={title}
        title={title}
        // eslint-disable-next-line react/destructuring-assignment
        data-testid={this.props['data-testid']}
      >
        <Icon
          block
          size={size === 'lg' ? 24 : 16}
          name={name}
        />
      </Tag>
    );
  }
}

IconButton.propTypes = {
  active: PropTypes.bool,
  color: PropTypes.oneOf(['dark', 'light', 'fade']),
  disabled: PropTypes.bool,
  href: PropTypes.string,
  name: PropTypes.oneOf(iconList).isRequired,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['lg', 'sm']),
  title: PropTypes.string,
  'data-testid': PropTypes.string,
};

IconButton.defaultProps = {
  active: false,
  color: 'dark',
  disabled: false,
  href: null,
  onClick: () => {},
  size: 'lg',
  title: null,
  'data-testid': 'IconButton',
};

export default IconButton;
