import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.scss';
import HorizontalListItem from '../HorizontalListItem';

class HorizontalList extends Component {
  constructor(props) {
    super(props);
    this.state = { };
  }

  render() {
    const {
      display,
      divided,
      noWrap,
      verticalAlign,
      spacing,
      children,
      boxView,
    } = this.props;
    const rootClasses = classNames({
      [styles['hList--inline']]: display === 'inline',
    });
    const listClasses = classNames({
      [styles.hList__list]: true,
      [styles['hList__list--inline']]: display === 'inline',
      [styles['hList__list--divided']]: divided,
      [styles['hList__list--noWrap']]: noWrap,
      [styles['hList__list--vMiddle']]: verticalAlign === 'middle',
      [styles['hList__list--vBottom']]: verticalAlign === 'bottom',
      [styles[`hList__list--spaced${spacing}x`]]: `${spacing}` !== '0',
      [styles['hList__list--boxView']]: boxView,
    });

    const filteredChildren = React.Children.map(children, (child) => {
      if (child && child.type === HorizontalListItem) {
        return child;
      }

      return null;
    });

    return (
      <div className={rootClasses}>
        <ul className={listClasses}>{filteredChildren}</ul>
      </div>
    );
  }
}

HorizontalList.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
  display: PropTypes.oneOf(['block', 'inline']),
  divided: PropTypes.bool,
  noWrap: PropTypes.bool,
  spacing: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, '0', '1', '2', '3', '4', '5', '6', '7', '8']),
  verticalAlign: PropTypes.oneOf(['top', 'middle', 'bottom']),
  boxView: PropTypes.bool,
};

HorizontalList.defaultProps = {
  children: null,
  display: 'inline',
  divided: false,
  noWrap: false,
  spacing: 0,
  verticalAlign: 'middle',
  boxView: false,
};

export default HorizontalList;
