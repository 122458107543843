/**
 * This is a better version of recomposes setDisplayName
 * as it doesn't modify the original class
 * @param displayName string name to set
 * @returns {function(*): {propTypes?: React.ValidationMap<P>, contextTypes?: React.ValidationMap<any>, defaultProps?: P, displayName?: string, new(): x}}
 */
const setDisplayName = (displayName) => (Component) => class x extends Component {
    static DisplayName = displayName
};

export default setDisplayName;
