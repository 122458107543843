import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './styles.scss';

const Section = ({
  children,
  divided,
}) => (
  <div className={classNames(css.Section, {
    [css['Section--divided']]: divided,
  })}
  >
    {children}
  </div>
);

Section.propTypes = {
  children: PropTypes.node.isRequired,
  divided: PropTypes.bool,
};

Section.defaultProps = {
  divided: false,
};

export default Section;
