import React from 'react';
import PropTypes from 'prop-types';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import {
  Modal as ModalV1,
  ModalAnimation,
} from '../../index';
import ActionBar from '../../ActionBar';

const Modal = ({
  isOpen,
  header,
  footerActions,
  tertiaryText,
  type,
  onClose,
  children,
  size,
  roundedCorners,
  headerDivider,
  hideFooter,
  bodyColor,
  position,
}) => {
  const footer = (isHidden) => (
    !isHidden && (
      <ActionBar
        footerActions={footerActions}
        tertiaryText={tertiaryText}
      />
    )
  );

  return (
    <TransitionGroup>
      { isOpen
        && (
        <ModalAnimation>
          <ModalV1
            isOpen
            header={header}
            headerDivider={headerDivider}
            hasActionBar
            footer={footer(hideFooter)}
            size={size}
            type={type}
            onCloseClick={onClose}
            roundedCorners={roundedCorners}
            bodyColor={bodyColor}
            position={position}
          >
            {children}
          </ModalV1>
        </ModalAnimation>
        )}
    </TransitionGroup>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  footerActions: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string,
    target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
    onClick: PropTypes.func,
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['primary', 'secondary', 'danger', 'text', 'custom']),
    disabled: PropTypes.bool,
    customComponent: PropTypes.element,
  })),
  tertiaryText: PropTypes.string,
  header: PropTypes.string,
  headerDivider: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['success', 'info', 'secondary', 'danger', 'text']),
  size: PropTypes.oneOf(['small', 'medium', 'large', 'extraLarge']),
  roundedCorners: PropTypes.bool,
  bodyColor: PropTypes.oneOf(['default', 'gray10', 'gray30']),
  hideFooter: PropTypes.bool,
  position: PropTypes.oneOf(['default', 'center']),
};

Modal.defaultProps = {
  footerActions: undefined,
  tertiaryText: '',
  header: undefined,
  headerDivider: true,
  isOpen: false,
  type: 'secondary',
  size: 'medium',
  roundedCorners: true,
  bodyColor: 'default',
  hideFooter: false,
  position: 'default',
};

export default Modal;
