
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class DividedListItem extends Component {
    render() {
      const { children } = this.props;
      const styles = this.context.dividedListStyles;
      return (
        <div className={styles.dividedList__item}>{children}</div>
      );
    }
}

DividedListItem.contextTypes = {
    dividedListStyles: PropTypes.object,
};
