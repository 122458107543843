/* eslint-disable no-console */
import { useRecoilState, useRecoilCallback, RecoilValue } from 'recoil';
import { save, Preferences } from './preferences.factory';
import prefsState from './prefs.state';
import propsState from './props.state';

const useTabs = () => {
  const [, setPrefs] = useRecoilState(prefsState);

  const rcb = useRecoilCallback(({ snapshot }) => (query: RecoilValue<any>) => {
    if (query && snapshot) {
      const { contents } = snapshot.getLoadable(query);
      return contents;
    }
    return null;
  });

  const setTab = (tab: string) => {
    // get the current state of the preferences
    const prefs = rcb(prefsState);
    const props = rcb(propsState);

    if (!props || !prefs) {
      return;
    }

    const tabExists = props.tabs.find(({ id }) => id === tab);
    if (!tabExists) {
      return;
    }

    const updatedPrefs: Preferences = {
      ...prefs,
      defaultTab: tab,
    };

    setPrefs(updatedPrefs);
    save(props, updatedPrefs).catch((err) => console.error(err));
  };

  return { setTab };
};

export default useTabs;
