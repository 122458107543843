import * as React from 'react';
import PropTypes from 'prop-types';
import Styles from './styles.scss';
import Grid, { GridColumn } from '../../Grid';
import Button from '../../legacy/Construction/Button';

const FooterComponent = ({ current, total }) => (
  <div className={Styles.FooterContainer}>
    <Grid>
      <GridColumn xs="6">
        <div className={Styles.FooterContainerLeft}>{`${current} / ${total} selected`}</div>
      </GridColumn>
      <GridColumn xs="6">
        <div className={Styles.FooterContainerRight}>
          <Button
            size="sm"
            type="text"
            label="Done"
          />
        </div>
      </GridColumn>
    </Grid>
  </div>
);

FooterComponent.propTypes = {
  current: PropTypes.number,
  total: PropTypes.number,
};

FooterComponent.defaultProps = {
  current: 0,
  total: 0,
};

export default FooterComponent;
