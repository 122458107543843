import { selector } from 'recoil';
import tabPrefsQuery from './tab-prefs.query';

const defaultPerPage = 100;

const activePerPageQuery = selector<number>({
  key: 'activePerPage',
  get: ({ get }) => {
    const prefs = get(tabPrefsQuery);

    if (!prefs) {
      return 1;
    }

    if (prefs.perPage) {
      return prefs.perPage;
    }

    return defaultPerPage;
  },
});

export default activePerPageQuery;
