/* eslint-disable no-console */
import { useRecoilState, useRecoilCallback, RecoilValue } from 'recoil';
import { save, Preferences, ColumnPreference } from './preferences.factory';
import prefsState from './prefs.state';
import tabPropsQuery from './tab-props.query';
import propsState from './props.state';
import tabPrefsQuery from './tab-prefs.query';
import { availableColumnsQuery } from './active-columns.query';

const useColumns = () => {
  const [, setPrefs] = useRecoilState(prefsState);
  const rcb = useRecoilCallback(({ snapshot }) => (query: RecoilValue<any>) => {
    if (query && snapshot) {
      const { contents } = snapshot.getLoadable(query);
      return contents;
    }
    return null;
  });
  const existing = new Map<string, ColumnPreference>();
  const defaultWidths = new Map<string, number>();

  const setActiveColumns = (columnsWithSize: string) => {
    const prefs = rcb(prefsState);
    const tabPrefs = rcb(tabPrefsQuery);
    const props = rcb(propsState);
    const tabProps = rcb(tabPropsQuery);
    const availableColumns = rcb(availableColumnsQuery);
    const availableColumnIds = availableColumns.map(({ id }) => id);

    if (!props || !tabPrefs || !prefs || !tabProps) {
      return;
    }

    const columns = columnsWithSize
      .split(',')
      .map((p) => p.split(':'))
      .filter((p) => p.length === 2)
      .map(([column]) => column)
      .filter((column) => availableColumnIds.includes(column));

    const updatedPrefs: Preferences = {
      ...prefs,
      tabs: (prefs.tabs || []).map((tab) => {
        if (tab.id === tabPrefs.id) {
          return {
            ...tab,
            columns: columns.map((column) => ({
              id: column,
              ...existing.get(column),
              width:
                existing.get(column)?.width || defaultWidths.get(column) || 180,
            })),
          };
        }

        return tab;
      }),
    };

    setPrefs(updatedPrefs);
    save(props, updatedPrefs).catch((err) => console.error(err));
  };

  const resetActiveColumns = () => {
    const prefs = rcb(prefsState);
    const tabPrefs = rcb(tabPrefsQuery);
    const props = rcb(propsState);
    const tabProps = rcb(tabPropsQuery);
    if (!props || !tabPrefs || !prefs || !tabProps) {
      return;
    }

    const updatedPrefs: Preferences = {
      ...prefs,
      tabs: (prefs.tabs || []).map((tab) => {
        if (tab.id === tabPrefs.id) {
          return {
            ...tab,
            columns: tabProps.columns
              .filter(({ withDefault }) => withDefault)
              .map((column) => ({
                id: column.id,
                width: column.defaultWidth || 180,
              })),
          };
        }

        return tab;
      }),
    };

    setPrefs(updatedPrefs);
    save(props, updatedPrefs).catch((err) => console.error(err));
  };

  return {
    resetActiveColumns,
    setActiveColumns,
  };
};

export default useColumns;
