import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '../TableRow';
import TableCell from '../TableCell';
import css from './styles.scss';

const highlightColor = 'nuetral';

const TableBanner = ({
  children,
  isNotFirstRow,
  width,
  totalColumnWidth,
  lastColumnWidth,
}) => (
  <TableRow
    highlightColor={highlightColor}
    isNotFirstRow={isNotFirstRow}
    totalColumnWidth={totalColumnWidth}
    lastColumnWidth={lastColumnWidth}
  >
    <TableCell
      highlightColor={highlightColor}
      width={width}
    >
      <div className={css.TableBanner__text}>
        {children}
      </div>
    </TableCell>
  </TableRow>
);

TableBanner.propTypes = {
  children: PropTypes.node,
  isNotFirstRow: PropTypes.bool,
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
    PropTypes.string,
  ]),
  totalColumnWidth: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  lastColumnWidth: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

TableBanner.defaultProps = {
  children: null,
  isNotFirstRow: false,
  width: 'auto',
  totalColumnWidth: 'auto',
  lastColumnWidth: 'auto',
};

export default TableBanner;
