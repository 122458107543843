import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { IntlProvider } from 'react-intl';
import Tip from '../../../Tip';


class PopoverContainer extends Component {
  constructor(props) {
      super(props);
      this.state = {};
  }

  getChildren() {
      const kids = [];
      const children = this.props.children;
      let refChild;
      return React.Children.map(children, child => {
          refChild = child;
          const propsObj = {
              key: child.key || uuid(),
          };
          if (typeof child === 'object') {
              if (child && child.type && (child.type.name === 'XhrContent')) {
                  const triggerScroll = function () {
                      window.dispatchEvent(new Event('scroll'));
                  };
                  propsObj.onContentLoaded = triggerScroll;
              }
              refChild = React.cloneElement(child, propsObj);
          }
          return refChild;
      });
  }

  render() {
      const {
          color,
          isFlush,
          widthAuto,
          noArrow,
      } = this.props;
      const Content = () => (
          <Tip color={color} isFlush={isFlush} widthAuto={widthAuto} noArrow={noArrow}>
              {this.getChildren()}
          </Tip>
      );
      if (window.locale && window.localeData) {
          return (
              <IntlProvider locale={window.locale} messages={window.localeData[window.locale]}>
                  <Content />
              </IntlProvider>
          );
      }
      return (
          <Content />
      );
  }
}

PopoverContainer.propTypes = {
  color: PropTypes.oneOf(['white', 'blue']),
  isFlush: PropTypes.bool,
  widthAuto: PropTypes.bool,
  noArrow: PropTypes.bool,
};

PopoverContainer.defaultProps = {
  color: 'white',
  isFlush: false,
  widthAuto: false,
  noArrow: false,
};

export default PopoverContainer;
