import React, { Component } from 'react';
import PropTypes from 'prop-types';
import css from './styles.scss';
import ContentContainer from '../ContentContainer';

export default class DrawerContainer extends Component {
  constructor(props) {
    super(props);
    this.element = React.createRef();
    this.doPositioning = this.doPositioning.bind(this);
  }

  componentDidMount() {
    this.doPositioning();
    window.addEventListener('resize', this.doPositioning);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.doPositioning);
  }

  doPositioning() {
    const { parentId } = this.props;
    if (!parentId) {
      return;
    }
    const $body = document.getElementById(`${parentId}`);
    const rects = $body ? $body.getBoundingClientRect() : {};
    const { top } = rects;

    this.element.current.style.top = `${top}px`;
  }

  render() {
    const {
      children,
      title,
      onBack,
      onClose,
      footerActions,
      customFooter,
      noBodyPadding,
      customHeader,
      customSecondaryHeader,
      footerBackgroundColor,
      headerBackgroundColor,
      headerBorderBottom,
      headerVerticalAlign,
      tertiaryFooter,
    } = this.props;
    return (
      <div
        className={css.DrawerContainer}
        ref={this.element}
      >
        <ContentContainer
          size="sm"
          title={title}
          onBack={onBack}
          onClose={onClose}
          footerActions={footerActions}
          customFooter={customFooter}
          noBodyPadding={noBodyPadding}
          customHeader={customHeader}
          customSecondaryHeader={customSecondaryHeader}
          footerBackgroundColor={footerBackgroundColor}
          headerBackgroundColor={headerBackgroundColor}
          headerBorderBottom={headerBorderBottom}
          headerVerticalAlign={headerVerticalAlign}
          tertiaryFooter={tertiaryFooter}
        >
          {children}
        </ContentContainer>
      </div>
    );
  }
}

DrawerContainer.propTypes = {
  children: PropTypes.node.isRequired,
  parentId: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  footerActions: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['primary', 'secondary']),
    disabled: PropTypes.bool,
    menuItem: PropTypes.bool,
  })),
  customFooter: PropTypes.element,
  noBodyPadding: PropTypes.bool,
  customHeader: PropTypes.element,
  customSecondaryHeader: PropTypes.element,
  footerBackgroundColor: PropTypes.oneOf(['default', 'grey20', 'grey30', 'grey40']),
  headerBackgroundColor: PropTypes.oneOf(['default', 'grey20', 'grey30', 'grey40']),
  headerBorderBottom: PropTypes.bool,
  headerVerticalAlign: PropTypes.oneOf(['middle', 'top', 'bottom']),
  tertiaryFooter: PropTypes.shape({
    action: PropTypes.shape({
      disabled: PropTypes.bool,
      icon: PropTypes.string,
      onClick: PropTypes.func,
    }),
    text: PropTypes.string,
  }),
};

DrawerContainer.defaultProps = {
  parentId: null,
  title: null,
  onBack: undefined,
  onClose: undefined,
  footerActions: [],
  customFooter: null,
  noBodyPadding: false,
  customHeader: null,
  customSecondaryHeader: null,
  footerBackgroundColor: 'default',
  headerBackgroundColor: 'default',
  headerBorderBottom: true,
  headerVerticalAlign: 'middle',
  tertiaryFooter: null,
};
