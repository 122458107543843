
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import textContent from 'react-addons-text-content';

export default class TileDivisionHeading extends Component {
    constructor(props) {
        super(props);
        this.state = { };
    }

    render() {
        const styles = this.context.tileStyles;
        return (
            <h3 className={[styles.Tile__division__hdg]} data-testid={textContent(this.props['data-testid'])}>
                {this.props.children}
            </h3>
        );
    }
}

TileDivisionHeading.contextTypes = {
    tileStyles: PropTypes.object,
};
