import {
  bool, func, number, oneOf, shape, string,
} from 'prop-types';

export const actionType = shape({
  disabled: bool,
  icon: string.isRequired,
  label: string.isRequired,
  onClick: func.isRequired,
});

export const slideType = shape({
  color: oneOf(['green40', 'red40', 'yellow40']),
  icon: string,
  id: number.isRequired,
  src: string,
  selected: bool,
  thumbnail: string,
});
