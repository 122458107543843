import React from 'react';
import PropTypes from 'prop-types';
import Option from '../../../Option';
import HorizontalList from '../../../HorizontalList';
import HorizontalListItem from '../../../HorizontalListItem';
import Menu from '../../../Menu';
import Icon from '../../../Icon';
import css from './styles.scss';

const triggerJSX = () => (
  <button
    type="button"
    className={css.SelectAll__trigger}
  >
    <Icon name="dropDown" />
  </button>
);

const SelectAll = ({
  checkboxState, onCheckboxChange, onSelectEntireData, selectAllLabel, deSelectAllLabel,
}) => {
  function checkBoxWrap(event) {
    onCheckboxChange(event.currentTarget.checked);
  }
  return (
    <HorizontalList
      spacing={0}
      display="inline"
      noWrap
    >
      <HorizontalListItem>
        <Option
          value="check-all-visible"
          indeterminate={checkboxState === 'indeterminate'}
          checked={checkboxState === 'checked'}
          onChange={checkBoxWrap}
          labelHidden
        >
          Select All Visible Rows
        </Option>
      </HorizontalListItem>
      {onSelectEntireData
        && (
        <HorizontalListItem>
          <Menu
            block
            disableAutoWidth
            trigger={triggerJSX}
            menuItems={[
              {
                label: selectAllLabel,
                onClick: () => { onSelectEntireData(true); },
              },
              {
                label: deSelectAllLabel,
                onClick: () => { onSelectEntireData(false); },
              },
            ]}
          />
        </HorizontalListItem>
        )}
    </HorizontalList>
  );
};

SelectAll.propTypes = {
  onSelectEntireData: PropTypes.func,
  checkboxState: PropTypes.oneOf(['checked', 'unchecked', 'indeterminate']),
  onCheckboxChange: PropTypes.func,
  selectAllLabel: PropTypes.string,
  deSelectAllLabel: PropTypes.string,
};

SelectAll.defaultProps = {
  onSelectEntireData: undefined,
  checkboxState: 'unchecked',
  onCheckboxChange: undefined,
  selectAllLabel: 'Select All Pages',
  deSelectAllLabel: 'Deselect All Pages',
};

export default SelectAll;
