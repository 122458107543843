
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import classes from './styles/Omega.scss';

export default class Omega extends Component {
    static childContextTypes = {
        isInNotificationBandOmega: PropTypes.bool,
    };

    constructor(props) {
        super(props);
    }

    getChildContext() {
        return { isInNotificationBandOmega: true };
    }

    render() {
        return (
            <div className={classes.Omega}>{this.props.children}</div>
        );
    }
}
