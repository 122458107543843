import React from 'react';
import HorizontalList from '../HorizontalList/HorizontalList';
import HorizontalListItem from '../HorizontalListItem/HorizontalListItem';

const getItem = (item, viewType, key, boxView) => {
  if (viewType === 'vertical') {
    return item;
  }

  return (
    <HorizontalListItem key={key} boxView={boxView}>{item}</HorizontalListItem>
  );
};

const WrapOptions = (items, viewType, boxView = false) => {
  const itemsAsArray = (Array.isArray(items)) ? [...items] : [items];

  if (viewType === 'vertical') {
    return (
      itemsAsArray.map((item, i) => getItem(item, viewType, i))
    );
  }

  return (
    <HorizontalList
      spacing={3}
      key="menu"
      boxView={boxView}
    >
      {itemsAsArray.map((item, i) => getItem(item, viewType, i, boxView))}
    </HorizontalList>
  );
};

export default WrapOptions;
