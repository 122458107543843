import { selector } from 'recoil';
import requestQuery from './request.query';
import tabPrefsQuery from './tab-prefs.query';
import tabPropsQuery from './tab-props.query';

const defaultWidth = 180;

/**
 * Returns a Map of column ids to their widths, with widths defined in
 * the following order:
 *
 * 1) By user preferences
 * 2) By the API response
 * 3) By the props provided to <DataGrid>
 * 4) Default of 180
 *
 * @returns {Map<string, number>}
 */
const columnWidthsQuery = selector<Map<string, number>>({
  key: 'columnWidthsQuery',
  get: ({ get }) => {
    const apiResponse = get(requestQuery);
    const props = get(tabPropsQuery);
    const prefs = get(tabPrefsQuery);

    const resp = new Map<string, number>();

    if (!props || !prefs || !apiResponse) {
      return resp;
    }

    // first: overwrite by API response, if the server is telling us a width:
    if (apiResponse) {
      const { metadata: { columns = '' } } = apiResponse;

      columns.split(',').forEach((str) => {
        const parts = str.split(':');
        if (parts.length === 2) {
          const [colName, colWidth] = parts;
          resp.set(colName, Number(colWidth) || defaultWidth);
        }
      });
    }

    // second: default or prop value
    props.columns.forEach((col) => {
      resp.set(col.id, col.defaultWidth || defaultWidth);
    });

    // finally: preferences is front of the line
    prefs.columns?.forEach((col) => {
      if (col.width) {
        resp.set(col.id, col.width);
      }
    });

    return resp;
  },
});

export default columnWidthsQuery;
