import { useRecoilState, useRecoilCallback, RecoilValue } from 'recoil';
import { save, ColumnPreference } from './preferences.factory';
import activeColumnsQuery from './active-columns.query';
import prefsState from './prefs.state';
import tabPrefsQuery from './tab-prefs.query';
import tabPropsQuery from './tab-props.query';
import propsState from './props.state';

const usePreferenceColumn = () => {
  const [, setPrefs] = useRecoilState(prefsState);

  const rcb = useRecoilCallback(({ snapshot }) => (query: RecoilValue<any>) => {
    if (query && snapshot) {
      const { contents } = snapshot.getLoadable(query);
      return contents;
    }
    return null;
  });

  return (columnId: string, columnPrefs: Partial<ColumnPreference>) => {
    const prefs = rcb(prefsState);
    const tabPrefs = rcb(tabPrefsQuery);
    const tabProps = rcb(tabPropsQuery);
    const props = rcb(propsState);
    const columns = rcb(activeColumnsQuery);

    if (!tabProps || !tabPrefs || !prefs || !props) {
      return;
    }

    const updatedColumns = columns?.map((col) => {
      const existingColumnPref = (tabPrefs?.columns || []).find(
        ({ id: cid }) => cid === col.id,
      );

      const {
        id, width, sorted, sortDirection,
      }: ColumnPreference = {
        ...col,
        ...existingColumnPref,
        ...(col.id === columnId ? columnPrefs : undefined),
      };

      return {
        id,
        width,
        // if we're making a new sort, clear all other sorts:
        sorted: col.id !== columnId && columnPrefs.sorted ? false : sorted,
        sortDirection,
      };
    });

    const updatedPrefs = {
      ...prefs,
      tabs: (prefs?.tabs || []).map((tab) => {
        if (tab.id === tabPrefs?.id) {
          return { ...tab, columns: updatedColumns };
        }

        return tab;
      }),
    };
    setPrefs(updatedPrefs);
    // eslint-disable-next-line no-console
    save(props, updatedPrefs).catch((err) => console.error(err));
  };
};

export default usePreferenceColumn;
