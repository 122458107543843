import React, { Component } from 'react';
import ReactDOM from 'react-dom';

const PANEL_SELECTOR = '.js-panelOverlay';
export default class PanelAnimation extends Component {
    constructor(props) {
        super(props);
    }
    reset() {
        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = null;
    }
    componentWillEnter(callback) {
        this.interval = setInterval(() => {
            const modal = document.querySelector(PANEL_SELECTOR);
            if (!modal) {
                return;
            }
            this.reset();
            TweenMax.fromTo(modal, .25, { y: '100%' }, { y: '0%', onComplete: callback });
        }, 25);
    }
    componentWillUnmount() {
        this.reset();
    }
    componentWillLeave(callback) {
        if (!this.interval) {
            const modal = document.querySelector(PANEL_SELECTOR);
            TweenMax.fromTo(modal, .25, { y: '0%' }, { y: '100%', onComplete: callback });
        }
    }
    render() {
        return (
            this.props.children
        );
    }
}
