import React, { Component } from 'react';
import ReactDOM from 'react-dom';

const SAVE_BAR_SELECTOR = '.js-saveBar';

export default class PanelAnimation extends Component {
    constructor(props) {
        super(props);
    }

    reset() {
        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = null;
    }

    componentWillEnter(callback) {
        if (document) {
            this.interval = setInterval(() => {
                const saveBar = document.querySelector(SAVE_BAR_SELECTOR);

                if (!saveBar) {
                    return;
                }

                this.reset();

                TweenMax.fromTo(saveBar, .2, { y: '100%' }, { y: '0%', onComplete: callback });
            }, 25);
        }
    }

    componentWillUnmount() {
        this.reset();
    }

    componentWillLeave(callback) {
        if (document) {
            const saveBar = document.querySelector(SAVE_BAR_SELECTOR);
            TweenMax.fromTo(saveBar, .2, { y: '0%' }, { y: '100%', onComplete: callback });
        }
    }

    render() {
        return (
            this.props.children
        );
    }
}
