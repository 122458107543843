
import PropTypes from 'prop-types';

import React, { Component } from 'react';

export default class TileSectionAction extends Component {
    constructor(props) {
        super(props);
        this.state = { };
    }

    render() {
        const styles = this.context.tileStyles;
        return (
            <div className={[styles.Tile__division__action]}>
                {this.props.children}
            </div>
        );
    }
}

TileSectionAction.contextTypes = {
    tileStyles: PropTypes.object,
};
