import React, { Component } from 'react';
import componentClasses from './BodyCopy/styles/BodyCopy.scss';

export default class BodyCopy extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={componentClasses.BodyCopy}>
                {this.props.children}
            </div>
        );
    }
}
