import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import classes from './Svg.scss';

const getPath = (name) => {
  let iconContent = null;
  switch (name) {
    case 'radio-base':
      iconContent = (
        <path d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0z M12,21.6c-5.3,0-9.6-4.3-9.6-9.6S6.7,2.4,12,2.4
      s9.6,4.3,9.6,9.6S17.3,21.6,12,21.6z"
        />
      );
      break;
    case 'radio-checked':
      iconContent = (
        <path d="M12,6c-3.3,0-6,2.7-6,6s2.7,6,6,6s6-2.7,6-6S15.3,6,12,6z M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0z
      M12,21.6c-5.3,0-9.6-4.3-9.6-9.6S6.7,2.4,12,2.4s9.6,4.3,9.6,9.6S17.3,21.6,12,21.6z"
        />
      );
      break;
    case 'checkbox-checked':
      iconContent = (
        <path d="M21.3,0H2.7C1.2,0,0,1.2,0,2.7v18.7C0,22.8,1.2,24,2.7,24h18.7c1.5,0,2.7-1.2,2.7-2.7V2.7C24,1.2,22.8,0,21.3,0z M9.3,18.7
      L2.7,12l1.9-1.9l4.8,4.8L19.5,4.8l1.9,1.9L9.3,18.7z"
        />
      );
      break;
    case 'checkbox-base':
      iconContent = (
        <path d="M21.3,2.7v18.7H2.7V2.7H21.3 M21.3,0H2.7C1.2,0,0,1.2,0,2.7v18.7C0,22.8,1.2,24,2.7,24h18.7c1.5,0,2.7-1.2,2.7-2.7V2.7
      C24,1.2,22.8,0,21.3,0z"
        />
      );
      break;
    case 'checkbox-indeterminate':
      iconContent = (
        <path d="M21.3,0H2.7C1.2,0,0,1.2,0,2.7v18.7C0,22.8,1.2,24,2.7,24h18.7c1.5,0,2.7-1.2,2.7-2.7V2.7C24,1.2,22.8,0,21.3,0z M18.7,13.3
      H5.3v-2.7h13.3V13.3z"
        />
      );
      break;
    default:
      iconContent = null;
  }
  return iconContent;
};

const Svg = ({
  checked,
  disabled,
  indeterminate,
  type,
}) => {
  let secondary = '';
  if (indeterminate && type === 'checkbox') {
    secondary += 'indeterminate';
  } else if (checked) {
    secondary += 'checked';
  }
  const animationDuration = parseInt(classes['svg-transition'], 10);
  return (
    <div className={classes.Svg}>
      <svg
        className={classNames([classes.Svg__elem, classes['Svg__elem--base']], {
          [classes['Svg__elem--disabled']]: disabled,
          [classes['Svg__elem--hidden']]: disabled && (checked || indeterminate),
        })}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        {getPath(`${type}-base`)}
      </svg>
      <ReactCSSTransitionGroup transitionName="Svg" transitionEnterTimeout={animationDuration} transitionLeaveTimeout={animationDuration}>
        {Boolean(secondary)
        && (
          <svg
            className={classNames([classes.Svg__elem, classes['Svg__elem--secondary']], {
              [classes['Svg__elem--selected']]: checked,
              [classes['Svg__elem--disabled']]: disabled,
              [classes['Svg__elem--indeterminate']]: indeterminate,
            })}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            {getPath(`${type}-${secondary}`)}
          </svg>
        )}
      </ReactCSSTransitionGroup>
    </div>
  );
};

Svg.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  indeterminate: PropTypes.bool,
  type: PropTypes.oneOf(['radio', 'checkbox']),

};

Svg.defaultProps = {
  checked: false,
  disabled: false,
  indeterminate: false,
  type: 'checkbox',
};

export default Svg;
