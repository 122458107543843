import React from 'react';
import protectedProps from '../AbstractInput/protectedProps';
import AbstractInput from '../AbstractInput';

export const textToCurrency = (event) => {
  const [number, decimal] = event.target.value.toString()
    .replace(/[^0-9.]/gi, '')
    .split('.');
  const isDecimal = typeof decimal !== 'undefined';
  const value = `${number}${isDecimal ? `.${decimal.substring(0, 2)}` : ''}`;
  return {
    ...event,
    target: { ...event.target, value },
    currentTarget: { ...event.currentTarget, value },
  };
};

const CurrencyInput = (intialProps) => {
  const props = {
    ...intialProps,
    iconOrientation: 'left',
    icon: 'dollar',
    onChange: (event) => intialProps.onChange(textToCurrency(event)),
    type: 'text',
  };

  return (
    <AbstractInput
      {...props}
    />
  );
};

CurrencyInput.defaultProps = {
  label: 'Currency',
};

export default protectedProps(CurrencyInput);
