
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import classNames from 'classnames';

export default class PanelBoxBody extends Component {
    constructor(props) {
        super(props);
        this.state = { };
    }

    render() {
        const styles = this.context.panelStyles;
        const bodyContainerClasses = classNames({
            [styles.Panel__bd__container]: true,
            [styles['u-static']]: this.context.panelPosition === 'static',
        });
        // position
        return (
            <div className={[styles.Panel__bd]}>
                <div className={bodyContainerClasses}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

PanelBoxBody.propTypes = {

};

PanelBoxBody.defaultProps = {

};

PanelBoxBody.contextTypes = {
    panelStyles: PropTypes.object,
    panelPosition: PropTypes.oneOf(['static', 'absolute']),
};
