/**
 * This component is intended to be a lower-level style componenent to be
 * consumed by higher-level, composed components. The Container componet will
 * provide container (padding, border, margin, backround-color) and
 * layout styling (flex-direction, flex-grow), but will not provide any
 * content styling (font-size, color, iconography).
 *
 * The props of the Container component will correlate directly to
 * styles (marginBottom, pulledHorizontal) that it applies, and the
 * props of the higher-level components will have props for named sections
 * (header, body, footer). Examples of components that could use Container:
 *    - Modal
 *    - Panel
 *    - Tile
 *    - Box
 *    - Card
 */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import css from './styles.scss';

const AbstractContainer = ({
  borderBottom,
  borderLeft,
  borderRight,
  borderTop,
  children,
  disablePadding,
  fullHeight,
  marginBottom,
  marginTop,
  pulledBottom,
  pulledTop,
  pulledHorizontal,
  flexDirection,
  flexGrow,
  scroll,
  backgroundColor,
  width,
}) => {
  let divProps = {};
  if (width) {
    divProps = {
      style: {
        width: Number(width) === width ? `${width}px` : width,
      },
    };
  }
  return (
    <div
      {...divProps}
      className={classNames(css.AbstractContainer, {
        [css['AbstractContainer--disablePadding']]: disablePadding,
        [css['AbstractContainer--fullHeight']]: fullHeight,
        [css['AbstractContainer--pulledTop']]: pulledTop,
        [css['AbstractContainer--pulledBottom']]: pulledBottom,
        [css['AbstractContainer--pulledHorizontal']]: pulledHorizontal,
        [css['AbstractContainer--borderBottom']]: borderBottom,
        [css['AbstractContainer--borderTop']]: borderTop,
        [css['AbstractContainer--borderRight']]: borderRight,
        [css['AbstractContainer--borderLeft']]: borderLeft,
        [css['AbstractContainer--marginBottom']]: marginBottom,
        [css['AbstractContainer--marginTop']]: marginTop,
        [css['AbstractContainer--flexColumn']]: flexDirection === 'column',
        [css['AbstractContainer--flexRow']]: flexDirection === 'row',
        [css['AbstractContainer--flexGrow']]: flexGrow,
        [css['AbstractContainer--scroll']]: scroll,
        [css[`AbstractContainer--backgroundColor--${backgroundColor}`]]: true,
      })}
    >
      {children}
    </div>
  );
};

AbstractContainer.propTypes = {
  /** Adds border bottom */
  borderBottom: PropTypes.bool,
  /** Adds border left */
  borderLeft: PropTypes.bool,
  /** Adds border right */
  borderRight: PropTypes.bool,
  /** Adds border top */
  borderTop: PropTypes.bool,
  children: PropTypes.node.isRequired,
  /** Removes default padding */
  disablePadding: PropTypes.bool,
  /** Adds height 100% */
  fullHeight: PropTypes.bool,
  /** Adds margin-bottom with the same value as the padding */
  marginBottom: PropTypes.bool,
  /** Adds margin-top with the same value as the padding */
  marginTop: PropTypes.bool,
  /** Adds negative margin-bottom with the same value as the padding */
  pulledBottom: PropTypes.bool,
  /** Adds negative margin-top with the same value as the padding */
  pulledTop: PropTypes.bool,
  /** Adds negative margin-right and margin-left with the same value as the padding */
  pulledHorizontal: PropTypes.bool,
  /** Adds display flex and flex-direction column or row */
  flexDirection: PropTypes.oneOf(['column', 'row']),
  /** Adds flex-grow: 1 */
  flexGrow: PropTypes.bool,
  /** Adds overflow: auto */
  scroll: PropTypes.bool,
  /** Adds backgroun color */
  backgroundColor: PropTypes.oneOf(['default', 'grey20', 'grey30', 'grey40']),
  width: PropTypes.oneOf(['string', 'number']),
};

AbstractContainer.defaultProps = {
  borderBottom: false,
  borderLeft: false,
  borderRight: false,
  borderTop: false,
  disablePadding: false,
  fullHeight: false,
  marginBottom: false,
  marginTop: false,
  pulledBottom: false,
  pulledTop: false,
  pulledHorizontal: false,
  flexDirection: null,
  flexGrow: false,
  scroll: false,
  backgroundColor: 'default',
  width: null,
};

export default AbstractContainer;
