import React from 'react';
import TextInput from '../TextInput/TextInput';
import classes from './CreditCardInput.scss';
import getCard from './creditCards';

const issuerIdNumber = {
    amex: ['34', '37'],
    discover: ['6011', '64', '65'],
    mastercard: ['2221-2720', '51-55'],
    visa: ['4'],
};

const DEFAULT_MASK = '9999 9999 9999 9999';

export const masks = {
    amex: '9999 999999 99999',
    visa: DEFAULT_MASK,
    mastercard: DEFAULT_MASK,
    discover: DEFAULT_MASK,
};

export const getIssuer = (value) => {
    if (value.length > 0) {
        return Object.keys(issuerIdNumber).reduce((acc, key) => acc === false ? (
            issuerIdNumber[key].some((num) => {
                if (num.indexOf('-') > 0) {
                    const [min, max] = num.split('-').map(x => parseInt(x, 10));
                    const acting = parseInt(value.slice(0, min.toString().length), 10);
                    return min <= acting && max >= acting;
                }
                return num === value.slice(0, num.length);
            }) ? key : acc
        ) : acc, false);
    }
    return false;
};

const CreditCardInput = (props) => {
    const issuer = getIssuer(props.value);
    return (<div className={classes.CreditCardInput}>
        {issuer && (<div className={classes.CreditCardInput__icon}>{getCard(issuer)}</div>)}
        <TextInput
            {...props}
            hasAppend
            maskChar={null}
            mask={masks[issuer] || DEFAULT_MASK} />
    </div>);
};
export default CreditCardInput;
