import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import { Button } from '../index';
import File from './File';
import wrapEventHandler, { ARG_ORDER } from '../utils/wrapEventHandler';

import css from './FileInput.scss';

const DEFAULT_BUTTON_LABEL = 'Add File';

class FileInput extends PureComponent {
  deleteFile(file, event) {
    const { onDeleteFile } = this.props;
    event.stopPropagation();
    onDeleteFile(file);
  }

  render() {
    const {
      accept,
      buttonLabel,
      multiple,
      files,
      helpText,
      onDrop,
      uploadLabelText,
      showThumbnail,
    } = this.props;
    const disabled = multiple === false && files.length > 0;
    return (
      <div>
        <Dropzone
          accept={accept}
          disabled={disabled}
          multiple={multiple}
          onDrop={onDrop}
        >
          {({
            getRootProps,
            getInputProps,
            isDragActive,
            isDragAccept,
            isDragReject,
          }) => (
            <div
              {...getRootProps()}
              className={classNames(css.FileInput, {
                [css['FileInput--activeAccept']]: isDragActive && isDragAccept,
                [css['FileInput--activeReject']]: isDragActive && isDragReject,
                [css['FileInput--disabled']]: disabled,
              })}
            >
              {!disabled && (
                <div className={classNames([css.FileInput__section, css['FileInput__section--center']])}>
                  <input {...getInputProps()} />
                  <div>
                    {uploadLabelText
                      ? (
                        <>
                          <div>{uploadLabelText}</div>
                          <span>
                            or
                            <Button
                              className={css['FileInput__upload-button']}
                              type="primary-reverse"
                            >
                              {buttonLabel || DEFAULT_BUTTON_LABEL}
                            </Button>
                          </span>
                        </>
                      )
                      : (
                        <>
                          <Button>{buttonLabel || DEFAULT_BUTTON_LABEL}</Button>
                          &nbsp;&nbsp;
                          or drag and drop here to upload
                        </>
                      )}
                  </div>
                  <div className={css.FileInput__helpText}>{helpText}</div>
                </div>
              )}
              {files.length > 0
                && (
                  <div className={css.FileInput__section}>
                    {files.map((file) => (
                      <File
                        key={file.name}
                        name={file.name}
                        size={file.size}
                        type={file.type}
                        thumbnail={file.thumbnail}
                        showThumbnail={showThumbnail}
                        percentUploaded={file.percentUploaded}
                        onDelete={wrapEventHandler(this.deleteFile, [file], ARG_ORDER.EVENT_LAST, this)}
                      />
                    ))}
                  </div>
                )}
            </div>
          )}
        </Dropzone>
      </div>
    );
  }
}

FileInput.propTypes = {
  accept: PropTypes.string,
  buttonLabel: PropTypes.string,
  files: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    type: PropTypes.string,
  })),
  helpText: PropTypes.string,
  multiple: PropTypes.bool,
  onDeleteFile: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  uploadLabelText: PropTypes.string,
  showThumbnail: PropTypes.bool,
};

FileInput.defaultProps = {
  accept: undefined,
  buttonLabel: undefined,
  files: [],
  helpText: 'All document and image file types supported',
  multiple: false,
  uploadLabelText: undefined,
  showThumbnail: false,
};

export default FileInput;
