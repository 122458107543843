import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ContainerQuery } from 'react-container-query';
import cssClasses from './FormGrid/styles/FormGrid.scss';
import remsToPixels from '../../../src/utils/remsToPixels';
import FormGridRow from './FormGrid/FormGridRow.jsx';
import FormGridColumn from './FormGrid/FormGridColumn.jsx';

export default class FormGrid extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = { };
    }

    getChildContext() {
        return {
            formGridClasses: cssClasses,
        };
    }

    render() {
        const props = this.props;
        if (this.props.containerSize) {
            const query = {
                minWidth: {
                    minWidth: remsToPixels(`${this.props.containerSize}rem`),
                },
            };
            return (
                <ContainerQuery query={query}>
                    {params => (
                        <div className={cssClasses.FormGrid}>
                            {React.Children.map(props.children, child => React.cloneElement(child, {
                                query: params,
                            }))}
                        </div>
                    )}
                </ContainerQuery>
            );
        }
        return (
            <div className={cssClasses.FormGrid}>{this.props.children}</div>
        );
    }
}

FormGrid.propTypes = {
    containerSize: PropTypes.number,
};

FormGrid.defaultProps = {
    containerSize: null,
};

FormGrid.childContextTypes = {
    formGridClasses: PropTypes.object,
};

export {
    FormGridRow,
    FormGridColumn,
};
