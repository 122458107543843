/* eslint consistent-return: 0 */
/* eslint no-return-assign: 0 */
import PropTypes from 'prop-types';
import React from 'react';
import SortableJS from 'sortablejs';

const store = {
  nextSibling: null,
  activeComponent: null,
};

class Sortable extends React.Component {
  constructor(props) {
    super(props);
    this.sortableRef = React.createRef();
  }

  componentDidMount() {
    const { options, onChange } = this.props;

    [
      'onChoose',
      'onStart',
      'onEnd',
      'onAdd',
      'onUpdate',
      'onSort',
      'onRemove',
      'onFilter',
      'onMove',
      'onClone'].forEach((name) => {
      const eventHandler = options[name];

      options[name] = (...params) => {
        const [evt] = params;

        if (name === 'onChoose') {
          store.nextSibling = evt.item.nextElementSibling;
          store.activeComponent = this;
        } else if ((name === 'onAdd' || name === 'onUpdate') && onChange) {
          const items = this.sortable.toArray();
          const remote = store.activeComponent;
          const remoteItems = remote.sortable.toArray();

          const referenceNode = (store.nextSibling && store.nextSibling.parentNode !== null)
            ? store.nextSibling : null;
          evt.from.insertBefore(evt.item, referenceNode);
          if (remote !== this) {
            const remoteOptions = remote.props.options || {};

            if ((typeof remoteOptions.group === 'object') && (remoteOptions.group.pull === 'clone')) {
            // Remove the node with the same data-reactid
              evt.item.parentNode.removeChild(evt.item);
            }

            // remote.props.onChange && remote.props.onChange(remoteItems, remote.sortable, evt);
            if (onChange) {
              onChange(remoteItems, remote.sortable, evt);
            }
          }

          if (onChange) {
            onChange(items, this.sortable, evt);
          }
        }

        if (evt.type === 'move') {
          const [originalEvent] = params;
          const canMove = eventHandler ? eventHandler(evt, originalEvent) : true;
          return canMove;
        }

        setTimeout(() => {
          if (eventHandler) {
            eventHandler(evt);
          }
        }, 0);
      };
    });

    this.sortable = SortableJS.create(this.sortableRef.current, options);
  }

  componentWillUnmount() {
    if (this.sortable) {
      this.sortable.destroy();
      this.sortable = null;
    }
  }

  sortable = null;

  render() {
    const { tag: Component, ...props } = this.props;
    delete props.options;
    delete props.onChange;

    return (
      <Component
        {...props}
        ref={this.sortableRef}
      />
    );
  }
}

Sortable.propTypes = {
  options: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.node,
  ])),
  onChange: PropTypes.func,
  tag: PropTypes.string,
  style: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.node,
  ])),
};

Sortable.defaultProps = {
  options: {},
  tag: 'div',
  style: {},
  onChange: undefined,
};

export default Sortable;
