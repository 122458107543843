import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Table, { TablePropTypes } from '../Table';
import MassActionBar from '../../MassActionBar';
import Toolbar from '../../Toolbar';
import ActionTabs, { ActionTabsPropTypes } from '../../ActionTabs';
import wrapEventHandler, { ARG_ORDER } from '../../utils/wrapEventHandler';
import css from './styles.scss';
import SummaryView, { SummaryViewPropTypes } from './SummaryView';

export default class Listing extends React.PureComponent {
  constructor(props) {
    super(props);
    const {
      activeView,
    } = props;
    this.state = {
      activeView,
      headerOffset: 0,
    };
    this.toolbarRef = React.createRef();
    this.changeView = this.changeView.bind(this);
  }

  componentDidMount() {
    this.recalculateOffset();
  }

  componentDidUpdate() {
    this.recalculateOffset();
  }

  changeView(view, event, cb) {
    this.setState({ activeView: view }, () => cb(view));
  }

  recalculateOffset() {
    const { tabItems, toolbarEnabled } = this.props;
    const { headerOffset } = this.state;
    let localHeaderOffset;
    // No toolbar reset to 0
    if (!toolbarEnabled) {
      localHeaderOffset = 0;
    // Full toolbar - do not recalculate top offset, use static value
    } else if (tabItems.length > 0 && toolbarEnabled) {
      localHeaderOffset = 5.6;
    // Toolbar without tabs calculate value
    } else if (get(this.toolbarRef, 'current.offsetHeight', 0) > 0) {
      localHeaderOffset = this.toolbarRef.current.offsetHeight / 10;
    }
    if (localHeaderOffset && headerOffset !== localHeaderOffset) {
      this.setState({ headerOffset: localHeaderOffset });
    }
  }

  renderBars() {
    const {
      views,
      toolbarEnabled,
      toolbarConfig,
      massActionBarConfig,
      massActionBarEnabled,
      selectedIds,
      toolbarConfig: { savedFilters, onDeleteSavedFilter, onSaveFilters },
      onViewChange,
      closeOnSelection,
    } = this.props;

    const {
      activeView,
    } = this.state;

    let jsx = '';

    const { actions } = massActionBarConfig || {};
    if (selectedIds.length && massActionBarEnabled && actions) {
      jsx = (
        <MassActionBar actions={actions} closeOnSelection={closeOnSelection} />
      );
    } else if (toolbarConfig && toolbarEnabled) {
      jsx = (
        <Toolbar
          {...toolbarConfig}
          activeView={activeView}
          views={views}
          onViewChange={wrapEventHandler(this.changeView, [onViewChange], ARG_ORDER.ARGS_FIRST, this)}
          onSaveFilters={onSaveFilters}
          savedFilters={savedFilters}
          onDeleteSavedFilter={onDeleteSavedFilter}
        />
      );
    }

    if (jsx) {
      jsx = (
        <div className={css.Listing__barContainer} ref={this.toolbarRef}>
          {jsx}
        </div>
      );
    }
    return jsx;
  }

  renderTabs() {
    const {
      tabItems,
    } = this.props;
    let jsx = '';
    if (tabItems.length > 0) {
      jsx = (
        <div className={css.Listing__TabsContainer}>
          <ActionTabs items={tabItems} />
        </div>
      );
    }
    return jsx;
  }

  renderSummaryView() {
    const {
      summaryViewConfig,
    } = this.props;
    if (summaryViewConfig?.data?.length > 0) {
      return <SummaryView {...summaryViewConfig} />;
    }
    return null;
  }

  render() {
    const {
      selectedIds,
      tableConfig,
    } = this.props;

    const {
      activeView,
      headerOffset,
    } = this.state;
    return (
      <div>
        {this.renderBars()}
        {this.renderTabs()}
        {this.renderSummaryView()}
        {
          activeView === 'list'
          && <Table {...tableConfig} selectedIds={selectedIds} headerOffset={headerOffset} />
        }
      </div>
    );
  }
}

Listing.propTypes = {
  closeOnSelection: PropTypes.bool,
  activeView: PropTypes.string,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  tableConfig: PropTypes.shape(TablePropTypes),
  toolbarEnabled: PropTypes.bool,
  toolbarConfig: PropTypes.shape(Toolbar.propTypes),
  massActionBarEnabled: PropTypes.bool,
  massActionBarConfig: PropTypes.shape(MassActionBar.propTypes),
  views: PropTypes.arrayOf(PropTypes.oneOf(['card', 'list', 'map'])),
  onViewChange: PropTypes.func,
  tabItems: ActionTabsPropTypes.items,
  summaryViewConfig: SummaryViewPropTypes,
};

Listing.defaultProps = {
  closeOnSelection: false,
  activeView: 'list',
  selectedIds: [],
  tableConfig: undefined,
  toolbarEnabled: false,
  massActionBarEnabled: false,
  toolbarConfig: {},
  massActionBarConfig: {},
  views: ['card', 'list', 'map'],
  onViewChange: () => {},
  tabItems: [],
  summaryViewConfig: undefined,
};
