import { selector } from 'recoil';
import requestQuery from './request.query';

const totalResultsQuery = selector<number>({
  key: 'totalResults',
  get: ({ get }) => {
    const apiResponse = get(requestQuery);

    if (!apiResponse) {
      return 0;
    }

    const { metadata } = apiResponse;

    return metadata.total || 0;
  },
});

export default totalResultsQuery;
