import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import css from './styles.scss';
import withDeprecation from '../utils/hoc/withDeprecation';

const Typography = ({
  tag: Tag,
  styleLevel,
  children,
}) => (
  <Tag
    className={
      classNames({
        [css['Typography--title']]: styleLevel === 'title',
        [css['Typography--heading']]: styleLevel === 'heading',
        [css['Typography--body']]: styleLevel === 'body',
        [css['Typography--bodySemibold']]: styleLevel === 'body-semi-bold',
        [css['Typography--sub']]: styleLevel === 'sub',
        [css['Typography--subBold']]: styleLevel === 'sub-bold',
      })
    }
  >
    {children}
  </Tag>
);

Typography.propTypes = {
  children: PropTypes.node.isRequired,
  tag: PropTypes.oneOf([
    'div',
    'span',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
  ]).isRequired,
  styleLevel: PropTypes.oneOf([
    'title',
    'heading',
    'body',
    'body-semi-bold',
    'body-italic',
    'sub',
    'sub-bold',
  ]),
};

Typography.defaultProps = {
  styleLevel: 'body',
};

export default withDeprecation(
  Typography,
  'Use <Header/> or <BodyText/> component as appropritate.',
);
