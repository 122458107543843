import moment from 'moment';

function formatRelativeTime(date) {
  const duration = moment.duration(moment().diff(date));
  const years = duration.asYears();
  if (years >= 1) {
    return `${Math.floor(years)}y`;
  }
  const months = duration.asMonths();
  if (months >= 1) {
    return `${Math.floor(months)}M`;
  }
  const days = duration.asDays();
  if (days >= 1) {
    return `${Math.floor(days)}d`;
  }
  const hours = duration.asHours();
  if (hours >= 1) {
    return `${Math.floor(hours)}h`;
  }
  const minutes = duration.asMinutes();
  if (minutes >= 1) {
    return `${Math.floor(minutes)}m`;
  }
  return 'now';
}

const getDateTime = (date = '') => {
  if (date === '') return '';

  const mom = moment.utc(date).local();
  return formatRelativeTime(mom);
};

export default getDateTime;
