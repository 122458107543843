import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';

export default class Filler extends PureComponent {
  static propTypes = {
    bottomOffset: PropTypes.number,
    minHeight: PropTypes.number,
  }

  static defaultProps = {
    bottomOffset: 20,
    minHeight: 0,
  }

  constructor(props, context) {
    super(props, context);
    this.state = { height: 0 };
    this.node = React.createRef();
    this.onResize = debounce(this.onResize.bind(this), 150);
  }

  onResize() {
    this.setHeight();
  }

  setHeight() {
    if (this.node.current) {
      const rect = this.node.current.getBoundingClientRect();
      let height = Math.floor(window.innerHeight) - (Math.ceil(rect.top) + this.props.bottomOffset);
      if (this.props.minHeight > 0 && height < this.props.minHeight) {
          height = this.props.minHeight;
      }
      // PID-4480 - Guard setState from triggering endless loop of didUpdate and setState
      if (height !== this.state.height) {
        this.setState({ height });
      }
    }
  }

  componentDidUpdate(prevProps) {
    // PID-4480 Only update height if props have changed
    if(!isEqual(prevProps, this.props)) {
      this.setHeight();
    }
  }

  componentDidMount() {
    this.setHeight();
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    this.onResize.cancel();
    window.removeEventListener('resize', this.onResize);
  }

  render() {
    const { height } = this.state;
    const { render, children } = this.props;

    // If render function provided -- pass 'height' via callback
    if (render && typeof render === 'function') {
      return (
        <div
          ref={this.node}
        >
          {render(height)}
        </div>
      );
    }
    // if children -- pass 'height' via prop
    return (
        <div
          ref={this.node}
        >
          {
            React.Children.map(children, (child) =>
              React.cloneElement(child, { height: height }))
          }
        </div>
    );
  }
}
