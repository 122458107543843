import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Section from '../Section';
import FilterInput from './FilterInput';
import wrapEventHandler, { ARG_ORDER } from '../utils/wrapEventHandler';
import css from './FilterDrawerContent.scss';

const getValueFromEvent = (event) => {
  if (event.value) {
    return event.value;
  }
  if (event.currentTarget) {
    return event.currentTarget.value;
  }
  return event;
};

const FilterDrawerContent = ({
  onChangeFilter,
  onApplyFilters,
  filters,
  filterOptions,
}) => {
  function filterChangeHandler(filterKey, event) {
    onChangeFilter(filterKey, getValueFromEvent(event));
  }
  return (
    <>
      {filterOptions.map((option) => (
        <fieldset key={option.groupLegend}>
          <legend className={classNames({
            [css['FilterDrawerContent__legend--hidden']]: option.groupLegendHidden,
          })}
          >
            {option.groupLegend}
          </legend>
          {option.filters.map((filter) => (
            <Section key={filter.key}>
              <FilterInput
                type={filter.type}
                id={filter.key}
                label={filter.label}
                optional
                onApplyFilters={onApplyFilters}
                value={filters[filter.key] ? filters[filter.key] : undefined}
                onChange={wrapEventHandler(filterChangeHandler, [filter.key], ARG_ORDER.EVENT_LAST)}
                options={filter.options}
              />
            </Section>
          ))}
        </fieldset>
      ))}
    </>
  );
};

FilterDrawerContent.propTypes = {
  onChangeFilter: PropTypes.func,
  onApplyFilters: PropTypes.func,
  filters: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  filterOptions: PropTypes.arrayOf(PropTypes.shape({
    groupLegend: PropTypes.string,
    groupLegendHidden: PropTypes.bool,
    filters: PropTypes.object,
  })).isRequired,
};

FilterDrawerContent.defaultProps = {
  onChangeFilter: undefined,
  onApplyFilters: undefined,
  filters: {},
};

export default FilterDrawerContent;
