import PropTypes from 'prop-types';

import React, { Component } from 'react';
import classNames from 'classnames';

export default class ButtonGroup extends Component {
    render() {
        const buttons = [];
        const groupClasses = classNames({
            'o-hList o-hList--spaced1x': true,
            'u-textRight': this.props.textAlign === 'right',
        });
        const children = this.props.children;
        React.Children.forEach(children, (child, i) => {
            if (child && child.type) {
                buttons.push(<span className="o-hList__item" key={i}>{child}</span>);
            }
        });

        return (
            <div className={groupClasses}>
                {buttons}
            </div>
        );
    }
}

ButtonGroup.propTypes = {
    textAlign: PropTypes.oneOf(['left', 'right']),
};

ButtonGroup.defaultProps = {
    textAlign: 'left',
};
