import round from 'lodash/round';
import isFinite from 'lodash/isFinite';
import findLast from 'lodash/fp/findLast';

const getMax = () => Number.MAX_SAFE_INTEGER || Math.pow(2, 53) - 1;

const precisionMap = [
    [0, 0],
    [10, -1],
    ...Array(getMax().toString().length - 1)
        .fill([])
        .map((_, idx) => [Math.pow(10, idx + 2), -(idx + 1)]),
];

const minimumAbbreviatedValue = 1000;
const symbolMap = [
    [1000, 'K'], // Thousand
    [1000000, 'M'], // Million
    [1000000000, 'B'], // Billion
    [1000000000000, 'T'], // Trillion
    [1000000000000000, 'P'], // Quadrillion
];

const findValue = needle => ([x]) => Math.floor(Math.abs(needle) / x) > 0;

export default function numberAbbreviate(value) {
    const parsedValue = parseInt(value, 10);

    if (!isFinite(parsedValue)) {
        return value;
    }
    if (Math.abs(parsedValue) < minimumAbbreviatedValue) {
        return `${parsedValue}`;
    }

    const [_, precision] = findLast(findValue(parsedValue))(precisionMap);
    const rounded = round(parsedValue, precision);
    const [divisor, symbol] = findLast(findValue(rounded))(symbolMap);
    return `${rounded / divisor}${symbol}`;
}

export function numberAbbreviateWithFormat(value, formatter = x => x) {
    const abbreviated = numberAbbreviate(value);
    if (typeof abbreviated !== 'string') {
        return abbreviated;
    }
    const [number, symbol = ''] = abbreviated.match(/[-]?[0-9]+|(.?[0-9]?[a-zA-Z])+/g);
    return `${formatter(number)}${symbol}`;
}
