import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './styles.scss';

const MedianOmega = ({
  alignRight,
  breakWords,
  children,
  verticalAlign,
  verticalFlush,
  leftFlush,
}) => (
  <div className={classNames({
    [css.MedianOmega]: true,
    [css['MedianOmega--topAlign']]: verticalAlign === 'top',
    [css['MedianOmega--bottomAlign']]: verticalAlign === 'bottom',
    [css['MedianOmega--alignRight']]: alignRight,
    [css['MedianOmega--breakWords']]: breakWords,
    [css['MedianOmega--verticalFlush']]: verticalFlush,
    [css['MedianOmega--leftFlush']]: leftFlush,
  })}
  >
    {children}
  </div>
);

MedianOmega.propTypes = {
  alignRight: PropTypes.bool,
  breakWords: PropTypes.bool,
  children: PropTypes.node.isRequired,
  verticalAlign: PropTypes.oneOf(['top', 'bottom', 'middle']),
  verticalFlush: PropTypes.bool,
  leftFlush: PropTypes.bool,
};

MedianOmega.defaultProps = {
  alignRight: false,
  breakWords: false,
  verticalAlign: 'middle',
  verticalFlush: false,
  leftFlush: false,
};

export default MedianOmega;
