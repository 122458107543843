import React from 'react';
import AbstractInput from '../AbstractInput';
import protectedProps from '../AbstractInput/protectedProps';

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const DEFAULT_ERROR = {
  type: 'error',
  message: 'Valid email address required',
};

export const internalValidation = (event) => EMAIL_REGEX.test(event.target.value) || DEFAULT_ERROR;

const EmailInput = (initialProps) => {
  const props = {
    ...initialProps,
    type: 'email',
    internalValidation,
    autoCapitalize: 'off',
  };
  return (
    <AbstractInput
      {...props}
    />
  );
};

EmailInput.defaultProps = {
  label: 'Email',
};

export default protectedProps(EmailInput);
