
import PropTypes from 'prop-types';

import React, { Component } from 'react';

export default class TileSectionHdg extends Component {
    constructor(props) {
        super(props);
        this.state = { };
    }

    render() {
        const styles = this.context.tileStyles;
        return (
            <h4 className={[styles.Tile__sectionHdg]}>
                {this.props.children}
            </h4>
        );
    }
}

TileSectionHdg.contextTypes = {
    tileStyles: PropTypes.object,
};
