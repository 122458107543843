import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class ExpanderAnimation extends Component {
    constructor(props) {
        super(props);
    }

    componentWillEnter(callback) {
        // reference: https://greensock.com/forums/topic/10637-animating-height-auto/

        if (document) {
            const el = this.expander;
            TweenLite.set(el, { height: 'auto' });
            el.style.overflow = 'hidden';
            TweenLite.from(el, 0.2, {
                height: 0,
                onComplete() {
                    el.style.overflow = '';
                    callback();
                },
            });
        }
    }

    componentWillLeave(callback) {
        if (document) {
            const el = this.expander;
            el.style.overflow = 'hidden';
            TweenLite.to(el, 0.25, {
                height: 0,
                onComplete() {
                    el.style.overflow = '';
                    callback();
                },
            });
        }
    }

    render() {
        return <div ref={expander => this.expander = expander}>{this.props.children}</div>;
    }
}
