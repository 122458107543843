
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import classNames from 'classnames';

export default class PanelSection extends Component {
    constructor(props) {
        super(props);
        this.state = { };
    }

    render() {
        const panelStyles = this.context.panelStyles;
        const sectionClasses = classNames({
            [panelStyles.Panel__section]: true,
            [panelStyles['Panel__section--flushHorizontal']]: this.props.isFlushHorizontal === true,
            [panelStyles['Panel_section--disableVerticalSpacing']]: this.props.disableVerticalSpacing === true,
            [panelStyles['Panel__section--pulledTop']]: this.props.pulledTop === true,
        });
        return (
            <div className={classNames(sectionClasses, this.props.className)}>
                {this.props.children}
            </div>
        );
    }
}

PanelSection.contextTypes = {
    panelStyles: PropTypes.object,
};

PanelSection.propTypes = {
    isFlushHorizontal: PropTypes.bool,
    disableVerticalSpacing: PropTypes.bool,
    pulledTop: PropTypes.bool,
};

PanelSection.defaultProps = {
    isFlushHorizontal: false,
    disableVerticalSpacing: false,
    pulledTop: false,
};
