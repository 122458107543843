
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import classNames from 'classnames';

export default class Tab extends Component {
    constructor(props) {
        super(props);
        this.state = { };
    }

    render() {
        return (
            this.props.children
        );
    }
}

Tab.propTypes = {
    'data-testid': PropTypes.string,
    isFlush: PropTypes.bool,
};

Tab.defaultProps = {
    'data-testid': '',
    isFlush: false,
};
