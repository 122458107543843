import React from 'react';
import PropTypes from 'prop-types';
import AbstractContainer from '../AbstractContainer';
import ActionBar from '../ActionBar';

const Footer = ({
  actions,
  size,
  customFooter,
  backgroundColor,
  tertiary,
}) => (
  (actions.length === 0 && !customFooter) ? null : (
    <AbstractContainer
      borderTop
      disablePadding
      backgroundColor={backgroundColor}
    >
      {customFooter
        ? (
          <ActionBar
            customFooter={customFooter}
          />
        )
        : (
          <ActionBar
            size={size}
            footerActions={actions}
            tertiaryAction={
              (tertiary && tertiary.action)
                ? {
                  icon: tertiary.action.icon,
                  onClick: tertiary.action.onClick,
                  disabled: tertiary.action.disabled,
                }
                : undefined
            }
            tertiaryText={tertiary ? tertiary.text : undefined}
          />
        )}
    </AbstractContainer>
  )
);

Footer.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['primary', 'secondary']),
    disabled: PropTypes.bool,
    menuItem: PropTypes.bool,
  })),
  size: PropTypes.oneOf(['sm']),
  customFooter: PropTypes.element,
  backgroundColor: PropTypes.oneOf(['default', 'grey20', 'grey30', 'grey40']),
  tertiary: PropTypes.shape({
    action: PropTypes.shape({
      disabled: PropTypes.bool,
      icon: PropTypes.string,
      onClick: PropTypes.func,
    }),
    text: PropTypes.string,
  }),
};

Footer.defaultProps = {
  actions: [],
  size: null,
  customFooter: null,
  backgroundColor: 'default',
  tertiary: null,
};

export default Footer;
