import { selector } from 'recoil';
import propsState from './props.state';
import { FilterProps } from './available-filters.query';
import prefsState from './prefs.state';

const activeFiltersQuery = selector<FilterProps[]>({
  key: 'activeFilters',
  get: ({ get }) => {
    const props = get(propsState);
    const prefs = get(prefsState);

    if (!props?.filters || !prefs?.filters) {
      return [];
    }

    const applied: FilterProps[] = [];

    prefs.filters.forEach((prefFilter) => {
      const filter = (props.filters || []).find(({ id }) => id === prefFilter.id);

      if (!filter || !prefFilter.value) {
        return;
      }

      applied.push({
        id: filter.id,
        name: filter.name || filter.id,
        type: filter.type || 'text',
        pattern: filter.pattern,
        placeholder: filter.placeholder,
        section: filter.section,
        options: filter.options,
        getLazyLoadOptions: filter.getLazyLoadOptions,
        value: prefFilter.value,
      });
    });

    return applied;
  },
});

export default activeFiltersQuery;
