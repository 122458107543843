import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { svgIcons } from '@fieldnation/font/components';
import componentClassnames from './styles.scss';
import Menu from '../Menu';
import HorizontalList from '../HorizontalList';
import HorizontalListItem from '../HorizontalListItem';
import Option from '../Option';

const DropTriggerJSX = () => (
  <span
    className={classNames({
      [componentClassnames.SelectionMenu__icon]: true,
    })}
  >
    {svgIcons.dropDown()}
  </span>
);

const SelectionMenu = ({
  menuItems,
  indeterminate,
  onChange,
  checked,
  disabled,
}) => (
  <span className={componentClassnames.SelectionMenu}>
    <HorizontalList
      spacing={2}
      display="block"
      noWrap
    >
      <HorizontalListItem>
        <Option
          indeterminate={indeterminate}
          onChange={onChange}
          checked={checked}
          disabled={disabled}
          value="opt2"
          labelHidden
        >
          Select
        </Option>
      </HorizontalListItem>
      <HorizontalListItem>
        <Menu
          title="Select Items"
          label="Select Items"
          disableAutoWidth
          trigger={DropTriggerJSX}
          menuItems={menuItems}
        />
      </HorizontalListItem>
    </HorizontalList>
  </span>
);

SelectionMenu.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    href: PropTypes.string,
    target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
  })),
  indeterminate: PropTypes.bool,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};

SelectionMenu.defaultProps = {
  menuItems: [],
  indeterminate: false,
  onChange: undefined,
  checked: false,
  disabled: false,
};

export default SelectionMenu;
