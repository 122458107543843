import React from 'react';
import IconIndicator from '../IconIndicator/IconIndicator.jsx';
import * as css from './FilterAddon.scss';

const FilterAddon = ({ children, active }) => (
    <div>
        {children}
        <div className={css.FilterAddon__indicator}><IconIndicator active={active} /></div>
    </div>
);

export default FilterAddon;
