
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import HorizontalList from '../../HorizontalList';
import HorizontalListItem from '../../HorizontalListItem';
import styles from './BackText/styles/BackText.scss';

class BackText extends Component {
    render() {
        const { onClick } = this.props;
        return (
            <HorizontalList display="inline" verticalAlign="middle" spacing={2}>
                <HorizontalListItem>
                    <button className="u-resetButton" styleName="BackText__button" onClick={onClick}>
                        <span className="icon-back-arrow-reversed" styleName="BackText__icon" />
                    </button>
                </HorizontalListItem>
                <HorizontalListItem>{this.props.children}</HorizontalListItem>
            </HorizontalList>
        );
    }
}
BackText.propTypes = {
    onClick: PropTypes.func,
};

export default CSSModules(BackText, styles);
