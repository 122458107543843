import React, { useCallback } from 'react';
import Slider, { Handle } from 'rc-slider';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import classes from './styles.scss';

export const getTrackStyles = (disabled) => ({
  backgroundColor: disabled ? '#97a1e7' : '#4051bf',
  borderRadius: '0',
});
const railStyles = {
  backgroundColor: '#c4cad4',
  borderRadius: '0',
  height: 4,
};
const dotStyles = {
  border: 'none',
  borderRadius: 'unset',
  marginLeft: '-1px',
  width: '1px',
};

const DiscreteSlider = ({
  defaultValue,
  disabled,
  label,
  max,
  marks,
  min,
  onAfterChange,
  onBeforeChange,
  onChange,
  rcSliderProps,
  step,
  unit,
  unitPlural,
}) => {
  const getFormattedValue = (value) => (unit
    ? (`${value} ${unit}${unitPlural && value > 1
      ? 's'
      : ''}`)
    : ''
  );
  const handle = useCallback((handleProps) => {
    const { dragging, ...restProps } = handleProps;
    return (
      <>
        <Handle
          dragging={dragging.toString()}
          {...restProps}
          className={classNames(handleProps.className, {
            [classes.DiscreteSlider__handle]: true,
            [classes['DiscreteSlider__handle--disabled']]: disabled,
          })}
        >
          <Icon name="drag" size={18} />
          <span className={classNames({
            [classes.DiscreteSlider__active_badge]: true,
            [classes.DiscreteSlider__active_badge_min]: handleProps.value === min,
            [classes.DiscreteSlider__active_badge_max]: handleProps.value === max,
            [classes['DiscreteSlider__active_badge--disabled']]: disabled,
          })}
          >
            { getFormattedValue(handleProps.value) }
          </span>
        </Handle>
      </>
    );
  }, [disabled]);
  return (
    <div className={classes.DiscreteSlider__container}>
      {label && <p className={classes.DiscreteSlider__label}>{label}</p>}
      <div className={classes.DiscreteSlider__wrapper}>
        <Slider
          defaultValue={defaultValue}
          disabled={disabled}
          min={min}
          max={max}
          step={step}
          onBeforeChange={onBeforeChange}
          onChange={onChange}
          onAfterChange={onAfterChange}
          trackStyle={getTrackStyles(disabled)}
          marks={marks}
          railStyle={railStyles}
          className={classNames(classes.DiscreteSlider__main, {
            [classes['DiscreteSlider__main--disabled']]: disabled,
          })}
          handle={handle}
          dots
          dotStyle={dotStyles}
          {...rcSliderProps}
        />
      </div>
    </div>
  );
};

DiscreteSlider.propTypes = {
  defaultValue: PropTypes.number,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  max: PropTypes.number,
  marks: PropTypes.object,
  min: PropTypes.number,
  onAfterChange: PropTypes.func,
  onBeforeChange: PropTypes.func,
  onChange: PropTypes.func,
  rcSliderProps: PropTypes.object,
  step: PropTypes.number,
  unit: PropTypes.string,
  unitPlural: PropTypes.bool,
};

DiscreteSlider.defaultProps = {
  defaultValue: 50,
  disabled: false,
  label: '',
  max: 100,
  marks: {},
  min: 0,
  onAfterChange: () => {},
  onBeforeChange: () => {},
  onChange: () => {},
  step: 10,
  rcSliderProps: {},
  unit: '',
  unitPlural: true,
};

export default DiscreteSlider;
