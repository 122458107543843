
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import classNames from 'classnames';
import CSSModules from 'react-css-modules';
import styles from './styles/IconAside.scss';
import IconAsideIcon from './IconAsideIcon.jsx';
import IconAsideDotIndicator from './IconAsideDotIndicator.jsx';

class IconAside extends Component {
    static childContextTypes = {
        iconAsideColor: PropTypes.oneOf(['', 'red', 'green', 'blue', 'grey', 'grey80', 'blue60']),
        iconAsideStyles: PropTypes.object,
        iconAsidePosition: PropTypes.oneOf(['left', 'right']),
        iconAsideIconSize: PropTypes.oneOf(['sm', 'lg']),
    }

    static propTypes = {
        iconColor: PropTypes.oneOf(['', 'red', 'green', 'blue', 'grey', 'grey80', 'blue60']),
        iconPosition: PropTypes.oneOf(['left', 'right']),
        iconSize: PropTypes.oneOf(['sm', 'lg']),
        narrow: PropTypes.bool,
    }

    static defaultProps = {
        iconColor: '',
        iconPosition: 'left',
        iconSize: 'sm',
        narrow: false,
    }

    constructor(props) {
        super(props);
        this.state = { };
    }

    getChildContext() {
        return {
            iconAsideColor: this.props.iconColor,
            iconAsideStyles: styles,
            iconAsidePosition: this.props.iconPosition,
            iconAsideIconSize: this.props.iconSize,
        };
    }

    render() {
        const classes = classNames({
            [styles.IconAside]: true,
            [styles['IconAside--lg']]: this.props.iconSize === 'lg',
            [styles['IconAside--reverse']]: this.props.iconPosition === 'right',
            [styles['IconAside--narrow']]: this.props.narrow,
        });
        return (
            <div className={classes}>
                {this.props.children}
            </div>
        );
    }
}

export default CSSModules(IconAside, styles);

export {
    IconAsideIcon,
    IconAsideDotIndicator
};
