import {
    compose,
    withProps,
} from 'recompose';
import setDisplayName from '../../../../HigherOrderComponents/setDisplayName';
import TextInput from '../TextInput/TextInput';

const enhancer = compose(
    withProps(({
        mask: '999-99-9999',
        maskChar: ' ',
    })),
    setDisplayName('SocialSecurityInput'),
);

export default enhancer(TextInput);
