import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as classes from './IconIndicator.scss';

const IconIndicator = ({ active, type }) => {
  return (
    <div className={classNames([classes.IconIndicator], {
        [classes['IconIndicator--active']]: active,
        [classes['IconIndicator--red']]: type === 'default' || type === 'red',
        [classes['IconIndicator--blue']]: type === 'blue',
        [classes['IconIndicator--yellow']]: type === 'yellow',
        [classes['IconIndicator--orange']]: type === 'orange',
        [classes['IconIndicator--gray']]: type === 'gray',
        [classes['IconIndicator--green']]: type === 'green',
    })} />
  );
}

IconIndicator.propTypes = {
  active: PropTypes.bool,
  type: PropTypes.oneOf(['default', 'blue', 'green', 'yellow', 'orange', 'red', 'gray']),
};

IconIndicator.defaultProps = {
  active: false,
  type: 'default',
};

export default IconIndicator;
