import { selector } from 'recoil';
import propsState from './props.state';
import { SavedFilter } from './preferences.factory';
import prefsState from './prefs.state';

const savedFiltersQuery = selector<SavedFilter[]>({
  key: 'savedFilters',
  get: ({ get }) => {
    const props = get(propsState);
    const prefs = get(prefsState);

    if (!props?.filters || !prefs?.filters) {
      return [];
    }

    return prefs.savedFilters || [];
  },
});

export default savedFiltersQuery;
