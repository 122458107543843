
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import classNames from 'classnames';
import classes from './styles/Item.scss';
import { NotificationBandTypes } from './Root.jsx';

export default class Item extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={classNames([classes.Item], {
                [classes['Item--closeEnabled']]: this.context.isCloseEnabled,
                [classes['Item--divided']]: !this.props.disableDivider,
                [classes[`Item--${this.props.type}`]]: this.props.type,
            })}>
                <div className={classes.Item__media}>
                    { this.props.iconClass !== '' ?
                        <div className={classes.Item__media__element}>
                            <span className={classNames(this.props.iconClass, {
                                [classes.Item__icon]: true,
                            })} />
                        </div>
                    :
                        null
                    }

                    <div className={classes.Item__media__body}>
                        <div>
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Item.propTypes = {
    iconClass: PropTypes.oneOf(['icon-notifications-2', '']),
    type: PropTypes.oneOf(['', ...NotificationBandTypes]),
    disableDivider: PropTypes.bool,
};

Item.defaultProps = {
    iconClass: 'icon-notifications-2',
    type: '',
    disableDivider: false,
};

Item.contextTypes = {
    isCloseEnabled: PropTypes.bool,
};
