import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import SkinlessPopover from '../SkinlessPopover';
import css from './ActiveFilter.scss';
import FilterEditPanel from './FilterEditPanel';
import { filterPropTypes } from './ToolbarPropTypes';
import FilterChip from '../Chip';
import applyTruncation from '../utils/truncate';
import wrapEventHandler, { ARG_ORDER } from '../utils/wrapEventHandler';
import noop from '../utils/noop';
import getFilteredValue from '../utils/getFilteredValue';

export function getBooleanMultiSelectString(filter) {
  const bool = (filter.options || []).find(({ value }) => value === filter.value.boolean);
  const multiSelect = (filter.options || [])
    .find(({ value }) => filter.value.multiSelect.includes(value));
  const numItems = filter.value.multiSelect.length;
  return `${bool.label}: ${multiSelect.label}${numItems > 1 ? ` + ${numItems - 1}` : ''}`;
}

/* eslint class-methods-use-this: ["error", { "exceptMethods": ["foo"] }] */
class ActiveFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      filter: props.filter,
      loading: true,
      failedFetchingOptions: false,
      failedFetchingOptionsMessage: '',
    };
    this.triggerId = `activeFilterTrigger__${props.filter.id}`;
    this.closeFilterEditPanel = this.closeFilterEditPanel.bind(this);
    this.clearFilterValue = this.clearFilterValue.bind(this);
  }

  componentDidMount() {
    const {
      filter,
      onFilterOptionsFetched,
      onMount,
      openImmediately,
    } = this.props;

    if (openImmediately) {
      document.getElementById(this.triggerId).click();
    }

    onMount();
    if (filter.getLazyLoadOptions || filter.getLazyLoadOptionsWithGroup) {
      let getLazyLoadData = filter.getLazyLoadOptions;
      let optionType = 'options';
      if (filter.optgroup) { // Overwrite for option group.
        getLazyLoadData = filter.getLazyLoadOptionsWithGroup;
        optionType = 'optionsWithGroup';
      }

      getLazyLoadData().then((options) => {
        this.setState({
          loading: false,
          filter: {
            ...filter,
            optgroup: filter.optgroup,
            [optionType]: options,
          },
        }, () => onFilterOptionsFetched({ ...filter, [optionType]: options }));
      }).catch((error) => this.setState({
        failedFetchingOptions: true,
        failedFetchingOptionsMessage: error.message || error,
      }));
    } else {
      this.setState({ loading: false });
    }
  }

  transformDisplayedValue = (value) => {
    const { filter: { transform } } = this.props;
    return transform?.(value) ?? value;
  }

  clearFilterValue() {
    const { filter } = this.props;
    delete filter.value;
  }

  closeFilterEditPanel() {
    this.setState({ isOpen: false });
  }

  respondToPopoverToggle = (open) => {
    const {
      onRemoveFilter,
      filter,
    } = this.props;

    const isEmptyValue = !filter.value || (filter.value && !filter.value.length);
    const isEmptyObj = isEmpty(filter.value);

    if (!open && isEmptyValue && isEmptyObj) {
      onRemoveFilter(filter);
    }
    return this.setState({ isOpen: open });
  };

  render() {
    const {
      onRemoveFilter,
      onSubmit,
      targetAttachment,
      attachment,
      type,
      filter,
      isSingleFilter,
    } = this.props;

    const {
      isOpen,
      loading,
      failedFetchingOptions,
      failedFetchingOptionsMessage,
      filter: { options, optionsWithGroup },
    } = this.state;

    const childElemCSSClasses = [
      'filter-dropdown-date-picker',
      'typeahead-option',
      'closeEventBlocker',
    ].join(' ');

    return (
      <SkinlessPopover
        isOpen={isOpen}
        targetAttachment={targetAttachment}
        attachment={attachment}
        onChangeOpen={this.respondToPopoverToggle}
        otherChildrenClasses={childElemCSSClasses}
      >
        <div
          role="button"
          tabIndex="0"
          id={this.triggerId}
        >
          {
            type === 'chip'
            && (
              <FilterChip
                active={isOpen}
                onDismiss={wrapEventHandler(onRemoveFilter, [filter], ARG_ORDER.EVENT_LAST)}
                onClick={noop}
                id={filter.id}
                label={filter.name}
                value={this.transformDisplayedValue(getFilteredValue(filter, true, this.state))}
                isSingleFilter={isSingleFilter}
              />
            )
          }
          {
            type === 'list'
            && (
              <div className={css.ActiveFilter__filterOption}>
                <span>
                  <span className={css.ActiveFilter__filterLabel}>{filter.name}</span>
                  {': '}
                  {applyTruncation(
                    this.transformDisplayedValue(getFilteredValue(filter, false, this.state), true, 15),
                  )}
                </span>
              </div>
            )
          }
        </div>
        <div className={classnames('filter-edit-panel', css.ActiveFilter__wrapper)}>
          <FilterEditPanel
            filter={{ ...filter, options, optionsWithGroup }}
            onSubmit={onSubmit}
            onClearFilterValue={this.clearFilterValue}
            loading={loading}
            failedFetchingOptions={failedFetchingOptions}
            failedFetchingOptionsMessage={failedFetchingOptionsMessage}
            closeFilterEditPanel={this.closeFilterEditPanel}
            isSingleFilter={isSingleFilter}
          />
        </div>
      </SkinlessPopover>
    );
  }
}

ActiveFilter.propTypes = {
  onRemoveFilter: PropTypes.func.isRequired,
  filter: filterPropTypes.isRequired,
  onSubmit: PropTypes.func.isRequired,
  openImmediately: PropTypes.bool,
  targetAttachment: PropTypes.string,
  attachment: PropTypes.string,
  type: PropTypes.oneOf(['chip', 'list']),
  onMount: PropTypes.func,
  onFilterOptionsFetched: PropTypes.func,
  isSingleFilter: PropTypes.bool,
};

ActiveFilter.defaultProps = {
  openImmediately: false,
  targetAttachment: 'bottom left',
  attachment: 'top left',
  type: 'chip',
  onMount: () => { },
  onFilterOptionsFetched: () => {},
  isSingleFilter: false,
};

export default ActiveFilter;
