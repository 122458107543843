
import PropTypes from 'prop-types';
import styles from './styles/Tile.scss';
import React, { Component } from 'react';

export default class TileSection extends Component {
    constructor(props) {
        super(props);
        this.state = { };
    }

    render() {
        return (
            <div className={[styles.Tile__section]}>
                {this.props.children}
            </div>
        );
    }
}
