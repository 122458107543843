const cleanValue = (value) => {
  const goodValue = value
    .replace(/ /g, '')
    .replace(/\(/g, '')
    .replace(/\)/g, '')
    .replace(/-/g, '');
  return goodValue;
};

export default cleanValue;
