import React from 'react';
import PropTypes from 'prop-types';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import {
  Panel as PanelV1,
  PanelAnimation,
  PanelHeading,
} from '../../index';
import ActionBar from '../../ActionBar';

const Panel = ({
  footerActions,
  tertiaryAction,
  tertiaryText,
  header,
  isOpen,
  onClose,
  children,
  sidebar,
}) => (
  <TransitionGroup>
    { isOpen
      && (
      <PanelAnimation>
        <PanelV1
          bodyFooter={(
            <ActionBar
              footerActions={footerActions}
              tertiaryAction={tertiaryAction}
              tertiaryText={tertiaryText}
            />
          )}
          bodyFooterFlush
          contentBackgroundColor="grey"
          header={<PanelHeading>{header}</PanelHeading>}
          sidebar={sidebar}
          onClickClose={onClose}
        >
          {children}
        </PanelV1>
      </PanelAnimation>
      )}
  </TransitionGroup>
);

Panel.propTypes = {
  footerActions: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string,
    onClick: PropTypes.func,
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['primary', 'secondary']),
    disabled: PropTypes.bool,
  })),
  tertiaryAction: PropTypes.shape({
    icon: PropTypes.string,
    onClick: PropTypes.func,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
  }),
  tertiaryText: PropTypes.string,
  children: PropTypes.node.isRequired,
  header: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  sidebar: PropTypes.node,
};

Panel.defaultProps = {
  footerActions: undefined,
  tertiaryAction: undefined,
  tertiaryText: '',
  isOpen: false,
  onClose: undefined,
  sidebar: undefined,
};

export default Panel;
