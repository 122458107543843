import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import classNames from 'classnames';
import classes from './styles/MenuItem.scss';
import { iconList } from '@fieldnation/font/components';
import PropTypes from 'prop-types';
import Menu from '../../../Menu';
import Option from '../../../Option';
import Icon from '../../../Icon';

@CSSModules(classes)
export default class MenuItem extends Component {
    static propTypes = {
        id: PropTypes.string,
        label: PropTypes.string,
        category: PropTypes.string,
        count: PropTypes.number,
        checked: PropTypes.bool,
        checkBoxValue: PropTypes.string,
        disabled: PropTypes.bool,
        onChangeCheckbox: PropTypes.func,
        icon: PropTypes.oneOf(iconList),
        iconColor: PropTypes.oneOf(['green', 'yellow', 'red', 'indigo', 'blue', 'orange', 'gray', '']),
        leftIcon: PropTypes.oneOf(iconList),
        leftIconColor: PropTypes.oneOf(['green', 'yellow', 'red', 'indigo', 'blue', '']),
        labelColor: PropTypes.oneOf(['green', 'yellow', 'red', 'indigo', 'blue', '']),
        onClickIcon: PropTypes.func,
        moreOptions: PropTypes.arrayOf(PropTypes.object),
        onClick: PropTypes.func,
        href: PropTypes.string,
        target: PropTypes.string,
        isActive: PropTypes.bool,
        isActiveBackGroundColor: PropTypes.oneOf(['gray', 'orange']),
        isActiveBorderColor: PropTypes.oneOf(['blue', 'orange']),
        isActiveBorderPlacement: PropTypes.oneOf(['left', 'right']),
        iconSize: PropTypes.oneOf([14, 16, 18, 22, 24, 28, 32, 48]),
        leftIconSize: PropTypes.oneOf([14, 16, 18, 22, 24, 28, 32, 48]),
        iconWithMoreOptions: PropTypes.bool,
        isIconActive: PropTypes.bool,
    };

    static defaultProps = {
        id: '',
        label: '',
        category: '',
        checked: false,
        disabled: false,
        onClickIcon: null,
        moreOptions: [],
        href: '',
        target: '_self',
        isActive: false,
        isActiveBackGroundColor: 'orange',
        isActiveBorderColor: 'orange',
        isActiveBorderPlacement: 'right',
        iconSize: 24,
        labelColor: '',
        leftIconSize: 24,
        iconWithMoreOptions: false,
        isIconActive: false,
    };

    getMenuTag() {
        const { props } = this;
        if (props.onChangeCheckbox) {
            return ['label'];
        } else if (props.disabled) {
            return ['div'];
        } else if (props.href) {
            return ['a', { href: this.props.href, target: this.props.target }];
        } else if (props.onClick && !props.isActive) {
            return ['button'];
        }
        return 'div';
    }

    render() {
        const [Tag, extraAttributes] = this.getMenuTag();
        return (
            <Tag
                {...extraAttributes || {}}
                disabled={this.props.disabled}
                styleName="MenuItem__link"
                onClick={this.props.onClick}>
                <div
                    styleName={this.props.iconWithMoreOptions === true ? 'MenuItem--iconWithMore' : 'MenuItem'}
                >
                    {this.props.iconWithMoreOptions ? (
                        <>
                            <div>
                                {this.getLeftIcon()}
                                {this.getCheckbox()}
                                {this.getLabel()}
                            </div>
                            <div styleName="MenuItem__iconWithMoreWrapper">
                                {this.getIconWithMoreOptions()}
                            </div>
                        </>
                    ) : (
                        <>
                            {this.getLeftIcon()}
                            {this.getCheckbox()}
                            {this.getLabel()}
                            {this.getIconOrCount()}
                        </>
                    )}
                </div>
            </Tag>
        );
    }

    getLeftIcon() {
        const { props } = this;
        if (props.leftIcon) {
            return (
                <div styleName="MenuItem__leftIcon">
                    <div
                        className={classNames(classes.MenuItem__icon, {
                            [classes['MenuItem__icon--green']]: props.leftIconColor === 'green',
                            [classes['MenuItem__icon--red']]: props.leftIconColor === 'red',
                            [classes['MenuItem__icon--yellow']]: props.leftIconColor === 'yellow',
                            [classes['MenuItem__icon--indigo']]: props.leftIconColor === 'indigo',
                            [classes['MenuItem__icon--blue']]: props.leftIconColor === 'blue',
                        })}>
                        <Icon
                            size={props.leftIconSize}
                            name={props.leftIcon}
                            block />
                    </div>
                </div>
            );
        }
        return null;
    }

    getCheckbox() {
        if (typeof this.props.onChangeCheckbox === 'undefined') {
            return null;
        }
        return (
            <div styleName="MenuItem__checkbox">
                <Option
                    component="div"
                    onChange={this.props.onChangeCheckbox}
                    checked={this.props.checked}
                    value={this.props.checkBoxValue} />
            </div>
        );
    }

    getLabel() {
        const { props } = this;
        return (
            <div styleName="MenuItem__label"
                className={classNames(classes.MenuItem__icon, {
                    [classes['MenuItem__icon--green']]: props.labelColor === 'green',
                            [classes['MenuItem__icon--red']]: props.labelColor === 'red',
                            [classes['MenuItem__icon--yellow']]: props.labelColor === 'yellow',
                            [classes['MenuItem__icon--indigo']]: props.labelColor === 'indigo',
                            [classes['MenuItem__icon--blue']]: props.labelColor === 'blue',
                })}>
                {this.props.label}
            </div>
        );
    }

    getIconWithMoreOptions() {
        const { props } = this;
        const Tag = `${props.onClickIcon ? 'button' : 'div'}`;
        return props.icon ? (
            <>
                {(props.moreOptions.length > 0 && props.isActive) && (
                    <div styleName="MenuItem__iconWithMoreItems">
                        <Menu
                            disableAutoWidth
                            trigger={(props, state) => (<div className={classes.MenuItem__icon}><Icon
                                size={props.iconSize}
                                name="moreVert"
                                block /></div>)}
                            menuItems={props.moreOptions} />
                    </div>
                )}
                <div
                    styleName="MenuItem__iconWithMoreItems"
                    className={classNames({
                        [classes['MenuItem__iconActive']]: props.isIconActive,
                    })}>
                    <Tag
                        styleName="MenuItem__actionIcon"
                        onClick={props.onClickIcon}
                        className={classNames(classes.MenuItem__icon, {
                            [classes['MenuItem__icon--green']]: props.iconColor === 'green',
                            [classes['MenuItem__icon--red']]: props.iconColor === 'red',
                            [classes['MenuItem__icon--yellow']]: props.iconColor === 'yellow',
                            [classes['MenuItem__icon--indigo']]: props.iconColor === 'indigo',
                            [classes['MenuItem__icon--blue']]: props.iconColor === 'blue',
                            [classes['MenuItem__icon--orange']]: props.iconColor === 'orange',
                            [classes['MenuItem__icon--gray']]: props.iconColor === 'gray',
                        })}>
                        <Icon
                            size={props.iconSize}
                            name={props.icon}
                            block />
                    </Tag>
                </div>
            </>
        ): null;
    }

    getIconOrCount() {
        const { props } = this;
        if (props.icon) {
            const Tag = `${props.onClickIcon ? 'button' : 'div'}`;
            return (
                <div styleName="MenuItem__iconOrCount">
                    <Tag
                        onClick={props.onClickIcon}
                        className={classNames(classes.MenuItem__icon, {
                            [classes['MenuItem__icon--green']]: props.iconColor === 'green',
                            [classes['MenuItem__icon--red']]: props.iconColor === 'red',
                            [classes['MenuItem__icon--yellow']]: props.iconColor === 'yellow',
                            [classes['MenuItem__icon--indigo']]: props.iconColor === 'indigo',
                            [classes['MenuItem__icon--blue']]: props.iconColor === 'blue',
                        })}>
                        <Icon
                            size={props.iconSize}
                            name={props.icon}
                            block />
                    </Tag>
                </div>
            );
        } else if (props.moreOptions.length > 0 && props.isActive) {
            return (
                <div styleName="MenuItem__iconOrCount">
                    <Menu
                        disableAutoWidth
                        trigger={(props, state) => (<div className={classes.MenuItem__icon}><Icon
                            size={props.iconSize}
                            name="moreVert"
                            block /></div>)}
                        menuItems={props.moreOptions} />
                </div>
            );
        } else if (typeof props.count !== 'undefined') {
            return (
                <div styleName="MenuItem__iconOrCount">{props.count}</div>
            );
        }
        return null;
    }
}
