import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import classes from './styles/PinnedMenu.scss';
import PinnedMenuTypeAhead from './PinnedMenuTypeAhead.jsx';
import PinnedMenuDivider from './PinnedMenuDivider.jsx';
import PinnedMenuItem from './PinnedMenuItem.jsx';

export class PinnedMenu extends Component {
    static propTypes = {
        showOnEvent: PropTypes.oneOf(['click', 'hover']),
        horizontalAlign: PropTypes.oneOf(['left', 'right']),
        menuWidth: PropTypes.string,
        offset: PropTypes.shape({
            left: PropTypes.string,
            top: PropTypes.string,
        }),
        isOpen: PropTypes.bool,
        onChangeOpen: PropTypes.func,
        pinnedMenuContent: PropTypes.func,
    }

    static defaultProps = {
        showOnEvent: 'click',
        horizontalAlign: 'left',
        menuWidth: null,
        offset: {
            top: '0',
            left: '0',
        },
        isOpen: false,
    }

    constructor(props) {
        super(props);
        this.state = {
            isOpen: this.props.isOpen,
            pos: {
                top: 0,
                left: 0,
            },
        };
        this.onDocClick = this.onDocClick.bind(this);
        this.setPosition = this.setPosition.bind(this);
        this.setMaxHeight = this.setMaxHeight.bind(this);
        this.turnOffVisiblity = this.turnOffVisiblity.bind(this);
        this.turnOnVisiblity = this.turnOnVisiblity.bind(this);
        this.toggleVisibility = this.toggleVisibility.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        this.setPosition();
        this.setMaxHeight();
        if (this.state.isOpen != prevState.isOpen && this.props.onChangeOpen) {
            this.props.onChangeOpen(this.state.isOpen);
        }
    }

    componentDidMount() {
        this.setPosition();
        this.setMaxHeight();
        window.addEventListener('resize', this.setPosition);
        window.addEventListener('resize', this.setMaxHeight);
        document.addEventListener('click', this.onDocClick);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setPosition);
        window.removeEventListener('resize', this.setMaxHeight);
        document.removeEventListener('click', this.onDocClick);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (this.props.isOpen !== newProps.isOpen) {
            this.setState({
                isOpen: newProps.isOpen,
            });
        }
    }

    onDocClick(e) {
        const $tooltip = this.popoverTarget;
        const $root = this.popoverTrigger;
        if ($tooltip && $root) {
            if (this.state.isOpen && !$tooltip.contains(e.target) && !$root.contains(e.target)) {
                this.setState({ isOpen: false });
            }
        }
    }
    closePopover() {
        this.setState({ isOpen: false });
    }
    turnOnVisiblity() {
        this.setState({ isOpen: true });
    }
    turnOffVisiblity() {
        this.setState({ isOpen: false });
    }
    toggleVisibility() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    setPosition() {
        if (this.popoverTrigger && typeof this.popoverTrigger.getBoundingClientRect === 'function') {
            const $el = this.popoverTrigger;
            const top = $el.getBoundingClientRect().bottom;
            let left = $el.getBoundingClientRect().left;

            if (this.props.horizontalAlign === 'right' && this.popoverTarget && typeof this.popoverTarget.getBoundingClientRect === 'function') {
                const targetWidth = this.popoverTarget.getBoundingClientRect().width;
                const triggerWidth = this.popoverTrigger.getBoundingClientRect().width;
                left = left - targetWidth + triggerWidth;
            }
            if (this.state.pos.top !== top || this.state.pos.left !== left) {
                this.setState({
                    pos: { top, left },
                });
            }
        }
    }
    setMaxHeight() {
        if (this.popoverTarget && typeof this.popoverTarget.getBoundingClientRect === 'function') {
            const maxHeight = `${0.8 * (window.innerHeight - this.popoverTarget.getBoundingClientRect().top)}px`;
            if (this.state.maxHeight !== maxHeight) {
                this.setState({
                    maxHeight,
                });
            }
        }
    }

    render() {
        this.trigger = React.Children.map(this.props.children, (c, index) => {
            if (index === 0) {
                let options = {};
                if (this.props.showOnEvent === 'click') {
                    options = {
                        onClick: this.toggleVisibility,
                    };
                } else if (this.props.showOnEvent === 'hover') {
                    options = {
                        onMouseEnter: this.turnOnVisiblity,
                        onMouseLeave: this.turnOffVisiblity,
                    };
                }
                return React.cloneElement(c, options);
            }
        });

        const offset = Object.assign({}, PinnedMenu.defaultProps.offset, this.props.offset);
        let styles = {
            left: this.state.pos.left + parseInt(offset.left),
            top: this.state.pos.top + parseInt(offset.top),
            maxHeight: this.state.maxHeight,
        };
        if (this.props.menuWidth) {
            styles = { ...styles, width: this.props.menuWidth, maxWidth: this.props.menuWidth };
        }
        const { innerRef } = this.props;
        return (

            <div>
                <div ref={node => this.popoverTrigger = node}>
                    {this.trigger}
                </div>
                {this.state.isOpen &&
                    <div
                        ref={node => {
                            this.popoverTarget = node;
                            if (innerRef) {
                                innerRef(node);
                            }
                        }}
                        styleName="PinnedMenu__content"
                        style={styles}>
                        {this.props.pinnedMenuContent()}
                    </div>
                }
            </div>
        );
    }
}

export default CSSModules(PinnedMenu, classes);

export {
    PinnedMenuTypeAhead,
    PinnedMenuDivider,
    PinnedMenuItem,
};
