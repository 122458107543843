import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TweenLite } from 'gsap';

export default class ExpanderAnimation extends Component {
  constructor(props) {
    super(props);
    this.expander = React.createRef();
  }

  componentWillEnter(callback) {
    // reference: https://greensock.com/forums/topic/10637-animating-height-auto/

    if (document) {
      const el = this.expander.current;
      el.style.overflow = 'hidden';

      TweenLite.set(el, { height: 'auto' });

      TweenLite.from(el, 0.2, {
        height: 0,
        onComplete() {
          el.style.overflow = '';
          callback();
        },
      });
    }
  }

  componentWillLeave(callback) {
    if (document) {
      const el = this.expander.current;
      el.style.overflow = 'hidden';

      TweenLite.to(el, 0.25, {
        height: 0,
        onComplete() {
          el.style.overflow = '';
          callback();
        },
      });
    }
  }

  render() {
    const { children } = this.props;
    return <div ref={this.expander}>{children}</div>;
  }
}

ExpanderAnimation.propTypes = {
  children: PropTypes.node.isRequired,
};
