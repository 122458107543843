import * as React from 'react';
import PropTypes from 'prop-types';
import Styles from './styles.scss';

const OptionComponent = ({ option, selectValue }) => (
  <div
    className={Styles.OptionContainer}
    onClick={() => selectValue(option.value)}
    aria-hidden="true"
  >
    {option?.label || ''}
    {!!option?.suggested && (
      <div className={Styles.OptionNote}>
        <span className={Styles.OptionNoteLink}>Suggested</span>
      </div>
    )}
  </div>
);

OptionComponent.propTypes = {
  selectValue: PropTypes.func,
  option: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
    suggested: PropTypes.bool,
  }),
};

OptionComponent.defaultProps = {
  selectValue: () => { /* null */ },
  option: undefined,
};

export default OptionComponent;
