import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import css from './styles.scss';

const DrawerContainer = ({
  drawerId,
  children,
  drawerFloating,
}) => (
  <div className={css.PushDrawerContainer}>
    <div className={css.PushDrawerContainer__main}>{children}</div>
    <div
      className={classNames(
        css.PushDrawerContainer__left,
        {
          [css['PushDrawerContainer__left--absolute']]: drawerFloating,
        },
      )}
      id={drawerId}
    />
  </div>
);

DrawerContainer.propTypes = {
  children: PropTypes.node.isRequired,
  drawerId: PropTypes.string.isRequired,
  drawerFloating: PropTypes.bool,
};

DrawerContainer.defaultProps = {
  drawerFloating: false,
};

export default DrawerContainer;
