import React from 'react';
import PropTypes from 'prop-types';
import css from './styles.scss';

function getRange(numbers, range) {
  const min = Math.min(...numbers.filter((n) => n), range.low) - 5;
  const max = Math.max(...numbers, range.high) + 5;
  const r = max - min;
  const normalizedNums = numbers.map((num) => ((num - min) * 100) / r);
  const normalizedPercentiles = [
    ((range.low - min) * 100) / r,
    ((range.high - min) * 100) / r,
  ];
  return {
    min,
    max,
    '25th': range.low,
    '75th': range.high,
    normalizedNums,
    normalizedPercentiles,
  };
}

export function getPlacementType(number, range) {
  let placement = 'zero';

  if (number < range.low && range.low !== 0 && number !== 0) {
    placement = 'low';
  } else if (number > range.high && range.low !== 0 && number !== 0) {
    placement = 'high';
  } else if (range.low !== 0 && number !== 0) {
    placement = 'normal';
  }

  return placement;
}

const RangeDistributionBar = ({
  id,
  firstNumber,
  range,
  secondNumber,
}) => {
  const data = getRange([firstNumber, secondNumber], range);
  const placement = getPlacementType(firstNumber, range);

  const {
    normalizedPercentiles: [percent25, percent75],
    normalizedNums: [circlePos, trianglePos],
  } = data;
  const negativeWidth = percent25;
  const middleWidth = percent75 - percent25;
  const positiveWidth = 100 - percent75;
  const circlePosition = 100 - circlePos;
  const trianglePosition = 100 - trianglePos;

  return (
    <div className={css.MSI__RangeDistributionBar}>
      <div id={id} className={css['MSI__insights-bar']}>
        <div className={css['MSI__insights-bar-negative']} style={{ width: `${negativeWidth}%` }}>
          &nbsp;
        </div>
        <div className={css['MSI__insights-bar-normal']} style={{ width: `${middleWidth}%` }}>
          <div className={css['MSI__insights-bar-normal-inside']}>
            &nbsp;
          </div>
        </div>
        <div className={css['MSI__insights-bar-positive']} style={{ width: `${positiveWidth}%` }}>
          &nbsp;
        </div>
        <div
          className={`${css[`MSI__insights-bar-circle-${placement}`]} ${css['MSI__insights-slider-icon']}`}
          style={{ right: `${circlePosition}%` }}
        >
          &nbsp;
        </div>
        {secondNumber > 0 && (
          <div
            className={`${css['MSI__insights-bar-triangle']} ${css['MSI__insights-slider-icon']}`}
            style={{ right: `${trianglePosition}%` }}
          >
            &nbsp;
          </div>
        )}
      </div>
    </div>
  );
};

RangeDistributionBar.propTypes = {
  firstNumber: PropTypes.number,
  secondNumber: PropTypes.number,
  id: PropTypes.string,
  range: PropTypes.shape({
    low: PropTypes.number,
    high: PropTypes.number,
  }),
};

RangeDistributionBar.defaultProps = {
  firstNumber: 0,
  id: 'insights-bar',
  range: {
    low: 0,
    high: 0,
  },
  secondNumber: 0,
};

export default RangeDistributionBar;
