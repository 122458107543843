import getDateFromString from './getDateFromString';

export default function getTimeOptions() {
  const timeOptions = [];

  const hours = [];
  for (let n = 1; n <= 12; n += 1) {
    hours.push(n);
  }
  // put 12 at the front
  hours.unshift(hours.pop());

  const minutes = ['00', '15', '30', '45'];
  const amPm = ['AM', 'PM'];

  for (let i = 0; i < amPm.length; i += 1) {
    for (let ii = 0; ii < hours.length; ii += 1) {
      for (let iii = 0; iii < minutes.length; iii += 1) {
        const dateString = `${hours[ii]}:${minutes[iii]} ${amPm[i]}`;
        timeOptions.push({
          label: dateString,
          value: getDateFromString(dateString),
        });
      }
    }
  }

  return timeOptions;
}
