import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Status from '../legacy/Construction/Status';
import css from './styles.scss';

const ActionTabs = ({ items }) => (
  <div className={css.ActionTabs}>
    {
      items.map((item) => (
        <button
          className={classNames(css.ActionTabs__item, {
            [css['ActionTabs__item--active']]: item.active,
          })}
          type="button"
          onClick={item.onClick}
          key={item.label}
        >
          <span>{item.label}</span>
          {item.count && (
            <>
              {' '}
              {parseInt(item.count, 10) === -1
                ? <span><Status isLoading size="sm" inlineFlex>&nbsp;</Status></span>
                : <span className={css.ActionTabs__itemCount}>{item.count}</span>}
            </>
          )}
        </button>
      ))
    }
  </div>
);

export const ActionTabsPropTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    count: PropTypes.number,
    active: PropTypes.bool,
  })).isRequired,
};

ActionTabs.propTypes = ActionTabsPropTypes;

export default ActionTabs;
