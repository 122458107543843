import { selector } from 'recoil';
import { Column } from './DataGrid';
import tabPropsQuery from './tab-props.query';
import tabPrefsQuery from './tab-prefs.query';
import { ColumnPreference } from './preferences.factory';

interface MergedColumn extends Column, ColumnPreference {}

interface IdLabel {
  id: string;
  label: string;
}

export const availableColumnsQuery = selector<IdLabel[]>({
  key: 'availableColumns',
  get: ({ get }) => {
    const props = get(tabPropsQuery);

    if (!props) {
      return [];
    }

    return props.columns
      .map(({ id, label }) => ({ id, label: label || id }));
  },
});

const activeColumnsQuery = selector<MergedColumn[]>({
  key: 'activeColumns',
  get: ({ get }) => {
    const props = get(tabPropsQuery);
    const prefs = get(tabPrefsQuery);

    if (!props || !prefs) {
      return [];
    }

    const availableColumns = props.columns.map(({ id }) => id);

    if (prefs.columns) {
      // just in case we saved a column that no longer exists, strip it
      const prefCols = prefs.columns
        .filter(({ id }) => availableColumns.includes(id))
        .map((col) => ({
          ...props.columns.find(({ id: cid }) => cid === col.id),
          ...col,
        }));

      if (props.rowActions) {
        // show the ... menu per row
        return [...prefCols, { id: 'inline_actions' }];
      }

      return prefCols;
    }

    const propCols = props.columns.filter(({ withDefault }) => withDefault).map((col) => {
      const pref = (prefs.columns || []).find(({ id }) => id === col.id);
      return pref ? { ...col, ...pref } : col;
    });

    if (props.rowActions) {
      // show the ... menu per row
      return [...propCols, { id: 'inline_actions' }];
    }

    return propCols;
  },
});

export const activeColumnIdsQuery = selector<string[]>({
  key: 'activeColumnIds',
  get: ({ get }) => {
    const columns = get(activeColumnsQuery);
    return columns.map(({ id }) => id);
  },
});

export default activeColumnsQuery;
