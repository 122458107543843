import getPixelsPerRem from './getPixelsPerRem';

function maxWidthMediaQuery(upTo) {
  const regex = /^(\d+)(\.)?(\d+)?em$/i;
  const found = upTo.match(regex);

  if (found) {
    const pixelsPerRem = getPixelsPerRem();
    const upToRemsMinusOnePixel = parseFloat(found[0]) - (1 / pixelsPerRem);
    return `(max-width: ${upToRemsMinusOnePixel}em)`;
  }
  // eslint-disable-next-line no-console
  console.warn('parameter to maxWidthMediaQuery() needs to be [number]em');
  return '';
}

export default maxWidthMediaQuery;

const minWidthMediaQuery = (minWidth) => `(min-width: ${minWidth})`;

export { minWidthMediaQuery };
