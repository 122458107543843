/* eslint-disable no-const-assign */
import * as React from 'react';
import currencyFormatter from '../legacy/Formatters/currencyFormatter';
const css = require('./styles.scss');

export interface Props {
  spentLabel?: string,
  remainingLabel?: string,
  overageLabel?: string,
  backgroundColor?: 'white' | 'gray',
  totalAmount: number,
  spentAmount: number,
}

/**
 * Gauge Bar Component that shows sliding guage bar
 * with optional header/footer labels and overage
 * @param {*} props
 */
const GaugeBar: React.FC<Props> = (
  { 
    spentLabel = 'Spent', 
    remainingLabel = 'Remaining', 
    overageLabel = 'Overage', 
    backgroundColor = 'white',
    totalAmount,
    spentAmount, 
  }) => {
  
    const spendRatio = spentAmount / totalAmount;
    const progress = (spendRatio < 1) ? (spendRatio) * 100 : 100;
    const backgroundColorMain = `GaugeBar__body_main_base-${backgroundColor}`;
    const backgroundColorOverage = `GaugeBar__body_overage_base-${backgroundColor}`;

  return (
    <React.Fragment>
      <div className={css.GaugeBar__header_container}>
        <div className={css.GaugeBar__header_start_label}>
          { spentLabel }
        </div>
        <div className={css.GaugeBar__header_end_label}>
          { remainingLabel }
        </div>
        <div className={css.GaugeBar__header_overage_label}>
          { overageLabel }
        </div>
      </div>

      <div className={css.GaugeBar__body_foundation}>
        <div className={css[backgroundColorMain]}>
          <div className={css.GaugeBar__body_base}>
            <div className={css.GaugeBar__body_overlay} style={{ width: progress + '%' }} data-testid='gauge-bar'></div>
          </div>
        </div>
        <div className={css[backgroundColorOverage]}>
          <div className={css.GaugeBar__body_container}>
          </div>
        </div>
      </div>

      <div className={css.GaugeBar__footer_container}>
        <div className={css.GaugeBar__footer_start_label} data-testid='spent-value'>
          { currencyFormatter((spentAmount < totalAmount) ? spentAmount : totalAmount, { forceDecimal: true }) }
        </div>
        <div className={css.GaugeBar__footer_end_label} data-testid='remaining-value'>
          { currencyFormatter(Math.max(0, totalAmount - spentAmount), { forceDecimal: true }) }
        </div>
        <div className={css.GaugeBar__footer_overage_label} data-testid='overage-value'>
          { currencyFormatter(Math.abs(Math.min(0, totalAmount - spentAmount)), { forceDecimal: true }) }
        </div>
      </div>
    </React.Fragment>
  )
}

export default GaugeBar;
