import React from 'react';
import PropTypes from 'prop-types';
import BorderedButton from '../../../legacy/Construction/BorderedButton';
import slugifyText from '../../../utils/slugifyText';
import Tooltip from '../../../Tooltip';
import TooltipComponent from '../../../TooltipComponent';

const StaticAction = ({
  icon,
  title,
  label,
  onClick,
  dataTestid,
  tableCellData,
  disableWhen,
  hideWhen,
  iconActive,
  customButtonProps,
  tooltipText,
  tooltipPosition,
  minWidth,
}) => {
  // Return an empty fragment if we are not to show the action
  if (hideWhen
    && typeof hideWhen === 'function'
    && hideWhen(tableCellData)) {
    return (<></>);
  }

  let buttonProps = {
    icon,
    title,
    label,
    onClick: (event) => {
      // Use event.data to obtain row and column details from the click
      onClick({ ...event, data: tableCellData });
    },
    size: 'xs',
    iconSize: 22,
    type: 'gray10',
    minWidth,
    dataTestid: dataTestid || slugifyText(title),
  };

  // Disable if conditions met based on tableCellData provided
  if (disableWhen && typeof disableWhen === 'function' && disableWhen(tableCellData)) {
    buttonProps.disabled = true;
  }

  if (iconActive && typeof iconActive === 'function') {
    const activeIcon = iconActive(tableCellData);
    if (activeIcon && typeof activeIcon === 'string') {
      buttonProps.icon = activeIcon;
    }
  }

  let tooltip = tooltipText;

  if (customButtonProps && typeof customButtonProps === 'function') {
    const {
      type = 'gray10',
      size = 'xs',
      title: updatedTitle = '',
      label: updatedLabel = '',
      icon: updatedIcon = '',
      iconSize = 18,
      iconColor,
      tooltipText: text = '',
    } = customButtonProps(tableCellData);
    buttonProps = {
      ...buttonProps,
      type,
      size,
      title: updatedTitle,
      label: updatedLabel,
      icon: updatedIcon,
      iconSize,
      iconColor,
    };

    tooltip = text;
  }

  return !tooltip ? (
    <BorderedButton key={slugifyText(title)} {...buttonProps} />
  ) : (
    <Tooltip position={tooltipPosition}>
      <BorderedButton key={slugifyText(title)} {...buttonProps} />
      <TooltipComponent>{tooltip}</TooltipComponent>
    </Tooltip>
  );
};

StaticAction.propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func,
  dataTestid: PropTypes.string,
  title: PropTypes.string,
  label: PropTypes.string,
  tableCellData: PropTypes.any,
  disableWhen: PropTypes.func,
  hideWhen: PropTypes.func,
  iconActive: PropTypes.func,
  customButtonProps: PropTypes.func,
  tooltipText: PropTypes.string,
  tooltipPosition: PropTypes.string,
  minWidth: PropTypes.number,
};

StaticAction.defaultProps = {
  icon: '',
  onClick: () => {},
  dataTestid: null,
  title: '',
  label: '',
  tableCellData: null,
  disableWhen: undefined,
  hideWhen: undefined,
  iconActive: undefined,
  customButtonProps: undefined,
  tooltipText: undefined,
  tooltipPosition: 'right',
  minWidth: undefined,
};

export default StaticAction;
