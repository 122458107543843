import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactModal from 'react-modal';
import elementClass from 'element-class';
import classNames from 'classnames';
import css from './Modal/styles/Modal.scss';
import ModalAnimation from './Modal/ModalAnimation.jsx';
import IconButton from '../../IconButton';
import Heading from '../../Heading';
import tokens from '../../utils/tokens.json';

ReactModal.defaultStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '',
    },
    content: {
        position: 'absolute',
        top: '40px',
        left: '40px',
        right: '40px',
        bottom: '40px',
        border: `${tokens.BorderWidthSm} solid ${tokens.ColorGray50}`,
        background: `${tokens.ColorWhite}`,
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: `${tokens.FnBorderRadiusSm}`,
        outline: 'none',
        padding: `${tokens.SpacingMd}`,
    },
};

class Modal extends Component {
    setBodyClassFromProps() {
        if (this.props.isOpen) {
            elementClass(document.body).add('ReactModal__Body--modalOpen');
        } else {
            elementClass(document.body).remove('ReactModal__Body--modalOpen');
        }
    }

    // need to check the isOpen prop to toggle the '...modalOpen' CSS class
    // using the libraries 'onRequestClose' method prevents auto-matic
    // closing functionality

    componentDidMount() {
        this.setBodyClassFromProps();
    }

    componentWillUnmount() {
        elementClass(document.body).remove('ReactModal__Body--modalOpen');
    }

    componentDidUpdate() {
        this.setBodyClassFromProps();
    }

    getChildContext() {
        return {
            isAboveModal: true,
        };
    }

    closeModal(e) {
        const self = this;
        this.setState({ open: false }, () => {
            if (self.props.onCloseClick) {
                self.props.onCloseClick(e);
            }
        });
    }

    render() {
        const {
            type,
            roundedCorners,
            headerDivider,
            bodyColor,
            disableOverflow,
        } = this.props;
        const header = this.props.header
            ? (
                <Heading
                    tag="h1"
                    styleLevel="h3"
                >
                    {this.props.header}
                </Heading>
            ) : '';

        const footer = this.props.footer
                ? <div>{this.props.footer}</div>
                : '';
        const modifierClass = '';
        const dialogClasses = classNames([
            css.Modal__dialog
        ], {
            [css['Modal__dialog--small']]: this.props.size === 'small',
            [css['Modal__dialog--large']]: this.props.size === 'large',
            [css['Modal__dialog--extraLarge']]: this.props.size === 'extraLarge',
            [css['Modal__dialog--centered']]: this.props.position === 'center',
            }
        );
        const closeButtonClasses = classNames({
            [css.Modal__close]: true,
            [css['Modal__close--light']]: type !== 'secondary',
        });
        const overlayClassnames = ['ReactModal__Overlay--modal', 'js-modalOverlay', css.Modal__overlay].join(' ');
        const modalHeaderClasses = classNames([css.Modal__header], {
            [css[`Modal__${type}`]]: type && type !== 'secondary',
            [css['Modal__header--BorderRadius']]: roundedCorners,
            [css['Modal__header--BorderBottom']]: headerDivider,
        });
        const iconColor = (type === 'secondary') ? 'dark' : 'light';
        return (
            <ReactModal
                {...this.props}
                closeTimeoutMS={1500}
                overlayClassName={overlayClassnames}
                className={dialogClasses}
                onRequestClose={this.closeModal.bind(this)}
                contentLabel="Modal"
                appElement={document.querySelector('.c-page__body')}>
                <div style={{ position: 'fixed', top: '0', left: '0', right: '0', bottom: '0' }} />
                <div className={classNames(
                    css['Modal__content'],
                    'js-modalContent',
                    {
                        [css['Modal__content--BorderRadius']]: roundedCorners,
                        [css['Modal__content--overflow']]: !disableOverflow,
                    },
                )}>
                    {header &&
                        <div data-testid="modal-header" className={modalHeaderClasses}>
                            {header}
                            {this.props.onCloseClick &&
                            <div className={closeButtonClasses} >
                                <IconButton
                                  color={iconColor}
                                  title="close"
                                  size="lg"
                                  name="close"
                                  onClick={this.props.onCloseClick}
                                />
                            </div>
                            }
                        </div>
                    }
                    <div className={classNames(css.Modal__body, {
                        [css['Modal__body--gray10']]: bodyColor === 'gray10',
                        [css['Modal__body--gray30']]: bodyColor === 'gray30',
                        [css['Modal__body--overflow']]: !disableOverflow,
                    })}>{this.props.children}</div>
                    {footer &&
                    <div className={classNames(css.Modal__footer, {
                        [css[['Modal__footer--hasActionBar']]]: Boolean(this.props.hasActionBar),
                    })}>{footer}</div>
                    }
                </div>
            </ReactModal>
        );
    }
}

Modal.propTypes = {
    type: PropTypes.oneOf(['success', 'info', 'secondary', 'danger']),
    size: PropTypes.oneOf(['small', 'medium', 'large', 'extraLarge']),
    onCloseClick: PropTypes.func,
    hasActionBar: PropTypes.bool,
    roundedCorners: PropTypes.bool,
    headerDivider: PropTypes.bool,
    bodyColor: PropTypes.oneOf(['default', 'gray10', 'gray30']),
    disableOverflow: PropTypes.bool,
    position: PropTypes.oneOf(['default', 'center'])
};

Modal.defaultProps = {
    type: 'secondary',
    size: 'large',
    onCloseClick: null,
    hasActionBar: false,
    roundedCorners: true,
    headerDivider: true,
    bodyColor: 'default',
    disableOverflow: false,
    position: 'default',
};

Modal.childContextTypes = {
    isAboveModal: PropTypes.bool,
};

export default Modal;

export { ModalAnimation };
