
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import classNames from 'classnames';

export default class PanelBoxMain extends Component {
    constructor(props) {
        super(props);
        this.state = { };
    }

    render() {
        const styles = this.context.panelStyles;
        const panelContentClasses = classNames({
            [styles.Panel__bd__col]: true,
            [styles['Panel__bd__col--main']]: true,
            [styles['Panel__bd__col--grey']]: this.props.backgroundColor === 'grey',
        });
        const containerClasses = classNames(
            [styles.Panel__bd__col__container],
            'u-flexColumn',
            {
                [styles['u-static']]: this.context.panelPosition === 'static',
            }
        );
        const innerClasses = classNames({
            [styles.Panel__bd__col__container__bd__container__inner]: true,
            [styles['u-static']]: this.context.panelPosition === 'static',
        });
        const containerBodyContainerClasses = classNames(
            [styles.Panel__bd__col__container__bd__container],
            {
                [styles['u-static']]: this.context.panelPosition === 'static',
            }
        );
        return (
            <div className={panelContentClasses}>
                <div className={containerClasses}>
                    {this.props.header ?
                        <div className={[styles.Panel__bd__col__container__hdr]}>{this.props.header}</div>
                        :
                        null
                    }
                    <div className={[styles.Panel__bd__col__container__bd]}>
                        <div className={containerBodyContainerClasses}>
                            <div className={innerClasses}>{this.props.children}</div>
                        </div>
                    </div>

                    {this.props.footer &&
                        <div className={classNames(styles.Panel__bd__col__container__ftr, {
                            [styles['Panel__bd__col__container__ftr--flush']]: this.props.bodyFooterFlush,
                        })}>{this.props.footer}</div>
                    }
                </div>
            </div>
        );
    }
}

PanelBoxMain.propTypes = {
    backgroundColor: PropTypes.oneOf(['white', 'grey']),
    bodyFooterFlush: PropTypes.bool,
};

PanelBoxMain.defaultProps = {
    backgroundColor: 'white',
    bodyFooterFlush: false,
};

PanelBoxMain.contextTypes = {
    panelStyles: PropTypes.object,
    panelPosition: PropTypes.oneOf(['static', 'absolute']),
};
