import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles/SummaryView.scss';
import Icon from '../../../Icon';
import InfoIcon from '../../../legacy/Construction/InfoIcon';

const SummaryCard = React.memo(({
  title,
  subtitle,
  meta,
  icon,
  info,
  render,
}) => (
  <div className={styles.SummaryCard__container}>
    {
      render ? render() : (
        <div className={styles.SummaryCard__content}>
          {
            icon && (
              <span className={styles.SummaryCard__content__icon}>
                <Icon name={icon} size={24} />
              </span>
            )
          }
          <div className={styles.SummaryCard__content__main}>
            <p className={styles.SummaryCard__content__title}>{title}</p>
            <div>
              <p className={styles.SummaryCard__content__subtitle}>{subtitle}</p>
              {
                info && (
                  <InfoIcon showOnEvent="click" color="indigo">
                    {
                      typeof info === 'function' ? info() : info
                    }
                  </InfoIcon>
                )
              }
            </div>
            <p className={styles.SummaryCard__content__meta}>{meta}</p>
          </div>
        </div>
      )
    }
  </div>
));

export const SummaryCardPropTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  meta: PropTypes.string,
  icon: PropTypes.string,
  info: PropTypes.element,
  render: PropTypes.node,
};
SummaryCard.propTypes = SummaryCardPropTypes;
SummaryCard.defaultProps = {
  title: '',
  subtitle: '',
  meta: '',
  icon: '',
  info: null,
  render: null,
};
export default SummaryCard;
