import React, { Component } from 'react';
import Status from '../Status';

class StatusBubble extends Component {
  constructor(props) {
    super(props);
    if (process.env.NODE_ENV !== 'production') {
      console.warn('StatusBubble has been deprecated. Use <Status size="sm" />.');
    }
  }

  render() {
    return (
      <Status {...this.props} size="sm" inlineFlex />
    );
  }
}

export default StatusBubble;
