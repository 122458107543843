/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import includes from 'lodash/includes';
import get from 'lodash/get';
import classNames from 'classnames';
import Filler from '../../legacy/Construction/Filler/Filler';
import TableComponent from '../../Table';
import TableHead from '../../TableHead';
import TableBody from '../../TableBody';
import TableRow from '../../TableRow';
import TableCell from '../../TableCell';
import TableColumnHeader from '../../TableColumnHeader';
import SelectAll from './SelectAll/SelectAll';
import Median from '../../Median';
import MedianAlpha from '../../MedianAlpha';
import MedianOmega from '../../MedianOmega';
import Menu from '../../Menu';
import HorizontalList from '../../HorizontalList';
import HorizontalListItem from '../../HorizontalListItem';
import IconButton from '../../IconButton';
import StaticAction from './StaticActions/StaticAction';
import Option from '../../Option';
import Pagination from '../../Pagination';
import Truncate from '../../Truncate';
import BodyText from '../../BodyText';
import css from './styles.scss';
import ColumnConfig, { ColumnConfigPropTypes } from '../../ColumnConfig';
import EmptyResultsContainer from '../../EmptyResultsContainer';
import wrapEventHandler, { ARG_ORDER } from '../../utils/wrapEventHandler';

const getSelectAllState = (data, selectedIds) => {
  const selectedCountInData = data.map((row) => {
    if (selectedIds && includes(selectedIds, row.id)) {
      return row;
    }
    return null;
  })
    .filter((x) => !!x)
    .length;

  if (selectedCountInData === 0) {
    return 'unchecked';
  }
  if (selectedCountInData === data.length) {
    return 'checked';
  }
  return 'indeterminate';
};

function menuTriggerJSX(isDisabled) {
  return (
    <div className={css['Table__inlineActions--position']}>
      <IconButton
        size="sm"
        disabled={isDisabled}
        name="moreHoriz"
      />
    </div>
  );
}

class Table extends Component {
  constructor(props) {
    super(props);

    this.state = {
      changedCol: {
        id: '',
        width: 0,
      },
      tableWidth: 0,
    };

    this.tableRef = React.createRef();

    this.handleColumnResize = this.handleColumnResize.bind(this);
    this.getTableDimensions = this.getTableDimensions.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleRowChange = this.handleRowChange.bind(this);

    this.selectableWidth = 55;
    this.inlineActionsWidth = 55;
    this.minColumnWidth = 100;
    this.bottomOffset = this.footerEnabled() ? 140 : 0;
  }

  componentDidMount() {
    this.getTableDimensions();
    window.addEventListener('resize', this.getTableDimensions);
  }

  componentDidUpdate(prevProp, prevState) {
    const { tableWidth } = this.state;

    const { data } = this.props;
    if (prevState.tableWidth !== tableWidth || prevProp.data.length !== data.length) {
      this.getTableDimensions();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getTableDimensions);
  }

  handleSelectAll(checkedValue) {
    const { data, selectedIds, onAllRowsChange } = this.props;
    const selectAllCheckboxState = getSelectAllState(data, selectedIds);
    onAllRowsChange(selectAllCheckboxState === 'indeterminate' ? false : checkedValue);
  }

  handleRowChange(id, event) {
    const { onRowChange } = this.props;
    const checked = get(event, 'currentTarget.checked');
    onRowChange(id, checked);
  }

  handleColumnResize(id, width) {
    const { onColumnResize } = this.props;

    this.setState({
      changedCol: {
        id,
        width,
      },
    });

    return onColumnResize(id, width);
  }

  getTableDimensions() {
    setTimeout(() => {
      // eslint-disable-next-line react/no-find-dom-node
      const tableDOM = ReactDOM.findDOMNode(this.tableRef.current);
      if (tableDOM) {
        this.setState({
          tableWidth: tableDOM.offsetWidth,
        });
      }
    }, 400);
  }

  getTotalColumnWidth() {
    const { columns, selectable } = this.props;
    const { changedCol } = this.state;

    const providedColumnTotalWidth = columns
      .map((col) => {
        let changedColDetails = {};

        if (col.id === changedCol.id) {
          changedColDetails = { ...col, width: changedCol.width };
        }

        return { ...col, ...changedColDetails };
      })
      .map((col) => (col.id === 'inline_actions' ? this.selectableWidth : col.width))
      .reduce((acc, cur) => acc + cur, 0);

    return providedColumnTotalWidth + (selectable ? this.selectableWidth : 0);
  }

  footerEnabled() {
    const {
      currentPage,
      itemsPerPage,
      totalRecords,
    } = this.props;
    return currentPage || itemsPerPage || totalRecords;
  }

  render() {
    const {
      columns,
      selectedTab,
      currentPage,
      data,
      itemsPerPage,
      onSelectEntireData,
      onColumnSort,
      onColumnResize,
      onPageChange,
      selectable,
      selectedIds,
      totalRecords,
      rowHeight,
      headerOffset,
      columnConfig,
      minBodyHeight,
      emptyResultsInfo,
      showColumnConfig,
      onColumnConfigToggle,
      stickyFirstColumn,
      stickyFooter,
      stickyColumnActions,
      stickyColumnActionButtonWidth,
      stickyRowHighlight,
      disableTableBorder,
      selectAllLabel,
      topHeaderEnabled,
      topHeaderColumns,
      applyRowBorder,
      applyDistinctBg,
    } = this.props;

    const processedColumns = [...columns];
    const {
      tableWidth,
    } = this.state;
    const selectAllCheckboxState = getSelectAllState(data, selectedIds);
    const selectedTabTitle = selectedTab ? `${selectedTab}: ` : '';
    const totalPages = Math.ceil(totalRecords / itemsPerPage);
    const lastRecordOnPage = (itemsPerPage * currentPage <= totalRecords) ? (
      itemsPerPage * currentPage
    ) : totalRecords;
    const firstRecordOnPage = itemsPerPage * (currentPage - 1) + 1;
    const totalColumnWidth = this.getTotalColumnWidth();
    const inlineActionsColumnIndex = processedColumns.findIndex((c) => c.id === 'inline_actions');

    let inlineActions = {};
    if (inlineActionsColumnIndex > -1) {
      [inlineActions] = processedColumns.splice(inlineActionsColumnIndex, 1);
    }
    const inlineActionsColumn = {
      ...inlineActions,
      id: 'inline_actions',
      header: (<IconButton onClick={onColumnConfigToggle} name="settings" title="Configure Columns" />),
      width: this.inlineActionsWidth,
      render: (row) => {
        if (inlineActions?.hideEmptyActions && !inlineActions?.render(row)?.length) {
          return '';
        }
        return (
          <Menu
            alignToRight
            disableAutoWidth
            trigger={wrapEventHandler(menuTriggerJSX, [!inlineActions?.render(row)?.length], ARG_ORDER.EVENT_LAST)}
            menuItems={inlineActions?.render ? inlineActions?.render(row) : []}
            closeOnSelection
          />
        );
      },
    };

    return (
      <>
        {showColumnConfig && (
          <ColumnConfig
            {...columnConfig}
            onCancel={columnConfig.onCancel}
            onDone={columnConfig.onDone} // eslint-disable-line react/prop-types
            onRestoreSystemDefaults={columnConfig.onRestoreSystemDefaults} // eslint-disable-line react/prop-types
            onSaveCompanyDefaults={columnConfig.onSaveCompanyDefaults} // eslint-disable-line react/prop-types
          />
        )}
        <Filler
          bottomOffset={this.bottomOffset}
          minHeight={minBodyHeight}
        >
          <div className={disableTableBorder ? undefined : css.Table__wrapper}>
            <TableComponent
              ref={this.tableRef}
            >
              {topHeaderEnabled && (
                <TableHead offset={headerOffset}>
                  <TableRow
                    totalColumnWidth={totalColumnWidth}
                    tableWidth={tableWidth}
                    lastColumnStretch={false}
                  >
                    {topHeaderColumns.map((col) => (
                      <TableColumnHeader
                        id={col.id}
                        key={col.id}
                        label={col.header}
                        sticky={undefined}
                        width={col.width}
                        isTopHeader={topHeaderEnabled}
                      />
                    ))}
                  </TableRow>
                </TableHead>
              )}
              <TableHead offset={headerOffset}>
                <TableRow
                  totalColumnWidth={totalColumnWidth}
                  tableWidth={tableWidth}
                  lastColumnStretch={false}
                >
                  {selectable && (
                    <TableColumnHeader
                      sticky={{
                        side: 'left',
                        offset: 0,
                        border: (stickyFirstColumn) ? 'none' : 'right',
                      }}
                      key="selectable-header"
                      id="selectable-header"
                      label={(
                        <SelectAll
                          checkboxState={selectAllCheckboxState}
                          onCheckboxChange={this.handleSelectAll}
                          onSelectEntireData={onSelectEntireData}
                          selectAllLabel={selectAllLabel}
                        />
                      )}
                      width={this.selectableWidth}
                    />
                  )}
                  {processedColumns.map((col, idx) => (
                    <TableColumnHeader
                      id={col.id}
                      key={col.id}
                      label={col.header}
                      sticky={(stickyFirstColumn && idx === 0) ? {
                        side: 'left',
                        offset: (selectable) ? this.selectableWidth : 0,
                        border: 'right',
                      } : undefined}
                      maxWidth={col.maxWidth}
                      minWidth={col.minWidth && col.minWidth > 20
                        ? col.minWidth
                        : this.minColumnWidth}
                      onResize={onColumnResize
                        ? this.handleColumnResize : undefined}
                      onSort={(col.sortable && onColumnSort)
                        ? onColumnSort : undefined}
                      sortDirection={col.sortDirection || undefined}
                      sortable={col.sortable || false}
                      width={col.width}
                      info={col.info || ''}
                    />
                  ))}
                  {columnConfig && (
                    <TableColumnHeader
                      sticky={{
                        side: 'right',
                        border: 'left',
                      }}
                      id={inlineActionsColumn.id}
                      key={inlineActionsColumn.id}
                      label={inlineActionsColumn.header}
                      width={inlineActionsColumn.width}
                      isConfig
                      isOverflow={tableWidth < totalColumnWidth}
                    />
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {!data.length && emptyResultsInfo
                  ? <EmptyResultsContainer>{emptyResultsInfo}</EmptyResultsContainer> : []}
                {data.map((row) => (
                  <TableRow
                    totalColumnWidth={totalColumnWidth}
                    tableWidth={tableWidth}
                    key={row.id}
                    highlightColor={(stickyRowHighlight && stickyRowHighlight == row.id) ? 'selected' : row.highlight}
                    applyRowBorder={applyRowBorder}
                    lastColumnStretch={false}
                    applyDistinctBg={applyDistinctBg}
                  >
                    {selectable && (
                      <TableCell
                        sticky={{
                          side: 'left',
                          offset: 0,
                          border: (stickyFirstColumn) ? 'none' : 'right',
                        }}
                        key={`${row.id}-selectable-column`}
                        id={`${row.id}-selectable-column`}
                        width={this.selectableWidth}
                        rowHeight={rowHeight}
                      >
                        <Option
                          checked={selectedIds.includes(row.id)}
                          onChange={wrapEventHandler(this.handleRowChange, [row.id], ARG_ORDER.ARGS_LAST, this)}
                          value={row.id}
                          labelHidden
                        >
                          Select Row
                          {` ${row.id}`}
                        </Option>
                      </TableCell>
                    )}
                    {processedColumns.map((col, idx) => (
                      <TableCell
                        key={`${row.id}-${col.id}`}
                        width={col.width}
                        rowHeight={row.rowHeight ? row.rowHeight : rowHeight}
                        sticky={(stickyFirstColumn && idx === 0) ? {
                          side: 'left',
                          offset: (selectable) ? this.selectableWidth : 0,
                          border: 'right',
                        } : undefined}
                        applyBorderRight={col.borderRight || false}
                        contentAlign={col.contentAlign}
                      >
                        {(stickyFirstColumn
                          && stickyColumnActions
                          && stickyColumnActions.length > 0
                          && idx === 0)
                          ? (
                            <>
                              <div
                                style={{
                                  width: `${
                                    col.width - 24 - ((stickyColumnActions.length) * stickyColumnActionButtonWidth)
                                  }px`,
                                }}
                                className={css.StaticAction__content}
                              >
                                <BodyText
                                  styleLevel="sm"
                                >
                                  <Truncate
                                    text={col.render(row)}
                                    lineHeight="1.5em"
                                    maxLines={2}
                                  />
                                </BodyText>
                              </div>
                              <div
                                style={{ width: `${stickyColumnActions.length * stickyColumnActionButtonWidth}px` }}
                                className={css.StaticAction__buttons}
                              >
                                {stickyColumnActions.map((sc) => <StaticAction tableCellData={{ row, col }} key="static-action" {...sc} />)}
                              </div>
                            </>
                          )
                          : (
                            <BodyText
                              styleLevel="sm"
                            >
                              <Truncate
                                text={col.render(row)}
                                lineHeight="1.5em"
                                maxLines={2}
                              />
                            </BodyText>
                          )}
                      </TableCell>
                    ))}
                    {inlineActionsColumnIndex > -1 && (
                      <TableCell
                        sticky={{
                          side: 'right',
                          offset: 0,
                          border: 'left',
                        }}
                        id={inlineActionsColumn.id}
                        key={`${row.id}-inline-actions-column`}
                        width={inlineActionsColumn.width}
                        rowHeight={rowHeight}
                        isConfig
                        isOverflow={tableWidth < totalColumnWidth}
                      >
                        {inlineActionsColumn.render(row)}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </TableComponent>
            {(this.footerEnabled()) && (
            <div className={
                    classNames({
                      [css.Table__footer]: !stickyFooter,
                      [css['Table__footer--sticky']]: stickyFooter,
                    })
                }
            >
              <Median>
                <MedianAlpha>
                  <HorizontalList spacing={2}>
                    {(selectedIds && selectedIds.length) ? (
                      <HorizontalListItem>
                        {selectedTabTitle}
                        {selectedIds.length}
                        {' of '}
                        {totalRecords}
                      </HorizontalListItem>
                    ) : (
                      <HorizontalListItem>
                        {selectedTabTitle}
                        {firstRecordOnPage}
                        -
                        {lastRecordOnPage}
                        {' of '}
                        {totalRecords}
                      </HorizontalListItem>
                    )}
                  </HorizontalList>
                </MedianAlpha>
                <MedianOmega>
                  <HorizontalList spacing={2}>
                    <HorizontalListItem>
                      <Pagination
                        onNext={wrapEventHandler(onPageChange, [currentPage + 1], ARG_ORDER.EVENT_LAST, this)}
                        onPrev={wrapEventHandler(onPageChange, [currentPage - 1], ARG_ORDER.EVENT_LAST, this)}
                        currentPage={currentPage}
                        disableNext={currentPage >= totalPages}
                        disablePrev={currentPage <= 1}
                        totalPages={totalPages}
                      />
                    </HorizontalListItem>
                  </HorizontalList>
                </MedianOmega>
              </Median>
            </div>
            )}
          </div>
        </Filler>
      </>
    );
  }
}

export const TablePropTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      header: PropTypes.node,
      sortable: PropTypes.bool,
      sortDirection: PropTypes.oneOf(['ASC', 'DESC']),
      width: PropTypes.number,
      truncate: PropTypes.bool,
      render: PropTypes.func,
      minWidth: PropTypes.number,
      maxWidth: PropTypes.number,
      borderRight: PropTypes.bool,
      contentAlign: PropTypes.string,
    }),
  ).isRequired,
  selectedTab: PropTypes.string,
  currentPage: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  itemsPerPage: PropTypes.number,
  onAllRowsChange: PropTypes.func,
  onSelectEntireData: PropTypes.func,
  onColumnResize: PropTypes.func,
  onColumnSort: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowChange: PropTypes.func,
  selectable: PropTypes.bool,
  stickyFirstColumn: PropTypes.bool,
  stickyFooter: PropTypes.bool,
  stickyColumnActions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func,
    'data-testid': PropTypes.string,
  })),
  stickyColumnActionButtonWidth: PropTypes.number,
  stickyRowHighlight: PropTypes.any,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  totalRecords: PropTypes.number,
  rowHeight: PropTypes.oneOf(['auto', '2']),
  headerOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  columnConfig: PropTypes.shape({
    ...ColumnConfigPropTypes,
    onCancel: PropTypes.func,
  }),
  minBodyHeight: PropTypes.number,
  emptyResultsInfo: PropTypes.node,
  showColumnConfig: PropTypes.bool,
  onColumnConfigToggle: PropTypes.func,
  disableTableBorder: PropTypes.bool,
  selectAllLabel: PropTypes.string,
  topHeaderEnabled: PropTypes.bool,
  topHeaderColumns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    header: PropTypes.string,
    width: PropTypes.number,
  })),
  applyRowBorder: PropTypes.bool,
  lastColumnStretch: PropTypes.bool,
  applyDistinctBg: PropTypes.bool,
};

Table.propTypes = TablePropTypes;
Table.defaultProps = {
  selectedTab: '',
  currentPage: undefined,
  itemsPerPage: undefined,
  onAllRowsChange: undefined,
  onSelectEntireData: undefined,
  onColumnResize: undefined,
  onColumnSort: undefined,
  onPageChange: undefined,
  onRowChange: undefined,
  selectable: false,
  stickyFirstColumn: false,
  stickyFooter: true,
  stickyColumnActions: undefined,
  stickyColumnActionButtonWidth: 24,
  stickyRowHighlight: undefined,
  selectedIds: [],
  totalRecords: undefined,
  rowHeight: '2',
  headerOffset: 0,
  columnConfig: undefined,
  minBodyHeight: 200,
  emptyResultsInfo: '',
  showColumnConfig: false,
  onColumnConfigToggle: () => {},
  disableTableBorder: false,
  selectAllLabel: undefined,
  topHeaderEnabled: false,
  topHeaderColumns: [],
  applyRowBorder: false,
  lastColumnStretch: true,
  applyDistinctBg: true,
};

export default Table;
