import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import classes from './Label.scss';
import InfoIcon from '../../InfoIcon';

export default class Label extends PureComponent {
    static propTypes = {
        'data-testid': PropTypes.string,
        help: PropTypes.string | PropTypes.element,
        helpTextShowEvent: PropTypes.string,
        optional: PropTypes.bool,
        hideOptionalLabel: PropTypes.bool,
        screenReaderOnly: PropTypes.bool,
    }

    static defaultProps = {
        'data-testid': undefined,
        screenReaderOnly: false,
        help: '',
        helpTextShowEvent: 'hover',
        hideOptionalLabel: false
    }

    static contextTypes = {
        fieldId: PropTypes.string.isRequired,
    }

    constructor(props, context) {
        super(props, context);
        this.state = { };

        this.renderHelp = this.renderHelp.bind(this);
    }

    renderHelp() {
        if (this.props.help) {
            return (<div className={classes.Label__help}>
                <InfoIcon showOnEvent={this.props.helpTextShowEvent}>{this.props.help}</InfoIcon>
            </div>);
        }
        return null;
    }

    render() {
        const { children, 'data-testid': dataNwId, hideOptionalLabel } = this.props;
        return (
            <label
                data-testid={dataNwId}
                htmlFor={this.context.fieldId}
                className={classNames(classes.Label, {
                    [classes['Label--srOnly']]: this.props.screenReaderOnly,
                })}>
                <span className={classes.Label__text}>{children}</span>
                {this.props.optional && !hideOptionalLabel &&
                    <span className={classes.Label__desc}>&ndash; Optional</span>
                }
                {this.renderHelp()}
            </label>
        );
    }
}
