import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Collapse from 'rc-collapse';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';

const CollapsePanel = Collapse.Panel;

class Accordion extends Component {
  constructor(props) {
    super(props);
    this.state = { activeKey: this.props.defaultActiveKey };
    this.onChange = this.onChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(props) {
    !isEqual(props.defaultActiveKey, this.props.defaultActiveKey) && this.setState({
      activeKey: props.defaultActiveKey,
    });
  }

  onChange(activeKey) {
    this.setState({ activeKey });
    this.props.onChange(activeKey);
  }

  getItems() {
    const isSingleChild = !(this.props.children.length > 1);
    const childrenWithProps = React.Children.map(this.props.children, child => React.cloneElement(child, {
      arrowAlign: this.props.arrowAlign,
      headerDivider: this.props.headerDivider,
      headerFlush: this.props.headerFlush,
      headerColor: this.props.headerColor,
      headerPadded: this.props.headerPadded,
      isSingleChild,
      allowMultipleActive: this.props.allowMultipleActive,
    }));
    return childrenWithProps;
  }

  render() {
    if (this.props.children.length > 1 || this.props.allowMultipleActive) {
      return (
        <Collapse {...this.props} activeKey={this.state.activeKey} onChange={this.onChange} prefixCls="c-accordion" accordion={!this.props.allowMultipleActive}>
          {this.getItems()}
        </Collapse>
      );
    }
    return (
      <div className="c-accordion">
        {this.getItems()}
      </div>
    );
  }
}

Accordion.propTypes = {
  arrowAlign: PropTypes.oneOf(['left', 'right']),
  headerDivider: PropTypes.bool,
  headerFlush: PropTypes.bool,
  defaultActiveKey: PropTypes.array,
  onChange: PropTypes.func,
  inactiveKeys: PropTypes.array,
  headerColor: PropTypes.oneOf(['white', 'grey']),
  headerPadded: PropTypes.bool,
  allowMultipleActive: PropTypes.bool,
};

Accordion.defaultProps = {
  arrowAlign: 'left',
  headerDivider: false,
  headerFlush: false,
  defaultActiveKey: ['.0'],
  onChange: () => {},
  inactiveKeys: [],
  headerColor: 'white',
  headerPadded: false,
  allowMultipleActive: false,
};

class AccordionPanel extends Component {
  constructor(props) {
    super(props);
    this.state = { };
  }

  componentWillUnmount() {
    // retain open panels upon re-render
    if (this.props.isActive) {
      this.props.dispatch({ type: 'APP_TOGGLE_ON', flag: `.$${this.props.toggleKey}` });
    }
  }

  render() {
    const accordionClasses = classNames({
      'c-accordion-item--arrowRight': this.props.arrowAlign === 'right',
      'c-accordion-item__headerDivider': this.props.headerDivider === true,
      'c-accordion-item--headerFlush': this.props.headerFlush === true,
      'c-accordion-item--headerGrey': this.props.headerColor === 'grey',
      'c-accordion-item--padded': this.props.headerPadded === true,
      'c-accordion-item--flush': this.props.bodyFlush === true,
    });
    if (!this.props.isSingleChild || this.props.allowMultipleActive) {
      return (
        <CollapsePanel {...this.props} className={accordionClasses}>
          {this.props.children}
        </CollapsePanel>
      );
    }
    return (
      <div className={accordionClasses}>
        <div data-testid="divided accordian selector" className="c-accordion-header c-accordion-header__noHPad">{this.props.header}</div>
        <div className="c-accordion-content-box">{this.props.children}</div>
      </div>
    );
  }
}

export default Accordion;
export { AccordionPanel };
