import { selector } from 'recoil';
import prefsState from './prefs.state';

const searchTextQuery = selector<string>({
  key: 'searchText',
  get: ({ get }) => {
    const prefs = get(prefsState);

    if (!prefs) {
      return '';
    }

    return prefs.searchText || '';
  },
});

export default searchTextQuery;
