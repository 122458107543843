export default function GetOptions(input, callback, options) {
    input = input.toLowerCase();

    if (!input) {
        return Promise.resolve({ options: [] });
    }

    const returnOptions = options.filter(i => i.label.indexOf(input) !== -1);

    const data = {
        options: returnOptions,
        complete: true,
    };

    setTimeout(() => {
        callback(null, data);
    }, 1);
}
