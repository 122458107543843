/* eslint-disable no-console */
import { useRecoilState, useRecoilCallback, RecoilValue } from 'recoil';
import { save, Preferences } from './preferences.factory';
import prefsState from './prefs.state';
import tabPropsQuery from './tab-props.query';
import propsState from './props.state';
import tabPrefsQuery from './tab-prefs.query';

const useView = () => {
  const [, setPrefs] = useRecoilState(prefsState);

  const rcb = useRecoilCallback(({ snapshot }) => (query: RecoilValue<any>) => {
    if (query && snapshot) {
      const { contents } = snapshot.getLoadable(query);
      return contents;
    }
    return null;
  });

  return (view: string) => {
    const prefs = rcb(prefsState);
    const tabPrefs = rcb(tabPrefsQuery);
    const tabProps = rcb(tabPropsQuery);
    const props = rcb(propsState);

    if (!tabProps || !tabPrefs || !prefs || !props) {
      return;
    }

    if (view === 'map' && !tabProps.withMapSupport) {
      return;
    }

    const updatedPrefs: Preferences = {
      ...prefs,
      tabs: (prefs.tabs || []).map((tab) => {
        if (tab.id === tabPrefs.id) {
          return {
            ...tab,
            view: view as 'map' | 'list',
          };
        }

        return tab;
      }),
    };

    setPrefs(updatedPrefs);
    save(props, updatedPrefs).catch((err) => console.error(err));
  };
};

export default useView;
