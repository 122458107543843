import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './styles.scss';

const Tip = ({
  isFlush,
  color,
  widthAuto,
  children,
  noArrow,
}) => (
  <div className={classNames(css.Tip, {
    [css['Tip--isFlush']]: isFlush,
    [css['Tip--blue']]: color === 'blue',
    [css['Tip--widthAuto']]: widthAuto,
  })}
  >
    {children}
    {!noArrow && <span className={css.Tip__arrow} />}
  </div>
);

Tip.propTypes = {
  color: PropTypes.oneOf(['white', 'blue']),
  isFlush: PropTypes.bool,
  widthAuto: PropTypes.bool,
  children: PropTypes.node.isRequired,
  noArrow: PropTypes.bool,
};

Tip.defaultProps = {
  color: 'white',
  isFlush: false,
  widthAuto: false,
  noArrow: false,
};

export default Tip;
