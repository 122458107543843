import React from 'react';
import { HorizontalListItem } from '../../../../';

const GetButtonsWrappedInItems = (buttons) =>
  React.Children.map(buttons, (button, i) =>
    <HorizontalListItem key={i}>
      {
        React.cloneElement(button, {
          isInGroup: true,
          firstInGroup: i === 0,
          lastInGroup: i === (React.Children.count(buttons) - 1),
        })
      }
    </HorizontalListItem>
  );

export default GetButtonsWrappedInItems;
