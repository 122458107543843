import { fromUnixTime, format, parse } from 'date-fns';
import { Column } from './DataGrid';

const createComponent = (
  property: string,
  fmt: string,
  parsing: 'timestamp' | string,
) => (row: any) => {
  const value = row?.[property] || '';

  if (!value) {
    return null;
  }

  const parsed = parsing === 'timestamp'
    ? fromUnixTime(value)
    : parse(value, parsing, new Date());

  return format(parsed, fmt);
};

interface Options {
  id: string;
  property: string;
  format?: string;
  label?: string;
  parse?: 'timestamp' | string;
  defaultWidth?: number;
  defaultSortDirection?: 'asc' | 'desc';
  withDefault?: boolean;
  withTruncation?: boolean;
  withSort?: boolean;
}

export default ({
  id,
  label,
  property,
  defaultSortDirection = 'desc',
  withDefault = false,
  defaultWidth = 90,
  format: fmt = 'M/d/yyyy',
  parse: parsing = 'timestamp',
  withTruncation = true,
  withSort = false,
}: Options) => {
  const column: Column = {
    id,
    label: label || id,
    render: createComponent(property, fmt, parsing),
    defaultWidth,
    withDefault,
    withSort,
    withTruncation,
    defaultSortDirection,
  };

  return column;
};
