
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import styles from './Tile/styles/Tile.scss';
import TileDivision from './Tile/TileDivision.jsx';
import TileDivisionHdg from './Tile/TileDivisionHdg.jsx';
import TileDivisionAction from './Tile/TileDivisionAction.jsx';
import TileSection from './Tile/TileSection.jsx';
import TileSectionHdg from './Tile/TileSectionHdg.jsx';

/**
 * @deprecated Use Tile V2 as a replacement for this component.
 */
class Tile extends Component {
    getChildContext() {
        return {
            tileStyles: styles,
        };
    }

    render() {
        return (
            <div>
                <div styleName="Tile__bd">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

Tile.propTypes = {

};

Tile.defaultProps = {

};

Tile.childContextTypes = {
    tileStyles: PropTypes.object,
};

export default CSSModules(Tile, styles);

export {
    TileDivision,
    TileDivisionHdg,
    TileDivisionAction,
    TileSection,
    TileSectionHdg,
};
