export default function getFormattedDateString(str) {
  let formatted = str;

  // don't replace anything if string
  if (str) {
    // remove leading zero
    formatted = formatted.replace(/^(0)(\d):/g, (match, p1, p2) => {
      const string = `${p2}:`;
      return string;
    });

    //  enforce colon between 1-4 digits (digits only)
    formatted = formatted.replace(/^(\d{1,4})$/g, (match) => {
      const strLength = match.length;
      let minuteString = '00';
      let hourString;
      if (strLength > 2) {
        minuteString = match.substring(strLength - 2);
        hourString = match.substring(0, strLength - 2);
      } else {
        hourString = match;
      }

      const string = `${hourString}:${minuteString}`;
      return string;
    });

    // replace lower case a & m
    formatted = formatted.replace(/a/g, 'A')
      .replace(/p/g, 'P')
      .replace(/m/g, 'M');

    // format digits (1-2) + am/pm
    formatted = formatted.replace(/^(\d{1,2})\s?(AM|PM)$/g, (match, p1, p2) => {
      const string = `${p1}:00${p2}`;
      return string;
    });

    const removeAfterSecondColin = (strval) => {
      const splitByColins = strval.split(':');
      if (splitByColins.length > 2) {
        return `${splitByColins[0]}:${splitByColins[1]}`;
      }
      return strval;
    };
    formatted = removeAfterSecondColin(formatted);

    // enforce space between numbers and am/pm
    formatted = formatted.replace(/^(\d{1}|\d{2}):(\d{2})(AM|PM)/g, (match, p1, p2, p3) => {
      const string = `${p1}:${p2} ${p3}`;
      return string;
    });

    // change leading zero to 12 and add AM
    formatted = formatted.replace(/^(0|00):(\d{2})$/g, (match, p1, p2) => {
      const string = `12:${p2} AM`;
      return string;
    });

    // enforce am/pm
    formatted = formatted.replace(/^(\d{1}|\d{2}):(\d{2})$/g, (match, p1, p2) => {
      const intHour = parseInt(p1, 10);
      let hourString;
      const minuteString = p2;
      let amPmString;

      if (intHour === 0 || intHour === 12) {
        hourString = '12';
        amPmString = 'AM';

        if (intHour === 12) {
          amPmString = 'PM';
        }
      } else if (intHour > 0 && intHour < 12) {
        hourString = intHour;
        amPmString = 'AM';
      } else if (intHour > 12 && intHour <= 23) {
        hourString = intHour - 12;
        amPmString = 'PM';
      }

      return `${hourString}:${minuteString} ${amPmString}`;
    });
  }

  return formatted;
}
