import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class BoxGridColumn extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = { };
    }

    render() {
        const styles = this.context.boxStyles;
        const classes = classNames([styles.box__grid__column], {
            [styles['box__grid__column--6of12']]: this.props.unitsWide === 2,
            [styles['box__grid__column--4of12']]: this.props.unitsWide === 3,
        });
        return (
            <div className={classes}>{this.props.children}</div>
        );
    }
}

BoxGridColumn.propTypes = {
    unitsWide: PropTypes.oneOf([2, 3]),
};

BoxGridColumn.defaultProps = {
    unitsWide: 2,
};

BoxGridColumn.contextTypes = {
    boxStyles: PropTypes.object,
};
