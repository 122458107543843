import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../legacy/Construction/Button';
import Menu from '../Menu';
import Icon from '../Icon';
import css from './styles.scss';

function getIconName(isTypePrimary, state) {
  if (isTypePrimary) {
    if (state.isOpen) {
      return 'dropDownOpen';
    }

    return 'dropDown';
  }

  return 'expand';
}

/*
  After cleanup, this should be a pure function. But functional component does
  not support refs. React forwardRef can be used but it may require to change
  in all the places where this component is used and needed ref usage. So for
  minimum impact I'm keeping it class component.
*/

// eslint-disable-next-line react/prefer-stateless-function
class ActionsMenu extends Component {
  constructor(props) {
    super(props);
    this.renderTrigger = this.renderTrigger.bind(this);
  }

  renderTrigger = (menuProps, menuState) => {
    const {
      type,
    } = this.props;
    const isTypePrimary = type === 'primary';

    const actionsMenuClasses = classNames(
      css.ActionsMenu,
      {
        [css['ActionsMenu--isTypePrimary']]: isTypePrimary,
      },
    );

    const actionsMenuIconClasses = classNames({
      [css.ActionsMenu__icon]: !isTypePrimary,
      [css['ActionsMenu__icon--isTypePrimary']]: isTypePrimary,
    });
    return (
      <Button
        isInGroup={menuProps.isInGroup}
        firstInGroup={menuProps.firstInGroup}
        lastInGroup={menuProps.lastInGroup}
        type={type}
        disabled={menuProps.disabled}
        dataTestid={`actions-menu-${type}`}
      >
        <span className={actionsMenuClasses}>
          <span className={isTypePrimary ? css['ActionsMenu__text--isTypePrimary'] : css.ActionsMenu__text}>{menuProps.label}</span>
          <span
            className={actionsMenuIconClasses}
          >
            <Icon
              block={isTypePrimary}
              size={isTypePrimary ? 24 : 16}
              name={getIconName(isTypePrimary, menuState)}
            />
          </span>
        </span>
      </Button>
    );
  };

  render() {
    const {
      type,
      ...otherProps
    } = this.props;

    const isTypePrimary = type === 'primary';

    return (
      <Menu
        {...otherProps}
        enableSpacingInDropdown={isTypePrimary}
        trigger={this.renderTrigger}
      />
    );
  }
}

ActionsMenu.propTypes = {
  type: PropTypes.oneOf(['primary', 'secondary']),
};

ActionsMenu.defaultProps = {
  type: 'secondary',
};

export default ActionsMenu;
