import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './styles.scss';

const GroupCardContentContainer = ({
  children,
  midSizeLeftPadding,
}) => (
  <div className={css.GroupCardContentContainer}>
    <div className={classNames(css.GroupCardContentBody, {
      [css['GroupCardContentBody---midSizeLeftPadding']]: midSizeLeftPadding,
    })}
    >
      { children }
    </div>
    <div className={css.GroupCardContainerContainer__gutter} />
  </div>
);

GroupCardContentContainer.propTypes = {
  children: PropTypes.node.isRequired,
  midSizeLeftPadding: PropTypes.bool,
};

GroupCardContentContainer.defaultProps = {
  midSizeLeftPadding: false,
};

export default GroupCardContentContainer;
