import React from 'react';
import classes from './HelpTextIcon.scss';
import classNames from 'classnames';

const Icon = ({ title, width, height, fieldValidation }) => {
    const getSvgContent = (title) => {
        let content;
        switch (title) {
            case 'Checkmark':
                content = (
                    <g className={classes.HelpTextIcon__fill}>
                        <path d="M17,0C7.6,0,0,7.6,0,17c0,9.4,7.6,17,17,17c9.4,0,17-7.6,17-17C34,7.6,26.4,0,17,0z M17,21.3l-3.4,3.4l-2.4-2.4l-3.7-3.6
                        l2.4-2.4l3.7,3.6L24.1,9.4l2.4,2.4L17,21.3z" />
                    </g>
                );
                break;
            case 'Triangle':
                content = (
                    <g className={classes.HelpTextIcon__fill}>
                        <path d="M33.3,29L18.5,2.6c-0.8-0.8-2.2-0.8-3,0L0.7,29c-0.8,0.8-0.8,2.2,0,3.1h32.6
                C34.1,31.2,34.1,29.8,33.3,29z M18.4,25.5c-0.3,0.3-0.7,0.5-1.3,0.5c-0.6,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.7-0.5-1.3
                c0-0.6,0.2-1,0.5-1.3c0.3-0.3,0.7-0.4,1.3-0.4c0.6,0,1,0.1,1.3,0.4c0.3,0.3,0.5,0.7,0.5,1.3C18.9,24.8,18.7,25.2,18.4,25.5z
                 M18.4,20.7h-2.5l-0.5-10h3.5L18.4,20.7z" />
                    </g>
                );
                break;
        }
        return content;
    };
    return (
        <svg
            className={classNames(classes.HelpTextIcon, {
                [classes['HelpTextIcon--error']]: fieldValidation === 'error',
                [classes['HelpTextIcon--success']]: fieldValidation === 'success',
            })}
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            aria-labelledby="title"
            viewBox="0 0 34 34">
            <title id="title">{title}</title>
            {getSvgContent(title)}
        </svg>
    );
};

export default Icon;
