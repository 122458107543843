import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class BoxHeading extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = { };
    }

    render() {
        const styles = this.context.boxStyles;
        return (
            <h3 className={styles.box__hdg}>
                {this.props.children}
            </h3>
        );
    }
}

BoxHeading.contextTypes = {
    boxStyles: PropTypes.object,
};
