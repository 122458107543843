import PropTypes from 'prop-types';

import React, { Component } from 'react';
import Root from './ButtonBar/Root';
import MultiButton from './ButtonBar/MultiButton.jsx';
import GetButtonsWrappedInItems from './ButtonBar/GetButtonsWrappedInItems';
import {
    HorizontalList
} from '../../../';

export default class ButtonBar extends Component {
    static propTypes = {
        // We are loosening this as the origial prop types checker was failing incorrectly
        children: PropTypes.node,
        inline: PropTypes.bool,
        type: PropTypes.oneOf(['primary', 'secondary', 'danger', 'link', 'text', 'gray10', 'primary-reverse', 'gray']),
    };

    static childContextTypes = {
        isInButtonBar: PropTypes.bool,
    };

    static contextTypes = {
        isInNotificationBandOmega: PropTypes.bool,
    };

    getChildContext() {
        return {
            isInButtonBar: true,
        };
    }

    shouldBeInline() {
        return this.props.inline || this.context.isInNotificationBandOmega;
    }

    render() {
        const childrenCount = React.Children.toArray(
          this.props.children
        ).length; // we'll use this for the cases below
        if (childrenCount === 0) {
            return <span />;
        } else if (childrenCount === 1) {
            return (
                <span>{this.props.children}</span>
            );
        } else if (this.props.menuDisabled) {
            return (
                <Root inline={this.shouldBeInline()}>
                    <HorizontalList
                        noWrap>
                        {GetButtonsWrappedInItems(this.props.children)}
                    </HorizontalList>
                </Root>
            )
        }
        return <MultiButton type={this.props?.type} inline={this.shouldBeInline()} buttons={this.props.children} />;
    }
}
