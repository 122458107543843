import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

export default class Group extends PureComponent {
    static propTypes = {
        'data-testid': PropTypes.string,
        id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.oneOf([false]),
        ]),
        validation: PropTypes.oneOf(['', 'error', 'success']),
    }

    static defaultProps = {
        'data-testid': undefined,
        id: false,
        validation: '',
    }

    static childContextTypes = {
        fieldId: PropTypes.string.isRequired,
        fieldValidation: PropTypes.oneOf(['', 'error', 'success']),
    }

    constructor(props, context) {
        super(props, context);
        this.state = { id: this.props.id || uuid() };
    }

    getChildContext() {
        return {
            fieldId: this.state.id,
            fieldValidation: this.props.validation,
        };
    }

    render() {
        const { children, 'data-testid': dataTestId } = this.props;
        return <div data-testid={dataTestId}>{children}</div>;
    }
}
