import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import Expander from '../legacy/Construction/Expander';
import {
  IconButton, BodyText, Icon, ProgressBar,
} from '..';
import css from './styles.scss';

export default class GroupCardAccordionFormWrapper extends PureComponent {
  constructor(props) {
    super(props);
    const { expand } = this.props;
    this.state = {
      expanded: expand,
    };
    this.onWholeRowClick = this.onWholeRowClick.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      expand,
    } = this.props;

    const {
      expanded,
    } = prevState;

    if (expand !== expanded) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ expanded: expand });
    }
  }

  toggle() {
    const { onCollapse, onExpand } = this.props;
    const { expanded } = this.state;
    this.setState(({ expanded: !expanded }),
      () => {
        if (!expanded) {
          onExpand();
        } else {
          onCollapse();
        }
      });
  }

  getExpander() {
    const {
      showExpandButton,
      expanderHideButtonStyling,
    } = this.props;
    const { expanded } = this.state;

    return (
      <div className={expanderHideButtonStyling
        ? css.GroupCardAccordion__expander : css.GroupCardAccordion__action}
      >
        {showExpandButton && (
        <IconButton
          data-testid="expandIconButton"
          name={expanded ? 'collapse' : 'expand'}
          onClick={this.toggle}
        />
        )}
      </div>
    );
  }

  getFormSubLabel() {
    const {
      type,
      required,
      subLabelIcon,
      subLabelIconColor,
      subLabelName,
      showSubLabel,
    } = this.props;
    const isInvalid = (type === 'error' && required) || showSubLabel;
    if (isInvalid) {
      return (
        <div style={{ display: 'flex', color: (subLabelIconColor) || 'red' }}>
          <Icon
            block
            size={14}
            name={(subLabelIcon || 'alertHollow')}
          />
          <BodyText styleLevel="xs">
            {(subLabelName || 'Required')}
          </BodyText>
        </div>
      );
    } if (!required && type !== 'error') {
      return (
        <div className={css['GroupCardAccordion__labelContent--optional']}>
          <BodyText styleLevel="xs">
            (Optional)
          </BodyText>
        </div>
      );
    }

    return '';
  }

  onWholeRowClick() {
    const { entireRowIsClickable } = this.props;

    if (!entireRowIsClickable) {
      return;
    }
    this.toggle();
  }

  render() {
    const {
      children,
      type,
      border,
      label,
      hideExpander,
      icon,
      iconSize,
      iconColor,
      progress,
      progressColor,
      progressStyle,
      noImpactOnCompletion,
      customIcon,
      showExpandButton,
    } = this.props;

    const { expanded } = this.state;

    return (
      <div>
        <div className={
            classNames(css.GroupCardAccordion__container, {
              [css['GroupCardAccordion__container--borderTop']]: border === 'top' || border === 'both',
              [css['GroupCardAccordion__container--borderBottom']]: border === 'bottom' || border === 'both',
              [css['GroupCardAccordion--default']]: type === 'default',
            })
  }
        >
          <div className={css.GroupCardAccordion__content} onClick={this.onWholeRowClick} onKeyDown={this.onWholeRowClick} data-testid="groupCardAccordion" role="presentation">
            <div className={css.GroupCardAccordion__container}>
              <div className={css.GroupCardAccordion__label}>
                <div className={css.GroupCardAccordion__labelContent}>
                  {icon && (
                  <span
                    className={(!noImpactOnCompletion && progress && progress.value === progress.max)
                      ? css.IconComplete : css.Icon}
                    style={{ marginRight: 8, ...iconColor ? { color: iconColor } : {} }}
                  >
                    <Icon
                      block
                      size={iconSize || 22}
                      name={(!noImpactOnCompletion && progress && progress.value === progress.max) ? 'completeSolid' : `${icon}`}
                    />
                  </span>
                  )}
                  <div
                    className={(!noImpactOnCompletion && progress && progress.value === progress.max) ? css['GroupCardAccordion__labelContent--header--completed'] : css['GroupCardAccordion__labelContent--header']}
                  >
                    {label}
                  </div>
                  {this.getFormSubLabel()}
                </div>
              </div>
              {customIcon
              && (
              <span className={!showExpandButton ? css.CustomIcon : ''}>
                <Icon
                  block
                  name={customIcon}
                  size={22}
                />
              </span>
              )}
            </div>
          </div>
          {!hideExpander && this.getExpander()}
        </div>
        {(progress && Number.isInteger(progress.max) && Number.isInteger(progress.value))
            && (
              <ProgressBar
                fillColor={type === 'error' ? 'red' : progressColor}
                style={expanded ? { borderRadius: 0, ...progressStyle } : progressStyle}
                currentProgress={type === 'error' ? progress.max : progress.value}
                maxProgress={progress.max}
              />
            )}
        <TransitionGroup component="div">
          {expanded && (
          <Expander>
            <div className={classNames({
              [css['GroupCardAccordion--info']]: type === 'info',
              [css['GroupCardAccordion--warning']]: type === 'warning',
              [css['GroupCardAccordion--critical']]: type === 'critical',
              [css['GroupCardAccordion--default']]: type === 'default',
            })}
            >
              {children}
            </div>
          </Expander>
          )}
        </TransitionGroup>
      </div>
    );
  }
}

GroupCardAccordionFormWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['default', 'error']),
  label: PropTypes.string.isRequired,
  expand: PropTypes.bool,
  onCollapse: PropTypes.func,
  onExpand: PropTypes.func,
  border: PropTypes.oneOf(['top', 'bottom', 'both', 'none']),
  hideExpander: PropTypes.bool,
  showExpandButton: PropTypes.bool,
  expanderHideButtonStyling: PropTypes.bool,
  entireRowIsClickable: PropTypes.bool,
  icon: PropTypes.string,
  iconSize: PropTypes.number,
  iconColor: PropTypes.string,
  noImpactOnCompletion: PropTypes.bool,
  progress: PropTypes.object,
  progressColor: PropTypes.string,
  progressStyle: PropTypes.object,
  required: PropTypes.bool,
  customIcon: PropTypes.string,
  subLabelIcon: PropTypes.string,
  subLabelIconColor: PropTypes.string,
  subLabelName: PropTypes.string,
  showSubLabel: PropTypes.bool,
};

GroupCardAccordionFormWrapper.defaultProps = {
  type: 'default',
  expand: false,
  border: 'none',
  onCollapse: () => {},
  onExpand: () => {},
  hideExpander: false,
  showExpandButton: true,
  expanderHideButtonStyling: false,
  entireRowIsClickable: false,
  icon: '',
  iconSize: 18,
  iconColor: null,
  noImpactOnCompletion: false,
  progress: {},
  progressColor: 'green',
  progressStyle: {},
  required: false,
  customIcon: '',
  subLabelIcon: '',
  subLabelIconColor: '',
  subLabelName: '',
  showSubLabel: false,
};
