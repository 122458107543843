import React, { Component } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import Button from '../legacy/Construction/Button';
import Modal from '../v2/Modal';
import Radio from '../v2/Radio';
import Select from '../v2/Select';
import TextInput from '../v2/TextInput';
import css from './FilterSave.scss';

class FilterSave extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      textInputValue: '',
      selectInputValue: props.currentSavedFilter
        ? {
          label: props.currentSavedFilter,
          value: props.currentSavedFilter,
        }
        : {},
      radioInputValue: 'create',
      isDuplicate: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleOnCloseClick = this.handleOnCloseClick.bind(this);
    this.handleTextInputChange = this.handleTextInputChange.bind(this);
    this.handleRadioInputChange = this.handleRadioInputChange.bind(this);
    this.guardHandleDoneClick = this.guardHandleDoneClick.bind(this);
    this.handleDoneClick = this.handleDoneClick.bind(this);
    this.handleSelectInputChange = this.handleSelectInputChange.bind(this);
  }

  handleClick() {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  }

  isFilterNameDuplicate = (value) => {
    const { savedFilters } = this.props;
    return Boolean(savedFilters.find((f) => f.name === value));
  }

  handleTextInputChange(e) {
    const { value } = e.target;
    const isDuplicate = this.isFilterNameDuplicate(value);
    this.setState({
      textInputValue: value,
      isDuplicate,
    });
  }

  handleSelectInputChange(value) {
    this.setState({
      selectInputValue: value,
    });
  }

  handleRadioInputChange(radioInputValue) {
    this.setState({
      radioInputValue,
    }, () => {
      if (radioInputValue === 'update') {
        this.setState({
          textInputValue: '',
        });
      }
    });
  }

  handleOnCloseClick() {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  }

  guardHandleDoneClick(event) {
    if (event.key === 'Enter') {
      this.handleDoneClick();
    }
  }

  handleDoneClick() {
    const { onFilterSaveDone } = this.props;
    const {
      textInputValue,
      selectInputValue,
      radioInputValue,
    } = this.state;

    if (this.isFilterNameDuplicate(textInputValue)) {
      return;
    }

    const data = {
      name: radioInputValue === 'update'
        ? selectInputValue.value
        : textInputValue,
      action: radioInputValue,
    };

    onFilterSaveDone(data);

    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  }

  filterNameValidation = (e) => {
    const { value } = e.target;
    if (this.isFilterNameDuplicate(value)) {
      return {
        message: `Filter '${value}' has already been created. Please change the name or update the existing saved filter.`,
        type: 'error',
      };
    }
    return true;
  }

  render() {
    const {
      hasFilterChanged,
      savedFilters,
    } = this.props;

    const mappedSavedFilters = savedFilters.map((f) => ({
      value: f.name,
      label: f.name,
    }));

    const {
      isOpen,
      textInputValue,
      selectInputValue,
      radioInputValue,
      isDuplicate,
    } = this.state;

    return (
      <>
        <Button
          label="Save"
          type="text"
          applyFixedHeight
          onClick={this.handleClick}
        />
        <Modal
          isOpen={isOpen}
          header="Save Filters"
          onClose={this.handleOnCloseClick}
          footerActions={[
            {
              label: 'Save',
              type: 'primary',
              onClick: this.handleDoneClick,
              disabled: radioInputValue === 'create'
                ? !textInputValue || isDuplicate
                : isEmpty(selectInputValue),
            },
          ]}
        >
          {
            hasFilterChanged
              && (
                <div
                  className={css.FilterSave__modalRadio}
                >
                  <Radio
                    legendHidden
                    legend="Create or update saved filter"
                    viewType="horizontal"
                    options={[
                      {
                        label: 'Create new saved filter',
                        value: 'create',
                      },
                      {
                        label: 'Update existing saved filter',
                        value: 'update',
                      },
                    ]}
                    value={radioInputValue}
                    onChange={this.handleRadioInputChange}
                  />
                </div>
              )
          }

          {
            radioInputValue === 'update'
              ? (
                <Select
                  id="Saved Filters"
                  label="Saved Filters"
                  value={selectInputValue}
                  onChange={this.handleSelectInputChange}
                  options={mappedSavedFilters}
                />
              )
              : (
                <TextInput
                  id="Saved filter name"
                  label="Saved filter name"
                  value={textInputValue}
                  onValid={this.filterNameValidation}
                  onChange={this.handleTextInputChange}
                  onKeyUp={this.guardHandleDoneClick}
                  helpText="Short description only visible to you"
                />
              )
          }
        </Modal>
      </>
    );
  }
}

FilterSave.propTypes = {
  hasFilterChanged: PropTypes.bool,
  onFilterSaveDone: PropTypes.func,
  savedFilters: PropTypes.arrayOf(PropTypes.shape({
    action: PropTypes.string,
    name: PropTypes.string,
  })),
  currentSavedFilter: PropTypes.string,
};

FilterSave.defaultProps = {
  hasFilterChanged: false,
  onFilterSaveDone: noop,
  savedFilters: [],
  currentSavedFilter: '',
};

export default FilterSave;
