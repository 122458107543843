import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import difference from 'lodash/difference';
import Drawer from '../Drawer';
import FilterDrawerContent from './FilterDrawerContent';

class FilterDrawer extends PureComponent {
  constructor(props) {
    super(props);
    const { filters } = props;
    this.state = {
      checkboxValue: [],
      filters,
    };

    this.setCheckBoxValue = this.setCheckBoxValue.bind(this);
    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.onApplyFilters = this.onApplyFilters.bind(this);
    this.closeUnappliedFilterCheckboxes = this.closeUnappliedFilterCheckboxes.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { isOpen: wasOpen } = prevProps;
    const { isOpen } = this.props;
    if (wasOpen !== isOpen) {
      this.closeUnappliedFilterCheckboxes();
    }
  }

  setCheckBoxValue(value) {
    const { checkboxValue } = this.state;
    const unCheckedKey = difference(checkboxValue, value)[0]; // unchecked checkbox to reset filter
    this.setState((state) => {
      const { filters } = state;
      if (unCheckedKey) {
        delete filters[unCheckedKey];
      }
      return ({
        checkboxValue: value,
        filters,
      });
    });
  }

  closeUnappliedFilterCheckboxes() {
    const { filters } = this.props;
    const checkBoxesToKeepOpen = Object.keys(filters);
    this.setState({ filters, checkboxValue: checkBoxesToKeepOpen });
  }

  onChangeFilter(key, value) {
    this.setState((state) => (
      {
        filters: { ...state.filters, ...{ [key]: value } },
      }
    ));
  }

  onApplyFilters() {
    const { filters } = this.state;
    const { applyFilters } = this.props;
    applyFilters(filters);
  }

  render() {
    const {
      isOpen, onClose, filterOptions, filters: filtersFromProps,
    } = this.props;
    const { checkboxValue, filters } = this.state;
    const {
      onChangeFilter,
      setCheckBoxValue,
      onApplyFilters,
    } = this;
    return (
      <Drawer
        isOpen={isOpen}
        parentId="js-page__body"
        onClose={onClose}
        title="Filters"
        footerActions={[
          {
            label: 'Cancel',
            type: 'secondary',
            onClick: onClose,
          },
          {
            label: 'Apply',
            type: 'primary',
            disabled: filters === filtersFromProps,
            onClick: onApplyFilters,
          },
        ]}
      >
        <FilterDrawerContent
          filters={filters}
          filterOptions={filterOptions}
          onApplyFilters={onApplyFilters}
          checkboxValue={checkboxValue}
          onChangeFilter={onChangeFilter}
          setCheckBoxValue={setCheckBoxValue}
        />
      </Drawer>
    );
  }
}

FilterDrawer.propTypes = {
  applyFilters: PropTypes.func,
  filters: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  filterOptions: PropTypes.arrayOf(PropTypes.shape({
    groupLegend: PropTypes.string,
    groupLegendHidden: PropTypes.bool,
    filters: PropTypes.object,
  })).isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

FilterDrawer.defaultProps = {
  applyFilters: undefined,
  filters: {},
  isOpen: false,
  onClose: undefined,
};

export default FilterDrawer;
