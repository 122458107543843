import round from 'lodash/round';
import isFinite from 'lodash/isFinite';

/**
 * Return a percent string for a numeric value
 * @param value {number}    numeric value
 * @param precision {number} decimal values to show
 * @returns     {string}    locale string
 */
export default function percentFormatter(value, precision = 2) {
    if (typeof value === 'string' && value.slice(-1) === '%') {
        return value;
    }
    const parsedValue = parseFloat(value);

    if (!isFinite(parsedValue)) {
        return value;
    }

    return `${round(value * 100, precision)}%`;
}
