import {
  add,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  formatISO,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  sub,
} from 'date-fns';

const buildDateFns = (date, defaultDate = null) => {
  if (!date) {
    return defaultDate;
  }

  const fnsDate = [
    date?.dateFns === 'startOfDay' && startOfDay(new Date()),
    date?.dateFns === 'startOfWeek' && startOfWeek(new Date()),
    date?.dateFns === 'startOfMonth' && startOfMonth(new Date()),
    date?.dateFns === 'startOfYear' && startOfYear(new Date()),
    date?.dateFns === 'endOfDay' && endOfDay(new Date()),
    date?.dateFns === 'endOfWeek' && endOfWeek(new Date()),
    date?.dateFns === 'endOfMonth' && endOfMonth(new Date()),
    date?.dateFns === 'endOfYear' && endOfYear(new Date()),
    new Date(),
  ].find((i) => i);

  if (date?.add) {
    return formatISO(add(fnsDate, date?.add));
  }
  if (date?.sub) {
    return formatISO(sub(fnsDate, date?.sub));
  }
  return formatISO(fnsDate);
};

export default buildDateFns;
