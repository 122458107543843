import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment-timezone';
import ReactDatePicker from 'react-datepicker';
import classNames from 'classnames';
import classes from './DatePicker.scss';
import Addon from '../Addon/Addon.jsx';
import TextInputWrapper from '../TextInputWrapper/TextInputWrapper.jsx';

export default class DatePicker extends Component {
    constructor(props, context) {
        super(props, context);
        // need to hide clickable icon (and show non-clickable one) when icon is clicked
        // then re-show after blur or value change
        this.state = {
            shouldEnableIconClick: true,
            timeValue: props.value ? moment(props.value) : null,
            dateFormat: props.time ? 'YYYY-MM-DD h:mm a' : 'YYYY-MM-DD',
        };
        this.handleIconClick = this.handleIconClick.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
    }

    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (
            (nextProps.value || nextProps.value === '')
            && (
                !this.state.timeValue
                || this.state.timeValue.format(this.state.dateFormat) !== nextProps.value
            )) {
            this.setState({ timeValue: nextProps.value === '' ? null : moment(nextProps.value) });
        }
        const VALIDATION_KEY = 'fieldValidation';
        if (nextContext[VALIDATION_KEY] !== this.state[VALIDATION_KEY]) {
            this.setState({
                fieldValidation: nextContext[VALIDATION_KEY],
            });
        }
    }

    render() {
        const { timeValue } = this.state;
        const { customClasses, time, timeIntervals } = this.props;
        const formattedTimeValue = timeValue?.isValid?.() ? timeValue?._d : null;

        return (
            <div
                className={classes.DatePicker}
                ref={(inputGroup) => { this.inputGroup = inputGroup; }}
            >
                <TextInputWrapper>
                    <ReactDatePicker
                        calendarClassName={classNames([classes.DatePicker__downDown, customClasses])}
                        className={classNames([classes.DatePicker__input, classes['DatePicker__input--hasAppend']], {
                            [classes['DatePicker__input--error']]: this.state.fieldValidation === 'error',
                        })}
                        disabled={this.props.disabled}
                        id={this.props.id}
                        maxDate={this.props.maxDate}
                        minDate={this.props.minDate}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        name={this.props.name}
                        onBlur={this.handleOnBlur}
                        onChange={this.handleDateChange}
                        onClickOutside={this.handleOutsideClick}
                        onFocus={() => this.setState({ shouldEnableIconClick: false })}
                        placeholderText={time ? 'MM/DD/YYYY h:mm a' : 'MM/DD/YYYY'}
                        ref={(input) => { this.textInput = input; }}
                        selected={formattedTimeValue}
                        style={this.props.style}
                        showTimeSelect={time}
                        timeIntervals={timeIntervals}
                        dateFormat={time ? 'MM/dd/yyyy h:mm a' : 'MM/dd/yyyy'}
                    />
                    {
                        this.state.shouldEnableIconClick ?
                            <Addon
                                adjacentToDisabled={this.props.disabled}
                                icon="event"
                                orientation="right"
                                onClick={this.handleIconClick}
                                disabled={this.props.disabled} />
                            :
                            <Addon
                                active
                                icon="event"
                                orientation="right"
                                onClick={() => { }}
                                disabled={this.props.disabled} />

                    }
                </TextInputWrapper>
            </div>

        );
    }

    showIconWithDelay() {
        // need a slight delay so clickable icon doesn't show
        // immediately after blur thus causing calendar to re-show
        let self = this;
        this.timeout = window.setTimeout(() => {
            self.setState({ shouldEnableIconClick: true });
        }, 300);
    }

    handleIconClick(e) {
        let $datePicker = this.textInput;
        // put focus in input to pop open the calendar
        $datePicker.handleFocus();
        // hide the clickable icon
        this.setState({ shouldEnableIconClick: false });
    }

    handleOutsideClick() {
        this.showIconWithDelay();
    }

    handleDateChange(momentObject) {
        const { dateFormat } = this.state;
        this.showIconWithDelay();
        this.inputGroup.focus(); // need to put focus on parent div so blur isn't triggered when icon is clicked again
        if (this.props.onChange) {
            // null, for empty input value
            let timeValue = null;

            // set value to string formatted date for valid dates
            if (momentObject) {
                timeValue = moment(momentObject);
                this.setState({ timeValue });
            }
            this.props.onChange(timeValue ? timeValue.format(dateFormat) : '');
        }
    }

    handleOnBlur() {
        const { dateFormat } = this.state;
        this.showIconWithDelay();
        this.inputGroup.focus();
        if (this.props.onBlur && this.state.timeValue) {
            const timeValue = this.state.timeValue.format(dateFormat);
            this.props.onBlur(timeValue);
        }
    }
}

const noOp = () => { };

DatePicker.propTypes = {
    disabled: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.string,
    customClasses: PropTypes.string,
    time: PropTypes.bool,
    timeIntervals: PropTypes.number,
};

DatePicker.defaultProps = {
    disabled: false,
    name: '',
    onBlur: noOp,
    onChange: noOp,
    value: '',
    customClasses: '',
    time: false,
    timeIntervals: 15,
};

DatePicker.contextTypes = {
    fieldValidation: PropTypes.oneOf(['', 'error', 'success']),
};
