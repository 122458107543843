/* eslint-disable no-console */
import { useRecoilState, useRecoilCallback, RecoilValue } from 'recoil';
import { save, Preferences } from './preferences.factory';
import prefsState from './prefs.state';
import tabPropsQuery from './tab-props.query';
import propsState from './props.state';
import requestQuery from './request.query';
import tabPrefsQuery from './tab-prefs.query';

const useCheckboxes = () => {
  const [, setPrefs] = useRecoilState(prefsState);
  const rcb = useRecoilCallback(({ snapshot }) => (query: RecoilValue<any>) => {
    if (query && snapshot) {
      const { contents } = snapshot.getLoadable(query);
      return contents;
    }
    return null;
  });

  const addIdToChecked = (id: string | number) => {
    const prefs = rcb(prefsState);
    const tabPrefs = rcb(tabPrefsQuery);
    const props = rcb(propsState);
    const tabProps = rcb(tabPropsQuery);
    const existing = new Set<number | string>(tabPrefs?.selectedItems || []);

    if (!tabProps || !tabPrefs || !prefs || !props) {
      return;
    }

    existing.add(id);
    const updatedPrefs: Preferences = {
      ...prefs,
      tabs: (prefs.tabs || []).map((tab) => {
        if (tab.id === tabPrefs.id) {
          return {
            ...tab,
            selectedItems: Array.from(existing) as string[] | number[],
          };
        }

        return tab;
      }),
    };
    setPrefs(updatedPrefs);
    save(props, updatedPrefs).catch((err) => console.error(err));
  };

  const removeIdFromChecked = (id: string | number) => {
    const prefs = rcb(prefsState);
    const tabPrefs = rcb(tabPrefsQuery);
    const props = rcb(propsState);
    const tabProps = rcb(tabPropsQuery);
    const existing = new Set<number | string>(tabPrefs?.selectedItems || []);

    if (!tabProps || !tabPrefs || !prefs || !props) {
      return;
    }

    existing.delete(id);

    const updatedPrefs: Preferences = {
      ...prefs,
      tabs: (prefs.tabs || []).map((tab) => {
        if (tab.id === tabPrefs.id) {
          return {
            ...tab,
            selectedItems: Array.from(existing) as string[] | number[],
          };
        }

        return tab;
      }),
    };

    setPrefs(updatedPrefs);
    save(props, updatedPrefs).catch((err) => console.error(err));
  };

  const checkAllIds = () => {
    const prefs = rcb(prefsState);
    const tabPrefs = rcb(tabPrefsQuery);
    const apiResponse = rcb(requestQuery);
    const props = rcb(propsState);

    if (!props || !tabPrefs || !prefs || !apiResponse) {
      return;
    }

    const allIds = new Set<number | string>(
      apiResponse.results.map((row) => (row?.id || '') as number | string),
    );

    const updatedPrefs: Preferences = {
      ...prefs,
      tabs: (prefs.tabs || []).map((tab) => {
        if (tab.id === tabPrefs.id) {
          return {
            ...tab,
            selectedItems: Array.from(allIds) as string[] | number[],
          };
        }

        return tab;
      }),
    };

    setPrefs(updatedPrefs);
    save(props, updatedPrefs).catch((err) => console.error(err));
  };

  const uncheckAllIds = () => {
    const prefs = rcb(prefsState);
    const tabPrefs = rcb(tabPrefsQuery);
    const apiResponse = rcb(requestQuery);
    const props = rcb(propsState);

    if (!props || !tabPrefs || !prefs || !apiResponse) {
      return;
    }

    const updatedPrefs: Preferences = {
      ...prefs,
      tabs: (prefs.tabs || []).map((tab) => {
        if (tab.id === tabPrefs.id) {
          return {
            ...tab,
            selectedItems: [],
          };
        }

        return tab;
      }),
    };

    setPrefs(updatedPrefs);
    save(props, updatedPrefs).catch((err) => console.error(err));
  };

  return {
    addIdToChecked,
    removeIdFromChecked,
    checkAllIds,
    uncheckAllIds,
  };
};

export default useCheckboxes;
