import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import css from './FileProcessing.scss';
import IconButton from '../IconButton';
import Button from '../legacy/Construction/Button';
import Icon from '../Icon';
import LoaderIndicator from '../LoaderIndicator';
import v2 from '../v2';
import wrapEventHandler, { ARG_ORDER } from '../utils/wrapEventHandler';

const {
  Modal,
} = v2;

const Row = ({
  id,
  name,
  status,
  message,
  type,
  onLoaderHover,
  loaderIsHovered,
  onCancel,
  onRetry,
  onDownload,
}) => (
  <div
    className={classnames(css.FileProcessing__row, {
      [css['FileProcessing__row--fullPadding']]: status === 'processing' && !loaderIsHovered,
      [css['FileProcessing__row--fullVerticalPadding']]: (status !== 'processing' && message)
                    || (message && loaderIsHovered && status === 'processing'),
    })}
  >
    <div className={css['FileProcessing__row--first']}>
      <div>{name}</div>
      {message && <div className={css[`FileProcessing__row--message--${status}`]}>{message}</div>}
    </div>
    <div
      className={classnames(css['FileProcessing__row--last'], {
        [css['FileProcessing__row--error']]: status === 'error',
      })}
      onMouseEnter={wrapEventHandler(onLoaderHover, [id], ARG_ORDER.EVENT_LAST)}
      onMouseLeave={wrapEventHandler(onLoaderHover, [false], ARG_ORDER.EVENT_LAST)}
      data-testid="FileProcessing__row--button"
    >
      {status === 'processing' && (loaderIsHovered ? (
        <IconButton
          name="close"
          data-testid="FileProcessing__row--cancelButton"
          onClick={wrapEventHandler(onCancel, [id], ARG_ORDER.EVENT_LAST)}
        />
      ) : (
        <LoaderIndicator
          isLoading
          size="sm"
          onClick={wrapEventHandler(onCancel, [id], ARG_ORDER.EVENT_LAST)}
        />
      ))}

      {status === 'success'
        && type === 'download' && (
          <IconButton
            name="download"
            onClick={wrapEventHandler(onDownload, [id], ARG_ORDER.EVENT_LAST)}
          />
      )}

      {status === 'success'
        && type === 'check' && (
          <div className={classnames({
            [css['FileProcessing__row--success']]: !loaderIsHovered,
          })}
          >
            <IconButton
              name={loaderIsHovered ? 'close' : 'check'}
              onClick={wrapEventHandler(onCancel, [id], ARG_ORDER.EVENT_LAST)}
            />
          </div>
      )}

      {status === 'success'
        && type === 'link' && (
          <Button
            label="View"
            type="link"
            onClick={wrapEventHandler(onDownload, [id], ARG_ORDER.EVENT_LAST)}
          />
      )}

      {status === 'error' && (
      <IconButton
        name={loaderIsHovered ? 'refresh' : 'alertSolid'}
        onClick={wrapEventHandler(onRetry, [id], ARG_ORDER.EVENT_LAST)}
      />
      )}
    </div>
  </div>
);

Row.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['success', 'error', 'processing']).isRequired,
  type: PropTypes.string,
  onLoaderHover: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRetry: PropTypes.func.isRequired,
  onDownload: PropTypes.func,
  message: PropTypes.string,
  loaderIsHovered: PropTypes.bool.isRequired,
};

Row.defaultProps = {
  type: 'download', // due to WFI team
  message: undefined,
  onDownload: undefined,
};

export default class FileProcessing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      cancelModalOpen: false,
      loaderHovered: null,
    };
  }

    toggleOpen = () => {
      const { collapsed } = this.state;
      this.setState({ collapsed: !collapsed });
    };

    toggleCancelModal = (open) => {
      const { cancelModalOpen } = this.state;
      const { onCustomClose } = this.props;
      // show custom close modal instead of common processing modal
      if (onCustomClose && typeof onCustomClose === 'function') {
        onCustomClose();
        return;
      }

      if (typeof open === 'undefined') {
        this.setState({ cancelModalOpen: !cancelModalOpen });
      } else {
        this.setState({ cancelModalOpen: open });
      }
    };

    onLoaderHover = (name) => {
      this.setState({ loaderHovered: name });
    };

    render() {
      const {
        collapsed,
        cancelModalOpen,
        loaderHovered,
      } = this.state;
      const {
        title,
        files,
        onClose,
        onRetry,
        onCancel,
        onDownload,
      } = this.props;

      if (files.length === 0) {
        return null;
      }

      const plural = files.length > 0 ? 's' : '';
      const alertTitle = title || `Processing ${files.length} file${plural}`;
      const hasProcessing = Boolean(files.find((f) => f.status === 'processing'));
      const hasError = Boolean(files.find((f) => f.status === 'error'));
      const hasSuccess = Boolean(files.find((f) => f.status === 'success'));
      return (
        <>
          <div className={css.FileProcessing}>
            <div
              className={classnames(css.FileProcessing__header, {
                [css['FileProcessing__header--hasError']]: hasError,
                [css['FileProcessing__header--hasSuccess']]: hasSuccess,
              })}
            >
              {hasError && (
              <div className={css.FileProcessing__errorIndicator}>
                <Icon name="alertSolid" />
              </div>
              )}
              <div className={css['FileProcessing__header--first']}>
                {alertTitle}
              </div>
              <div className={css['FileProcessing__header--last']}>
                <IconButton
                  name={collapsed ? 'collapse' : 'expand'}
                  color="light"
                  onClick={this.toggleOpen}
                  data-testid="FileProcessing__collapseButton"
                />
              </div>
              <div className={css['FileProcessing__header--last']}>
                <IconButton
                  name="close"
                  color="light"
                  onClick={hasProcessing
                    ? this.toggleCancelModal
                    : wrapEventHandler(onClose, [false], ARG_ORDER.EVENT_LAST)}
                  data-testid="FileProcessing__closeButton"
                />
              </div>
            </div>
            <div
              className={classnames(css.FileProcessing__body, {
                [css['FileProcessing__body--collapsed']]: collapsed,
              })}
            >
              {files.map((file) => {
                const id = file?.id || file?.name; // short circuit - WFI team using name as unique
                return (
                  <Row
                    {...file}
                    {...{ onRetry, onCancel, onDownload }}
                    onLoaderHover={this.onLoaderHover}
                    loaderIsHovered={loaderHovered === id}
                    key={id}
                    id={id}
                  />
                );
              })}
            </div>
          </div>
          <Modal
            header="Cancel processing?"
            isOpen={cancelModalOpen}
            footerActions={[
              {
                label: 'Cancel processing',
                type: 'secondary',
                onClick: () => {
                  this.toggleCancelModal();
                  onClose();
                },
              },
              {
                label: 'Continue processing',
                type: 'primary',
                onClick: () => this.toggleCancelModal(false),
              },
            ]}
            onClose={wrapEventHandler(this.toggleCancelModal, [false], ARG_ORDER.EVENT_LAST)}
          >
            Some items are still processing. Did you still want to cancel?
          </Modal>
        </>
      );
    }
}

FileProcessing.propTypes = {
  title: PropTypes.string,
  files: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    progress: PropTypes.number,
    type: PropTypes.oneOf(['upload', 'download', 'check', undefined]),
  })).isRequired,
  onClose: PropTypes.func.isRequired,
  onRetry: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDownload: PropTypes.func,
  onCustomClose: PropTypes.func,
};

FileProcessing.defaultProps = {
  title: '',
  onDownload: () => {},
  onCustomClose: undefined,
};
