import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles/Tile.scss';

const Tile = ({ children }) => (
  <div className={styles.Tile__bd} aria-label="tile">
    {children}
  </div>
);

Tile.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default Tile;
