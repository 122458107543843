import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classes from './styles/PinnedMenu.scss';
import classNames from 'classnames';

export default class PinnedMenuItem extends React.PureComponent {
    static propTypes = {
        slug: PropTypes.string.isRequired,
        isSelected: PropTypes.bool,
        onClick: PropTypes.func,
    };

    static defaultProps = {
        isSelected: false,
    }

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
        event.preventDefault();

        const { onClick = false } = this.props;

        if (onClick) {
            onClick(event.currentTarget.dataset.slug);
        }
    }

    render() {
        return (<div
            className={classNames([classes.PinnedMenu__item], {
                [classes['PinnedMenu__item--active']]: this.props.isSelected,
            })}
            onClick={this.handleClick}
            data-slug={this.props.slug}>
            {this.props.children}
            {this.props.isSelected && <span className={classNames(['icon-checkmark', [classes.PinnedMenu__selectedIcon]])} />}
        </div>);
    }
}
