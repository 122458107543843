import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import {
  FieldGroup as Group,
  FieldLabel as Label,
  FieldSelect as SelectV1,
  Link,
  Median,
  MedianAlpha,
  MedianOmega,
} from '../..';
import HelpText from '../../legacy/Construction/Field/HelpText/HelpText';
import styles from './styles.scss';

export const DEFAULT_ERROR = {
  type: 'error',
  message: 'Must select a value',
};

const isValueEmpty = (value) => value === '' || value === null || (Array.isArray(value) && !value.length);

export const internalValidation = (event) => !isValueEmpty(event.target.value) || DEFAULT_ERROR;

class Select extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id || uuid(),
      validation: false,
      // react-select v1.2.1 has a bug where event.target.value is an empty string.
      // This is the workaround. For more info -
      // https://github.com/JedWatson/react-select/issues/186#issuecomment-106695850
    };
  }

  validation(event) {
    const initialValidation = internalValidation(event);
    const {
      onValid,
      errorMessage,
    } = this.props;

    if (initialValidation === true || (
      initialValidation && initialValidation.type === 'success'
    )) {
      if (onValid) {
        return onValid(event);
      }
    }

    if (initialValidation
      && initialValidation.type === 'error'
      && errorMessage.length
    ) {
      return { ...initialValidation, message: errorMessage };
    }

    return initialValidation;
  }

  onBlur = (event) => {
    event.persist();

    const {
      optional,
      onBlur,
    } = this.props;
    const { value } = this.props;

    const updatedEvent = {
      ...event,
      target: { ...event.target, value },
      currentTarget: { ...event.currentTarget, value },
    };

    // Exit case if optional and empty value
    if (isValueEmpty(updatedEvent.target.value)) {
      if (onBlur) {
        onBlur(updatedEvent);
      }

      if (optional) {
        return;
      }
    }

    const validation = this.validation(updatedEvent);

    this.setState({ validation: validation === true ? {} : validation }, () => {
      if ((validation === true || (validation && validation.type === 'success'))
        && onBlur
      ) {
        onBlur(updatedEvent);
      }
    });
  };

  render() {
    const {
      id,
      validation: { type: validationType = '', message: validationMessage } = {},
    } = this.state;
    const {
      additionalInfo,
      additionalInfoShowEvent,
      tertiaryAction,
      footerActions,
      customFooter,
      clearButton,
      'data-testid': dataTestId,
      disabled,
      helpText,
      label,
      labelHidden,
      onKeyDown,
      loadOptions,
      optional,
      options,
      value,
      multi,
      maxSelect,
      noResultsText,
      typeahead,
      autoload,
      searchable,
      ignoreCase,
      cache,
      delimiter,
      placeholder,
      hideOptionalLabel,
      closeOnSelect,
      removeSelected,
      optionComponent,
      onChange,
      showSearchIconOnFocus,
    } = this.props;
    function onClear() {
      onChange('');
    }
    return (
      <Group id={id} validation={validationType} data-testid={dataTestId}>
        <Label
          screenReaderOnly={labelHidden}
          optional={optional}
          hideOptionalLabel={hideOptionalLabel}
          help={additionalInfo}
          helpTextShowEvent={additionalInfoShowEvent}
        >
          {label}
        </Label>
        <SelectV1
          tertiaryAction={tertiaryAction}
          footerActions={footerActions}
          customFooter={customFooter}
          multi={multi}
          maxSelect={maxSelect}
          disabled={disabled}
          value={value}
          options={options}
          onChange={onChange}
          onBlur={this.onBlur}
          onInputKeyDown={onKeyDown}
          loadOptions={loadOptions}
          noResultsText={noResultsText}
          typeahead={typeahead}
          autoload={autoload}
          searchable={searchable}
          ignoreCase={ignoreCase}
          cache={cache}
          delimiter={delimiter}
          placeholder={placeholder}
          closeOnSelect={closeOnSelect}
          removeSelected={removeSelected}
          optionComponent={optionComponent}
          showSearchIconOnFocus={showSearchIconOnFocus}
        />
        <Median>
          <MedianAlpha>
            {validationMessage && <HelpText>{validationMessage}</HelpText>}
            {helpText && <HelpText fieldValidation="">{helpText}</HelpText>}
          </MedianAlpha>
          <MedianOmega>
            {clearButton && Boolean(value) && (
              <div className={styles.ClearButtonWrapper}>
                <Link onClick={onClear}>
                  Clear
                </Link>
              </div>
            )}
          </MedianOmega>
        </Median>
      </Group>
    );
  }
}

const optionsShape = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
});
const actionPropTypes = PropTypes.shape({
  className: PropTypes.string,
  href: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
  type: PropTypes.string,
});
Select.propTypes = {
  additionalInfo: PropTypes.string,
  additionalInfoShowEvent: PropTypes.string,
  tertiaryAction: PropTypes.oneOfType([PropTypes.arrayOf(actionPropTypes), actionPropTypes]),
  footerActions: PropTypes.arrayOf(actionPropTypes),
  customFooter: PropTypes.element,
  clearButton: PropTypes.bool,
  'data-testid': PropTypes.string,
  disabled: PropTypes.bool,
  helpText: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelHidden: PropTypes.bool,
  multi: PropTypes.bool,
  maxSelect: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  loadOptions: PropTypes.func,
  optional: PropTypes.bool,
  onValid: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    optionsShape,
    PropTypes.arrayOf(optionsShape),
  ]).isRequired,
  options: PropTypes.arrayOf(optionsShape),
  noResultsText: PropTypes.string,
  typeahead: PropTypes.bool,
  autoload: PropTypes.bool,
  searchable: PropTypes.bool,
  ignoreCase: PropTypes.bool,
  cache: optionsShape,
  delimiter: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  hideOptionalLabel: PropTypes.bool,
  closeOnSelect: PropTypes.bool,
  removeSelected: PropTypes.bool,
  optionComponent: PropTypes.func,
  showSearchIconOnFocus: PropTypes.bool,
};

Select.defaultProps = {
  additionalInfo: '',
  additionalInfoShowEvent: 'hover',
  tertiaryAction: undefined,
  footerActions: undefined,
  customFooter: undefined,
  clearButton: false,
  'data-testid': undefined,
  disabled: false,
  helpText: '',
  id: undefined,
  multi: false,
  maxSelect: 0,
  onBlur: undefined,
  onKeyDown: undefined,
  labelHidden: false,
  loadOptions: undefined,
  onValid: undefined,
  optional: false,
  options: undefined,
  noResultsText: 'No results found',
  typeahead: false,
  autoload: true,
  searchable: true,
  ignoreCase: true,
  cache: undefined,
  delimiter: ',',
  placeholder: 'Select ...',
  errorMessage: '',
  hideOptionalLabel: false,
  closeOnSelect: true,
  removeSelected: true,
  optionComponent: undefined,
  showSearchIconOnFocus: false,
};

export default Select;
