import React from 'react';

import BaseWeek from 'react-big-calendar/lib/Week';
import TimeGrid from './TimeGrid';

export default class Week extends BaseWeek {
  render() {
    const { date, ...props } = this.props;
    const range = BaseWeek.range(date, this.props);

    return <TimeGrid {...props} className="fn-calendar-week" range={range} eventOffset={15} />;
  }
}
