import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import css from './SavedFilters.scss';
import Button from '../legacy/Construction/Button';
import Icon from '../Icon';
import BodyText from '../BodyText';
import { filtersPropTypes } from './ToolbarPropTypes';
import {
  Median,
  MedianAlpha,
  MedianOmega,
} from '..';
import wrapEventHandler, { ARG_ORDER } from '../utils/wrapEventHandler';

class SavedFilters extends React.PureComponent {
  emptyMessage = () => (
    <div className={css.SavedFilters__empty}>
      Filters you add can be saved here. Quickly launch them anytime to speed up your workflow.
    </div>
  );

  savedFilters = () => {
    const { filters, activeSavedFilter } = this.props;

    return (
      <>
        <div className={css.SavedFilters__header}>
          <div className={css.SavedFilters__headerLabel}>
            <BodyText
              styleLevel="mdSemiBold"
            >
              Saved Filters
            </BodyText>
          </div>
          <div>
            <Button
              label="Clear"
              type="link"
              onClick={this.onClearFilters}
            />
          </div>
        </div>
        <div className={css.SavedFilters__section}>
          {
            filters.map((filter) => (
              <div
                key={filter.name}
                role="button"
                tabIndex={0}
                className={classnames(
                  css.SavedFilters__option,
                  {
                    [css.SavedFilters__optionSelected]: filter.name === activeSavedFilter,
                  },
                )}
                onClick={wrapEventHandler(this.onClickSavedFilter, [filter, 'click'], ARG_ORDER.EVENT_LAST, this)}
                onKeyUp={wrapEventHandler(this.onClickSavedFilter, [filter, 'key'], ARG_ORDER.EVENT_LAST, this)}
              >
                <Median>
                  <MedianAlpha>
                    <div className={css.SavedFilter__name}>
                      {filter.name}
                    </div>
                  </MedianAlpha>
                  <MedianOmega>
                    <div
                      role="button"
                      tabIndex={0}
                      className={css.SavedFilters__deleteIcon}
                      onClick={wrapEventHandler(this.onClickDeleteFilter, [filter, 'click'], ARG_ORDER.EVENT_LAST, this)}
                      onKeyUp={wrapEventHandler(this.onClickDeleteFilter, [filter, 'key'], ARG_ORDER.EVENT_LAST, this)}
                    >
                      <Icon
                        block
                        name="deleteHollow"
                      />
                    </div>
                  </MedianOmega>
                </Median>
              </div>
            ))
          }
        </div>
      </>
    );
  };

  onClearFilters = () => {
    const { onClearFilters } = this.props;

    onClearFilters();
  }

  onClickDeleteFilter(filter, key, event) {
    event.preventDefault();
    event.stopPropagation();

    const { onDeleteSavedFilter } = this.props;

    if (key === 'key' && event.key === 'Enter') {
      onDeleteSavedFilter(filter);
    } else if (key === 'click') {
      onDeleteSavedFilter(filter);
    }
  }

  onClickSavedFilter(filter, key, event) {
    const { onClickSavedFilter } = this.props;
    if (key === 'key' && event.key === 'Enter') {
      onClickSavedFilter(filter);
    } else if (key === 'click') {
      onClickSavedFilter(filter);
    }
  }

  render() {
    const { filters } = this.props;

    return (
      <div className={css.SavedFilters}>
        { filters.length
          ? this.savedFilters()
          : this.emptyMessage()}
      </div>
    );
  }
}

SavedFilters.propTypes = {
  filters: filtersPropTypes,
  activeSavedFilter: PropTypes.string,
  onClearFilters: PropTypes.func.isRequired,
  onDeleteSavedFilter: PropTypes.func.isRequired,
  onClickSavedFilter: PropTypes.func.isRequired,
};

SavedFilters.defaultProps = {
  filters: [],
  activeSavedFilter: '',
};

export default SavedFilters;
