import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import DrawerAnimationAndLayout from './DrawerAnimationAndLayout';

/**
 * Sets up the portal connection to the DrawerContainer that it
 * is paird with via the drawerId prop
 */
const PushDrawer = ({
  drawerId,
  isOpen,
  children,
}) => {
  const drawerWrapper = document.querySelector(`#${drawerId}`);
  return (
    drawerWrapper
      && createPortal(
        <DrawerAnimationAndLayout
          isOpen={isOpen}
        >
          {children}
        </DrawerAnimationAndLayout>,
        drawerWrapper,
      )
  );
};

PushDrawer.propTypes = {
  children: PropTypes.node.isRequired,
  drawerId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
};

PushDrawer.defaultProps = {
  isOpen: false,
};

export default PushDrawer;
