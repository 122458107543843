import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './styles.scss';

const colUnits = [
  '',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
];

export default class GridColumn extends Component {
  getGridCSSClasses() {
    const { props } = this;
    const baseColClasses = classNames(css.GridColumn, {
      [css['GridColumn--divided']]: props.divided,
      [css['GridColumn--vSpace']]: props.vSpace,
    });
    let composedClassnames = {};
    if (Object.keys(props.query || {}).length) {
      let gridCSSClasses = {};
      const { containerUnits } = props;

      Object.keys(props.query).forEach((key) => {
        if (props.query[key]) {
          const gridUnit = containerUnits[key];
          gridCSSClasses = classNames(css[`GridColumn--xs-${gridUnit}`]);
        }
      });
      composedClassnames = classNames(gridCSSClasses, baseColClasses);
    } else {
      const gridCSSClasses = classNames({
        [css[`GridColumn--xs-${props.xs}`]]: props.xs,
        [css[`GridColumn--sm-${props.sm}`]]: props.sm,
        [css[`GridColumn--md-${props.md}`]]: props.md,
        [css[`GridColumn--lg-${props.lg}`]]: props.lg,
        [css[`GridColumn--xl-${props.xl}`]]: props.xl,

        [css[`GridColumn--xs-push-${props['push-xs']}`]]: props['push-xs'],
        [css[`GridColumn--sm-push-${props['push-sm']}`]]: props['push-sm'],
        [css[`GridColumn--md-push-${props['push-md']}`]]: props['push-md'],
        [css[`GridColumn--lg-push-${props['push-lg']}`]]: props['push-lg'],
        [css[`GridColumn--xl-push-${props['push-xl']}`]]: props['push-xl'],

        [css[`GridColumn--xs-pull-${props['pull-xs']}`]]: props['pull-xs'],
        [css[`GridColumn--sm-pull-${props['pull-sm']}`]]: props['pull-sm'],
        [css[`GridColumn--md-pull-${props['pull-md']}`]]: props['pull-md'],
        [css[`GridColumn--lg-pull-${props['pull-lg']}`]]: props['pull-lg'],
        [css[`GridColumn--xl-pull-${props['pull-xl']}`]]: props['pull-xl'],
      });

      composedClassnames = classNames(gridCSSClasses, baseColClasses);
    }
    return composedClassnames;
  }

  render() {
    const { children, 'data-testid': dataNwId } = this.props;
    return (
      <div data-testid={dataNwId} className={this.getGridCSSClasses()}>{children}</div>
    );
  }
}

GridColumn.propTypes = {
  'data-testid': PropTypes.string,
  children: PropTypes.node,
  xs: PropTypes.oneOf(colUnits), // eslint-disable-line react/no-unused-prop-types
  sm: PropTypes.oneOf(colUnits), // eslint-disable-line react/no-unused-prop-types
  md: PropTypes.oneOf(colUnits), // eslint-disable-line react/no-unused-prop-types
  lg: PropTypes.oneOf(colUnits), // eslint-disable-line react/no-unused-prop-types
  xl: PropTypes.oneOf(colUnits), // eslint-disable-line react/no-unused-prop-types
  'push-xs': PropTypes.oneOf(colUnits), // eslint-disable-line react/no-unused-prop-types
  'push-sm': PropTypes.oneOf(colUnits), // eslint-disable-line react/no-unused-prop-types
  'push-md': PropTypes.oneOf(colUnits), // eslint-disable-line react/no-unused-prop-types
  'push-lg': PropTypes.oneOf(colUnits), // eslint-disable-line react/no-unused-prop-types
  'push-xl': PropTypes.oneOf(colUnits), // eslint-disable-line react/no-unused-prop-types
  'pull-xs': PropTypes.oneOf(colUnits), // eslint-disable-line react/no-unused-prop-types
  'pull-sm': PropTypes.oneOf(colUnits), // eslint-disable-line react/no-unused-prop-types
  'pull-md': PropTypes.oneOf(colUnits), // eslint-disable-line react/no-unused-prop-types
  'pull-lg': PropTypes.oneOf(colUnits), // eslint-disable-line react/no-unused-prop-types
  'pull-xl': PropTypes.oneOf(colUnits), // eslint-disable-line react/no-unused-prop-types
  divided: PropTypes.bool,
  vSpace: PropTypes.bool,
  query: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  containerUnits: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

GridColumn.defaultProps = {
  'data-testid': undefined,
  children: null,
  xs: '12',
  sm: '',
  md: '',
  lg: '',
  xl: '',
  'push-xs': '',
  'push-sm': '',
  'push-md': '',
  'push-lg': '',
  'push-xl': '',
  'pull-xs': '',
  'pull-sm': '',
  'pull-md': '',
  'pull-lg': '',
  'pull-xl': '',
  divided: false,
  vSpace: false,
  query: {},
  containerUnits: {},
};
