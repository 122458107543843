import React from 'react';
import PropTypes from 'prop-types';
import { onlyUpdateForKeys, compose } from 'recompose';
import classNames from 'classnames';
import componentClassnames from './Card/styles/Card.scss';

const Card = onlyUpdateForKeys(['color', 'children'])(({ color, children, size }) => {
    const cardClassNames = classNames(componentClassnames.Card, {
        [componentClassnames['Card--grey']]: color === 'grey',
        [componentClassnames['Card--grey20']]: color === 'grey20',
        [componentClassnames['Card--small']]: size === 'sm',
    });
    return (
        <div className={cardClassNames}>
            {children}
        </div>
    );
});

const CardComposed = compose()(Card);
CardComposed.propTypes = {
    color: PropTypes.oneOf(['white', 'grey', 'grey20']),
    size: PropTypes.oneOf(['sm', 'md']),
};
CardComposed.defaultProps = {
    color: 'white',
    size: 'md',
};

export default CardComposed;
