import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuill } from 'react-quilljs';
import './TextEdit/styles/TextEdit.global.scss';
import componentClasses from './TextEdit/styles/TextEdit.scss';
import classNames from 'classnames';
import handle from './Slider';

const toolbarClassnames = classNames(
    'ql-toolbar',
    'ql-snow',
    componentClasses.TextEdit__toolbar
);

const CustomToolbar = ({ markConfidential, toolbarId }) => (
    <div id={toolbarId} className={toolbarClassnames}>
        <button className="ql-bold" title="bold" />

        <button className="ql-italic" title="italic" />

        <button className="ql-underline" title="underline" />

        <button className="ql-list" value="ordered" title="ordered list" />

        <button className="ql-list" value="bullet" title="unordered list" />

        {markConfidential &&
          <button className="ql-delete" title="mark confidential">
            <svg viewBox="0 0 32 32">
              <path
                className="ql-fill"
                d="M16,11.2l4.1,4.3l0-0.2C20.1,13.1,18.3,11.2,16,11.2L16,11.2z M9.9,12.6l2.2,1.8c-0.1,0.3-0.1,0.6-0.1,0.9
                c0,2.3,1.8,4.1,4.1,4.1c0.3,0,0.6,0,0.9-0.1l2.1,2.1c-0.9,0.5-1.9,0.7-3,0.7c-3.8,0-6.8-3.1-6.8-6.8C9.2,14.2,9.5,13.2,9.9,12.6z
                M2.4,5.1l3.1,2.8l0.6,0.6c-2.3,1.8-4,4.1-5.1,6.8c2.4,6,8.2,10.2,15,10.2c2.1,0,4.1-0.4,6-1.1l0.6,0.6l4,4l1.7-1.7L4.1,3L2.4,5.1z
                M16,8.5c3.8,0,6.8,3.1,6.8,6.8c0,0.9-0.2,1.7-0.5,2.5l4,4c2.1-1.7,3.7-3.9,4.7-6.5c-2.4-6-8.2-10.2-15-10.2c-1.9,0-3.7,0.3-5.4,1
                L13.5,9C14.3,8.7,15.1,8.5,16,8.5z" />
              </svg>
          </button>
        }
    </div>
);

// paste handler example taken from https://github.com/quilljs/quill/issues/109
const PasteHandler = (node, delta) => {
    // regex from here
    // https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url
    const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;

    if (typeof (node.data) !== 'string') return;
    const matches = node.data.match(regex);

    if (matches && matches.length > 0) {
        const ops = [];
        let str = node.data;
        matches.forEach((match) => {
            const split = str.split(match);
            const beforeLink = split.shift();
            ops.push({ insert: beforeLink });
            ops.push({ insert: match, attributes: { link: match } });
            str = split.join(match);
        });
        ops.push({ insert: str });
        delta.ops = ops;
    }

    return delta;
};

const TextEdit = ({ toolbarId, markConfidential, onChange, onBlur, onFocus, value, height, disabled, placeholder }) => {
  const theme = 'snow';
  const modules = {
    toolbar: {
      container: `#${toolbarId}`,
    },
    clipboard: {
      matchers: [
        [
          Node.TEXT_NODE,
          PasteHandler,
        ],
      ],
    },
  };

  /*
   * Quill editor formats
   * See http://quilljs.com/docs/formats/
   */
  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'color',
    'clear', 'delete'
  ];

  const { quill, quillRef, Quill } = useQuill({ theme, formats, modules, placeholder, readOnly: disabled });

  // One time setup
  if(Quill && !quill) {
    // only display errors in console
    Quill.debug('error');
    // custom del formatter
    const Inline = Quill.import('blots/inline');
    class DelBlot extends Inline { }
    DelBlot.blotName = 'delete';
    DelBlot.tagName = 'del';
    Quill.register(DelBlot);
  }

  const handleChange = () => {
    if (quill
        && onChange
        && onChange !== TextEdit.defaultProps.onChange) {
      // send HTML content to callback
      onChange(quill.root.innerHTML);
    }
  };

  const handleOnBlur = () => {
    if (quill && onBlur) {
      onBlur();
    }
  };

  const handleOnFocus = () => {
    if (quill && onFocus) {
      onFocus();
    }
  };

  useEffect(() => {
    if (quill) {
      if (value) {
        quill.setContents(quill.clipboard.convert(value));
      }

      quill.on('selection-change', (range, oldRange, source) => {
        if (range) {
          if (range.length == 0) {
            handleOnFocus();
          }
        } else {
          handleOnBlur();
        }
      });

      quill.on('text-change', handleChange);
      return () => quill.off('text-change', handleChange);
    }
  }, [quill]);

  return (
    <div>
      <CustomToolbar markConfidential={markConfidential} toolbarId={toolbarId} />
      <div
        key="editor"
        id="editor"
        className="quill-contents"
        data-testid={toolbarId}
        style={{ height: height ? height : 'none' }}
      >
        <div
          className={componentClasses.TextEdit__editor}
          ref={quillRef}
        />
      </div>
    </div>
  );
};

TextEdit.propTypes = {
    markConfidential: PropTypes.bool,
    toolbarId: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
};

TextEdit.defaultProps = {
    markConfidential: false,
    toolbarId: 'js-toolbar',
    onChange: () => {},
    onBlur: () => {},
    onFocus: () => {},
    disabled: false,
    placeholder: '',
};

export const TextEditContent = (props) => {
  const textEditorClasses = classNames(componentClasses.TextEdit__output, 'ql-editor', {
      [componentClasses['TextEdit__output--authorMode']]: props.authorMode,
  });
  return (
      <div className={textEditorClasses} dangerouslySetInnerHTML={{ __html: props.children }} />
  );
};

export default TextEdit;
