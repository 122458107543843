export default function getPixelsPerRem(pixelsOnly) {
  // if no window object, just return default of 16
  if (!window) {
    return 16;
  }

  const htmlFontSize = parseFloat('62.5%') / 100;

  /* eslint-disable */

  // return if value has already been set on the window
  if (window.__pixelsPerRem) {
    return pixelsOnly
      ? window.__pixelsPerRem
      : window.__pixelsPerRem / htmlFontSize;
  }

  const parentElement = document.body;
  const $div = document.createElement('div');
  $div.style.fontSize = '1rem';
  parentElement.appendChild($div);
  const pixels = parseFloat(
    window.getComputedStyle($div, null).getPropertyValue('font-size'),
  );
  parentElement.removeChild($div);
  // set value on window so this doesn't need to be computed next time
  window.__pixelsPerRem = pixels;

  return pixelsOnly
    ? window.__pixelsPerRem
    : window.__pixelsPerRem / htmlFontSize;
}
