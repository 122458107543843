const defaultDateOptions = {
    year: 'numeric',
    month: 'short',
};

/**
 * Generate a date locale string
 * @param date      {string}  string to generate date from
 * @param options   {object}  toLocaleDateString config object
 * @returns         {string}  locale string
 */
export default function dateFormatter(date, options = defaultDateOptions) {
    return new Date(date.replace(/-/g, '/')).toLocaleDateString('en-US', options);
}

export const dayFormatter = date => dateFormatter(
    date,
    { year: 'numeric', month: 'short', day: 'numeric' }
);
