import React from 'react';
import PropTypes from 'prop-types';
import AbstractInput from '../AbstractInput';
import protectedProps from '../AbstractInput/protectedProps';
import css from './styles.scss';

export const getContainerClass = (length) => (length === 4 ? css.Otp4InputContainer : css.Otp6InputContainer);
export const getInputFormat = (length) => (length === 4 ? '9999' : '999999');

const OtpInput = (initialProps) => {
  const { codelength } = initialProps;
  const props = {
    ...initialProps,
    format: getInputFormat(codelength),
  };

  return (
    <div className={getContainerClass(codelength)}>
      <AbstractInput {...props} />
    </div>
  );
};

OtpInput.propTypes = {
  codelength: PropTypes.oneOf([4, 6]),
};

OtpInput.defaultProps = {
  codelength: 6,
};

export default protectedProps(OtpInput);
