import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// taken from http://jamesknelson.com/rendering-react-components-to-the-document-body/
export default class PortalRenderer extends Component {
    static defaultProps = {
        target: document.body,
    };

    constructor(props, context) {
        super(props, context);
        this.state = { };
        this.target = this.props.target;
    }

    componentDidMount() {
        this.popup = document.createElement('div');
        this.target.appendChild(this.popup);
        this._renderLayer();
    }

    componentDidUpdate() {
        this._renderLayer();
    }

    componentWillUnmount() {
        ReactDOM.unmountComponentAtNode(this.popup);
        this.target.removeChild(this.popup);
    }

    _renderLayer() {
        ReactDOM.render(this.props.children, this.popup);
    }

    render() {
        // Render a placeholder
        return null;
    }
}
