import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import classes from './Switch/styles/Switch.scss';

const Switch = forwardRef((props, ref) => {
    const {
        disabled,
        displayBlock,
        checked,
        label,
        onChange,
        redUnchecked,
        sideLabel,
        inlineFlex,
        checkedColor,
    } = props;

    if (props.labelOn || props.labelOff) {
        console.warn('<Switch> props labelOn and labelOff have been deprecated and will no longer be used. Please remove.');
    }

    const rootClassnames = classNames([classes.Switch], {
        [classes['Switch--disabled']]: disabled,
        [classes['Switch--block']]: displayBlock && !sideLabel,
        [classes['Switch--withSideLabel']]: sideLabel,
    });

    const SwitchJSX = (
        <label
            className={rootClassnames}
            data-testid={label}
            >
            <input
                disabled={disabled}
                className={classes.Switch__input}
                type="checkbox"
                checked={checked}
                onChange={event => onChange(event, event.target.checked)}
                data-testid={label}
                checkedColor={checkedColor}/>
            <span
                className={classNames([checkedColor === 'danger' ? classes.Switch__yesNo__danger : classes.Switch__yesNo], {
                    [classes.Switch__yesNo__redUnchecked]: redUnchecked,
                })}
            />
            <span className={classes.Switch__label}>{label}</span>
            <span
                className={classNames([classes.Switch__handle], {
                    [classes['Switch__handle--disabled']]: disabled,
                })}
            />
        </label>
    );

    const SwitchWithSideLabel = (
        <div className={classNames({
            [classes.Switch__withSideLabel]: true,
            [classes['Switch__withSideLabel--inlineFlex']]: inlineFlex
        })}
        ref={ref}>
            <div className={classes.Switch__withSideLabelSwitch}>
                {SwitchJSX}
            </div>
            <div>
                <span className={classes.Switch__withSideLabelText}>{sideLabel}</span>
            </div>
        </div>
    );

    return sideLabel ? SwitchWithSideLabel : SwitchJSX;
});

Switch.propTypes = {
    label: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    redUnchecked: PropTypes.bool,
    displayBlock: PropTypes.bool,
    sideLabel: PropTypes.string,
    inlineFlex: PropTypes.bool,
    checkedColor: PropTypes.string,
};

Switch.defaultProps = {
    label: 'Enable',
    checked: false,
    disabled: false,
    onChange: () => {},
    redUnchecked: false,
    displayBlock: false,
    sideLabel: '',
    inlineFlex: false,
    checkedColor: 'default',
};

export default Switch;
