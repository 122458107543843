import React from 'react';
import ActionsMenu from '../ActionsMenu';
import Button from '../legacy/Construction/Button';
import IconButton from '../IconButton';
import css from './styles.scss';

function getItemByProp(children, prop, comparator) {
  let returnItem = null;

  children.forEach((child) => {
    if (prop === child.props[comparator]) {
      returnItem = React.cloneElement(
        child,
      );
    }
  });

  return returnItem;
}

function getFinalActionsMenu(actionsMenus, isIconButton = false, closeOnSelection = false) {
  const actionsMenuProps = actionsMenus.map((menu) => {
    if (isIconButton) {
      const {
        props: {
          title: label,
          name: icon,
          onClick,
          disabled,
        },
      } = menu;

      return {
        label,
        icon,
        onClick,
        disabled,
      };
    }

    return menu.props;
  });

  if (!actionsMenuProps.length) {
    return null;
  }

  return (
    <div
      className={css.MassActionBarItem}
      key={`renderMeasured_MassActionBar${isIconButton ? 'IconButton' : 'Button'}`}
    >
      <ActionsMenu
        label="More"
        type="primary"
        disableAutoWidth
        menuItems={actionsMenuProps}
        alignToRight
        closeOnSelection={closeOnSelection}
      />
    </div>
  );
}

function getFinalButtonList(buttonProps, isIconButton = false) {
  const buttons = [];

  if (!buttonProps.length) {
    return [];
  }

  buttonProps.forEach((buttonProp) => {
    const { props } = buttonProp;

    const button = (
      <div
        className={css.MassActionBarItem}
        key={props[`${isIconButton ? 'title' : 'label'}`]}
      >
        {
          isIconButton
            ? <IconButton {...props} />
            : <Button {...props} />
        }
      </div>
    );

    buttons.push(button);
  });

  return buttons;
}

function getButtonsndActionMenuFromSplitProps(splitProps, isIconButton = false, closeOnSelection = false) {
  const buttons = getFinalButtonList(splitProps.buttonArray, isIconButton);
  const actionsMenu = getFinalActionsMenu(splitProps.actionMenuArray, isIconButton, closeOnSelection);

  const children = actionsMenu ? buttons.concat(actionsMenu) : buttons;

  return children;
}

export {
  getItemByProp,
  getButtonsndActionMenuFromSplitProps,
};
