import React from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import classNames from 'classnames';
import css from './DrawerTransition.scss';

const duration = parseInt(css.DrawerAnimationDuration, 10);

const DrawerTransition = ({
  children,
  isOpen,
  size,
}) => (
  <ReactCSSTransitionGroup
    component="div"
    className={css.DrawerTransition}
    transitionName={{
      enter: css['DrawerTransition__content--enter'],
      enterActive: css['DrawerTransition__content--enterActive'],
      leave: css['DrawerTransition__content--leave'],
      leaveActive: css['DrawerTransition__content--leaveActive'],
    }}
    transitionEnterTimeout={duration}
    transitionLeaveTimeout={duration}
  >
    {Boolean(isOpen)
        && (
          <div className={classNames({
            [css['DrawerTransition__content--sm']]: size === 'sm',
            [css['DrawerTransition__content--lg']]: size === 'lg',
          })}
          >
            {children}
          </div>
        )}
  </ReactCSSTransitionGroup>
);

DrawerTransition.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  size: PropTypes.oneOf(['lg', 'sm']),
};

DrawerTransition.defaultProps = {
  isOpen: false,
  size: 'sm',
};

export default DrawerTransition;
