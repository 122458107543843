import React from 'react';
import PropTypes from 'prop-types';
import css from './styles.scss';

const EmptyResultsContainer = ({ children }) => (
  <tr><td className={css.EmptyResultsContainer}>{children}</td></tr>
);

EmptyResultsContainer.propTypes = {
  children: PropTypes.node,
};

EmptyResultsContainer.defaultProps = {
  children: '',
};

export default EmptyResultsContainer;
