import * as React from 'react';
import { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { Loader } from '../index';
import { Props } from './DataGrid';
import { load } from './preferences.factory';
import View from './View';
import prefsState from './prefs.state';
import propsState from './props.state';
import { dataVersionState } from './request.query';

const Preferences: React.FC<Props> = (props: Props) => {
  const [, setPreferences] = useRecoilState(prefsState);
  const [, setProps] = useRecoilState(propsState);
  const [, setDataVersion] = useRecoilState(dataVersionState);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const { dataVersion } = props;
    setDataVersion(dataVersion || 1);
  }, [props.dataVersion]);

  useEffect(() => {
    load(props)
      .then((prefs) => {
        setProps(props);
        setPreferences(prefs);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
      });
  }, []);

  if (error) {
    return <span className="text-danger">{error}</span>;
  }

  if (loading) {
    return <Loader fixed isLoading />;
  }

  return <View />;
};

export default Preferences;
