import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

export default class Slide extends PureComponent {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    slideStyle: PropTypes.object,
    slide: PropTypes.object,
  }

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    slideStyle: {
      marginLeft: 8,
      marginRight: 8,
      backgroundColor: 'white',
      borderRadius: 4,
      borderTop: 'solid',
      borderTopColor: '#268ED9',
      borderTopWidth: 4,
      display: 'flex',
      width: 350,
      height: 180,
      boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)',
    },
    slide: [],
  }

  renderIcon = ({ slide }) => slide.icon && (
    <span style={{ color: '#4051bf' }}>
      <Icon
        block
        size={slide.iconSize || 32}
        name={`${slide.icon}`}
      />
    </span>
  )

  renderSlide = ({ slide }) => (
    slide.content
  )

  render() {
    const {
      slide = {},
      slideStyle,
    } = this.props;
    return (
      <div style={slideStyle}>
        <div style={{ margin: 16, display: 'flex' }}>
          {slide.icon && (
          <div style={{ marginRight: 8 }}>
            {this.renderIcon({ slide })}
          </div>
          )}
          {this.renderSlide({ slide })}
        </div>
      </div>
    );
  }
}
