import React from 'react';
import PropTypes from 'prop-types';

function Weekday({
  className,
  locale,
  localeUtils,
  weekday,
}) {
  const weekdayName = localeUtils.formatWeekdayLong(weekday, locale);
  return (
    <div className={className} title={weekdayName}>
      {weekdayName.slice(0, 1)}
    </div>
  );
}

Weekday.propTypes = {
  className: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  localeUtils: PropTypes.shape({
    formatWeekdayLong: PropTypes.func.isRequired,
  }).isRequired,
  weekday: PropTypes.number.isRequired,
};

export default Weekday;
