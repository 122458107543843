import { selector } from 'recoil';
import tabPropsQuery from './tab-props.query';
import tabPrefsQuery from './tab-prefs.query';

const activeViewQuery = selector<'map' | 'list'>({
  key: 'activeView',
  get: ({ get }) => {
    const props = get(tabPropsQuery);
    const prefs = get(tabPrefsQuery);

    if (!props || !prefs) {
      return 'list';
    }

    if (props.withMapSupport && prefs.view === 'map') {
      return 'map';
    }

    return 'list';
  },
});

export default activeViewQuery;
