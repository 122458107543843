import { selector } from 'recoil';
import tabPropsQuery from './tab-props.query';
import tabPrefsQuery from './tab-prefs.query';
import { Column } from './DataGrid';
import { ColumnPreference } from './preferences.factory';

interface Merged extends Column, ColumnPreference {}

/**
 * Returns the actively sorted column and the direction of the sort
 * in the following order:
 *
 * 1) Preferences column designated as current sort
 * 2) Props default sort
 * 3) No sort, or null
 *
 * @return {{ id: string, sortDirection: 'asc' | 'desc' }}
 */
const activeSortQuery = selector<Merged | null>({
  key: 'activeSort',
  get: ({ get }) => {
    const props = get(tabPropsQuery);
    const prefs = get(tabPrefsQuery);

    if (!props || !prefs) {
      return null;
    }

    const { defaultSort, columns } = props;

    const prefsCol = (prefs.columns || []).find(({ sorted: s }) => s);
    if (prefsCol) {
      const col = columns.find(({ id }) => id === prefsCol.id);
      if (col) {
        return { id: col.id, sortDirection: prefsCol.sortDirection };
      }
    }

    const propsCol = defaultSort
      ? columns.find(({ id: cid }) => cid === defaultSort)
      : undefined;
    if (propsCol) {
      const col = props.columns.find(({ id }) => id === propsCol.id);
      if (col) {
        return {
          id: col.id,
          sortDirection: propsCol.defaultSortDirection || 'asc',
        };
      }
    }

    return null;
  },
});

export default activeSortQuery;
