import React from 'react';
import PropTypes from 'prop-types';
import css from './styles.scss';

const GroupCard = ({
  children,
}) => (
  <div className={css.GroupCard__container}>
    { children }
  </div>
);

GroupCard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GroupCard;
