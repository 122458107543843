import React from 'react';
import PropTypes from 'prop-types';
import css from './styles.scss';
import HorizontalList from '../HorizontalList';
import HorizontalListItem from '../HorizontalListItem';
import Icon from '../Icon';

const Caret = ({ label }) => (
  <HorizontalList display="inline" noWrap>
    <HorizontalListItem>{label}</HorizontalListItem>
    <HorizontalListItem>
      <div className={css.Caret__icon}><Icon block name="dropDown" size={24} /></div>
    </HorizontalListItem>
  </HorizontalList>
);

Caret.propTypes = {
  label: PropTypes.node,
};

Caret.defaultProps = {
  label: '',
};

export default Caret;
