import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class FormGridRow extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = { };
    }

    render() {
        const cssClasses = this.context.formGridClasses;
        const props = this.props;
        const rowClasses = classNames(cssClasses.FormGrid__row, {
            [cssClasses['FormGrid__row--engaged']]: this.props.query.minWidth,
        });
        return (
            <div className={rowClasses}>
                {React.Children.map(props.children, child => React.cloneElement(child, {
                    query: this.props.query,
                }))}
            </div>
        );
    }
}

FormGridRow.propTypes = {
    query: PropTypes.object,
};

FormGridRow.defaultProps = {
    query: {},
};

FormGridRow.contextTypes = {
    formGridClasses: PropTypes.object,
};
