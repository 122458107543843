import React from 'react';
import PropTypes from 'prop-types';
import {
  Group,
  Label,
  HelpText,
  DatePicker as FieldDatePicker,
} from '../../legacy/Construction/Field/Field';

const DatePicker = ({
  id,
  validationType,
  labelHidden,
  optional,
  additionalInfo,
  label,
  helpText,
  disabled,
  value,
  onChange,
}) => (
  <Group
    id={id}
    validation={validationType}
  >
    <Label
      screenReaderOnly={labelHidden}
      optional={optional}
      help={additionalInfo}
    >
      {label}
    </Label>
    <FieldDatePicker
      name={id}
      disabled={disabled}
      value={value}
      onChange={onChange}
    />
    {helpText && <HelpText fieldValidation={validationType}>{helpText}</HelpText>}
  </Group>
);

DatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  validationType: PropTypes.oneOf(['', 'error', 'success']),
  labelHidden: PropTypes.bool,
  optional: PropTypes.bool,
  additionalInfo: PropTypes.string,
  label: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

DatePicker.defaultProps = {
  validationType: '',
  labelHidden: false,
  optional: false,
  additionalInfo: '',
  helpText: '',
  disabled: false,
  value: '',
  onChange: () => {},
};

export default DatePicker;
