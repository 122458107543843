import React from 'react';
import PropTypes from 'prop-types';

const PaginationCount = (props) => {
  const {
    totalRows,
    currentPage,
    itemsPerPage,
  } = props;

  const getFirstItemPlacement = () => ((currentPage - 1) * itemsPerPage) + 1;

  const getLastItemPlacement = () => {
    const lastItemPlacement = getFirstItemPlacement() + (itemsPerPage - 1);
    return lastItemPlacement > totalRows ? totalRows : lastItemPlacement;
  };

  return (
    <span>
      {getFirstItemPlacement()}
      -
      {getLastItemPlacement()}
      {' '}
      of
      {' '}
      {totalRows}
    </span>
  );
};

PaginationCount.propTypes = {
  totalRows: PropTypes.number,
  itemsPerPage: PropTypes.number,
  currentPage: PropTypes.number,
};

PaginationCount.defaultProps = {
  totalRows: 0,
  itemsPerPage: 100,
  currentPage: 1,
};

export default PaginationCount;
