import React from 'react';
import AbstractInput from '../AbstractInput';
import protectedProps from '../AbstractInput/protectedProps';

export const EIN_REGEX = /^\d{2}-\d{7}$/;

export const DEFAULT_ERROR = {
  type: 'error',
  message: 'Employer Identification Number is invalid',
};

export const internalValidation = (event) => EIN_REGEX.test(event.target.value) || DEFAULT_ERROR;

const EmployeeIdentificationNumberInput = (initialProps) => {
  const props = {
    ...initialProps,
    format: '99-9999999',
    internalValidation,
  };

  return (<AbstractInput {...props} />);
};

EmployeeIdentificationNumberInput.defaultProps = {
  label: 'Employee Identification Number',
};

export default protectedProps(EmployeeIdentificationNumberInput);
