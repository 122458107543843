import * as React from 'react';
import { Suspense } from 'react';
import { RecoilRoot } from 'recoil';
import { Loader } from '../index';
import Preferences from './Preferences';
import { FilterValue } from './preferences.factory';
import { IconList } from '../Icon/types.d';
import DebugObserver from './DebugObserver';

interface InlineAction {
  label: string;
  onClick: (e: React.ReactEventHandler<HTMLButtonElement>) => void;
}

export interface Column {
  id: string;
  label?: string;
  defaultSortDirection?: 'asc' | 'desc';
  withSort?: boolean;
  withRequired?: boolean;
  withTruncation?: boolean;
  withDefault?: boolean;
  minWidth?: number;
  defaultWidth?: number;
  maxWidth?: number;
  render?: (row: any, rows: any[], index: number) => React.ReactNode | React.ReactNode[];
}

interface FilterOption {
  label: string;
  value: string;
}

export interface Filter {
  id: string;
  name?: string;
  type?: 'multi-select' | 'boolean' | 'date' | 'date-time' | 'typeahead' | 'slider' | 'date-range' |'advanced-date-range' | 'phone' | 'text' | 'number';
  pattern?: RegExp;
  placeholder?: string;
  section?: number;
  options?: FilterOption[];
  getLazyLoadOptions?: () => FilterOption[] | Promise<FilterOption[]>;
  value?: FilterValue;
}

export interface Tab {
  url: string;
  id: string;
  columns: Column[];
  label?: string;
  empty?: React.ReactNode | React.ReactNode[];
  withCounter?: boolean;
  withSearch?: boolean;
  withMapSupport?: boolean;
  withCheckboxes?: boolean;
  withPagination?: boolean;
  defaultPerPage?: number;
  defaultSort?: string;
  defaultColumnWidth?: number;
  rowHeight?: 'auto' | '2';
  rowActions?: (row: any, rows: any[], index: number) => InlineAction[];
}

export interface MassAction {
  label?: string;
  icon?: IconList;
  onClick?: (e: React.ReactEventHandler<HTMLButtonElement>) => void,
  disabled?: boolean;
}

export interface Props {
  userId?: number;
  companyId?: number;
  defaultTab?: string;
  tabs: Tab[];
  uniqueId: string;
  filters?: Filter[];
  version?: number;
  dataVersion?: number;
  massActions?: MassAction[];
  onSelections?: (ids?: number[]) => void,
  debug?: boolean,
}

const DataGrid: React.FC<Props> = ({ debug = false, ...props }) => (
  <RecoilRoot>
    {debug && <DebugObserver />}
    <Suspense fallback={<Loader fixed isLoading />}>
      <Preferences {...props} />
    </Suspense>
  </RecoilRoot>
);

DataGrid.defaultProps = {
  userId: 0,
  companyId: 0,
  defaultTab: undefined,
  tabs: [],
  version: 1,
  dataVersion: 1,
  massActions: [],
  onSelections: () => {},
};

export default DataGrid;
