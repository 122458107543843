/* eslint-disable no-console */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useEffect } from 'react';
import { useRecoilSnapshot, useRecoilCallback } from 'recoil';

const DebugButton = () => {
  const onClickDebug = useRecoilCallback(({ snapshot }) => async () => {
    console.log('Atom values');
    for (const node of snapshot.getNodes_UNSTABLE()) {
      const value = await snapshot.getPromise(node);
      console.log('\t', node.key, value);
    }
  }, []);
  return (<button type="button" onClick={onClickDebug}>View State</button>);
};

const DebugObserver = () => {
  const snapshot = useRecoilSnapshot();
  useEffect(() => {
    console.log('The following atoms were modified:');
    for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
      console.log('\t', node.key, snapshot.getLoadable(node));
    }
  }, [snapshot]);

  return (<DebugButton />);
};

export default DebugObserver;
