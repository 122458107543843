import React from 'react';
import PropTypes from 'prop-types';
import css from './styles.scss';
import DatePicker from './DatePicker';
import Timepicker from './TimePicker';

const DateTimePicker = ({
  disabled,
  helpText,
  datePickerId,
  datePickerLabel,
  onDateChange,
  optional,
  datePickerValue,
  timePickerId,
  datePickerValidationType,
  timePickerValidationType,
  timePickerLabel,
  timePickerValue,
  onTimeChange,
}) => {
  const setValidationType = () => {
    if (timePickerValidationType === 'error') {
      return 'error';
    }

    if (timePickerValidationType === 'success' && !datePickerValidationType) {
      return 'success';
    }

    return datePickerValidationType;
  };

  return (
    <div className={css.DateTimePicker}>
      <div className={css.DatePicker}>
        <DatePicker
          disabled={disabled}
          helpText={helpText}
          id={datePickerId}
          validationType={setValidationType()}
          name={datePickerId}
          label={datePickerLabel}
          onChange={onDateChange}
          optional={optional}
          value={datePickerValue}
        />
      </div>
      <div className={css.TimePicker}>
        <Timepicker
          disabled={disabled}
          id={timePickerId}
          validationType={timePickerValidationType}
          optional={optional}
          label={timePickerLabel}
          value={timePickerValue}
          onChange={onTimeChange}
        />
      </div>
    </div>
  );
};

DateTimePicker.propTypes = {
  datePickerId: PropTypes.string.isRequired,
  datePickerLabel: PropTypes.string.isRequired,
  timePickerId: PropTypes.string.isRequired,
  timePickerLabel: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  onDateChange: PropTypes.func,
  datePickerValue: PropTypes.string,
  timePickerValidationType: PropTypes.oneOf(['', 'error', 'success']),
  datePickerValidationType: PropTypes.oneOf(['', 'error', 'success']),
  timePickerValue: PropTypes.string,
  onTimeChange: PropTypes.func,
  optional: PropTypes.bool,
  disabled: PropTypes.bool,
};

DateTimePicker.defaultProps = {
  disabled: false,
  helpText: '',
  onDateChange: () => {},
  optional: false,
  datePickerValue: '',
  timePickerValidationType: '',
  datePickerValidationType: '',
  timePickerValue: '',
  onTimeChange: () => {},
};

export default DateTimePicker;
