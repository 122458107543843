import React from 'react';
import PropTypes from 'prop-types';
import ActionsMenu from '../ActionsMenu';

const ButtonBarMoreMenu = ({
  menuItems,
  isInGroup,
  firstInGroup,
  lastInGroup,
  alignToRight,
  label,
}) => (
  <ActionsMenu
    alignToRight={alignToRight}
    isInGroup={isInGroup}
    firstInGroup={firstInGroup}
    lastInGroup={lastInGroup}
    menuItems={menuItems}
    label={label || 'More'}
    disableAutoWidth
  />
);

ButtonBarMoreMenu.propTypes = {
  alignToRight: PropTypes.bool,
  isInGroup: PropTypes.bool,
  firstInGroup: PropTypes.bool,
  lastInGroup: PropTypes.bool,
  menuItems: PropTypes.arrayOf(PropTypes.element),
  label: PropTypes.string,
};

ButtonBarMoreMenu.defaultProps = {
  alignToRight: false,
  isInGroup: false,
  firstInGroup: false,
  lastInGroup: false,
  menuItems: [],
  label: 'More',
};

export default ButtonBarMoreMenu;
