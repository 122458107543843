import React from 'react';
import PropTypes from 'prop-types';
import DrawerContainer from './DrawerContainer';
import DrawerTransition from './DrawerTransition';
import PortalRender from '../PortalRender';

const Drawer = (props) => {
  const {
    isOpen,
    children,
    ...restProps
  } = props;
  return (
    <PortalRender>
      <DrawerTransition
        {...restProps}
        isOpen={isOpen}
      >
        <DrawerContainer
          {...restProps}
        >
          {children}
        </DrawerContainer>
      </DrawerTransition>
    </PortalRender>
  );
};

Drawer.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  children: PropTypes.node.isRequired,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  footerActions: PropTypes.instanceOf(Array),
  parentId: PropTypes.string,
  size: PropTypes.oneOf(['lg', 'sm']),
  noBodyPadding: PropTypes.bool,
  customHeader: PropTypes.element,
  customSecondaryHeader: PropTypes.element,
  footerBackgroundColor: PropTypes.oneOf(['default', 'grey20', 'grey30', 'grey40']),
  headerBackgroundColor: PropTypes.oneOf(['default', 'grey20', 'grey30', 'grey40']),
  headerBorderBottom: PropTypes.bool,
  headerVerticalAlign: PropTypes.oneOf(['middle', 'top', 'bottom']),
  tertiaryFooter: PropTypes.shape({
    action: PropTypes.shape({
      disabled: PropTypes.bool,
      icon: PropTypes.string,
      onClick: PropTypes.func,
    }),
    text: PropTypes.string,
  }),
};

Drawer.defaultProps = {
  isOpen: false,
  title: null,
  onBack: null,
  onClose: null,
  footerActions: [],
  parentId: null,
  size: 'sm',
  noBodyPadding: false,
  customHeader: null,
  customSecondaryHeader: null,
  footerBackgroundColor: 'default',
  headerBackgroundColor: 'default',
  headerBorderBottom: true,
  headerVerticalAlign: 'middle',
  tertiaryFooter: null,
};

export default Drawer;
