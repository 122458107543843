import { selector } from 'recoil';
import propsState from './props.state';

const selectionsQuery = selector({
  key: 'selections',
  get: ({ get }) => {
    const props = get(propsState);

    if (!props) {
      return [];
    }

    return props.onSelections as Function;
  },
});

export default selectionsQuery;
