/* eslint-disable no-const-assign */
import * as React from 'react';
import { Grid, GridColumn, Heading } from '../index';
const css = require('./styles.scss');

export interface Props {
  headingText?: string,
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl',
  sourceObject: object | {},
}

/**
 * Simple component to illustrate use of React with hooks
 * @param {*} props
 */
const ExampleComponent: React.FC<Props> = ({ sourceObject, size, headingText }) => {
  const cssClass = `ExampleListItem--text-${size}`

  // useState instead of this.state
  const [updateCount, setUpdateCount] = React.useState(0)

  // useEffect instead of lifecycle methods
  // this fn only called on update of the props specified in array param
  React.useEffect(() => {
    setUpdateCount(updateCount + 1);
  }, [size, sourceObject, headingText])

  return (
    <React.Fragment>
      <div>
        {headingText && headingText.length > 0
          && (<Heading styleLevel="h2">{headingText}</Heading>
          )}
      </div>
      <div className={css.ExampleList}>
        {`Prop update count [${updateCount}]`}
      </div>
      {sourceObject && Object.keys(sourceObject).length > 0
        && (
          <div className={css.ExampleList} data-testid="object-table">
            <Grid vSpace>
              {(Object.entries(sourceObject).map(([key, val]) => (
                <React.Fragment key={key}>
                  <GridColumn xs="12" sm="3">
                    <div className={css[cssClass]}><i>{key}</i></div>
                  </GridColumn>
                  <GridColumn xs="12" sm="5">
                    <div className={css[cssClass]}>{val}</div>
                  </GridColumn>
                  <GridColumn xs="12" sm="4">&nbsp;</GridColumn>
                </React.Fragment>
              )))}
            </Grid>
          </div>
        )}
    </React.Fragment>
  )
}

export default ExampleComponent;
