import React from 'react';
import PropTypes from 'prop-types';
import AbstractOptions from '../AbstractOptions';

const Checkbox = ({
  expandWhenChecked,
  legend,
  legendHidden,
  onChange,
  options,
  optgroup,
  optionsWithGroup,
  value,
  viewType,
}) => (
  <AbstractOptions
    expandWhenChecked={expandWhenChecked}
    inputType="checkbox"
    legend={legend}
    legendHidden={legendHidden}
    onChange={onChange}
    options={options}
    optgroup={optgroup}
    optionsWithGroup={optionsWithGroup}
    value={value}
    viewType={viewType}
  />
);

Checkbox.propTypes = {
  expandWhenChecked: PropTypes.bool,
  legend: PropTypes.string,
  legendHidden: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    secondaryContent: PropTypes.node,
  })),
  optgroup: PropTypes.bool,
  optionsWithGroup: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      secondaryContent: PropTypes.node,
    })),
  })),
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  viewType: PropTypes.oneOf(['horizontal', 'vertical']),
};

Checkbox.defaultProps = {
  expandWhenChecked: false,
  legend: '',
  legendHidden: false,
  options: [],
  optgroup: false,
  optionsWithGroup: [],
  value: [],
  viewType: 'vertical',
};

export default Checkbox;
