import React, { cloneElement, Component } from "react";
import CSSModules from "react-css-modules";
import PropTypes from "prop-types";
import classNames from "classnames";

import MenuItem from "./MenuItem";
import classes from "./styles/NestedMenuItem.scss";

@CSSModules(classes)
export default class NestedMenuItem extends Component {
  constructor(props) {
    super(props);
    this.state = { expended: false };
  }

  static propTypes = {
    label: PropTypes.string.isRequired,
    nestedLevel: PropTypes.number,
  };

  static defaultProps = {
    nestedLevel: 0,
  };

  expandOrCollapse() {
    this.setState({
      expended: !this.state.expended,
    });
  }

  getIcon() {
    return this.state.expended ? "collapse" : "expand";
  }

  render() {
    const items = this.props.children;
    const nestedLevel = this.props.nestedLevel || 0;

    return (
      <div
        className={classNames(classes.NestedMenuItem, {
          [classes.NestedMenuItem__applyNegativeMargin]: !nestedLevel,
        })}
      >
        <div className={classNames(classes.NestedMenuItem__applyHoverEffect)}>
          <div
            className={classNames({
              [classes[
                `NestedMenuItem__applyPadding--level${this.props.nestedLevel}`
              ]]: this.props.nestedLevel,
            })}
          >
            <MenuItem
              isActive
              label={this.props.label}
              icon={this.getIcon()}
              onClick={() => this.expandOrCollapse()}
            />
          </div>
        </div>
        {this.state.expended &&
          Array.isArray(items) &&
          items.length &&
          items.map((item, index) => {
            const deeplyNested = Array.isArray(item?.props?.children);
            if (deeplyNested) {
              return cloneElement(item, {
                nestedLevel: Number(this.props.nestedLevel) + 1,
              });
            }

            return (
              <div
                id={index}
                className={classNames(
                  classes.NestedMenuItem__applyHoverEffect,
                  {
                    [classes.NestedMenuItem__applyPadding]: !this.props
                      .nestedLevel,
                    [classes[
                      `NestedMenuItem__applyPadding--level${this.props
                        .nestedLevel + 1}`
                    ]]: this.props.nestedLevel,
                  }
                )}
              >
                {item}
              </div>
            );
          })}
      </div>
    );
  }
}
