import React from 'react';
import PropTypes, { string } from 'prop-types';
import styles from './styles/SummaryView.scss';
import SummaryCard, { SummaryCardPropTypes } from './SummaryCard';

const SummaryView = React.memo(({ data, footerText }) => (
  <div className={styles.SummaryView__container}>
    <div className={styles.SummaryView__content}>
      {data && data.map((ele) => (
        <SummaryCard {...ele} />
      ))}
    </div>
    <p>{footerText}</p>
  </div>
));
export const SummaryViewPropTypes = {
  data: PropTypes.arrayOf(SummaryCardPropTypes).isRequired,
  footerText: string,
};
SummaryView.propTypes = SummaryViewPropTypes;
SummaryView.defaultProps = {
  footerText: '',
};
export default SummaryView;
