import { selector } from 'recoil';
import { Tab } from './DataGrid';
import propsState from './props.state';

const tabsQuery = selector<Tab[]>({
  key: 'tabs',
  get: ({ get }) => {
    const props = get(propsState);

    if (!props) {
      return [];
    }

    return props.tabs || [];
  },
});

export default tabsQuery;
