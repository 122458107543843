import React from 'react';
import PropTypes from 'prop-types';
import { onlyUpdateForKeys, compose } from 'recompose';
import classNames from 'classnames';
import classes from './styles/Media.scss';

const Media = onlyUpdateForKeys(['element', 'body', 'verticalAlign'])(({ element, body, verticalAlign }) => {
    const rootClasses = classNames([classes.Media], {
        [classes['Media--vMiddle']]: verticalAlign === 'middle',
    });
    return (
        <div className={rootClasses}>
            {element && <div className={classes.Media__element}>{element}</div>}
            <div className={classes.Media__body}>{body}</div>
        </div>
    );
});

const MediaComposed = compose()(Media);
MediaComposed.propTypes = {
    verticalAlign: PropTypes.oneOf(['top', 'middle']),
};
MediaComposed.defaultProps = {
    verticalAlign: 'top',
};

export default Media;
