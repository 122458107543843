import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './styles.scss';
import {
  IconButton,
  Heading,
  BodyText,
  Truncate,
  Median,
  MedianAlpha,
  MedianOmega,
  IconAside,
  IconAsideIcon,
} from '../index';
import Menu from '../Menu';

const WorkOrderCard = ({
  heading,
  subHeading,
  status,
  title,
  typeOfWork,
  schedule,
  location,
  distance,
  actions,
}) => {
  const locationText = location && distance ? `${location} | ${distance}` : location;
  const color = {
    Draft: 'grey80',
    Published: 'green',
    Routed: 'blue60',
    Problem: 'red',
  };
  const workOrderCardBody = (
    <div className={css.WorkOrderCard__body}>
      <div>
        <IconAside iconColor={color[status] || 'grey80'}>
          <IconAsideIcon verticalAlign="middle">
            <span className="iconCircle" />
          </IconAsideIcon>
          <span className={css.WorkOrderCard__mutedText}>{status}</span>
        </IconAside>
      </div>
      <div>
        <Heading styleLevel="h6">
          <Truncate maxLines={1} text={title} />
        </Heading>
      </div>
      {
        [
          typeOfWork,
          schedule,
          locationText,
        ].filter((f) => f)
          .map((m, i) => (
            <div key={`${i + 1}`} className={css['WorkOrderCard__body--paddingTop']}>
              <span className={css.WorkOrderCard__mutedText}>{m}</span>
            </div>
          ))
      }
    </div>
  );

  return (
    <div className={css.WorkOrderCard__container}>
      <div>
        <Median>
          <MedianAlpha>
            {heading
              && (
                <div className={classNames(
                  css.WorkOrderCard__labelContent,
                  css.WorkOrderCard__header,
                )}
                >
                  <div className={css['WorkOrderCard__labelContent--header']}>
                    <Heading styleLevel="h4">{heading}</Heading>
                  </div>
                  <div className={css['WorkOrderCard__labelContent--subHeader']}>
                    <BodyText styleLevel="sm">
                      {subHeading}
                    </BodyText>
                  </div>
                </div>
              )}
          </MedianAlpha>
          <MedianOmega>
            <div className={css.WorkOrderCard__actions}>
              {
                actions
                && actions.length > 0
                && (
                  <Menu
                    disableAutoWidth
                    closeOnSelection
                    label="More actions"
                    menuItems={actions}
                    trigger={(
                      <div>
                        <IconButton
                          name="moreHoriz"
                        />
                      </div>
                    )}
                  />
                )
              }
            </div>
          </MedianOmega>
        </Median>
      </div>
      {workOrderCardBody}
    </div>
  );
};

WorkOrderCard.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  status: PropTypes.oneOf([
    'Draft',
    'Published',
    'Assigned',
    'Work Done',
    'Approved',
    'Paid',
    'Cancelled',
    'Postponed',
    'Routed',
    'Deleted',
    'Problem',
  ]).isRequired,
  title: PropTypes.string.isRequired,
  typeOfWork: PropTypes.string,
  schedule: PropTypes.string,
  location: PropTypes.string,
  distance: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string,
    onClick: PropTypes.func,
    label: PropTypes.string,
    type: PropTypes.oneOf(['primary', 'secondary', 'critical', 'text']),
    disabled: PropTypes.bool,
    icon: PropTypes.string,
  })),
};

WorkOrderCard.defaultProps = {
  heading: '',
  subHeading: '',
  typeOfWork: '',
  schedule: '',
  location: '',
  distance: '',
  actions: undefined,
};

export default WorkOrderCard;
