import React from 'react';
import AbstractInput from '../AbstractInput';
import protectedProps from '../AbstractInput/protectedProps';

export const DEFAULT_ERROR = {
  type: 'error',
  message: 'Password Length has to be at least one character',
};

export const internalValidation = (event) => event.target.value.length > 0 || DEFAULT_ERROR;

const PasswordInput = (initialProps) => {
  const props = {
    ...initialProps,
    internalValidation,
    type: 'password',
  };

  return (<AbstractInput {...props} />);
};

PasswordInput.defaultProps = {
  label: 'Password',
};

export default protectedProps(PasswordInput);
