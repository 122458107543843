import React from 'react';
import PropTypes from 'prop-types';
import noop from '../utils/noop';
import { Chip, IconIndicator } from '../index';

const CommStatusChip = ({ online, userId, onClick }) => {
  if (online) {
    return (
      <div style={{ display: 'inline-block' }}>
        <Chip
          id={userId}
          active
          hideCloseButton
          type="info"
          value="Online"
          size="sm"
          onClick={onClick}
          customLeftNode={(
            <span data-testid="online" style={{ paddingRight: '8px' }}>
              <IconIndicator active type="green" />
            </span>
          )}
        />
      </div>
    );
  }
  return (
    <div style={{ display: 'inline-block' }}>
      <Chip
        id={userId}
        active
        size="sm"
        hideCloseButton
        type="none"
        value="Offline"
      />
    </div>
  );
};

CommStatusChip.propTypes = {
  online: PropTypes.bool,
  userId: PropTypes.string,
  onClick: PropTypes.func,
};
CommStatusChip.defaultProps = {
  online: false,
  userId: null,
  onClick: noop,
};

export default CommStatusChip;
