import React, { Component } from 'react';
import { TweenMax } from 'gsap';

export default class ModalAnimation extends Component {
    constructor(props) {
        super(props);
    }

    componentWillUnmount() {
        this.reset();
    }

    reset() {
        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = null;
    }

    componentWillEnter(callback) {
        this.interval = setInterval(() => {
            const overlay = document.querySelector('.js-modalOverlay');
            if (!overlay) {
                return;
            }

            this.reset();

            TweenMax.fromTo(
                overlay,
                .25,
                {
                    opacity: 0,
                },
                {
                    opacity: 1,
                    onComplete: callback,
                }
            );

            const content = document.querySelector('.js-modalContent');
            TweenMax.fromTo(
                content,
                .25,
                {
                    y: '15%',
                },
                {
                    y: '0%',
                }
            );
        }, 25);
    }

    componentWillUnmount() {
        this.reset();
    }

    componentWillLeave(callback) {
        if (!this.interval) {
            const overlay = document.querySelector('.js-modalOverlay');
            TweenMax.fromTo(
                overlay,
                .25,
                {
                    opacity: 1,
                },
                {
                    opacity: 0,
                    onComplete: callback, // can only call one callback per componentWillLeave method
                }
            );

            const content = document.querySelector('.js-modalContent');
            TweenMax.fromTo(
                content,
                .25,
                {
                    y: '0%',
                },
                {
                    y: '15%',
                }
            );
        }
    }

    render() {
        return (
            this.props.children
        );
    }
}
