import React from 'react';
import PropTypes from 'prop-types';
import { iconList } from '@fieldnation/font/components';
import AbstractContainer from '../AbstractContainer';
import {
  Median,
  MedianAlpha,
  MedianOmega,
  IconButton,
  IconButtons,
  Heading,
  HorizontalList,
  HorizontalListItem,
} from '../index';

const renderIcons = (list, Wrapper) => (
  (list.length > 0)
    && (
    <Wrapper>
      <IconButtons
        isFlush
      >
        {list.map((action) => (
          <IconButton
            key={action.title}
            title={action.title}
            name={action.icon}
            onClick={action.onClick}
          />
        ))}
      </IconButtons>
    </Wrapper>
    )
);

const Header = ({
  actions,
  onClose,
  onBack,
  title,
  titleTag,
  backgroundColor,
  borderBottom,
  custom,
  customSecondary,
  verticalAlign,
}) => {
  if (!title && !onClose && !onBack && !actions.length) {
    return null;
  }

  const leftActions = onBack ? [{
    icon: 'back',
    onClick: onBack,
    title: 'Back',
  }] : [];

  const rightActions = [
    ...actions,
    ...(onClose ? [
      {
        icon: 'close',
        onClick: onClose,
        title: 'Close',
      },
    ]
      : []
    )];

  return (
    <AbstractContainer
      borderBottom={borderBottom}
      backgroundColor={backgroundColor}
    >
      {custom
        ? (
          <div>
            {custom}
          </div>
        ) : (
          <Median
            noWrap
            verticalAlign={verticalAlign}
          >
            <MedianAlpha>
              <HorizontalList
                display="block"
                spacing={2}
              >
                {renderIcons(leftActions, HorizontalListItem)}
                { title
                  && (
                  <HorizontalListItem>
                    <Heading
                      tag={titleTag}
                      styleLevel="h4"
                    >
                      {title}
                    </Heading>
                  </HorizontalListItem>
                  )}
              </HorizontalList>

            </MedianAlpha>
            {renderIcons(rightActions, MedianOmega)}
          </Median>
        )}
      {customSecondary
        && (
          <div>
            {customSecondary}
          </div>
        )}
    </AbstractContainer>
  );
};

Header.propTypes = {
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  actions: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.oneOf(iconList).isRequired,
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
  })),
  title: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  titleTag: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
  ]),
  backgroundColor: PropTypes.oneOf(['default', 'grey20', 'grey30', 'grey40']),
  borderBottom: PropTypes.bool,
  custom: PropTypes.element,
  customSecondary: PropTypes.element,
  verticalAlign: PropTypes.oneOf(['middle', 'top', 'bottom']),
};

Header.defaultProps = {
  actions: [],
  title: null,
  titleTag: 'h2',
  onBack: null,
  onClose: null,
  backgroundColor: 'default',
  borderBottom: true,
  custom: null,
  customSecondary: null,
  verticalAlign: 'middle',
};

export default Header;
