import { selector } from 'recoil';
import tabPropsQuery from './tab-props.query';
import tabPrefsQuery from './tab-prefs.query';

const activePageQuery = selector<number>({
  key: 'activePage',
  get: ({ get }) => {
    const props = get(tabPropsQuery);
    const prefs = get(tabPrefsQuery);

    if (!props || !prefs) {
      return 1;
    }

    if (prefs.page) {
      return prefs.page;
    }

    return 1;
  },
});

export default activePageQuery;
