import { Props } from './DataGrid';
import { PreferenceFactory, Preferences } from './preferences.factory';

interface BasicWithVersion {
  version: number;
}

const preferenceLocalStorage: PreferenceFactory = {
  load: (props: Props, prefs?: Preferences): Preferences | undefined => {
    // no support for localStorage or disabled, skip load
    if (typeof Storage === 'undefined') {
      return prefs;
    }

    // no settings saved, skip load
    const storedPrefs = localStorage.getItem(props.uniqueId);
    if (!storedPrefs) {
      return prefs;
    }

    // all settings should be saved as JSON, but try/catch JIC
    const unserializedPrefs = (() => {
      try {
        return JSON.parse(storedPrefs);
      } catch (e) {
        return null;
      }
    })();

    if (!unserializedPrefs) {
      return prefs;
    }

    // interface change denoted by incompatible version, skip load
    const basic = unserializedPrefs as unknown as BasicWithVersion;
    if (basic.version !== (props.version || 1)) {
      return prefs;
    }

    // risky but that's why we have versioning
    const existingPrefs = unserializedPrefs as unknown as Preferences;

    return existingPrefs;
  },

  save: (props: Props, prefs: Preferences) => {
    // no support for localStorage or disabled, skip load
    if (typeof Storage === 'undefined') {
      return;
    }
    localStorage.setItem(props.uniqueId, JSON.stringify({
      ...prefs,
      version: props.version || 1,
    }));
  },
};

export default preferenceLocalStorage;
