import { selector } from 'recoil';
import requestQuery from './request.query';

const activeResultsQuery = selector<any[]>({
  key: 'activeResults',
  get: ({ get }) => {
    const apiResponse = get(requestQuery);

    if (!apiResponse) {
      return [];
    }

    const { results } = apiResponse;

    return results;
  },
});

export default activeResultsQuery;
