import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classes from './styles.scss';

class TableBody extends Component {
  constructor(props) {
    super(props);
    this.tbody = React.createRef();
    this.onScroll = this.onScroll.bind(this);
  }

  onScroll(evt) {
    const { onScroll } = this.props;
    if (onScroll) {
      onScroll(evt);
    }
  }

  render() {
    const { children, height } = this.props;
    return (
      <tbody
        ref={this.tbody}
        className={classes.TableBody}
        onScroll={this.onScroll}
        style={{ height: height || 'auto' }}
      >
        {children}
      </tbody>
    );
  }
}

TableBody.propTypes = {
  children: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.node,
  ])),
  onScroll: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  height: PropTypes.number,
};

TableBody.defaultProps = {
  children: null,
  onScroll: false,
  height: 0,
};

TableBody.displayName = 'TableBody';

export default TableBody;
