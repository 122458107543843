import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../../Icon';
import Heading from '../../Heading';
import styles from './styles/Tile.scss';
import { Button, IconButton } from '../../index';
import Menu from '../../Menu';

const TileDivision = ({
  children,
  heading,
  count,
  actions,
  buttonActions,
  showActionsMenu,
  forceShowActionMenus,
  subHeading,
  empty,
  wrapButtons,
  subComponent,
  statusChip,
  compactHeading = false,
}) => (
  <div className={styles.Tile__division} aria-label="tile-division">
    {heading && (
      <div
        className={classNames({
          [styles.Tile__division__heading_compact]: compactHeading,
          [styles.Tile__division__heading]: !compactHeading,
          [styles.Tile__division__spaceBetween]: statusChip,
        })}
      >
        <Heading styleLevel="h3" tag="h3">
          {heading}
        </Heading>
        {count && (
          <div className={styles.Tile__division__count} aria-label="tile-count">
            {count}
          </div>
        )}
        {subHeading && (
          <div
            className={styles.Tile__division__subHeading}
            aria-label="tile-sub-heading"
          >
            {subHeading}
          </div>
        )}
        {subComponent && (
          <div
            className={styles.Tile__division__subComponent}
            aria-label="tile-sub-component"
          >
            {subComponent}
          </div>
        )}
        {statusChip && (
          <div
            className={styles.Tile__division__statusChip}
            aria-label="tile-status-chip"
          >
            {statusChip}
          </div>
        )}
        {actions.length > 0 && (
          <div
            className={styles.Tile__division__actions}
            aria-label="tile-actions"
          >
            {buttonActions.map(({
              onClick,
              label,
              icon,
            }) => {
              if (!wrapButtons) {
                return (
                  <div className={styles.Tile__division__action__button}>
                    <Button
                      vAlign="baseline"
                      label={label}
                      type="secondary"
                      icon={icon}
                      shouldUseIconComponent
                      onClick={onClick}
                    />
                  </div>
                );
              }

              return null;
            })}
            {(forceShowActionMenus && actions?.length > 0) || (showActionsMenu && actions?.length > 1)
              ? (
                <Menu
                  disableAutoWidth
                  alignToRight
                  label="More actions"
                  menuItems={actions}
                  closeOnSelection
                  trigger={(
                    <div>
                      <IconButton
                        name="moreVert"
                      />
                    </div>
                )}
                />
              )
              : (
                actions.map(({
                  href,
                  onClick,
                  icon,
                  disabled = false,
                }) => {
                  const el = <Icon name={icon} size={24} />;

                  if (href) {
                    return (
                      <a
                        key={icon}
                        href={href}
                        rel="noreferrer nofollow"
                        aria-label="tile-action"
                      >
                        {el}
                      </a>
                    );
                  }

                  if (onClick) {
                    return (
                      <div
                        className={styles.Tile__division__action__button}
                        style={buttonActions.length > 0 && !wrapButtons ? { top: 8 } : {}}
                      >
                        <button
                          aria-label="tile-action"
                          type="button"
                          className="u-resetButton"
                          onClick={onClick}
                          key={icon}
                          disabled={disabled}
                        >
                          {el}
                        </button>
                      </div>
                    );
                  }

                  return null;
                })
              )}
          </div>
        )}
        {wrapButtons
        && buttonActions.map((b) => (
          <div className={styles.Tile__division__action__button} style={{ marginLeft: 16, marginTop: 12 }}>
            <Button
              vAlign="baseline"
              label={b.label}
              type="secondary"
              icon={b.icon}
              shouldUseIconComponent
              onClick={b.onClick}
            />
          </div>
        ))}
      </div>
    )}
    {empty ? (
      <div className={styles.Tile__division__empty} aria-label="tile-empty">
        {empty}
      </div>
    ) : (
      children
    )}
  </div>
);

TileDivision.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  count: PropTypes.number,
  empty: PropTypes.string,
  subComponent: PropTypes.element,
  statusChip: PropTypes.element,
  compactHeading: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
      href: PropTypes.string,
      icon: PropTypes.string.isRequired,
      label: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ),
  buttonActions: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
      icon: PropTypes.string.isRequired,
      label: PropTypes.string,
    }),
  ),
  showActionsMenu: PropTypes.bool,
  forceShowActionMenus: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  wrapButtons: PropTypes.bool,
};

TileDivision.defaultProps = {
  heading: null,
  subHeading: null,
  count: null,
  actions: [],
  buttonActions: [],
  showActionsMenu: false,
  forceShowActionMenus: false,
  children: null,
  empty: null,
  wrapButtons: false,
  subComponent: null,
  statusChip: null,
  compactHeading: false,
};

export default TileDivision;
