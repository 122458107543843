import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

export default class PortalRender extends React.Component {
  constructor(props) {
    super(props);

    this.portalRoot = document.body;
    this.el = document.createElement('div');
  }

  componentDidMount = () => {
    this.portalRoot.appendChild(this.el);
  };

  componentWillUnmount = () => {
    this.portalRoot.removeChild(this.el);
  };

  render() {
    const {
      children,
    } = this.props;

    return createPortal(
      children,
      this.el,
    );
  }
}

PortalRender.propTypes = {
  children: PropTypes.node.isRequired,
};
