import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Heading from '../Heading';
import Button from '../legacy/Construction/Button';
import BodyText from '../BodyText';
import css from './UpgradeTier.scss';

class UpgradeTier extends React.PureComponent {
  render() {
    const {
      header,
      text,
      tier,
      image,
      learnMoreHref,
      contactUsOnClick,
      learnMoreOnClick,
    } = this.props;

    return (
      <div className={css.UpgradeTier__container}>
        <div className={css.UpgradeTier__body}>
          <div className={css.UpgradeTier__content}>
            {
              React.cloneElement(image,
                {
                  className: css.UpgradeTier__image,
                })
            }

            <div className={css.UpgradeTier__right}>
              <div className={classNames(css.UpgradeTier__tier, css.UpgradeTier__contentItem)}>
                <Heading styleLevel="h5">{tier}</Heading>
              </div>

              <div className={css.UpgradeTier__contentItem}>
                <Heading
                  styleLevel="h2"
                >
                  {header}
                </Heading>
              </div>

              <BodyText styleLevel="md">
                {text}
              </BodyText>

              <div className={css.UpgradeTier__buttons}>
                <div className={css.UpgradeTier__button}>
                  <Button id="upgrade-tier-contact-us" onClick={contactUsOnClick} type="primary" disableTitleCasing>Contact us to Upgrade</Button>
                </div>
                {learnMoreHref && (
                  <div className={css.UpgradeTier__button}>
                    <Button type="link" href={learnMoreHref} onClick={learnMoreOnClick} target="_blank">Learn More</Button>
                  </div>
                )}
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

UpgradeTier.propTypes = {
  header: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  tier: PropTypes.string.isRequired,
  image: PropTypes.node.isRequired,
  learnMoreHref: PropTypes.string.isRequired,
  contactUsOnClick: PropTypes.func.isRequired,
  learnMoreOnClick: PropTypes.func,
};

UpgradeTier.defaultProps = {
  learnMoreOnClick: () => {},
};

export default UpgradeTier;
