import {
  parseNumber as parseNumberCustom,
  formatNumber as formatNumberCustom,
  isValidNumber as isValidNumberCustom,
  getNumberType as getNumberTypeCustom,
  AsYouType as AsYouTypeCustom,
} from 'libphonenumber-js/custom';

import metadata from '../../config/phone-metadata.min.json';

export const parseNumber = (...args) => parseNumberCustom(...args, metadata);
export const formatNumber = (...args) => formatNumberCustom(...args, metadata);
export const isValidNumber = (...args) => isValidNumberCustom(...args, metadata);
export const getNumberType = (...args) => getNumberTypeCustom(...args, metadata);

export class AsYouType extends AsYouTypeCustom {
  constructor(country) {
    super(country, metadata);
  }
}
