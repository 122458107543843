import React from 'react';
import PropTypes from 'prop-types';
import Group from '../Group/Group';
import Label from '../Label/Label';
import SocialSecurityInput from '../SocialSecurityInput';

const SocialSecurityField = ({ label, value, onChange }) => (
    <Group>
        <Label>{label}</Label>
        <SocialSecurityInput value={value} onChange={onChange} />
    </Group>
);

SocialSecurityField.defaultProps = {
    label: 'Social Security Number',
};

SocialSecurityField.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
};

export default SocialSecurityField;
