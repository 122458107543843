import React from 'react';
import cn from 'classnames';
import scrollbarSize from 'dom-helpers/scrollbarSize';
import BaseTimeGridHeader from 'react-big-calendar/lib/TimeGridHeader';
import DateContentRow from 'react-big-calendar/lib/DateContentRow';
import dates from 'react-big-calendar/lib/utils/dates';
import get from 'lodash/get';
import {
  Media,
  Thumbnail,
  Link,
} from '..';
import Header from './Header';
import wrapEventHandler, { ARG_ORDER } from '../utils/wrapEventHandler';
import css from './TimeGridHeader.scss';

export default class TimeGridHeader extends BaseTimeGridHeader {
  renderHeaderCells(range) {
    const {
      localizer,
      getDrilldownView,
      getNow,
      getters: { dayProp },
      components: { header: HeaderComponent = Header },
    } = this.props;

    const today = getNow();

    return range.map((date) => {
      const drilldownView = getDrilldownView(date);
      const label = localizer.format(date, 'dayFormat');

      const { className, style } = dayProp(date);

      const header = (
        <HeaderComponent
          key={date.key}
          date={date}
          label={label}
          localizer={localizer}
        />
      );
      const drillDownFn = wrapEventHandler(this.handleHeaderClick, [date, drilldownView], ARG_ORDER.EVENT_LAST, this);
      return (
        <div
          key={date.key}
          style={style}
          className={cn(
            'rbc-header',
            className,
            dates.eq(date, today, 'day') && 'rbc-today',
          )}
        >
          {drilldownView
            ? (
              <Link
                key="header_link"
                onClick={drillDownFn}
              >
                {header}
              </Link>
            )
            : <span>{header}</span>}
        </div>
      );
    });
  }

  render() {
    const {
      width,
      rtl,
      resources,
      range,
      events,
      getNow,
      accessors,
      selectable,
      components,
      getters,
      scrollRef,
      localizer,
      isOverflowing,
      components: { timeGutterHeader: TimeGutterHeader },
    } = this.props;

    const style = {};
    if (isOverflowing) {
      style[rtl ? 'marginLeft' : 'marginRight'] = `${scrollbarSize()}px`;
    }

    const groupedEvents = resources.groupEvents(events);

    return (
      <div
        style={style}
        ref={scrollRef}
        className={cn('rbc-time-header', 'rbc-overflowing')}
      >
        <div
          className="rbc-label rbc-time-header-gutter"
          style={{ width, minWidth: width, maxWidth: width }}
        >
          {TimeGutterHeader && <TimeGutterHeader />}
        </div>

        {resources.map(([id, resource], idx) => (
          <div className="rbc-time-header-content" key={id || idx}>
            {resource && (
              <div className="rbc-row rbc-row-resource">
                <div key={`resource_${get(resource, 'resourceId')}`} className={cn(css.TimeGridHeader, 'rbc-header')}>
                  <Media
                    element={(
                      <Thumbnail
                        title={accessors.resourceTitle(resource)}
                        src={get(resource, 'thumbnail')}
                      />
)}
                    body={(
                      <div>
                        <div>
                          { get(resource, 'url')
                            ? <Link href={get(resource, 'url')} target="_blank">{accessors.resourceTitle(resource)}</Link>
                            : accessors.resourceTitle(resource)}
                        </div>
                        <div className={css.TimeGridHeader__mutedText}>{get(resource, 'phone')}</div>
                      </div>
)}
                  />
                </div>
              </div>
            )}
            <div
              className={`rbc-row rbc-time-header-cell${
                range.length <= 1 ? ' rbc-time-header-cell-single-day' : ''
              }`}
            >
              {this.renderHeaderCells(range)}
            </div>
            <DateContentRow
              isAllDay
              rtl={rtl}
              getNow={getNow}
              minRows={2}
              range={range}
              events={groupedEvents.get(id) || []}
              resourceId={resource && id}
              className="rbc-allday-cell"
              selectable={selectable}
              selected={this.props.selected}
              components={components}
              accessors={accessors}
              getters={getters}
              localizer={localizer}
              onSelect={this.props.onSelectEvent}
              onDoubleClick={this.props.onDoubleClickEvent}
              onSelectSlot={this.props.onSelectSlot}
              longPressThreshold={this.props.longPressThreshold}
            />
          </div>
        ))}
      </div>
    );
  }
}
