import React from 'react';
import PropTypes from 'prop-types';
import AbstractOptions from '../AbstractOptions';

const Radio = ({
  expandWhenChecked,
  legend,
  legendHidden,
  onChange,
  options,
  optgroup,
  optionsWithGroup,
  value,
  viewType,
  boxView,
}) => (
  <AbstractOptions
    expandWhenChecked={expandWhenChecked}
    inputType="radio"
    legend={legend}
    legendHidden={legendHidden}
    onChange={onChange}
    options={options}
    optgroup={optgroup}
    optionsWithGroup={optionsWithGroup}
    value={value}
    viewType={viewType}
    boxView={boxView}
  />
);

export const actionPropTypes = PropTypes.shape({
  className: PropTypes.string,
  href: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
  type: PropTypes.string,
});

Radio.propTypes = {
  expandWhenChecked: PropTypes.bool,
  legend: PropTypes.string,
  legendHidden: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    secondaryContent: PropTypes.node,
    tertiaryAction: PropTypes.arrayOf(actionPropTypes),
    value: PropTypes.any,
  })),
  optgroup: PropTypes.bool,
  optionsWithGroup: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      secondaryContent: PropTypes.node,
      tertiaryAction: PropTypes.arrayOf(actionPropTypes),
      value: PropTypes.any,
    })),
  })),
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
    PropTypes.number,
  ]),
  viewType: PropTypes.oneOf(['horizontal', 'vertical']),
  boxView: PropTypes.bool,
};

Radio.defaultProps = {
  expandWhenChecked: false,
  legendHidden: false,
  legend: '',
  options: [],
  optgroup: false,
  optionsWithGroup: [],
  value: [],
  viewType: 'vertical',
  boxView: false,
};

export default Radio;
