import React from 'react';
import { onlyUpdateForKeys } from 'recompose';
import componentClassnames from './styles/Thumbnail.scss';
import classNames from 'classnames';

const Thumbnail = onlyUpdateForKeys(['src', 'title', 'profileRing'])(({ title, src, widthAuto, profileRing }) => (
    <div className={classNames([componentClassnames.Thumbnail], {
        [componentClassnames['Thumbnail--widthAuto']]: widthAuto,
        [componentClassnames[`Thumbnail__${profileRing ? profileRing.toLowerCase() : ''}--border`]]: Boolean(profileRing),
    })}>
        <div
            className={classNames([componentClassnames.Thumbnail__main])}
            title={title}
            style={{ backgroundImage: `url(" ${src} ")` }}>
            <div className={componentClassnames.Thumbnail__inner} />
        </div>
    </div>
    ));

export default Thumbnail;
