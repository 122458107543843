import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ContainerQuery } from 'react-container-query';
import classNames from 'classnames';
import remsToPixels from '../../../utils/remsToPixels';

const query = {
    minWidth: {
        minWidth: remsToPixels('28rem'),
    },
};

export default class BoxGrid extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = { };
    }

    getChildren(query) {
        const props = this.props;
        return React.Children.map(props.children, child => React.cloneElement(child, {
            query,
        }));
    }

    getClasses(params, styles) {
        const classes = classNames({
            [styles.box__grid]: true,
            [styles['box__grid--minWidthMet']]: params.minWidth,
            [styles['box__grid--minWidthNotMet']]: !params.minWidth,
        });
        return classes;
    }

    render() {
        const styles = this.context.boxStyles;
        return (
            <ContainerQuery query={query} style={{ position: 'static' }}>
                {params =>
                    (<div className={this.getClasses(params, styles)}>
                        {this.getChildren(params)}
                    </div>)
                }
            </ContainerQuery>
        );
    }
}

BoxGrid.propTypes = {

};

BoxGrid.defaultProps = {

};

BoxGrid.contextTypes = {
    boxStyles: PropTypes.object,
};
