import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import classes from './Heading.scss';

const Heading = ({
  tag,
  styleLevel,
  children,
  className,
}) => {
  const Tag = tag || styleLevel;
  return (
    <Tag className={
      classNames({
        [classes['Heading--h1']]: styleLevel === 'h1',
        [classes['Heading--h2']]: styleLevel === 'h2',
        [classes['Heading--h3']]: styleLevel === 'h3',
        [classes['Heading--h4']]: styleLevel === 'h4',
        [classes['Heading--h5']]: styleLevel === 'h5',
        [classes['Heading--h6']]: styleLevel === 'h6',
        [className]: (className),
      })
}
    >
      {children}
    </Tag>
  );
};

Heading.propTypes = {
  tag: PropTypes.oneOf(['', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span', 'div', 'small']),
  styleLevel: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Heading.defaultProps = {
  tag: '',
  styleLevel: 'h1',
  className: undefined,
};

export default Heading;
