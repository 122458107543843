import React from 'react';
import { omit } from 'lodash/fp';
import { compose, mapProps, defaultProps } from 'recompose';
import Tooltip from '../../../Tooltip';
import TooltipContainer from '../../../TooltipComponent';
import css from './styles.scss';
import classNames from 'classnames';

const iconGenerator = ({ children, containerProps, ...props }) => ({
    ...props,
    children: [
        <button className={classNames(
          css.InfoIcon__btn,
          { [css.InfoIcon__btn__indigo] : props.color === 'indigo'},
          { [css.InfoIcon__btn__default] : !props.color || props.color === 'default' }
        )} />,
        <TooltipContainer {...containerProps}>{children}</TooltipContainer>,
    ],
});

const omitKeys = omit(['className', 'styles']);

const enhancer = compose(
    mapProps(iconGenerator),
    mapProps(omitKeys),
    defaultProps({ position: 'top', showOnEvent: 'hover', containerProps: {}, color: 'default' }),
);

const InfoIcon = enhancer(Tooltip);
export default InfoIcon;
