import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../../Icon';
import StringToTitleCase from './Button/StringToTitleCase.js';
import css from './Button/styles/BorderedButton.scss';
import slugifyText from '../../utils/slugifyText';

export default class BorderedButton extends React.Component {
    constructor(props) {
        super(props);
    }

    isRightBorderRadiusDisabled() {
        if (!this.props.isInGroup) {
            return false;
        }
        return (!(this.props.lastInGroup));
    }

    isLeftBorderRadiusDisabled() {
        if (!this.props.isInGroup) {
            return false;
        }
        return (!(this.props.firstInGroup));
    }

    isRightBorderDisabled() {
        if (!this.props.isInGroup) {
            return false;
        }
        return (!(this.props.lastInGroup));
    }

    render() {
        const {
            id,
            title,
            type,
            href,
            target,
            icon,
            iconColor,
            iconSize,
            disabled,
            applyFixedHeight,
            size,
            active,
            block,
            vAlign,
            dataTestid,
            minWidth,
            disableTitleCasing,
        } = this.props;

        const classes = classNames([css.Button], {
            [this.props.className]: this.props.className,
            [css['Button--isActive']]: active,
            [css[`Button--${type}`]]: type,
            [css['Button--sm']]: this.context.isInButtonBar || size === 'sm',
            [css['Button--xs']]: size === 'xs',
            [css['Button--block']]: block,
            [css['Button--rightBorderRadiusDisabled']]: this.isRightBorderRadiusDisabled(),
            [css['Button--leftBorderRadiusDisabled']]: this.isLeftBorderRadiusDisabled(),
            [css['Button--rightBorderDisabled']]: this.isRightBorderDisabled(),
            [css['Button--hasFixedHeight']]: applyFixedHeight,
        });

        let label = this.props.label;
        let testId = dataTestid || label;
        if (!label) {
          label = this.props.children;
        }
        if(!testId) {
          testId = slugifyText([id, icon, type, size].join(' '));
        }

        const ButtonTag = `${(href.length ? 'a' : 'button')}`;

        return (
            <ButtonTag
                id={id}
                type="button"
                className={classes}
                onClick={this.props.onClick ? this.props.onClick : null}
                disabled={disabled ? 'disabled' : ''}
                title={title}
                href={href}
                target={target}
                data-testid={testId}
                style={minWidth && {minWidth: minWidth}} >
                {icon
                    && <span
                        className={classNames({
                            [css['Button__Icon']]: true,
                            [css['Button_Icon--label']]: (label || '').length,
                            [css[`Button--${type}--icon`]]: type,
                        })}
                        style={iconColor && {color: iconColor}}
                    >
                        <Icon
                            block
                            size={[
                                !label && iconSize,
                                size === 'lg' && 22,
                                size === 'sm' && 18,
                                size === 'xs' && 16,
                                18,
                            ]?.find(i => i)}
                            name={`${icon}`} />
                    </span>
                }

                <span className={classNames({
                    [css['Button__txt--vAlignBaseline']]: vAlign === 'baseline',
                    [css['Button__txt--vAlignMiddle']]: vAlign === 'middle',
                })}>{!disableTitleCasing ? StringToTitleCase(label) : label }</span>
            </ButtonTag>
        );
    }
}

BorderedButton.propTypes = {
    id: PropTypes.string,
    active: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    type: PropTypes.oneOf(['primary', 'secondary', 'warning', 'gray10', 'fade']),
    onClick: PropTypes.func,
    className: PropTypes.string,
    block: PropTypes.bool,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    iconSize: PropTypes.number,
    href: PropTypes.string,
    isInGroup: PropTypes.bool,
    firstInGroup: PropTypes.bool,
    lastInGroup: PropTypes.bool,
    disableTitleCasing: PropTypes.bool,
    target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
    vAlign: PropTypes.oneOf(['baseline', 'middle']),
    applyFixedHeight: PropTypes.bool,
    size: PropTypes.oneOf(['lg', 'sm', 'xs']),
    minWidth: PropTypes.number,
    dataTestid: PropTypes.string,
};

BorderedButton.defaultProps = {
    id: undefined,
    active: false,
    label: '',
    type: 'secondary',
    onClick: undefined,
    className: '',
    block: false,
    disabled: false,
    title: '',
    icon: '',
    iconColor: undefined,
    iconSize: undefined,
    href: '',
    isInGroup: false,
    firstInGroup: false,
    lastInGroup: false,
    disableTitleCasing: false,
    target: '_self',
    vAlign: 'baseline',
    applyFixedHeight: false,
    size: 'lg',
    minWidth: undefined,
    dataTestid: undefined,
};

BorderedButton.contextTypes = {
    isInButtonBar: PropTypes.bool,
};
