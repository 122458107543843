import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import styles from './CloseButton/styles/CloseButton.scss';

const CloseButton = (props) => (
  <button
    data-testid={props['data-testid'] || 'close-button'}
    styleName="closeButton"
    onClick={props.onClick}
  >
    <span className="u-srOnly">Close</span>
  </button>
);

CloseButton.propTypes = {
    onClick: PropTypes.func,
};

CloseButton.defaultProps = {
    onClick: () => {},
};

export default CSSModules(CloseButton, styles);
