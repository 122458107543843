import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import css from './styles.scss';

const MedianAlpha = ({
  alignRight,
  breakWords,
  children,
  verticalAlign,
  verticalFlush,
  overflowHidden,
}) => (
  <div className={classNames(
    [
      css.MedianAlpha,
    ],
    {
      [css['MedianAlpha--topAlign']]: verticalAlign === 'top',
      [css['MedianAlpha--bottomAlign']]: verticalAlign === 'bottom',
      [css['MedianAlpha--alignRight']]: alignRight,
      [css['MedianAlpha--breakWords']]: breakWords,
      [css['MedianAlpha--verticalFlush']]: verticalFlush,
      [css['MedianAlpha--overflowHidden']]: overflowHidden,
    },
  )}
  >
    {children}
  </div>
);

MedianAlpha.propTypes = {
  alignRight: PropTypes.bool,
  breakWords: PropTypes.bool,
  children: PropTypes.node,
  verticalAlign: PropTypes.oneOf(['top', 'bottom', 'middle']),
  verticalFlush: PropTypes.bool,
  overflowHidden: PropTypes.bool,
};

MedianAlpha.defaultProps = {
  alignRight: false,
  breakWords: false,
  verticalAlign: 'middle',
  children: false,
  verticalFlush: false,
  overflowHidden: false,
};

export default MedianAlpha;
