import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import classes from './styles.scss';
import isSticky from './isSticky';
import getStickyPosition from './getStickyPosition';
import { checkChildren, checkProps } from '../utils/comparison';

export default class TableCell extends Component {
  shouldComponentUpdate(nextProps) {
    const {
      children: currentChildren,
      ...restCurrentProps
    } = this.props;

    const {
      children: nextChildren,
      ...restNextProps
    } = nextProps;

    // check if children and props are equal
    if (checkChildren(currentChildren, nextChildren)
      && checkProps(restCurrentProps, restNextProps)) {
      return false;
    }
    return true;
  }

  render() {
    const {
      id,
      children,
      width,
      isNotFirstRow,
      highlightColor,
      sticky,
      applyDistinctBg,
      isLastColumn,
      lastColumnWidth,
      expandableWidth,
      rowHeight,
      applyBorderRight,
      contentAlign,
      isConfig,
      isOverflow,
    } = this.props;

    return (
      <td
        className={classNames([classes.TableCell], {
          [classes['TableCell--isNotFirstRow']]: isNotFirstRow,
          [classes['TableCell--info']]: highlightColor === 'info',
          [classes['TableCell--warning']]: highlightColor === 'warning',
          [classes['TableCell--danger']]: highlightColor === 'danger',
          [classes['TableCell--success']]: highlightColor === 'success',
          [classes['TableCell--nuetral']]: highlightColor === 'nuetral' || highlightColor === 'neutral',
          [classes['TableCell--selected']]: highlightColor === 'selected',
          [classes['TableCell--sticky']]: isSticky(sticky),
          [classes['TableCell--config']]: isConfig && !isOverflow,
          [classes['TableCell--stickyBorderLeft']]: isSticky(sticky) && sticky.border === 'left',
          [classes['TableCell--stickyBorderRight']]: isSticky(sticky) && sticky.border === 'right',
          [classes['TableCell--distinctBg']]: applyDistinctBg,
          [classes['TableCell--rowHeight1']]: rowHeight === '1',
          [classes['TableCell--rowHeight2']]: rowHeight === '2',
          [classes['TableCell--rowHeight3']]: rowHeight === '3',
          [classes['TableCell--borderRight']]: applyBorderRight,
          [classes['TableCell--alignCenter']]: contentAlign === 'center',
        })}
        style={{
          ...getStickyPosition(sticky),
          ...{ width: `${isLastColumn && expandableWidth ? lastColumnWidth : width}px` },
        }}
      >
        <div
          className={classNames({
            [classes.TableCell__outer]: true,
            [classes['TableCell__outer--horizontalSm']]: id === 'inline_actions',
          })}
        >
          <div
            className={classes.TableCell__inner}
            style={{
              width: `${isLastColumn && expandableWidth ? lastColumnWidth : width}px`,
              ...id === 'inline_actions' && { textAlign: 'center' },
            }}
          >
            {children}
          </div>
        </div>
      </td>
    );
  }
}

TableCell.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  highlightColor: PropTypes.oneOf([
    '',
    'info',
    'warning',
    'danger',
    'nuetral',
    'neutral',
    'success',
    'selected',
  ]),
  isNotFirstRow: PropTypes.bool,
  sticky: PropTypes.shape({
    side: PropTypes.oneOf(['left', 'right']),
    offset: PropTypes.number,
    border: PropTypes.oneOf(['left', 'right', 'none']),
  }),
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
    PropTypes.string,
  ]),
  applyDistinctBg: PropTypes.bool,
  isLastColumn: PropTypes.bool,
  lastColumnWidth: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
    PropTypes.string,
  ]),
  expandableWidth: PropTypes.number,
  rowHeight: PropTypes.oneOf(['auto', '2']),
  applyBorderRight: PropTypes.bool,
  contentAlign: PropTypes.string,
  isConfig: PropTypes.bool,
  isOverflow: PropTypes.bool,
};

TableCell.defaultProps = {
  id: '',
  children: null,
  highlightColor: '',
  isNotFirstRow: false,
  sticky: undefined,
  width: false,
  applyDistinctBg: false,
  isLastColumn: false,
  lastColumnWidth: 0,
  expandableWidth: 0,
  rowHeight: '2',
  applyBorderRight: false,
  contentAlign: 'left',
  isConfig: false,
  isOverflow: true,
};

TableCell.displayName = 'TableCell';
