
import React, { Component } from 'react';
import ReactNotificationSystem from 'react-notification-system';

export default class NotificationSystem extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.notificationSystem) {
            this._notificationSystem = this.notificationSystem;
        }
    }

    addNotification(params) {
        if (this._notificationSystem) {
            return this._notificationSystem.addNotification(params);
        }
    }

    render() {
        return (
            <ReactNotificationSystem style={false} ref={notificationSystem => { this.notificationSystem = notificationSystem; }} />
        );
    }
}
