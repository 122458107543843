import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import componentClassnames from './styles.scss';
import LoaderIndicator from '../LoaderIndicator';

const Loader = (props) => {
  const {
    isLoading,
    fixed,
    display,
    children,
    fillParent,
  } = props;
  const loaderClasses = classNames({
    [componentClassnames['Loader--fill']]: fillParent,
    [componentClassnames.Loader]: isLoading,
    [componentClassnames['Loader--fixed']]: fixed,
  });
  const loaderStyles = {};
  if (display === 'inline-block') {
    loaderStyles.display = 'inline-block';
  }
  return (
    <div data-testid="Loader" className={loaderClasses} style={loaderStyles}>
      <div
        data-testid="Loader-backdrop"
        className={
          isLoading ? componentClassnames.Loader__backdrop : ''
        }
      />
      <div
        data-testid="Loader-wrapper"
        className={
          isLoading ? componentClassnames.Loader__wrapper : ''
        }
      >
        {isLoading && <LoaderIndicator {...props} />}
      </div>
      <div className={componentClassnames.Loader__content}>
        {children}
      </div>
    </div>
  );
};

export default Loader;

Loader.propTypes = {
  children: PropTypes.node,
  display: PropTypes.oneOf(['block', 'inline-block']),
  fillParent: PropTypes.bool,
  fixed: PropTypes.bool,
  isLoading: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md']),
};

Loader.defaultProps = {
  children: null,
  display: 'block',
  fillParent: false,
  fixed: false,
  isLoading: true,
  size: 'md',
};
