import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import WrapOptions from '../WrapOptions';
import slugifyText from '../utils/slugifyText';
import Option from '../Option';
import Legend from '../Legend';

const onChangeHandler = (evt, groupValue, optionValue, type, onChange) => {
  const { target } = evt;
  const { checked: isChecked } = target;
  if (isChecked && type === 'radio') {
    onChange(((typeof groupValue === 'string' || typeof groupValue === 'number') ? optionValue : [optionValue]));
  } else if (isChecked) {
    onChange([...groupValue, optionValue]);
  } else {
    onChange(groupValue.filter((item) => item !== optionValue));
  }
};

const getLabelText = (children) => {
  let textSlug = '';
  React.Children.forEach(children, (child) => {
    const typeName = get(child, 'type.name');
    if (typeName === 'Legend') {
      const childContent = child.props.children;
      if (typeof childContent === 'string') {
        textSlug = slugifyText(childContent);
      }
    }
  });
  return textSlug;
};

const isChecked = (value, optionValue, inputType) => {
  // will support string & numbers values only for Radio inputs
  if (inputType === 'radio' && (typeof value === 'string' || typeof value === 'number')) {
    return value === optionValue;
  }
  return value.includes(optionValue);
};

const getChildren = (children, inputType, viewType, onChange, value, expandWhenChecked, boxView) => {
  const options = [];
  const legends = [];
  const optgroups = [];
  React.Children.map(children, (child) => {
    const childType = get(child, 'type');
    if (childType === Option) {
      const optionValue = child.props.value;
      const option = React.cloneElement(
        child,
        {
          key: `option-${child.props.value}`,
          type: inputType,
          checked: isChecked(value, optionValue, inputType),
          expandWhenChecked,
          secondaryContent: viewType === 'horizontal' ? null : child.props.secondaryContent,
          onChange: (evt) => { onChangeHandler(evt, value, optionValue, inputType, onChange); },
          boxView,
        },
      );
      if (get(child, 'props.optgroup', false)) {
        optgroups.push(option);
      } else {
        options.push(option);
      }
    }
    if (childType === Legend) {
      const legend = React.cloneElement(
        child,
        { key: child.key },
      );
      if (get(child, 'props.optgroup', false)) {
        optgroups.push(legend);
      } else {
        legends.push(legend);
      }
    }

    return null;
  });

  if (optgroups.length) {
    return optgroups;
  }

  const anySecondaryContent = Boolean(viewType === 'horizontal' ? false : options.filter((option) => option.props.secondaryContent).length);

  let finalOptions = [];
  // bold treatment needs to be applied after first loop through
  if (anySecondaryContent && !expandWhenChecked) {
    finalOptions = options.map((item) => (
      React.cloneElement(item, { labelBold: true })
    ));
  } else {
    finalOptions = [...options];
  }

  if (legends.length || finalOptions.length) {
    return [legends, WrapOptions(finalOptions, viewType, boxView)];
  }
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.warn('OptionsGroup requires a Legend/Option');
  }
  return (
    <div />
  );
};

const OptionsGroup = ({
  children,
  inputType,
  viewType,
  onChange,
  value,
  expandWhenChecked,
  boxView,
}) => (
  <fieldset data-testid={getLabelText(children)}>
    {getChildren(children, inputType, viewType, onChange, value, expandWhenChecked, boxView)}
  </fieldset>
);

OptionsGroup.propTypes = {
  children: PropTypes.node,
  inputType: PropTypes.oneOf(['radio', 'checkbox']).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    PropTypes.string,
    PropTypes.number,
  ]),
  viewType: PropTypes.oneOf(['horizontal', 'vertical']),
  onChange: PropTypes.func,
  expandWhenChecked: PropTypes.bool,
  boxView: PropTypes.bool,
};

OptionsGroup.defaultProps = {
  expandWhenChecked: false,
  onChange: () => {},
  viewType: 'vertical',
  value: [],
  children: null,
  boxView: false,
};

export default OptionsGroup;
