import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classes from './styles.scss';

class TableHead extends Component {
  constructor(props) {
    super(props);
    this.thead = React.createRef();
    this.onScroll = this.onScroll.bind(this);
  }

  onScroll(evt) {
    const { onScroll } = this.props;
    if (onScroll) {
      onScroll(evt);
    }
  }

  render() {
    const { children, offset } = this.props;
    return (
      <thead
        className={classes.TableHead}
        style={{ top: (typeof offset === 'string') ? offset : `${offset}rem` }}
        onScroll={this.onScroll}
        ref={this.thead}
      >
        {children}
      </thead>
    );
  }
}

TableHead.propTypes = {
  offset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node,
  onScroll: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

TableHead.defaultProps = {
  offset: 0,
  children: null,
  onScroll: false,
};

TableHead.displayName = 'TableHead';

export default TableHead;
