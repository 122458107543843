import React from 'react';

import cn from 'classnames';
import BaseTimeGrid from 'react-big-calendar/lib/TimeGrid';
import TimeGutter from 'react-big-calendar/lib/TimeGutter';
import { inRange, sortEvents } from 'react-big-calendar/lib/utils/eventLevels';
import dates from 'react-big-calendar/lib/utils/dates';
import Resources from 'react-big-calendar/lib/utils/Resources';
import TimeGridHeader from './TimeGridHeader';

export default class TimeGrid extends BaseTimeGrid {
  handleScroll = (e) => {
    if (this.scrollRef) {
      this.scrollRef.scrollLeft = e.target.scrollLeft;
    }
  }

  setScrollRef = (el) => {
    this.scrollRef = el;
  }

  render() {
    const {
      events,
      range,
      selected,
      getNow,
      resources,
      components,
      accessors,
      getters,
      localizer,
      min,
      max,
      showMultiDayTimes,
      longPressThreshold,
      className,
    } = this.props;

    let {
      width,
    } = this.props;

    width = width || this.state.gutterWidth;

    const start = range[0];
    const end = range[range.length - 1];

    this.slots = range.length;

    const allDayEvents = [];
    const rangeEvents = [];

    events.forEach((event) => {
      if (inRange(event, start, end, accessors)) {
        const eStart = accessors.start(event);
        const eEnd = accessors.end(event);

        if (
          accessors.allDay(event)
          || (dates.isJustDate(eStart) && dates.isJustDate(eEnd))
          || (!showMultiDayTimes && !dates.eq(eStart, eEnd, 'day'))
        ) {
          allDayEvents.push(event);
        } else {
          rangeEvents.push(event);
        }
      }
    });

    allDayEvents.sort((a, b) => sortEvents(a, b, accessors));

    return (
      <div
        className={cn('rbc-time-view', resources && 'rbc-time-view-resources', className)}
      >
        <TimeGridHeader
          range={range}
          events={allDayEvents}
          width={width}
          getNow={getNow}
          localizer={localizer}
          selected={selected}
          resources={Resources(this.props.resources, this.props.accessors)}
          selectable={this.props.selectable}
          accessors={accessors}
          getters={getters}
          components={components}
          scrollRef={this.setScrollRef}
          isOverflowing={this.state.isOverflowing}
          longPressThreshold={longPressThreshold}
          onSelectSlot={this.handleSelectAllDaySlot}
          onSelectEvent={this.handleSelectAlldayEvent}
          onDoubleClickEvent={this.props.onDoubleClickEvent}
          onDrillDown={this.props.onDrillDown}
          getDrilldownView={this.props.getDrilldownView}
        />
        <div
          ref="content" // eslint-disable-line react/no-string-refs
          className="rbc-time-content"
          onScroll={this.handleScroll}
        >
          <TimeGutter
            date={start}
            ref={this.gutterRef}
            localizer={localizer}
            min={dates.merge(start, min)}
            max={dates.merge(start, max)}
            step={this.props.step}
            getNow={this.props.getNow}
            timeslots={this.props.timeslots}
            components={components}
            className="rbc-time-gutter"
          />
          {this.renderEvents(range, rangeEvents, getNow())}
        </div>
      </div>
    );
  }
}
