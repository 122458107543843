import { selector } from 'recoil';
import propsState from './props.state';

export interface FilterOptionProps {
  label: string;
  value: string;
}

export interface FilterProps {
  id: string;
  name: string;
  type: 'multi-select' | 'boolean' | 'date' | 'date-time' | 'typeahead' | 'slider' | 'date-range' | 'advanced-date-range' | 'phone' | 'text' | 'number';
  pattern?: RegExp;
  placeholder?: string;
  section?: number;
  options?: FilterOptionProps[];
  getLazyLoadOptions?: () => FilterOptionProps[] | Promise<FilterOptionProps[]>;
  value?: string | number | string[] | number[];
}

const availableFiltersQuery = selector<FilterProps[]>({
  key: 'availableFilters',
  get: ({ get }) => {
    const props = get(propsState);

    if (!props?.filters) {
      return [];
    }

    return props.filters.map((filter) => ({
      id: filter.id,
      name: filter.name || filter.id,
      type: filter.type || 'text',
      pattern: filter.pattern,
      placeholder: filter.placeholder,
      section: filter.section,
      options: filter.options,
      getLazyLoadOptions: filter.getLazyLoadOptions,
    }));
  },
});

export default availableFiltersQuery;
