import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classes from './MessageContent.scss';
import Truncate from '../../Truncate';
import Icon from '../../Icon';
import fnIcon from './fn_icon.png';
import { getFileIconNameByExtension } from '../../utils/getAttachmentIconName';

const ACCEPTED_ATTACHMENT_IMG_TYPE_EXT = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'];
const FN_SUPPORT_NAME = 'Field Nation Support';

const MessageContent = ({ thread }) => {
  const getDateTime = (date = '') => {
    if (date === '') return '';
    const mom = moment.utc(date).local();
    const today = moment();
    const yesterday = moment().subtract(1, 'day');
    if (mom.isSame(today, 'day')) {
      return mom.format('h:mm A');
    }
    if (mom.isSame(yesterday, 'day')) {
      return 'YESTERDAY';
    }
    return mom.format('MMM D');
  };

  const getFileType = (fileName) => {
    const extension = fileName?.split('.')?.pop()?.toLowerCase() || '';
    if (ACCEPTED_ATTACHMENT_IMG_TYPE_EXT.includes(extension)) {
      return 'image';
    }

    return extension;
  };

  // eslint-disable-next-line react/no-danger
  const message = <div className={classes.MessageContent} dangerouslySetInnerHTML={{ __html: thread?.message }} />;
  return (
    <div className={classes.TotalContent}>
      <div className={classes.ThumbnailArea}>
        <div className={classes.User}>
          <div
            className={classes.Thumb}
            style={{
              backgroundImage: `url(${thread?.from?.name !== FN_SUPPORT_NAME ? thread?.from?.thumbnail : fnIcon})`,
            }}
          />
        </div>
      </div>
      <div className={classes.MessageArea}>
        <div className={classes.UpporRow}>
          <div className={classes.Title}>
            <Truncate
              maxLines={1}
              text={thread?.from?.name ?? ''}
            />
          </div>
          <div className={classes.Timestamp}>
            {getDateTime(thread?.created?.utc)}
          </div>
        </div>
        <div className={`${classes.ContentItemText}`}>
          {message}
        </div>
        {!!thread?.attachments?.length && (
          <div className={classes.AttachmentWrapper}>
            {thread.attachments.map((attachment) => {
              const fileName = `${attachment.name}.${attachment.extension}`;
              return (
                <a
                  download={fileName}
                  href={attachment.downloadUrl}
                  className={[classes.AttachmentContainer]}
                >
                  {getFileType(fileName) === 'image' ? (
                    <img src={attachment.downloadUrl} alt="" className={classes.AttachedImage} />
                  ) : (
                    <span
                      className={`${
                        classes.Attachment_Icon
                      } Attachment_Icon--${getFileIconNameByExtension(attachment.extension)}`}
                    >
                      <Icon
                        size={24}
                        name={getFileIconNameByExtension(attachment.extension)}
                      />
                    </span>
                  )}
                  <p className={getFileType(fileName) === 'image' ? classes.Attachment_Name : classes.Icon_Name}>
                    {fileName.length > 20 ? fileName.substring(0, 20) : fileName}
                  </p>
                </a>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

MessageContent.propTypes = {
  thread: PropTypes.object,
};

MessageContent.defaultProps = {
  thread: {},
};

export default MessageContent;
