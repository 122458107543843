import React from 'react';
import Button from '../legacy/Construction/Button';

export default function getAcceptableButtonChildren(children) {
  return React.Children.map(children, (child) => {
    if (child && child.type === Button) {
      return child;
    }

    return null;
  });
}
