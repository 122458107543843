import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from '../HorizontalList/styles.scss';
/* eslint-disable react/prefer-stateless-function */
class HorizontalListItem extends Component {
  render() {
    const {
      flexGrow,
      children,
      boxView,
    } = this.props;
    return (
      <li
        className={classNames([css.hList__item], {
          [css['hList__item--flexGrow']]: flexGrow,
          [css['hList__item--boxView']]: boxView,
        })}
      >
        {children}
      </li>
    );
  }
}
/* eslint-enable react/prefer-stateless-function */

export default HorizontalListItem;

HorizontalListItem.propTypes = {
  children: PropTypes.node.isRequired,
  flexGrow: PropTypes.bool,
  boxView: PropTypes.bool,
};

HorizontalListItem.defaultProps = {
  flexGrow: false,
  boxView: false,
};
