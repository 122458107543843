/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import moment from 'moment';
import isObject from 'lodash/isObject';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { v4 } from 'uuid';
import classNames from 'classnames';
import {
  formatISO,
  format,
  isValid,
  parseISO,
  startOfDay,
  startOfToday,
  endOfDay,
  endOfToday,
  parse,
} from 'date-fns';
import css from './FilterEditPanel.scss';
import Search from '../Search';
import Checkbox from '../v2/Checkbox';
import { filterEditButtonsAttrPropTypes, filterPropTypes } from './ToolbarPropTypes';
import Radio from '../v2/Radio';
import TextInput from '../v2/TextInput';
import DatePicker from '../legacy/Construction/Field/DatePicker/DatePicker';
import PhoneInput from '../v2/PhoneInput';
import ActionBar from '../ActionBar';
import Loader from '../Loader';
import HelpText from '../legacy/Construction/Field/HelpText/HelpText';
import Chip from '../Chip';
import NumericRange from './Filters/NumericRange';
import Select from '../v2/Select';
import advancedDateRangeList from './AdvancedDateRanges';
import buildDateFns from '../utils/buildDateFns';
import wrapEventHandler, { ARG_ORDER } from '../utils/wrapEventHandler';
import noop from '../utils/noop';

const SHOW_SEARCH_THRESHOLD = 8;

class FilterEditPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      filter: props.filter,
      disableApply: false,
      dateRangeStartDate: {},
      dateRangeEndDate: {},
      typeaheadLoading: false,
      advancedDateRangeType: props.filter?.value?.advancedDateRangeType || 'custom',
    };

    /*
      Though React documentation encourages to name the variable '_isMounted',
      it shows warning. This is a bug in checking for the deprecated isMounted()
      method.
    */
    this.isPlacedtoDOM = false;

    this.onClearButton = this.onClearButton.bind(this);
    this.handleTypeaheadValueClick = this.handleTypeaheadValueClick.bind(this);
    this.removeTypeaheadValue = this.removeTypeaheadValue.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  componentDidMount() {
    this.isPlacedtoDOM = true;
    const { filter } = this.state;
    const listType = get(filter, 'listType', 'default');
    if (this.isPlacedtoDOM) {
      const { dateRangeStartDate, dateRangeEndDate } = this.state;

      if (filter.type === 'date-range') {
        this.setState({
          filter: {
            ...filter,
            value: {
              ...filter.value,
              startDate: filter.value ? filter.value.startDate : moment().format('YYYY-MM-DD'), // Setting default value to current date.
              endDate: filter.value ? filter.value.endDate : moment().format('YYYY-MM-DD'),
            },
          },
          dateRangeStartDate: {
            ...dateRangeStartDate,
            [filter.id]: new Date(filter.value ? filter.value.startDate : moment().format('YYYY-MM-DD')),
          },
          dateRangeEndDate: {
            ...dateRangeEndDate,
            [filter.id]: new Date(filter.value ? filter.value.endDate : moment().format('YYYY-MM-DD')),
          },
        });
      }

      if (
        filter?.type === 'typeahead'
        && filter?.id === 'location_ids'
        && filter?.value?.length === 1
        && !filter?.value?.[0]?.label
        && filter?.options?.length
      ) {
        const values = {
          value: filter?.value?.[0]?.value,
          label: filter?.options?.find?.((op) => Number(op?.value) === Number(filter?.value?.[0]?.value))?.label || '',
        };

        this.setState({
          filter: {
            ...filter,
            value: [values],
          },
        });
      }

      if (filter.type === 'advanced-date-range') {
        const advancedDateRangeType = filter.value?.advancedDateRangeType ?? 'custom';
        const dateRange = (advancedDateRangeList[listType]
          .find(({ value }) => value === advancedDateRangeType) || {});

        if (Object.keys(dateRange).length) {
          this.setAdvancedDateRangeType({
            filter,
            dateRange,
            advancedDateRangeType,
            startDate: filter.value?.startDate || formatISO(startOfToday()),
            endDate: filter.value?.endDate || formatISO(endOfToday()),
            dateRangeStartDate,
            dateRangeEndDate,
          });
        }
      }
    }
    const inputSection = document.getElementById('filter-options-input-section');
    if (inputSection && ['date', 'date-time', 'date-range', 'phone', 'numeric-range'].includes(filter.type)) {
      this.applyFocusFirstInput(inputSection.childNodes || []);
    }
  }

  componentWillUnmount() {
    this.isPlacedtoDOM = false;
  }

  // eslint-disable-next-line react/sort-comp
  onClearButton(filter, callbackFn) {
    const clearValue = (type, value) => {
      if (type === 'slider') {
        return {};
      }
      if (Array.isArray(value)) {
        return [];
      }
      if (type === 'date' || type === 'date-range') {
        return null;
      }
      if (type === 'advanced-date-range') {
        return {
          advancedDateRangeType: 'custom',
          startDate: null,
          endDate: null,
        };
      }
      return '';
    };
    this.setState({
      search: '',
      ...(filter.type ? { advancedDateRangeType: 'custom' } : {}),
      filter: {
        ...filter,
        value: clearValue(filter.type, filter.value),
      },
    }, callbackFn);
  }

  applyFocusFirstInput(nodes) {
    if (this.focused) {
      return;
    }

    for (const node of nodes) { // eslint-disable-line
      if (node.tagName === 'INPUT') {
        node.focus();
        this.focused = true;
        break;
      }
      if (!this.focused && node.childNodes) {
        this.applyFocusFirstInput(node.childNodes);
      }
    }
  }

  shouldActionDisabled(filter, type = null) {
    const { value, type: filterType } = filter;
    const { disableApply } = this.state;

    if (!value) {
      return true;
    }

    if (Array.isArray(value) || (typeof value === 'string')) {
      if (!value.length) {
        return true;
      }
    }

    if (filterType === 'boolean-multi-select' && (
      !value.boolean || !value.multiSelect || value.multiSelect.length === 0
    )) {
      return true;
    }

    if (filterType === 'boolean-number'
      && type === 'apply'
      && (disableApply || !value.boolean || !value.number)
    ) {
      return true;
    }

    if (filterType === 'advanced-date-range' && (
      value.advancedDateRangeType === 'custom' && (value.endDate === null || value.startDate === null)
    )) {
      return true;
    }
    // because we're already checking the array case above, we're safe here.
    if (isObject(value)) {
      const keys = Object.keys(value);

      if (!keys.length) {
        return true;
      }

      return !keys.some((key) => Boolean(value[key]));
    }

    if (disableApply && type === 'apply') {
      return true;
    }

    return false;
  }

  getTypeaheadResults = debounce(async (value) => {
    const { filter } = this.state;
    if (!value) {
      this.setState({ filter: { ...filter, results: [] } });
      return;
    }
    // The updateId is a safeguard against having stale requests updating our state.
    // For example, if you kick off a request and then begin typing again and send a new request,
    // we only want our results to be updated with the most recent request.
    const updateId = v4();
    this.setState({ typeaheadLoading: true, onUpdateCallId: updateId });
    if (typeof filter.onUpdate !== 'undefined') {
      const results = await filter.onUpdate(value);
      const { onUpdateCallId } = this.state;
      if (updateId === onUpdateCallId) {
        // need most recent version of the filter so we don't use a stale filter object
        const { filter: currentFilter } = this.state;
        this.setState({ filter: { ...currentFilter, results }, typeaheadLoading: false });
      }
    }
  }, 500);

  handleTypeaheadValueClick(value) {
    const { filter: { value: existingValue }, filter } = this.state;
    const newSelectedValues = Array.isArray(existingValue) ? [...existingValue, value] : [value];
    this.setState({
      filter: {
        ...filter,
        value: newSelectedValues,
        searchValue: '',
        results: null,
      },
    });
  }

  removeTypeaheadValue(v) {
    const { filter } = this.state;
    this.setState({
      filter: {
        ...filter,
        value: filter.value.filter((s) => s !== v),
      },
    });
  }

  handleFilterChange(filterType, args, eventOrvalue) {
    const { filter, dateRangeEndDate, dateRangeStartDate } = this.state;
    let computedState;
    let cbFn = noop;
    let computedValue;

    switch (filterType) {
      case 'typeahead':
        computedValue = eventOrvalue?.target?.value || '';
        computedState = {
          filter: {
            ...filter,
            searchValue: computedValue,
          },
        };
        cbFn = () => this.getTypeaheadResults(computedValue);
        break;
      case 'multi-select':
        computedState = { filter: { ...filter, value: eventOrvalue } };
        break;
      case 'boolean':
        computedValue = Array.isArray(eventOrvalue) ? eventOrvalue[0] : eventOrvalue;
        computedState = {
          filter: {
            ...filter,
            value: computedValue,
          },
        };
        break;
      case 'boolean-number':
      case 'boolean-multi-select':
        computedValue = Array.isArray(eventOrvalue) ? eventOrvalue[0] : eventOrvalue;
        computedState = {
          filter: {
            ...filter,
            value: {
              ...filter.value,
              boolean: computedValue,
            },
          },
        };
        break;
      case 'boolean-number-range':
        computedValue = Array.isArray(eventOrvalue) ? eventOrvalue[0] : eventOrvalue;
        computedState = {
          filter: {
            ...filter,
            value: {
              ...filter.value,
              ...typeof eventOrvalue === 'object' && !Array.isArray(eventOrvalue) ? {
                ...eventOrvalue,
              } : {
                boolean: computedValue,
              },
            },
          },
        };
        break;

      case 'boolean-number-text':
        computedValue = eventOrvalue?.target?.value || '';
        if ((filter.pattern && !computedValue.match(new RegExp(filter.pattern, 'gi')))
            || (filter.maximum && computedValue > filter.maximum)
            || (filter.minimum && computedValue < filter.minimum)
        ) {
          this.setState({ disableApply: true });
        } else {
          this.setState({ disableApply: false });
        }
        computedState = {
          filter: {
            ...filter,
            value: {
              ...filter.value,
              number: computedValue,
            },
          },
        };
        break;
      case 'boolean-multi-select-text':
        computedState = {
          filter:
            {
              ...filter,
              value: {
                ...filter.value,
                multiSelect: eventOrvalue,
              },
            },
        };
        break;
      case 'text':
      case 'number':
        if (filter.pattern && !eventOrvalue.target.value.match(new RegExp(filter.pattern, 'gi'))) {
          this.setState({ disableApply: true });
        } else {
          this.setState({ disableApply: false });
        }
        computedState = {
          filter: {
            ...filter,
            value: eventOrvalue.target.value,
          },
        };
        break;
      case 'numeric-range':
        computedState = {
          filter: {
            ...filter,
            value: eventOrvalue,
          },
        };
        break;
      case 'phone':
        // eslint-disable-next-line prefer-rest-params
        if (arguments[3].type && arguments[3].type === 'error') {
          this.setState({ disableApply: true });
        } else {
          this.setState({ disableApply: false });
        }
        this.setState({ filter: { ...filter, value: eventOrvalue.target.value } });
        break;
      case 'slider':
        computedState = {
          filter: {
            ...filter,
            value: {
              ...filter.value,
              [args.name]: eventOrvalue,
            },
            valueWithLabel: {
              ...filter.valueWithLabel,
              [args.label]: eventOrvalue,
            },
          },
        };
        break;
      case 'date':
      case 'date-time':
        computedState = {
          filter: {
            ...filter,
            value: eventOrvalue,
          },
        };
        break;
      case 'date-range-start':
        computedState = {
          filter: { ...filter, value: { ...filter.value, startDate: eventOrvalue } },
          dateRangeStartDate: {
            ...dateRangeStartDate,
            [filter.id]: new Date(eventOrvalue),
          },
        };
        break;
      case 'date-range-end':
        computedState = {
          filter: { ...filter, value: { ...filter.value, endDate: eventOrvalue } },
          dateRangeStartDate: {
            ...dateRangeEndDate,
            [filter.id]: new Date(eventOrvalue),
          },
        };
        break;
      case 'advanced-date-range':
        // eslint-disable-next-line no-case-declarations
        const dateRange = (advancedDateRangeList[get(filter, 'listType', 'default')]
          .find(({ value }) => value === eventOrvalue.value) || {});

        if (!Object.keys(dateRange).length) {
          return;
        }

        this.setState({
          advancedDateRangeType: eventOrvalue.value,
        });

        this.setAdvancedDateRangeType({
          filter,
          dateRange,
          advancedDateRangeType: eventOrvalue.value,
          startDate: buildDateFns(
            dateRange.offset?.startDate,
            formatISO(startOfToday()),
          ),
          endDate: buildDateFns(
            dateRange.offset?.endDate,
            formatISO(endOfToday()),
          ),
          dateRangeStartDate,
          dateRangeEndDate,
        });
        return;
      case 'advanced-date-range-start':
        computedState = {
          filter: {
            ...filter,
            value: {
              ...filter.value,
              startDate: formatISO(startOfDay(parse(eventOrvalue, 'yyyy-MM-dd', new Date()))),
              advancedDateRangeType: 'custom',
              label: '',
              value: '',
            },
          },
          dateRangeStartDate: {
            ...dateRangeStartDate,
            [filter.id]: parse(eventOrvalue, 'yyyy-MM-dd', new Date()),
          },
        };
        break;
      case 'advanced-date-range-end':
        computedState = {
          filter: {
            ...filter,
            value: {
              ...filter.value,
              endDate: formatISO(endOfDay(parse(eventOrvalue, 'yyyy-MM-dd', new Date()))),
              advancedDateRangeType: 'custom',
              label: '',
              value: '',
            },
          },
          dateRangeEndDate: {
            ...dateRangeEndDate,
            [filter.id]: parse(eventOrvalue, 'yyyy-MM-dd', new Date()),
          },
        };
        break;
      case 'location':
        if (!args?.name) {
          break;
        }
        computedValue = eventOrvalue?.value || eventOrvalue?.target?.value || '';
        computedState = {
          filter: {
            ...filter,
            value: {
              ...filter?.value || {},
              [args.name]: computedValue,
            },
          },
        };
        break;
      default:
        computedState = {
          filter: {
            ...filter,
          },
        };
        break;
    }
    this.setState(computedState, cbFn);
  }

  getTypeaheadBody = () => {
    const {
      filter: {
        value,
        results,
        placeholder,
      },
      typeaheadLoading,
    } = this.state;
    function guardKeyUp(event, val) {
      if (event.key === 'Enter') {
        this.handleTypeaheadValueClick(val);
      }
    }
    const resultsJsx = typeaheadLoading ? (
      <Loader>
        <div className={css.FilterEditPanel__filtersOptionLoading} />
      </Loader>
    ) : (
      <>
        {!typeaheadLoading && results == null && (
          <div className={css.FilterEditPanel__filtersEmpty}>
            {placeholder}
          </div>
        )}
        {!typeaheadLoading && isEqual(results, []) && (
          <div className={css.FilterEditPanel__filtersEmpty}>
            No results match your search!
          </div>
        )}
        {results && results.length > 0 && !typeaheadLoading && results.map((r, i) => (
          <div
            key={`result-${i}`}
            tabIndex="0"
            role="button"
            onClick={wrapEventHandler(this.handleTypeaheadValueClick, [r], ARG_ORDER.EVENT_LAST, this)}
            onKeyUp={wrapEventHandler(guardKeyUp, [r], ARG_ORDER.EVENT_FIRST, this)}
            className={`${css.FilterEditPanel__filterOption} typeahead-option`}
          >
            {r.label}
          </div>
        ))}
      </>
    );

    return (
      <React.Fragment key="typeahead-filter">
        {value && value.length > 0 && (
          <div className={css.FilterEditPanel__typeaheadChips}>
            {value.map((v, i) => (
              <div
                className={`${css.FilterEditPanel__typeaheadChip} typeahead-option`}
                key={`tya-opt-panel-${i}`}
              >
                <Chip
                  active
                  key={`selected-filter-${i}`}
                  onClick={wrapEventHandler(this.removeTypeaheadValue, [v], ARG_ORDER.EVENT_LAST, this)}
                  onDismiss={wrapEventHandler(this.removeTypeaheadValue, [v], ARG_ORDER.EVENT_LAST, this)}
                  id={v.value}
                  value={v.label}
                />
              </div>
            ))}
          </div>
        )}
        {resultsJsx}
      </React.Fragment>
    );
  };

  setAdvancedDateRangeType = ({
    filter,
    dateRange,
    advancedDateRangeType,
    startDate,
    endDate,
    dateRangeStartDate,
    dateRangeEndDate,
  }) => {
    this.setState({
      filter: {
        ...filter,
        value: {
          ...filter.value,
          ...dateRange,
          advancedDateRangeType,
          startDate,
          endDate,
        },
      },
      ...(advancedDateRangeType === 'custom' ? {
        dateRangeStartDate: {
          ...dateRangeStartDate,
          [filter.id]: new Date(),
        },
      } : {}),
      ...(advancedDateRangeType === 'custom' ? {
        dateRangeEndDate: {
          ...dateRangeEndDate,
          [filter.id]: new Date(),
        },
      } : {}),
    });
  };

  render() {
    const {
      onSubmit,
      onClearFilterValue,
    } = this.props;
    let filteredOptions = [];
    let filteredOptionsWithGroup = [];

    const {
      filter,
      search,
      dateRangeStartDate,
      dateRangeEndDate,
      advancedDateRangeType,
    } = this.state;
    const {
      loading,
      failedFetchingOptions,
      failedFetchingOptionsMessage,
      closeFilterEditPanel,
      isSingleFilter,
    } = this.props;
    const filterableOptions = filter.options || [];
    const filterableOptionsWithGroup = filter.optionsWithGroup || [];

    const isSearchable = [
      'multi-select',
      'boolean',
      'boolean-multi-select',
    ].includes(filter.type);

    const showSearch = (
      isSearchable
        && (filter.optgroup
          ? filterableOptionsWithGroup
            .reduce((acc, cur) => (acc + cur.options.length), 0) >= SHOW_SEARCH_THRESHOLD
          : filterableOptions.length >= SHOW_SEARCH_THRESHOLD)
    );

    if (isSearchable) {
      const searchString = search.toLowerCase();
      if (filter.optgroup) {
        filteredOptionsWithGroup = filterableOptionsWithGroup
          .filter((optroup) => (
            optroup.label.toLowerCase().includes(searchString)
            || optroup.options
              .filter((option) => option.label.toLowerCase().includes(searchString)).length
          )).map((element) => ({
            ...element,
            options: element.options.filter((opt) => (
              element.label.toLowerCase().includes(searchString)
              || opt.label.toLowerCase().includes(searchString)
            )),
          }));
      } else {
        filteredOptions = filterableOptions
          .filter((option) => option.label.toLowerCase().includes(searchString));
      }
    }

    const getFilterEditPanelFilterOptionsClasses = (type) => {
      switch (type) {
        case 'multi-select':
        case 'boolean':
        case 'boolean-multi-select':
          return showSearch ? css.FilterEditPanel__filterOptions : null;
        case 'typeahead':
          return css.FilterEditPanel__filterOptions;
        default:
          return null;
      }
    };

    const getSliderValue = (field) => {
      if (filter.value && filter.value[field]) {
        return filter.value[field];
      }

      return 0;
    };

    const emptyFilters = (
      <div className={css.FilterEditPanel__filtersEmpty}>
        {search.length ? 'No filters match your search!' : 'No filters'}
      </div>
    );
    function applyValue(event) {
      const updatedValue = event?.target?.value || '';
      this.setState({ search: updatedValue });
    }
    function guardKeyEventExecution(fn, args, event) {
      if (event.key === 'Enter' && typeof fn === 'function') {
        fn([...args]);
      }
    }
    const searchBar = showSearch
      ? (
        <div className={css.FilterEditPanel__filterSearch}>
          <Search
            applyFocus
            disableAutoComplete
            disableCloseIconEvent
            iconDirection="right"
            onChange={wrapEventHandler(applyValue, [], ARG_ORDER.ARGS_LAST, this)}
            onClearSearch={wrapEventHandler(this.setState, [{ search: '' }], ARG_ORDER.OMIT, this)}
            onKeyUp={wrapEventHandler(guardKeyEventExecution, [onSubmit, [filter]], ARG_ORDER.EVENT_LAST, this)}
            placeholder={filter.placeholder}
            value={search}
          />
        </div>
      ) : null;

    const listType = get(filter, 'listType', 'default');

    return (
      <div
        className={classNames(isSingleFilter ? css.FilterEditPanel__singleFilterPanel : css.FilterEditPanel__filterPanel, {
          [css['FilterEditPanel__filterPanel--date']]: ['date', 'date-range', 'date-time', 'advanced-date-range'].includes(filter.type),
        })}
      >
        {
          loading && failedFetchingOptions && (
            <div className={css.FilterEditPanel__filtersOptionFetchingError}>
              {
                typeof failedFetchingOptionsMessage === 'string'
                  ? <HelpText fieldValidation="error">{failedFetchingOptionsMessage}</HelpText>
                  : <HelpText fieldValidation="error">Failed to load options. Please try again.</HelpText>
              }
            </div>
          )
}
        {
          loading && !failedFetchingOptions && (
            <Loader>
              <div className={css.FilterEditPanel__filtersOptionLoading} />
            </Loader>
          )
}
        {
          !loading && (
            <>
              {filter.type === 'typeahead' && (
                <>
                  <div className={css.FilterEditPanel__filterSearch}>
                    <Search
                      applyFocus
                      disableAutoComplete
                      disableCloseIconEvent
                      iconDirection="right"
                      onChange={wrapEventHandler(this.handleFilterChange, [filter.type, {}], ARG_ORDER.ARGS_LAST, this)}
                      onClearSearch={wrapEventHandler(this.setState, [{
                        filter: {
                          ...filter,
                          searchValue: null,
                          results: null,
                        },
                      }],
                      ARG_ORDER.OMIT,
                      this)}
                      onKeyUp={wrapEventHandler(guardKeyEventExecution, [onSubmit, [filter]], ARG_ORDER.EVENT_LAST)}
                      placeholder="Search..."
                      value={filter.searchValue ? filter.searchValue : ''}
                    />
                  </div>
                </>
              )}

              {filter.type !== 'boolean-multi-select' && searchBar}

              <div className={getFilterEditPanelFilterOptionsClasses(filter.type)}>
                <div
                  id="filter-options-input-section"
                  role="presentation"
                  className={classNames({
                    [css.FilterEditPanel__filterSection]: ![
                      'boolean-number',
                      'boolean-multi-select',
                    ].includes(filter.type),
                  })}
                  // eslint-disable-next-line react/jsx-no-bind
                  onKeyUp={(e) => {
                    if (e.key === 'Enter' && !this.shouldActionDisabled(filter, 'apply')) {
                      onSubmit(filter);
                      closeFilterEditPanel();
                    }
                  }}
                >
                  {filter.type === 'typeahead' && this.getTypeaheadBody()}
                  {filter.type === 'multi-select'
                    && (filteredOptions.length || filteredOptionsWithGroup.length
                      ? (
                        <>
                          <div className={css.FilterEditPanel__filterValues}>
                            <Checkbox
                              onChange={wrapEventHandler(this.handleFilterChange, [filter.type, {}], ARG_ORDER.ARGS_LAST, this)}
                              options={filteredOptions}
                              optgroup={filter.optgroup}
                              optionsWithGroup={filteredOptionsWithGroup}
                              value={filter.value}
                              legend=""
                              legendHidden={!filter.optgroup}
                            />
                          </div>
                        </>
                      )
                      : emptyFilters)}
                  {filter.type === 'boolean' && (
                    filteredOptions.length || filteredOptionsWithGroup.length
                      ? (
                        <>
                          <div className={css.FilterEditPanel__filterValues}>
                            {
                              filter.showLabel && <label className={css['FilterEditPanel__filtersLabel--bold']}>{filter.name}</label>
                            }
                            {
                              filter.info && <label className={css['FilterEditPanel__filtersLabel--info']}>{filter.info}</label>
                            }
                            <Radio
                              onChange={wrapEventHandler(this.handleFilterChange, [filter.type, {}], ARG_ORDER.ARGS_LAST, this)}
                              options={filteredOptions}
                              optgroup={filter.optgroup}
                              optionsWithGroup={filteredOptionsWithGroup}
                              value={filter.value}
                              legend=""
                              legendHidden={!filter.optgroup}
                            />
                          </div>
                        </>
                      ) : emptyFilters
                  )}
                  {filter.type === 'boolean-number' && (
                    <div className={css['FilterEditPanel__booleanMultiSelect--container']}>
                      <div className={css.FilterEditPanel__fixedTop}>
                        <div className={css['FilterEditPanel__booleanMultiSelect--top']}>
                          <Radio
                            onChange={wrapEventHandler(this.handleFilterChange, [filter.type, {}], ARG_ORDER.ARGS_LAST, this)}
                            options={(filter.options || [])}
                            optgroup={filter.optgroup}
                            optionsWithGroup={(filter.optionsWithGroup || [])}
                            value={get(filter, 'value.boolean')}
                            legend=""
                            legendHidden={!filter.optgroup}
                          />
                        </div>
                      </div>
                      <div className={css['FilterEditPanel__booleanNumber--bottom']}>
                        <div className={css.FilterEditPanel__textInput}>
                          <TextInput
                            onChange={wrapEventHandler(this.handleFilterChange, ['boolean-number-text', {}], ARG_ORDER.ARGS_LAST, this)}
                            fieldValidation={
                              ((filter.maximum && Number(filter.value?.number) > filter.maximum)
                                || (filter.minimum && Number(filter.value?.number) < filter.minimum)
                              ) ? 'error' : ''
                            }
                            type="number"
                            value={filter.value?.number ? filter.value.number : ''}
                            placeholder={filter.placeholder}
                            helpText={filter.helpText}
                            label={filter.label}
                            applyFocus
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {filter.type === 'boolean-number-range' && (
                    <div className={css['FilterEditPanel__booleanNumberRange--container']}>
                      <div className={css.FilterEditPanel__fixedTop}>
                        <div className={css['FilterEditPanel__booleanNumberRange--top']}>
                          <label className={css['FilterEditPanel__filtersLabel--bold']}>{filter.label}</label>
                          <Radio
                            onChange={wrapEventHandler(this.handleFilterChange, [filter.type, {}], ARG_ORDER.ARGS_LAST, this)}
                            options={(filter.options || [])}
                            optgroup={filter.optgroup}
                            optionsWithGroup={(filter.optionsWithGroup || [])}
                            value={get(filter, 'value.boolean')}
                            legend=""
                            legendHidden={!filter.optgroup}
                          />
                        </div>
                      </div>
                      {Boolean(parseInt(get(filter, 'value.boolean', 0), 10))
                        && (
                        <div className={css['FilterEditPanel__booleanNumberRange--bottom']}>
                          <NumericRange
                            optional
                            {...filter.numericRange}
                            value={{ startValue: get(filter, 'value.startValue', ''), endValue: get(filter, 'value.endValue', '') }}
                            onChange={wrapEventHandler(this.handleFilterChange, [filter.type, {}], ARG_ORDER.ARGS_LAST, this)}
                          />
                        </div>
                        )}
                    </div>
                  )}
                  {filter.type === 'boolean-multi-select' && (
                    filteredOptions.length || filteredOptionsWithGroup.length
                      ? (
                        <div className={css['FilterEditPanel__booleanMultiSelect--container']}>
                          <div className={css.FilterEditPanel__fixedTop}>
                            <div className={css['FilterEditPanel__booleanMultiSelect--top']}>
                              <Radio
                                onChange={wrapEventHandler(
                                  this.handleFilterChange,
                                  [filter.type, {}],
                                  ARG_ORDER.ARGS_LAST,
                                  this,
                                )}
                                options={(filter.options || []).filter((f) => f.section === 'boolean')}
                                optgroup={filter.optgroup}
                                optionsWithGroup={(filter.optionsWithGroup || [])
                                  .filter((f) => f.section === 'boolean')}
                                value={get(filter, 'value.boolean')}
                                legend=""
                                legendHidden={!filter.optgroup}
                              />
                            </div>
                            {searchBar}
                          </div>
                          <div className={css['FilterEditPanel__booleanMultiSelect--bottom']}>
                            <Checkbox
                              onChange={wrapEventHandler(this.handleFilterChange, ['boolean-multi-select-text', {}], ARG_ORDER.ARGS_LAST, this)}
                              options={filteredOptions.filter((f) => f.section === 'multi-select')}
                              optgroup={filter.optgroup}
                              optionsWithGroup={filteredOptionsWithGroup.filter((f) => f.section === 'multi-select')}
                              value={get(filter, 'value.multiSelect')}
                              legend=""
                              legendHidden={!filter.optgroup}
                            />
                          </div>
                        </div>
                      ) : emptyFilters
                  )}
                  {(filter.type === 'text' || filter.type === 'number') && (
                    <div className={css.FilterEditPanel__textInput}>
                      <TextInput
                        onChange={wrapEventHandler(this.handleFilterChange, [filter.type, {}], ARG_ORDER.ARGS_LAST, this)}
                        type={filter.type}
                        value={filter.value ? filter.value : ''}
                        placeholder={filter.placeholder}
                        helpText={filter.helpText}
                        label={filter.label}
                        applyFocus
                      />
                    </div>
                  )}
                  {filter.type === 'date' && (
                    <div className={css.FilterEditPanel__textInput}>
                      <DatePicker
                        onChange={wrapEventHandler(this.handleFilterChange, [filter.type, {}], ARG_ORDER.ARGS_LAST, this)}
                        label=""
                        customClasses="filter-dropdown-date-picker"
                        value={filter.value ? moment(filter.value).format('MM/DD/YYYY') : ''}
                      />
                    </div>
                  )}
                  {filter.type === 'date-time' && (
                    <div className={css.FilterEditPanel__textInput}>
                      <DatePicker
                        onChange={wrapEventHandler(this.handleFilterChange, [filter.type, {}], ARG_ORDER.ARGS_LAST, this)}
                        label=""
                        time
                        customClasses="filter-dropdown-date-picker"
                        value={filter.value ? moment(filter.value).format('MM/DD/YYYY h:mm a') : ''}
                      />
                    </div>
                  )}
                  {filter.type === 'advanced-date-range' && (
                    <div className={css.FilterEditPanel__textInput} style={{ paddingTop: 8, paddingBottom: 8 }}>
                      {
                        filter.showLabel && <label className={css['FilterEditPanel__filtersLabel--bold']} style={{ marginLeft: 16 }}>{filter.name}</label>
                      }
                      <div className={css.FilterEditPanel__dateInput}>
                        <Select
                          value={advancedDateRangeType}
                          options={advancedDateRangeList[listType].map(({ label, value }) => ({ label, value }))}
                          onChange={wrapEventHandler(this.handleFilterChange, [filter.type, {}], ARG_ORDER.ARGS_LAST, this)}
                          label=""
                        />
                      </div>
                      { advancedDateRangeType === 'custom' && (
                        <div className={css.FilterEditPanel__dateInput}>
                          <div className={css.FilterEditPanel__dateSeparator}>
                            <div data-testid="start-date-label" className={css.FilterEditPanel__filtersLabel}>Start Date</div>
                            <DatePicker
                              onChange={wrapEventHandler(this.handleFilterChange, ['advanced-date-range-start', {}], ARG_ORDER.ARGS_LAST, this)}
                              label=""
                              startDate={dateRangeStartDate[filter.id]}
                              endDate={dateRangeEndDate[filter.id]}
                              customClasses="filter-dropdown-date-picker"
                              value={filter.value?.startDate && isValid(parseISO(filter.value?.startDate)) ? format(parseISO(filter.value?.startDate), 'MM/dd/yyyy') : ''}
                            />
                          </div>
                          <div className={css.FilterEditPanel__dateSeparator}>
                            <div className={css.FilterEditPanel__filtersLabel}>End Date</div>
                            <DatePicker
                              onChange={wrapEventHandler(this.handleFilterChange, ['advanced-date-range-end', {}], ARG_ORDER.ARGS_LAST, this)}
                              label=""
                              startDate={dateRangeStartDate[filter.id]}
                              endDate={dateRangeEndDate[filter.id]}
                              customClasses="filter-dropdown-date-picker"
                              value={filter.value?.endDate && isValid(parseISO(filter.value?.endDate)) ? format(parseISO(filter.value?.endDate), 'MM/dd/yyyy') : ''}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {filter.type === 'date-range' && (
                    <div className={css.FilterEditPanel__dateInput}>
                      <div className={css.FilterEditPanel__dateSeparator}>
                        <div className={css.FilterEditPanel__filtersLabel}>Start Date</div>
                        <DatePicker
                          onChange={wrapEventHandler(this.handleFilterChange, ['date-range-start', {}], ARG_ORDER.ARGS_LAST, this)}
                          label=""
                          startDate={dateRangeStartDate[filter.id]}
                          endDate={dateRangeEndDate[filter.id]}
                          customClasses="filter-dropdown-date-picker"
                          value={filter.value && filter.value.startDate ? moment(filter.value.startDate).format('MM/DD/YYYY') : ''}
                        />
                      </div>
                      <div className={css.FilterEditPanel__dateSeparator}>
                        <div className={css.FilterEditPanel__filtersLabel}>End Date</div>
                        <DatePicker
                          onChange={wrapEventHandler(this.handleFilterChange, ['date-range-end', {}], ARG_ORDER.ARGS_LAST, this)}
                          label=""
                          startDate={dateRangeStartDate[filter.id]}
                          endDate={dateRangeEndDate[filter.id]}
                          customClasses="filter-dropdown-date-picker"
                          value={filter.value && filter.value.endDate ? moment(filter.value.endDate).format('MM/DD/YYYY') : ''}
                        />
                      </div>
                    </div>
                  )}
                  {filter.type === 'slider' && (
                    <div className={css.FilterEditPanel__filterValues}>
                      {
                        filter.fields.map((field) => (
                          <div className={css.FilterEditPanel__slider} key={field.name}>
                            <div className={css.FilterEditPanel__filtersLabel}>{field.label}</div>
                            <Slider
                              tipTransitionName="rc-slider-tooltip-zoom-down"
                              max={field.max}
                              onChange={wrapEventHandler(
                                this.handleFilterChange,
                                [filter.type, { name: field.name, label: field.label }],
                                ARG_ORDER.ARGS_LAST,
                                this,
                              )}
                              value={getSliderValue(field.name)}
                            />
                            <div className={css.FilterEditPanel__sliderValue}>
                              {`${getSliderValue(field.name)}/${field.max}`}
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  )}

                  {filter.type === 'phone' && (
                    <div className={css.FilterEditPanel__textInput}>
                      <PhoneInput
                        value={filter.value ? filter.value : ''}
                        placeholder={filter.placeholder}
                        optional={filter.optional || true}
                        label=""
                        onChange={wrapEventHandler(this.handleFilterChange, [filter.type, {}], ARG_ORDER.ARGS_LAST, this)}
                      />
                    </div>
                  )}

                  {filter.type === 'numeric-range' && (
                    <NumericRange
                      {...filter}
                      value={{ startValue: get(filter, 'value.startValue', ''), endValue: get(filter, 'value.endValue', '') }}
                      onChange={wrapEventHandler(this.handleFilterChange, [filter.type, {}], ARG_ORDER.ARGS_LAST, this)}
                    />
                  )}
                  {filter.type === 'location' && (
                    <div className={css['FilterEditPanel__location--container']}>
                      <div className={css['FilterEditPanel__location--section']}>
                        <TextInput
                          onChange={wrapEventHandler(this.handleFilterChange, [filter.type, { name: 'city' }], ARG_ORDER.ARGS_LAST, this)}
                          type="text"
                          value={get(filter, 'value.city', '')}
                          placeholder={get(filter, 'city.placeholder', '')}
                          helpText={get(filter, 'city.helpText', '')}
                          label={get(filter, 'city.label', '')}
                          optional={get(filter, 'city.optional', true)}
                        />
                      </div>
                      <div className={css['FilterEditPanel__location--section']}>
                        <Select
                          value={get(filter, 'value.state', '')}
                          options={get(filter, 'state.options', [])}
                          onChange={wrapEventHandler(this.handleFilterChange, [filter.type, { name: 'state' }], ARG_ORDER.ARGS_LAST, this)}
                          label={get(filter, 'state.label', '')}
                          optional={get(filter, 'state.optional', true)}
                        />
                      </div>
                      <div className={css['FilterEditPanel__location--section']}>
                        <TextInput
                          onChange={wrapEventHandler(this.handleFilterChange, [filter.type, { name: 'zip' }], ARG_ORDER.ARGS_LAST, this)}
                          type="text"
                          value={get(filter, 'value.zip', '')}
                          placeholder={get(filter, 'zip.placeholder', '')}
                          helpText={get(filter, 'zip.helpText', '')}
                          label={get(filter, 'zip.label', '')}
                          optional={get(filter, 'zip.optional', true)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={css.FilterEditPanel__filterEditActions}>
                <ActionBar
                  footerActions={[
                    {
                      label: get(filter, 'editButtonsAttr.secondary.label', 'Clear'),
                      type: get(filter, 'editButtonsAttr.secondary.type', 'link'),
                      className: get(filter, 'editButtonsAttr.secondary.className', 'closeEventBlocker'),
                      onClick: () => this.onClearButton(filter, onClearFilterValue),
                      disabled: this.shouldActionDisabled(filter),
                    },
                    {
                      label: get(filter, 'editButtonsAttr.primary.label', 'Apply'),
                      type: get(filter, 'editButtonsAttr.primary.type', 'secondary'),
                      className: get(filter, 'editButtonsAttr.primary.className', ''),
                      onClick: () => {
                        onSubmit(filter);
                        closeFilterEditPanel();
                      },
                      disabled: this.shouldActionDisabled(filter, 'apply'),
                    },
                  ]}
                  {...(filter.type === 'multi-select'
                    ? {
                      tertiaryText: `${(filter.value && filter.value.length) || '0'} / ${filterableOptions.length}`,
                    }
                    : {})}
                />
              </div>
            </>
          )
}
      </div>
    );
  }
}

FilterEditPanel.propTypes = {
  onClearFilterValue: PropTypes.func.isRequired,
  filter: filterPropTypes.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  failedFetchingOptions: PropTypes.bool,
  failedFetchingOptionsMessage: PropTypes.string,
  closeFilterEditPanel: PropTypes.func,
  filterEditButtonsAttr: filterEditButtonsAttrPropTypes,
  isSingleFilter: PropTypes.bool,
};

FilterEditPanel.defaultProps = {
  loading: false,
  failedFetchingOptions: false,
  failedFetchingOptionsMessage: '',
  closeFilterEditPanel: () => {},
  filterEditButtonsAttr: {},
  isSingleFilter: false,
};

export default FilterEditPanel;
