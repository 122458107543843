import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import classes from './HelpText.scss';
import HelpTextIcon from '../HelpTextIcon/HelpTextIcon.jsx';

const validationTypes = [false, '', 'error', 'success'];
const VALIDATION_KEY = 'fieldValidation';

export default class HelpText extends PureComponent {
    static propTypes = {
        fieldValidation: PropTypes.oneOf(validationTypes),
    }

    static defaultProps = {
        fieldValidation: false,
    }

    static contextTypes = {
        fieldValidation: PropTypes.oneOf(validationTypes),
    }

    constructor(props, context) {
        super(props, context);
        this.state = {
            [VALIDATION_KEY]: this.props[VALIDATION_KEY] || this.props[VALIDATION_KEY] === '' ?
                this.props[VALIDATION_KEY] :
                this.context[VALIDATION_KEY],
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const fieldValidation = nextProps[VALIDATION_KEY] || nextProps[VALIDATION_KEY] === '' ?
            nextProps[VALIDATION_KEY] :
            nextContext[VALIDATION_KEY];
        if (fieldValidation !== this.state[VALIDATION_KEY]) {
            this.setState({ fieldValidation });
        }
    }

    render() {
        const hasIcon = this.state.fieldValidation === 'error' || this.state.fieldValidation === 'success';
        let title;
        switch (this.state.fieldValidation) {
            case 'error':
                title = 'Triangle';
                break;
            case 'success':
                title = 'Checkmark';
                break;
        }

        return (
            <div
                className={classNames(classes.HelpText, {
                    [classes['HelpText--error']]: this.state.fieldValidation === 'error',
                    [classes['HelpText--success']]: this.state.fieldValidation === 'success',
                    [classes['HelpText--hasIcon']]: hasIcon,
                })}>
                { hasIcon &&
                    <div className={classes.HelpText__icon}>
                        <HelpTextIcon
                            fieldValidation={this.state.fieldValidation}
                            title={title}
                            width="16"
                            height="16" />
                    </div>
                }
                {this.props.children}
            </div>
        );
    }
}
