import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './Search.scss';
import Icon from '../Icon';
import IconButton from '../IconButton';
import wrapEventHandler, { ARG_ORDER } from '../utils/wrapEventHandler';

class Search extends Component {
  constructor(props) {
    super(props);
    this.searchInput = React.createRef();
    this.node = React.createRef();
    this.toggleSearch = this.toggleSearch.bind(this);
    this.handleInputEvent = this.handleInputEvent.bind(this);
  }

  componentDidMount() {
    const { collapsible } = this.props;
    if (collapsible) {
      document.addEventListener('click', this.handleClick, true);
    }
  }

  componentWillUnmount = () => {
    const { collapsible } = this.props;
    if (collapsible) {
      document.removeEventListener('click', this.handleClick, true);
    }
  };

  toggleSearch(expanded, clickedOutside = false) {
    const searchExpanded = clickedOutside ? false : !expanded;
    const { toggleSearchExpanded } = this.props;
    toggleSearchExpanded(searchExpanded);
  }

  // eslint-disable-next-line class-methods-use-this
  handleInputEvent(inputFn, event) {
    event.persist();
    if (typeof inputFn === 'function') {
      inputFn(event);
    }
  }

  handleClick = (event) => {
    // Close searchbox if someone clicks outside of search div.
    if (!this.node.current || !this.node.current.contains(event.target)) {
      const { value, searchExpanded } = this.props;
      if (searchExpanded && value === '') {
        this.toggleSearch(null, true); // First param isn't essential if clicked outside with empty search field.
      }
    }

    // Focus search input if someone clicks at search icon.
    if (this.node.current && this.node.current.contains(event.target)) {
      setTimeout(() => {
        this.searchInput.current.focus();
      }, 100);
    }
  };

  getSearchExpandClass() {
    const {
      searchExpanded,
      collapsible,
    } = this.props;

    if (!collapsible) {
      return null;
    }
    if (searchExpanded === null) {
      return css['Search__input--init'];
    }
    if (searchExpanded) {
      return css['Search__input--expanded'];
    }

    return css['Search__input--collapsed'];
  }

  // Max of 50em and min of 20rem (0 for collapsed)
  getCustomSearchExpandWidth() {
    const {
      searchExpanded,
      placeholder,
    } = this.props;
    let width = '20rem';

    // This isn't perfect, but it still looks good at all lengths
    if (searchExpanded) {
      width = `${Math.min(Math.max((placeholder.length / 1.75), 20), 50)}em`;
    } else {
      width = 0;
    }

    return width;
  }

  renderSearchIcon = (collapsible, searchExpanded) => (
    <>
      {
        collapsible
          ? (
            <IconButton
              name="search"
              onClick={wrapEventHandler(this.toggleSearch, [searchExpanded], ARG_ORDER.OMIT, this)}
            />
          )
          : (
            <Icon
              name="search"
              block
            />
          )
        }
    </>
  );

  render() {
    const {
      applyFocus,
      collapsible,
      disableCloseIconEvent,
      disableAutoComplete,
      iconDirection,
      id,
      onChange,
      onClearSearch,
      onKeyUp,
      placeholder,
      searchExpanded,
      searchWidthFitPlaceholderText,
      value,
    } = this.props;

    return (
      <div
        className={css.Search}
        ref={this.node}
      >
        <div
          className={classNames(
            css.Search__icon,
            {
              [css['Search__icon--left']]: iconDirection === 'left',
              [css['Search__icon--right']]: iconDirection === 'right' && collapsible,
              [css['Search__icon--rightSpacingMd']]: iconDirection === 'right' && !collapsible,
            },
          )}
          {...(value
            ? {
              role: 'button',
              onKeyPress: () => {},
              tabIndex: -1,
              onClick: onClearSearch,
            }
            : {}
          )}
        >
          {
            value
              ? (
                <Icon
                  name="cancelSolid"
                  block
                  disablePointerEvent={disableCloseIconEvent}
                />
              )
              : this.renderSearchIcon(collapsible, searchExpanded)
          }
        </div>
        <label
          className={css.Search__label}
          htmlFor={id}
        >
          Search
        </label>
        <input
          autoFocus={applyFocus ? 'autofocus' : ''} // eslint-disable-line
          autoComplete={disableAutoComplete ? 'off' : 'on'}
          className={classNames(
            css.Search__input,
            {
              [css['Search__input--hasIconLeft']]: iconDirection === 'left',
              [css['Search__input--hasIconRight']]: iconDirection === 'right',
            },
            this.getSearchExpandClass(),
          )}
          data-testid="search"
          id={id}
          name="search"
          onChange={wrapEventHandler(this.handleInputEvent, [onChange], ARG_ORDER.EVENT_LAST)}
          onKeyPress={wrapEventHandler(this.handleInputEvent, [onKeyUp], ARG_ORDER.EVENT_LAST)}
          placeholder={placeholder}
          ref={this.searchInput}
          style={searchWidthFitPlaceholderText ? { width: this.getCustomSearchExpandWidth() } : {}}
          type="search"
          value={value}
        />
        <span className={css.Search__focusIndicator} />
      </div>
    );
  }
}

Search.propTypes = {
  applyFocus: PropTypes.bool,
  disableAutoComplete: PropTypes.bool,
  collapsible: PropTypes.bool,
  disableCloseIconEvent: PropTypes.bool,
  iconDirection: PropTypes.oneOf(['right', 'left']),
  id: PropTypes.string,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  onClearSearch: PropTypes.func,
  placeholder: PropTypes.string,
  searchExpanded: PropTypes.bool,
  searchWidthFitPlaceholderText: PropTypes.bool,
  toggleSearchExpanded: PropTypes.func,
  value: PropTypes.string,
};

Search.defaultProps = {
  applyFocus: false,
  disableAutoComplete: false,
  collapsible: false,
  disableCloseIconEvent: false,
  iconDirection: 'left',
  id: 'borderless-search',
  onChange: undefined,
  onClearSearch: undefined,
  onKeyUp: undefined,
  placeholder: 'Search...',
  searchExpanded: false,
  searchWidthFitPlaceholderText: false,
  toggleSearchExpanded: undefined,
  value: undefined,
};

export default Search;
