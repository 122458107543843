import React from 'react';
import PropTypes from 'prop-types';
import FilterAddon from './FilterAddon.jsx';
import {
    Addon,
    Group,
    Label,
    Search,
    TextInputWrapper,
} from '../Field/Field.jsx';

const FilterSearch = ({
    buttonActive,
    filterActive,
    id,
    label,
    onChange,
    onFilterClick,
    onKeyUp,
    onSubmit,
    value,
}) => (
    <Group
        id={id}>
        <Label
            screenReaderOnly
            optional>{label}</Label>
        <TextInputWrapper>
            <Search
                placeholder={label}
                hasAppend
                onChange={onChange}
                onKeyUp={onKeyUp}
                value={value}
                onSubmit={onSubmit} />
            <FilterAddon active={filterActive}>
                <Addon
                    onClick={onFilterClick}
                    active={buttonActive}
                    icon="filter"
                    orientation="right" />
            </FilterAddon>
        </TextInputWrapper>
    </Group>
);

FilterSearch.propTypes = {
    id: Group.propTypes.id,
    label: PropTypes.string.isRequired,
    filterActive: PropTypes.bool,
    onChange: Search.propTypes.onChange,
    onKeyUp: Search.propTypes.onKeyUp,
    value: Search.propTypes.value,
};

export default FilterSearch;
