import React, { Component } from 'react';
import parse from  'date-fns/parse';
import format from  'date-fns/format';
import isValid from  'date-fns/isValid';
import PropTypes from 'prop-types';
import classes from './TimePicker.scss';
import TextInput from '../TextInput/TextInput.jsx';
import TextInputWrapper from '../TextInputWrapper/TextInputWrapper.jsx';
import Addon from '../Addon/Addon.jsx';
import SkinlessPopover from '../../../../SkinlessPopover';
import isValidDateString from '../../../../utils/isValidDateString';
import getFormattedDateString from '../../../../utils/getFormattedDateString';
import getDateFromString from '../../../../utils/getDateFromString';
import getTimeOptions from '../../../../utils/getTimeOptions';

export default class TimePicker extends Component {
    constructor(props, context) {
        super(props);
        let formattedDateString = getFormattedDateString(this.props.value);
        let isValidDate = isValidDateString(formattedDateString);
        this.state = {
            value: isValidDate ? formattedDateString : '',
            displayValue: isValidDateString(formattedDateString) ? formattedDateString : '',
            dropDownWidth: 'auto',
            dropDownOpen: false,
        };
        this.timeOptions = getTimeOptions();
        this.sizeDropDown = this.sizeDropDown.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.dropDownref = React.createRef();
        // time can come in any of these formats
        this.timeFormats = ['HH:mm:ss', 'HH:mm', 'h:mm a'];
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let nValue = nextProps.value;
        if (this.state.value !== nValue) {
            let formattedDateString = getFormattedDateString(nValue);
            let isValidDate = isValidDateString(formattedDateString);
            if (isValidDate) {
                this.setState({
                    value: nValue,
                    displayValue: formattedDateString,
                });
            }
        }
    }

    handleDropDownItemClick(option) {
        this.updateAndSyncValues(option.label);
    }

    componentDidMount() {
        this.sizeDropDown();
    }

    getDropDownItems() {
        let timeOptions = this.timeOptions;
        let self = this;
        let items = timeOptions.map((timeOption, i) => {
            let onItemClick = self.handleDropDownItemClick.bind(self, timeOption);
            return (
                <li onClick={onItemClick} className={classes.TimePicker__dropdownItem} key={i}>{timeOption.label}</li>
            );
        });
        return items;
    }

    scrollToTime(isOpen, time) {
        if (!isOpen || !time) {
            return;
        }

        // need a timeout to get around the animation
        setTimeout(() => {
            if(!this.dropDownref.current.popoverTarget) {
              return;
            }

            let pt;
            for (let i = 0; i < this.timeFormats.length; i++) {
                if (isValid(parse(time, this.timeFormats[i], new Date()))) {
                    pt = parse(time, this.timeFormats[i], new Date());
                    break;
                }
            }
            if (!pt) {
                return;
            }
            
            const search = format(pt, 'HH:mm');
            const heightPerItem = this.dropDownref.current.popoverTarget.scrollHeight / this.timeOptions.length;

            const index = this.timeOptions.findIndex(({ value }, i) => {
                const start = value;
                const end = this.timeOptions[i + 1] ? this.timeOptions[i + 1].value : start;

                // 13:09:17 should match 13:00:00 and not 13:15:00
                if (search >= start && search < end) {
                    return true;
                }

                return false;
            });

            this.dropDownref.current.popoverTarget.scrollTop = heightPerItem * index;
        }, 75);
    }

    render() {
        if (this.context.fieldId) {
            let inlineStyle = {};
            if (this.props.disabled) {
                inlineStyle.cursor = 'not-allowed';
            }

            let dropDownStyles = {
                width: this.state.dropDownWidth,
            };

            let self = this;

            return (
                <div onClick={this.sizeDropDown} ref={(root) => { this.inputGroup = root; }}>
                    <TextInputWrapper>
                        <TextInput
                            disabled={this.props.disabled}
                            hasAppend
                            name={this.props.name}
                            onBlur={this.handleBlur}
                            onChange={this.handleChange}
                            onKeyUp={this.handleKeyUp}
                            placeholder={this.props.placeholder}
                            ref={(input) => { this.textInput = input; }}
                            style={inlineStyle}
                            value={this.state.displayValue}
                        />
                        <SkinlessPopover
                            attachment="top right"
                            onChangeOpen={isOpen => this.scrollToTime(isOpen, this.props.timeToScrollTo)}
                            targetAttachment="bottom right"
                            ref={this.dropDownref}
                        >
                            <Addon
                                adjacentToDisabled={this.props.disabled}
                                orientation="right"
                                icon="time"
                                disabled={this.props.disabled} />
                            <div style={dropDownStyles} ref={(dropdown) => { this.dropdown = dropdown; }} className={classes.TimePicker__dropdown}>
                                <ol className={classes.TimePicker__dropdownList}>
                                    {this.getDropDownItems()}
                                </ol>
                            </div>
                        </SkinlessPopover>
                    </TextInputWrapper>
                </div>
            );
        }
        return (<div />);
    }

    // componentDidUpdate(prevProps, prevState) {
    //     this.sizeDropDown();
    // }

    sizeDropDown() {
        if (this.inputGroup) {
            let width = this.inputGroup.offsetWidth;
            if (width !== this.state.dropDownWidth) {
                this.setState({ dropDownWidth: `${width}px` });
            }
        }
    }

    updateAndSyncValues(dateString) {
        let self = this;
        if (isValidDateString(dateString)) {
            let d = getDateFromString(dateString);
            this.setState({
                displayValue: dateString,
                value: d,
            });
            this.props.onChange(d);
        }
    }

    formatValueAndTryEnter(value, invalidCallback) {
        let formattedValue = getFormattedDateString(value);
        if (isValidDateString(formattedValue)) {
            this.updateAndSyncValues(formattedValue);
        } else if (invalidCallback && (typeof invalidCallback === 'function')) {
            invalidCallback();
        }
    }

    handleBlur(e) {
        let self = this;
        let invalidCallback = function () {
            let x = getFormattedDateString(self.state.value);
            // reset displayValue back from internal value
            self.setState({
                displayValue: getFormattedDateString(self.state.value),
            });
        };
        this.formatValueAndTryEnter(e.target.value, invalidCallback);
    }

    handleChange(e) {
        this.setState({
            displayValue: e.target.value,
        });
    }

    handleKeyUp(e) {
        // handle enter
        if (e.which === 13) {
            this.formatValueAndTryEnter(this.state.displayValue);
        }
    }

    getChildContext() {
        return {
            timepickerClassName: classes.TimePicker,
        };
    }
}

TimePicker.contextTypes = {
    fieldId: PropTypes.string.isRequired,
    fieldValidation: PropTypes.oneOf(['', 'error', 'success']),
};

TimePicker.childContextTypes = {
    timepickerClassName: PropTypes.string,
};

TimePicker.propTypes = {
    disabled: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    timeToScrollTo: PropTypes.string,
    value: PropTypes.string,
};

TimePicker.defaultProps = {
    disabled: false,
    name: '',
    onChange: () => {},
    placeholder: '',
    timeToScrollTo: null,
    value: '',
};
