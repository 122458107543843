import PropTypes from 'prop-types';
import React from 'react';

const Header = ({ label, date }) => (
  <div>
    <div>{label}</div>
    <div>{date.getDate()}</div>
  </div>
);

Header.propTypes = {
  label: PropTypes.node,
  date: PropTypes.instanceOf(Date),
};

Header.defaultProps = {
  label: '',
  date: new Date(),
};

export default Header;
