import { selector } from 'recoil';
import tabPrefsQuery from './tab-prefs.query';
import tabPropsQuery from './tab-props.query';

export const selectedColumnIdsQuery = selector<string>({
  key: 'selectedColumnIds',
  get: ({ get }) => {
    const props = get(tabPropsQuery);
    const prefs = get(tabPrefsQuery);

    if (!prefs || !props) {
      return '';
    }

    const { columns } = prefs;
    const { columns: availableColumns } = props;
    const availableColumnIds = availableColumns.map(({ id }) => id);
    const columnIds = (columns || []).map(({ id }) => id);

    const selectedIds = columnIds.reduce((acc, id) => {
      if (availableColumnIds.includes(id)) {
        acc.push(id);
        return acc;
      }

      return acc;
    }, [] as string[]);

    return selectedIds.join(',');
  },
});

export default selectedColumnIdsQuery;
