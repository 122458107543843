import React from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import classNames from 'classnames';
import {
  ActionsMenu,
  Button,
  ButtonGroup,
  Heading,
  IconButton,
  Link,
  Median,
  MedianAlpha,
  MedianOmega,
  Menu,
} from '../index';
import css from './styles.scss';
import TitleBarHOC from './TitleBarHOC';

const limitProps = (props) => (pick(props, [
  'href',
  'target',
  'onClick',
  'label',
  'disabled',
  'name',
]));

const TitleBar = ({
  title,
  actions,
  content,
}) => {
  const hasOnlyContentComponent = actions.length === 0 && content;

  const buttonGroup = (
    <ButtonGroup>
      {actions.map((action, i) => {
        if (action.menuItems && action.menuItems.length > 0 && action.menuType === 'list') {
          return (
            <Menu
              // eslint-disable-next-line react/no-array-index-key
              key={`${action.label}-${i}`}
              disableAutoWidth
              trigger={<IconButton name="moreHoriz" />}
              menuItems={action.menuItems.map((item) => limitProps(item))}
              closeOnSelection
            />
          );
        }

        if (action.menuItems && action.menuItems.length > 0 && action.menuType === 'button') {
          return (
            <ActionsMenu
              // eslint-disable-next-line react/no-array-index-key
              key={`${action.label}-${i}`}
              disableAutoWidth
              alignToRight
              isBelowPanel
              type={action.type}
              label={action.label}
              menuItems={action.menuItems.map((item) => limitProps(item))}
            />
          );
        }

        if (action.type === 'primary') {
          // eslint-disable-next-line react/no-array-index-key
          return (<Button {...limitProps(action)} type="primary" key={`${action.label}-${i}`} />);
        }

        if (action.type === 'secondary') {
          // eslint-disable-next-line react/no-array-index-key
          return (<Button {...limitProps(action)} key={`${action.label}-${i}`} />);
        }

        if (action.type === 'link') {
          // eslint-disable-next-line react/no-array-index-key
          return (<Link {...limitProps(action)} key={`${action.label}-${i}`}>{action.label}</Link>);
        }

        if (action.type === 'buttonLink') {
          // eslint-disable-next-line react/no-array-index-key
          return (<Button {...limitProps(action)} type="link" key={`${action.label}-${i}`} />);
        }

        if (action.type === 'icon') {
          // eslint-disable-next-line react/no-array-index-key
          return (<IconButton {...limitProps(action)} key={`${action.label}-${i}`} />);
        }

        return null;
      })}
    </ButtonGroup>
  );

  const withContentComponent = (
    <>
      <div
        className={classNames({
          [css.TitleBar__contentComponent]: true,
          [css['TitleBar__contentComponent--noMarginRight']]: hasOnlyContentComponent,
        })}
      >
        {content}
      </div>
      <div
        className={css.TitleBar__buttonGroup}
      >
        {buttonGroup}
      </div>
    </>
  );

  return (
    <div className={css.TitleBar}>
      <Median>
        <MedianAlpha>
          <div className={css.TitleBar__title}>
            <Heading>
              {title}
            </Heading>
          </div>
        </MedianAlpha>
        {(actions.length || content) && (
          <MedianOmega>
            {
              content
                ? withContentComponent
                : buttonGroup
            }
          </MedianOmega>
        )}
      </Median>
    </div>
  );
};

TitleBar.propTypes = {
  title: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string,
    target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
    onClick: PropTypes.func,
    label: PropTypes.string,
    type: PropTypes.oneOf(['primary', 'secondary', 'link', 'buttonLink']),
    disabled: PropTypes.bool,
    menuType: PropTypes.oneOf(['button', 'list']),
    menuItems: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.string,
      disabled: PropTypes.bool,
      onClick: PropTypes.func,
      href: PropTypes.string,
      target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
    })),
  })),
  content: PropTypes.element,
};

TitleBar.defaultProps = {
  actions: [],
  content: null,
};

const TitleBarBridge = TitleBarHOC(TitleBar);

export default TitleBarBridge;
