import {
    compose,
    withProps,
} from 'recompose';
import setDisplayName from '../../../../HigherOrderComponents/setDisplayName';
import TextInput from '../TextInput/TextInput';

const enhancer = compose(
    withProps({
        mask: '99-9999999',
        maskChar: null,
    }),
    setDisplayName('EmployerIdentificationNumberInput'),
);

export default enhancer(TextInput);
