import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { getBooleanMultiSelectString } from '../Toolbar/ActiveFilter';
import { FilterProps, FilterValueProps } from '../v2/Listing/types';

interface StateProps {
  filter: FilterProps;
}

const getNumericRangeValueAsString = (filter: FilterProps): string => {
  const getUnit = (numericRangeValue: number, useSingular: boolean = false) => {
    let unit;
    if (typeof filter?.units === 'string') {
      unit = filter?.units;
    } else if (filter?.units?.singular && Number(numericRangeValue) === 1) {
      unit = useSingular ? filter?.units?.singular : filter?.units?.plural;
    } else if (filter?.units?.plural && Number(numericRangeValue) !== 1) {
      unit = filter?.units?.plural;
    }
    return unit || '';
  };
  const { startValue = 0, endValue = 0 } = filter?.value as FilterValueProps;
  if (Number(endValue)) {
    if (!Number(startValue)) {
      return `up to ${endValue} ${getUnit(endValue, true)}`;
    }
    return `${startValue} - ${endValue} ${getUnit(endValue)}`;
  }
  return `${startValue}+ ${getUnit(startValue)}`;
};

/**
 * This function is for the ActiveFilter component and also being used for the other consumers like Analytics-ui
 *
 * @param  { Object } filter - any filter like slider, advanced-date-range, date-range as an object
 * @param  { Boolean } asArray - Boolean value like true/false
 * @param { Object } state - selected filter's state
 * @return { String } - it returns string value
 */
export default function getFilteredValue(filter: FilterProps, asArray?: boolean, state?: StateProps): string {
  if (isEmpty(filter?.value)) {
    return '';
  }

  if (filter?.type === 'slider') {
    return Object.keys(filter?.valueWithLabel || {})
      .map((key) => `${key}-${filter?.valueWithLabel?.[key]}`)
      .join(', ');
  }

  if (filter?.type === 'advanced-date-range'
    && (filter?.value as FilterValueProps)?.advancedDateRangeType !== 'custom') {
    return (filter?.value as FilterValueProps)?.label || '';
  }

  if (filter?.type === 'date-range'
    || (filter?.type === 'advanced-date-range'
      && (filter?.value as FilterValueProps)?.advancedDateRangeType === 'custom')) {
    const { startDate = '', endDate = '' } = filter?.value as FilterValueProps;
    const startDateArr = moment(startDate)?.format?.('YYYY,MM,DD,MMM')?.split?.(',');
    const endDateArr = moment(endDate)?.format?.('YYYY,MM,DD,MMM')?.split?.(',');
    const startDateYear = startDateArr?.[0];
    const endDateYear = endDateArr?.[0];
    const startDateMonth = startDateArr?.[1];
    const startDateMonthText = startDateArr?.[3];
    const endDateMonth = endDateArr?.[1];
    const endDateMonthText = endDateArr?.[3];
    const startDateDay = startDateArr?.[2];
    const endDateDay = endDateArr?.[2];

    if (startDateYear !== endDateYear) {
      return `${startDateMonthText}. ${startDateDay}, ${startDateYear}-${endDateMonthText}. ${endDateDay}, ${endDateYear}`;
    }

    if (startDateYear === endDateYear && startDateMonth !== endDateMonth) {
      return `${startDateMonthText}. ${startDateDay}-${endDateMonthText}. ${endDateDay}, ${startDateYear}`;
    }

    if (startDateYear === endDateYear && startDateMonth === endDateMonth) {
      return `${startDateMonthText}. ${startDateDay}-${endDateDay}, ${startDateYear}`;
    }
  }

  const optgroup = state?.filter?.optgroup || filter?.optgroup || false;
  const options = state?.filter?.options || filter?.options || [];
  const optionsWithGroup = state?.filter?.optionsWithGroup || filter?.optionsWithGroup || [];
  if (filter?.type === 'multi-select') {
    const value = (filter?.value as any)?.map?.(
      (val) => {
        let f: any = null;
        if (optgroup) {
          for (let i = 0; i < optionsWithGroup?.length; i += 1) {
            f = optionsWithGroup?.[i]?.options?.find?.((opt) => opt?.value === val);
            if (f) {
              break;
            }
          }
        } else {
          f = options?.find?.((opt) => opt?.value === val);
        }

        return f?.label || '';
      },
    );
    return asArray ? value : value?.join?.(', ');
  }

  if (filter?.type === 'boolean') {
    let f: any = null;
    if (optgroup) {
      for (let i = 0; i < optionsWithGroup?.length; i += 1) {
        f = optionsWithGroup?.[i]?.options?.find?.((opt) => opt?.value === filter?.value);
        if (f) {
          break;
        }
      }
    } else {
      f = options?.find?.((opt) => opt?.value === filter?.value);
    }

    return f?.label || '';
  }

  if (filter?.type === 'boolean-multi-select') {
    return getBooleanMultiSelectString(filter);
  }

  if (filter?.type === 'date') {
    return moment(filter?.value as string)?.format?.('MMM. DD, YYYY');
  }

  if (filter?.type === 'date-time') {
    return moment(filter?.value as string)?.format?.('MMM. DD, YYYY h:mm a');
  }

  if (filter?.type === 'typeahead') {
    return (filter?.value as any)?.map?.(({ value, label = '' }) => {
      if (label) {
        return label;
      }

      return options?.length
        ? (options?.find?.(op => Number(op?.value) === Number(value))?.label || '')
        : '';
    });
  }

  if (filter?.type === 'numeric-range') {
    return getNumericRangeValueAsString(filter);
  }

  if (filter?.type === 'boolean-number-range') {
    const numberRange = getNumericRangeValueAsString(filter);
    if (Object.prototype.hasOwnProperty.call(filter?.value, 'boolean')) {
      const { boolean } = filter?.value as FilterValueProps;
      const booleanVal = options?.find?.((opt) => opt?.value === boolean);
      const label = filter?.label && filter?.label !== filter?.name ? `${filter?.label}: ` : ''
      return `${label}${booleanVal?.label}${parseInt(booleanVal?.value || '0', 10) ? `, ${numberRange}` : ''}`;
    }
    return numberRange;
  }

  if (filter?.type === 'location') {
    const groupArr: Array<string> = [];
    if (typeof filter.value === 'object') {
      Object.keys(filter.value).forEach((key) => {
        if (filter[key] && filter[key].label && filter?.value && filter?.value[key]) {
          groupArr.push(`${filter[key].label}: ${filter?.value[key]}`);
        }
      });
    }
    return groupArr.join(', ');
  }

  if (filter?.type === 'boolean-number') {
    return String((filter?.value as FilterValueProps)?.number);
  }

  return filter?.value as string;
}
