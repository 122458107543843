import React from 'react';
import PropTypes from 'prop-types';
import css from './CustomEvent.scss';
import {
  Icon,
  HorizontalList,
  HorizontalListItem,
} from '..';

const eventIcons = {
  unavailable: 'event',
  unconfirmed: 'time',
  confirmed: 'qualification',
};

const CustomEvent = ({ event = {} }) => (
  <HorizontalList
    noWrap
    verticalAlign="top"
    spacing={1}
  >
    {Boolean(eventIcons[event.type]) && (
      <HorizontalListItem>
        <Icon block name={eventIcons[event.type]} size={16} />
      </HorizontalListItem>
    )}
    <HorizontalListItem>
      <div className={css.CustomEvent}>
        {event.label
          && <div className={css.CustomEvent__label}>{event.label}</div>}
        <div>{event.title}</div>
      </div>
    </HorizontalListItem>
  </HorizontalList>
);

CustomEvent.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
  }),
};

CustomEvent.defaultProps = {
  event: {},
};

export default CustomEvent;
