import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import css from '../FilterEditPanel.scss';
import TextInput from '../../v2/TextInput';
import HelpText from '../../legacy/Construction/Field/HelpText/HelpText';

const NumericRange = (props) => {
  const {
    labels,
    value,
    helpText,
    onChange,
    icons,
    optional = false,
  } = props;
  function rangeStart(event) {
    let startValue = event.target.value;
    if (event.type === 'blur') {
      startValue = event.target.value || 0;
    }
    onChange({ ...value, startValue });
  }
  function rangeEnd(event) {
    let endValue = event.target.value;
    if (event.type === 'blur') {
      endValue = (Number(event.target.value) !== 0) ? event.target.value : '';
    }
    onChange({ ...value, endValue });
  }
  return (
    <div className={css.FilterEditPanel__dateInput}>
      <div className={css.FilterEditPanel__dateSeparator}>
        <div className={classnames(
          css.FilterEditPanel__filtersLabel, { [css['FilterEditPanel__numericFiltersLabel--icon']]: icons.startIcon },
        )}
        >
          {labels.startLabel}
          {optional
            && <span className={css.FilterEditPanel__numericRangeLabelOptional}> - Optional</span>}
        </div>
        <div className={!icons.startIcon ? css.FilterEditPanel__textInput : null}>
          <TextInput
            data-testid="numeric-range-start"
            onChange={rangeStart}
            onBlur={rangeStart}
            value={value.startValue}
            label=""
            helpText={helpText.startHelp}
            {...icons.startIcon ? {
              icon: icons.startIcon,
              iconOrientation: 'left',
            } : {
              placeholder: 'Min Value',
            }}
          />
        </div>
      </div>
      <div className={css.FilterEditPanel__dateSeparator}>
        <div className={classnames(
          css.FilterEditPanel__filtersLabel, { [css['FilterEditPanel__numericFiltersLabel--icon']]: icons.startIcon },
        )}
        >
          {labels.endLabel}
          {optional
            && <span className={css.FilterEditPanel__numericRangeLabelOptional}> - Optional</span>}
        </div>
        <div className={!icons.endIcon ? css.FilterEditPanel__textInput : null}>
          <TextInput
            data-testid="numeric-range-end"
            onChange={rangeEnd}
            onBlur={rangeEnd}
            value={value.endValue}
            label=""
            helpText={helpText.endHelp}
            {...icons.endIcon ? {
              icon: icons.endIcon,
              iconOrientation: 'left',
            } : {
              placeholder: 'Max Value',
            }}
          />
        </div>
      </div>
      {(Number(value.endValue) !== 0 && Number(value.endValue) < Number(value.startValue)) && (
        <HelpText fieldValidation="error">
          <div>
            Second value should be greater than or equal to first value.
          </div>
        </HelpText>
      )}
    </div>
  );
};

NumericRange.propTypes = {
  labels: PropTypes.shape({
    startLabel: PropTypes.string,
    endLabel: PropTypes.string,
  }),
  helpText: PropTypes.shape({
    startHelp: PropTypes.string,
    endHelp: PropTypes.string,
  }),
  value: PropTypes.shape({
    startValue: PropTypes.string,
    endValue: PropTypes.string,
  }),
  icons: PropTypes.shape({
    startIcon: PropTypes.string,
    endIcon: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  optional: PropTypes.bool,
};

NumericRange.defaultProps = {
  labels: {
    startLabel: 'Start',
    endLabel: 'End',
  },
  value: {
    startValue: '0',
    endValue: '',
  },
  helpText: {
    startHelp: '',
    endHelp: '',
  },
  icons: {
    startIcon: '',
    endIcon: '',
  },
  optional: false,
};

export default NumericRange;
