import preferenceDefault from './preferences.default';
import preferenceLocalStorage from './preferences.local-storage';
import { Props } from './DataGrid';

export interface ColumnPreference {
  id: string;
  width?: number;
  sorted?: boolean;
  sortDirection?: 'asc' | 'desc';
}

export type FilterValue = string | string[] | number | number[];

interface FilterPreference {
  id: string;
  value?: FilterValue;
}

export interface TabPreference {
  id: string;
  columns?: ColumnPreference[];
  selectedItems?: number[] | string[];
  page?: number;
  perPage?: number;
  view?: 'map' | 'list';
}

export interface SavedFilter {
  name: string;
  action?: string;
  filters: FilterPreference[];
}

export interface Preferences {
  version: number;
  tabs?: TabPreference[];
  defaultTab?: string;
  searchText?: string;
  filters?: FilterPreference[];
  savedFilters?: SavedFilter[];
}

export interface PreferenceFactory {
  load: (props: Props, prefs?: Preferences) => Promise<Preferences> | Preferences | undefined;
  save?: (props: Props, prefs: Preferences) => Promise<void> | void;
}

const factories: PreferenceFactory[] = [
  preferenceDefault,
  preferenceLocalStorage,
];

/**
 * Make sure all tabs have preference objects, and any preferences for
 * tabs that no longer exist are removed.
 *
 * @param {Props} props
 * @param {Preferences} props
 * @return {void}
 */
function reconcileTabs(props: Props, prefs: Preferences): void {
  if (!prefs.tabs) {
    prefs.tabs = [];
  }

  const prefTabs = prefs.tabs.map(({ id }) => id);
  props.tabs.forEach((tab) => {
    if (!prefTabs.includes(tab.id)) {
      (prefs.tabs || []).push({ id: tab.id });
    }
  });

  const propTabs = props.tabs.map(({ id }) => id);
  prefs.tabs.forEach(({ id }, index) => {
    if (!propTabs.includes(id)) {
      (prefs.tabs || []).splice(index, 1);
    }
  });
}

export async function save(props: Props, prefs: Preferences) {
  await Promise.all(factories.map(async (factory) => {
    if (factory.save) {
      return factory.save(props, prefs);
    }

    return Promise.resolve(null);
  }));
}

export async function load(props: Props): Promise<Preferences> {
  let prefs: Preferences | undefined;

  for (const factory of factories) {
    prefs = await factory.load(props, prefs);
  }

  if (prefs === undefined) {
    throw new Error('Datagrid: no factory responded with preferences!');
  }

  reconcileTabs(props, prefs);

  return prefs;
}
