import PropTypes from 'prop-types';
import React from 'react';
import memobind from 'memobind';
import { navigate } from './constants';
import {
  Button,
  ButtonBar,
  ButtonGroup,
  Heading,
  HorizontalList,
  HorizontalListItem,
  Median,
  MedianAlpha,
  MedianOmega,
} from '..';
import IconButton from '../IconButton';
import css from './Toolbar.scss';

const messages = {
  today: 'Today',
  previous: 'Back',
  next: 'Next',
  month: 'Month',
  week: 'Week',
  day: 'Day',
};

class Toolbar extends React.Component {
  constructor(props) {
    super(props);
    this.view = this.view.bind(this);
    this.navigate = this.navigate.bind(this);
  }

  navigate = (action) => {
    const { onNavigate } = this.props;
    onNavigate(action);
  }

  view = (view) => {
    const { onView } = this.props;
    onView(view);
  }

  viewNamesGroup() {
    const { views } = this.props;
    const viewNames = views;
    const { view } = this.props;

    if (viewNames.length > 1) {
      return (
        <ButtonBar menuDisabled>
          {
            viewNames.map((name) => (
              <Button
                active={view === name}
                key={name}
                onClick={memobind(this, 'view', name)}
                dataTestid={messages[name]}
              >
                {messages[name]}
              </Button>
            ))
          }
        </ButtonBar>
      );
    }
    return null;
  }

  render() {
    const { label } = this.props;

    return (
      <div className={css.Toolbar}>
        <Median>
          <MedianAlpha>
            <HorizontalList spacing={1}>
              <HorizontalListItem>
                <IconButton
                  title={messages.previous}
                  name="back"
                  onClick={memobind(this, 'navigate', navigate.PREVIOUS)}
                />
              </HorizontalListItem>
              <HorizontalListItem>
                <IconButton
                  title={messages.next}
                  name="forward"
                  onClick={memobind(this, 'navigate', navigate.NEXT)}
                />
              </HorizontalListItem>
              <HorizontalListItem>
                <Heading
                  styleLevel="h4"
                >
                  {label}
                </Heading>
              </HorizontalListItem>
            </HorizontalList>
          </MedianAlpha>

          <MedianOmega>
            <ButtonGroup>
              <ButtonBar>
                <Button
                  label={messages.today}
                  dataTestid={messages.today}
                  onClick={memobind(this, 'navigate', navigate.TODAY)}
                />
              </ButtonBar>
              {this.viewNamesGroup(messages)}
            </ButtonGroup>
          </MedianOmega>
        </Median>
      </div>
    );
  }
}

Toolbar.propTypes = {
  view: PropTypes.string.isRequired,
  views: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.node.isRequired,
  onNavigate: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
};

export default Toolbar;
