import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Pagination from './Pagination';
import Slide from './Slide';
import { actionType, slideType } from './types';
import wrapEventHandler, { ARG_ORDER } from '../utils/wrapEventHandler';
import Css from './styles.scss';

export default class Carousel extends PureComponent {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    actions: PropTypes.arrayOf(actionType),
    onSlideSelect: PropTypes.func,
    pageLimit: PropTypes.number,
    perPage: PropTypes.oneOf([1, 4, 16]),
    selectedSlide: slideType,
    slides: PropTypes.arrayOf(slideType),
  }

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    actions: [],
    onSlideSelect: () => {},
    pageLimit: 8,
    perPage: 1,
    selectedSlide: {},
    slides: [],
  }

  constructor(props) {
    super(props);

    const {
      selectedSlide = {},
    } = props;

    this.state = {
      currentPage: {},
      selectedSlide,
    };
  }

  getPages = ({
    perPage, selectedSlide, slides, totalPages,
  }) => this.getPagesRange({ totalPages }).map((page) => {
    const start = (page - 1) * perPage;
    const end = page * perPage;
    const slicedSlides = slides.slice(start, end).map(
      (slide) => (slide.id === selectedSlide.id
        ? { ...slide, selected: true }
        : slide),
    );
    return { id: page, slides: slicedSlides };
  })

  getPagesRange = ({ totalPages }) => Array.from({ length: totalPages }, (_, k) => k + 1)

  getTotalPages = ({ totalItems, perPage }) => Math.ceil(totalItems / perPage)

  changeSlide(props) {
    const { onSlideSelect } = this.props;
    const { slide } = props;
    this.setState(
      { selectedSlide: slide },
      () => onSlideSelect({ slide }),
    );
  }

  changePage(props) {
    const { onSlideSelect } = this.props;
    const { page } = props;
    this.setState(
      {
        currentPage: page,
        selectedSlide: page.slides.find((slide) => slide.selected) || page.slides[0],
      },
      () => onSlideSelect({
        slide: page.slides.find((slide) => slide.selected) || page.slides[0],
      }),
    );
  }

  render() {
    const {
      actions = [],
      pageLimit = 8,
      perPage = 1,
      slides = [],
    } = this.props;
    const {
      currentPage = {},
      selectedSlide = {},
    } = this.state;
    slides.forEach((slide, index) => {
      // eslint-disable-next-line no-param-reassign
      slide.index = index + 1;
    });
    const totalPages = this.getTotalPages({ totalItems: slides.length, perPage });
    const pages = this.getPages({
      perPage, selectedSlide, slides, totalPages,
    });
    return (
      <div className={Css.Carousel}>
        <div className={Css.Carousel__Wrap}>
          <div className={Css.Carousel__Slides}>
            {(currentPage.slides || []).map((cpSlide) => (
              <Slide
                actions={actions}
                key={cpSlide.id}
                onSlideSelect={wrapEventHandler(this.changeSlide, [], ARG_ORDER.ARGS_FIRST, this)}
                perPage={perPage}
                selectedSlide={selectedSlide}
                slide={cpSlide}
              />
            ))}
          </div>
        </div>
        <Pagination
          onPageChange={wrapEventHandler(this.changePage, [], ARG_ORDER.ARGS_FIRST, this)}
          pageLimit={pageLimit}
          pages={pages}
          perPage={perPage}
          totalPages={totalPages}
        />
      </div>
    );
  }
}
