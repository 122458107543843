import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from '../index';
import css from './styles.scss';
import applyTruncation, { shouldTruncate } from '../utils/truncate';

const TRUNCATE_THRESHOLD_SMALL = 20;
const TRUNCATE_THRESHOLD_LARGE = 50;

const typeMapping = {
  info: {
    icon: 'infoHollow',
    class: 'Chip--info',
  },
  success: {
    icon: 'insuranceIcon',
    class: 'Chip--success',
  },
  successDark: {
    icon: 'completeSolid',
    class: 'Chip--success--dark',
  },
  warning: {
    icon: 'alertHollow',
    class: 'Chip--warning',
  },
  warningDark: {
    icon: 'completeSolid',
    class: 'Chip--warning--dark',
  },
  defaultDark: {
    icon: 'add',
    class: 'Chip--default--dark',
  },
  critical: {
    icon: 'warningHollow',
    class: 'Chip--critical',
  },
  approved: {
    icon: 'check',
    class: 'Chip--active',
  },
  denied: {
    icon: 'block',
    class: 'Chip--active',
  },
  none: {
    icon: '',
    class: '',
  },
};

const Chip = ({
  id,
  label,
  icon,
  iconRight,
  value,
  onClick,
  onDismiss,
  active,
  type,
  hideCloseButton,
  truncateText,
  textStrikethrough,
  customLeftNode,
  size,
  isSingleFilter,
  cursor,
  hideIcon = false,
}) => {
  function handleClick(event) {
    return onClick(event, id);
  }
  function onCloseIconClick(event) {
    event.stopPropagation();
    onDismiss(event, id);
  }
  const TRUNCATE_THRESHOLD = isSingleFilter ? TRUNCATE_THRESHOLD_LARGE : TRUNCATE_THRESHOLD_SMALL;
  return (
    <div
      role="presentation"
      className={classNames(css.Chip, css[typeMapping[type].class], {
        [css['Chip--active']]: active,
        [css['Chip--Small']]: size === 'sm',
        [css['Chip--ESmall']]: size === 'xs',
        [css['Chip--cursor--default']]: cursor !== true,
      })}
      data-testid={`chip-${type}`}
      onClick={handleClick}
    >
      {((type !== 'none' && !customLeftNode && !hideIcon) || (icon && !hideIcon)) && (
        <div className={css.Chip__Icon}>
          <Icon
            block
            name={icon || typeMapping[type].icon}
            size={size === 'md' ? 22 : 18}
          />
        </div>
      )}
      {(customLeftNode) && customLeftNode}
      <div>
        {label.length > 0
        && (
          <span className={classNames(css.Chip__Label, textStrikethrough && css['Chip--TextStrikethrough'])}>
            {`${applyTruncation(label, truncateText, TRUNCATE_THRESHOLD)}${shouldTruncate(label, TRUNCATE_THRESHOLD) ? ' :' : ':'}`}
          </span>
        )}
        <span className={classNames(css.Chip__Value, textStrikethrough && css['Chip--TextStrikethrough'])}>
          {
            Array.isArray(value) && value.length > 1
              ? `${applyTruncation(value[0].toString(), truncateText, TRUNCATE_THRESHOLD)} + ${value.length - 1}`
              : applyTruncation(value.toString(), truncateText, TRUNCATE_THRESHOLD)
          }
        </span>
      </div>
      {!hideCloseButton
      && (
        <div
          role="presentation"
          className={css.Chip__CloseIcon}
          onClick={onCloseIconClick}
        >
          <Icon
            block
            name="close"
            size={16}
          />
        </div>
      )}
      {hideCloseButton && iconRight && (
        <div className={classNames(css.Chip__Icon, css.Chip__IconRight)}>
          <Icon
            block
            name={iconRight}
            size={size === 'md' ? 22 : 18}
          />
        </div>
      )}
    </div>
  );
};

Chip.propTypes = {
  /** The state of the chip */
  active: PropTypes.bool,
  /** Hide the close button */
  hideCloseButton: PropTypes.bool,
  /** The id of the chip */
  id: PropTypes.string.isRequired,
  /** The label or super text of the chip */
  label: PropTypes.string,
  /** The icon of the chip */
  icon: PropTypes.string,
  /** The icon at the right of the chip */
  iconRight: PropTypes.string,
  /** Callback called when the chip is clicked */
  onClick: PropTypes.func,
  /** Callback called when the chip is dismissed */
  onDismiss: PropTypes.func,
  /** The size of the chip */
  size: PropTypes.oneOf([
    'sm',
    'md',
  ]),
  /** Add strikethrough on the chip text */
  textStrikethrough: PropTypes.bool,
  /** Truncate the text of chip */
  truncateText: PropTypes.bool,
  /** Type of the chip */
  type: PropTypes.oneOf([
    'none',
    'info',
    'success',
    'warning',
    'critical',
    'approved',
    'denied',
  ]),
  /** The subtext of the chip */
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.string,
    ),
  ]),
  /** Provide a custom element to show far left such as a custom icon */
  customLeftNode: PropTypes.oneOfType([undefined, Node]),
  isSingleFilter: PropTypes.bool,
  cursor: PropTypes.bool,
  hideIcon: PropTypes.bool,
};

Chip.defaultProps = {
  active: false,
  hideCloseButton: false,
  label: '',
  icon: '',
  iconRight: '',
  onClick: () => {},
  onDismiss: () => {},
  size: 'md',
  textStrikethrough: false,
  truncateText: true,
  type: 'none',
  value: '',
  customLeftNode: undefined,
  isSingleFilter: false,
  cursor: true,
  hideIcon: false,
};

export default Chip;
