import React from 'react';
import PropTypes from 'prop-types';
import { onlyUpdateForKeys, compose, withContext } from 'recompose';
import { TinyBand } from '../NotificationBand.jsx';

const StatusBand = onlyUpdateForKeys(['type'])(({ type, onClick, body, children }) => (
    <TinyBand onClick={onClick} type={type} body={body || children} />
    ));

const StatusBandComposed = compose(
        withContext({ isInStatusBand: PropTypes.bool }, () => ({ isInStatusBand: true }))
    )(StatusBand);

StatusBandComposed.propTypes = {
    type: PropTypes.oneOf(['danger', 'warning', 'default', 'success', 'info', 'secondary']),
    onClick: PropTypes.func,
};
StatusBandComposed.defaultProps = {
    type: 'danger',
    onClick: null,
};

export default StatusBandComposed;
