import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../Checkbox';
import Styles from './styles.scss';

const CheckboxComponent = ({
  option,
  onSelect,
  onFocus,
  isFocused,
  values,
}) => {
  const handleMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!option?.disabled) {
      onSelect(option, event);
    }
  };

  const handleMouseEnter = (event) => {
    onFocus(option, event);
  };

  const handleMouseMove = (event) => {
    if (isFocused) return;
    onFocus(option, event);
  };

  const currentValues = [];
  values.forEach((item) => currentValues.push(item?.value));

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={Styles.CheckboxContainer}
      data-testid="custom-option"
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      onTouchStart={handleMouseEnter}
      onTouchEnd={handleMouseDown}
    >
      <div style={{ pointerEvents: 'none' }}>
        <Checkbox
          options={[option]}
          value={currentValues.includes(option?.value) ? [option?.value] : []}
        />
      </div>
      {!!option?.suggested && (
        <div className={Styles.OptionNote}>
          <span className={Styles.OptionNoteLink}>
            Suggested
          </span>
        </div>
      )}
    </div>
  );
};

CheckboxComponent.propTypes = {
  values: PropTypes.array,
  isFocused: PropTypes.bool,
  onFocus: PropTypes.func,
  onSelect: PropTypes.func,
  option: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
    suggested: PropTypes.bool,
    disabled: PropTypes.bool,
  }),
};

CheckboxComponent.defaultProps = {
  values: [],
  isFocused: false,
  onFocus: () => { /* null */ },
  onSelect: () => { /* null */ },
  option: undefined,
};

export default React.memo(CheckboxComponent);
