import PropTypes from 'prop-types';
import React from 'react';
import Median from '../../Median';
import MedianAlpha from '../../MedianAlpha';
import MedianOmega from '../../MedianOmega';
import IconButton from '../../IconButton';

function NavBar({
  className,
  localeUtils,
  onNextClick,
  onPreviousClick,
  previousMonth,
}) {
  const year = previousMonth.getMonth() < 11
    ? previousMonth.getFullYear()
    : previousMonth.getFullYear() + 1;
  const month = previousMonth.getMonth() < 11
    ? previousMonth.getMonth() + 1
    : 0;
  function clickPrevious() {
    onPreviousClick();
  }
  function clickNext() {
    onNextClick();
  }
  return (
    <div className={className}>
      <Median>
        <MedianAlpha>
          {localeUtils.getMonths()[month]}
          &nbsp;
          {year}
        </MedianAlpha>
        <MedianOmega>
          <span style={{ textAlign: 'right' }}>
            <IconButton
              name="previous"
              size="sm"
              onClick={clickPrevious}
            />
            <IconButton
              name="next"
              size="sm"
              onClick={clickNext}
            />
          </span>
        </MedianOmega>
      </Median>
    </div>
  );
}

NavBar.propTypes = {
  className: PropTypes.string,
  localeUtils: PropTypes.shape({
    getMonths: PropTypes.func.isRequired,
    getFullYear: PropTypes.func.isRequired,
  }).isRequired,
  onNextClick: PropTypes.func.isRequired,
  onPreviousClick: PropTypes.func.isRequired,
  previousMonth: PropTypes.shape({
    getMonth: PropTypes.func.isRequired,
    getFullYear: PropTypes.func.isRequired,
  }).isRequired,
};

NavBar.defaultProps = {
  className: '',
};

export default NavBar;
