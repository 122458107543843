import React from 'react';
import Icon from '../../../../Icon';

const SelectedValueMulti = props => {
  let label = props.children[0];
  if (label.length > 20) {
    label = label.substring(0,20) + '...';
  }

  return (
    <div className="Select-value">
        <span
            className="Select-value-icon"
            aria-hidden="true"

            // https://developer.mozilla.org/en-US/docs/Web/API/Document/touchend_event
            // Looks like Safari, Opera and IE is not gonna support this as of 2019-09-19 [Hopefully they joins in soon]
            // Open for TODO/suggestion here for all browser compatibility

            onClick={() => props.onRemove(props.value)}
            onTouchEnd={() => props.onRemove(props.value)}>
            <Icon
                block
                name="close"
                size={14} />
        </span>
        <span
            id={props.id}
            className="Select-value-label"
            role="option"
            aria-selected="true"
            onClick={() => props.onRemove(props.value)}
            onTouchEnd={() => props.onRemove(props.value)}>
          {label}
        </span>
        <span className="Select-aria-only">&nbsp;</span>
    </div>);
};

export default SelectedValueMulti;
