import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class BoxHeader extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = { };
    }

    render() {
        const styles = this.context.boxStyles;
        return (
            <div className={styles.box__hdr}>
                {this.props.children}
            </div>
        );
    }
}

BoxHeader.contextTypes = {
    boxStyles: PropTypes.object,
};
