import React from 'react';
import PropTypes from 'prop-types';
import css from './styles.scss';
import InsightsChip from './InsightsChip';
import RangeDistributionBar, { getPlacementType } from './RangeDistributionBar';
import {
  Button,
  GroupCardListItem,
} from '../index';

const InsightsPopout = ({
  barLabel,
  button,
  buttonAction,
  buttonLabel,
  descriptionText,
  firstNumber,
  firstNumberTerm,
  id,
  messageStates,
  moreInfo,
  moreInfoHeader,
  moreInfoText,
  numberPrefix,
  numberSuffix,
  payRange,
  secondNumber,
  secondNumberTerm,
  zeroStateMessage,
}) => {
  const placement = getPlacementType(firstNumber, payRange);
  return (
    <div className={css['MSI__insights-popout']}>
      <div className={`${css['MSI__insights-popout-holder']} ${css['MSI_insights-popout-padding-top-md']}`}>
        <InsightsChip
          id={`${id}-chip`}
          currentPrice={firstNumber}
          payRange={payRange}
          messageStates={messageStates}
        />
      </div>
      {(firstNumber === 0 || payRange.low === 0 || payRange.high === 0) && (
        <div className={`${css['MSI__insights-popout-description']} ${css['MSI_insights-popout-padding-top-md']}`}>
          {zeroStateMessage}
        </div>
      )}
      {firstNumber > 0 && payRange.low > 0 && payRange.high > 0 && (
        <>
          <RangeDistributionBar
            id={`${id}-bar`}
            firstNumber={firstNumber}
            secondNumber={secondNumber}
            range={payRange}
          />
          <div className={`${css['MSI__insights-popout-label']}`}>
            {barLabel}
          </div>
          <div className={`${css['MSI__insights-popout-price']} ${css['MSI_insights-popout-padding-top-xs']}`}>
            {numberPrefix}
            {payRange.low}
            &nbsp;-&nbsp;
            {numberPrefix}
            {payRange.high}
            <span className={css['MSI__insights-popout-price-label']}>
              &nbsp;
              {numberSuffix}
            </span>
          </div>
          <div className={css['MSI_insights-popout-padding-top-xs']}>
            <GroupCardListItem
              label={(
                <div className={css['MSI_insights-popout-legend']}>
                  <div
                    className={css[`MSI__insights-bar-circle-${placement}`]}
                    style={{
                      right: 0,
                    }}
                  >
                    &nbsp;
                  </div>
                  <div>
                    &nbsp;
                    {firstNumberTerm}
                  </div>
                </div>
              )}
              data={`${numberPrefix}${firstNumber.toFixed(2)} ${numberSuffix}`}
              type="default"
              actions={[]}
              border="none"
              middleContent=""
              hideExpander
              dottedLine
            />
            <GroupCardListItem
              label={(
                <div className={css['MSI_insights-popout-legend']}>
                  <div
                    className={css['MSI__insights-bar-triangle']}
                    style={{
                      right: 0,
                    }}
                  >
                    &nbsp;
                  </div>
                  <div>
                    &nbsp;
                    {secondNumberTerm}
                  </div>
                </div>
              )}
              data={secondNumber > 0
                ? `${numberPrefix}${secondNumber.toFixed(2)} ${numberSuffix}`
                : 'n/a'}
              type="default"
              actions={[]}
              border="none"
              middleContent=""
              hideExpander
              dottedLine
            />
          </div>
          <div className={`${css['MSI__insights-popout-description']} ${css['MSI_insights-popout-padding-top-md']}`}>
            {descriptionText}
          </div>
        </>
      )}
      {moreInfo && (
        <div className={css['MSI__insights-popout-explore']}>
          <div className={`${css['MSI__insights-popout-explore-header']} ${css['MSI_insights-popout-padding-top-md']}`}>
            {moreInfoHeader}
          </div>
          <div className={css['MSI__insights-popout-explore-text']}>
            {moreInfoText}
          </div>
        </div>
      )}
      {button && (
        <div className={`${css['MSI__insights-popout-button']} ${css['MSI_insights-popout-padding-top-sm']}`}>
          <Button
            type="secondary"
            label={buttonLabel || 'Click'}
            onClick={buttonAction}
          />
        </div>
      )}
    </div>
  );
};

InsightsPopout.propTypes = {
  barLabel: PropTypes.string,
  button: PropTypes.bool,
  buttonAction: PropTypes.func,
  buttonLabel: PropTypes.string,
  descriptionText: PropTypes.string,
  firstNumber: PropTypes.number,
  firstNumberTerm: PropTypes.string,
  id: PropTypes.string,
  numberPrefix: PropTypes.string,
  numberSuffix: PropTypes.string,
  messageStates: PropTypes.shape({
    zero: PropTypes.string,
    low: PropTypes.string,
    normal: PropTypes.string,
    high: PropTypes.string,
  }),
  moreInfo: PropTypes.bool,
  moreInfoHeader: PropTypes.string,
  moreInfoText: PropTypes.string,
  payRange: PropTypes.shape({
    low: PropTypes.number,
    high: PropTypes.number,
  }),
  secondNumber: PropTypes.number,
  secondNumberTerm: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  zeroStateMessage: PropTypes.string,
};

InsightsPopout.defaultProps = {
  barLabel: 'Average pay range',
  button: false,
  buttonAction: () => {},
  buttonLabel: '',
  descriptionText: 'Average pay range based on nearby work completed within the last 12 months',
  firstNumber: 0,
  firstNumberTerm: 'Work order pay',
  id: 'insights-popout',
  messageStates: {
    zero: 'Rate comparison unavailable',
    low: 'Below standard rate',
    normal: 'Above standard rate',
    high: 'Standard rate',
  },
  moreInfo: false,
  moreInfoHeader: 'Explore MarketSmart™ Insights',
  moreInfoText: '',
  numberPrefix: '$',
  numberSuffix: '/ hr',
  payRange: {
    low: 0,
    high: 0,
  },
  secondNumber: 0,
  secondNumberTerm: <span>My company&#39;s average pay</span>,
  zeroStateMessage: 'We\'re unfortunately unable to calculate range with given data.',
};

export default InsightsPopout;
