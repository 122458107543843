
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import CSSModules from 'react-css-modules';
import classes from './styles/NotificationBand.scss';

export const NotificationBandTypes = ['danger', 'warning', 'default', 'success', 'info', 'infoLight', 'secondary', 'warning-orange', 'gray10'];

export default class NotificationBand extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: this.props.isVisible,
            pointerLeft: 0,
        };
        this.pointedElement = null;
        this.windowSetArrowPosition = this.setArrowPosition.bind(this);
    }

    getChildContext() {
        return {
            isCloseEnabled: this.props.isCloseEnabled,
        };
    }

    getElementOffset($element) {
        if ($element) {
            return $element.getBoundingClientRect().left;
        }
    }

    getOffsetMiddle($element) {
        const elOffset = this.getElementOffset($element);
        const elWidth = $element.offsetWidth;
        return (elOffset + (elWidth / 2));
    }

    setPointed($el) {
        if (!this.pointedElement && $el) {
            this.pointedElement = $el;
        }
    }

    setArrowLeftState(pointedOffsetMiddle, bandOffset) {
        const arrowLeft = pointedOffsetMiddle - bandOffset;
        if (this.state.pointerLeft !== arrowLeft) {
            this.setState({ pointerLeft: arrowLeft });
        }
    }

    setArrowPosition($el) {
        if ($el) {
            this.setPointed($el);
        }
        if (this.pointedElement) {
            if (typeof this.pointedElement.getBoundingClientRect === 'function') {
                const getOffsetMiddle = this.getOffsetMiddle(this.pointedElement);
                const bandOffset = this.getElementOffset(ReactDOM.findDOMNode(this.band));
                this.setArrowLeftState(getOffsetMiddle, bandOffset);
            }
        }
    }

    componentDidMount() {
        this.setArrowPosition(this.props.pointedElement);
        window.addEventListener('resize', this.windowSetArrowPosition);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.windowSetArrowPosition);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isVisible !== this.state.isVisible) {
            this.setState({ isVisible: nextProps.isVisible });
        }
        this.setArrowPosition(nextProps.pointedElement);
    }

    componentDidUpdate() {
        this.setArrowPosition();
    }

    closeNotification() {
        this.setState({ isVisible: false });
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    render() {
        let pointerStyles = {};
        if (this.state.pointerLeft > 0 && this.props.pointedElement) {
            pointerStyles = {
                right: 'auto',
                left: `${this.state.pointerLeft}px`,
            };
        }
        return (
            <div
                ref={band => { this.band = band; }}
                className={classNames({
                    [classes.notificationBand]: true,
                    [classes['notificationBand--pointerDisabled']]: !this.props.fixed && !this.props.pointedElement,
                    [classes['notificationBand--isVisible']]: this.state.isVisible,
                    [classes[`notificationBand--${this.props.notificationType}`]]: this.props.notificationType,
                    [classes['notificationBand--fixed']]: this.props.fixed === true,
                })}>
                {(this.props.pointedElement && !this.props.fixed) &&
                    <div className={classes.notificationBand__pointer} style={pointerStyles} />
                }

                { this.props.isCloseEnabled ?
                    <button onClick={this.closeNotification.bind(this)} className={classes.notificationBand__close}>
                        <span className="icon-x" />
                        <span className="u-srOnly">Close</span>
                    </button>
                    :
                    null
                }
                {this.props.children}
            </div>
        );
    }
}

NotificationBand.propTypes = {
    notificationType: PropTypes.oneOf(NotificationBandTypes),
    isVisible: PropTypes.bool,
    isCloseEnabled: PropTypes.bool,
    fixed: PropTypes.bool,
    onClose: PropTypes.func,
};

NotificationBand.defaultProps = {
    notificationType: 'danger',
    isVisible: false,
    isCloseEnabled: true,
    fixed: false,
    onClose: () => {},
};

NotificationBand.childContextTypes = {
    isCloseEnabled: PropTypes.bool,
};
