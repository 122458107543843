import React from 'react';
import TextInput from '../TextInput/TextInput.jsx';
import Icon from '../Icon/Icon.jsx';
import PropTypes from 'prop-types';
import TextInputWrapper from '../TextInputWrapper/TextInputWrapper.jsx';

const Search = props => (
    <TextInputWrapper>
        <TextInput
            hasIconRight
            type="search"
            {...props} />
        <Icon
            onClick={props.onSubmit}
            icon="search"
            orientation="right" />
    </TextInputWrapper>
);

Search.propTypes = {
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onKeyUp: PropTypes.func,
    onSubmit: PropTypes.func,
    value: PropTypes.string,
};

Search.defaultProps = {
    disabled: false,
    onChange: () => {},
    onKeyUp: () => {},
    value: '',
};

export default Search;
