import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TetherComponent from '../../TetherComponent';
import Tip from '../../Tip';
import IconButton from '../../IconButton';
import css from './styles.scss';

const positions = {
  top: {
    attachment: 'bottom center',
    targetAttachment: 'top center',
  },
  right: {
    attachment: 'middle left',
    targetAttachment: 'middle right',
  },
};

const scrollDisabler = (e) => {
  e.preventDefault();
  // we need the line below so mobile devices preventDefault
  e.returnValue = false;
};

class Tooltip extends Component {
  constructor(props) {
    super(props);
    this.onDismiss = this.onDismiss.bind(this);
    this.onDocClick = this.onDocClick.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.disableScroll = this.disableScroll.bind(this);
    this.tooltipRef = React.createRef();
    this.elementsThatScroll = [
      document,
      document.getElementById('js-page__body'),
    ];
  }

  componentDidMount() {
    this.mountTimeout = setTimeout(() => {
      document.addEventListener('click', this.onDocClick);
      document.addEventListener('keyup', this.onKeyUp);
      this.disableScroll();
    }, 200);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onDocClick);
    document.removeEventListener('keyup', this.onKeyUp);
    window.clearTimeout(this.mountTimeout);
    this.enableScroll();
  }

  disableScroll() {
    this.elementsThatScroll.forEach((element) => {
      this.disableOrEnableScrollingOnElement(element, true);
    });
  }

  enableScroll() {
    this.elementsThatScroll.forEach((element) => {
      this.disableOrEnableScrollingOnElement(element, false);
    });
  }

  disableOrEnableScrollingOnElement = (elem, disable) => {
    const events = [
      'DOMMouseScroll',
      'wheel',
      'touchmove',
    ];
    if (elem) {
      events.forEach((event) => {
        if (disable) {
          elem.addEventListener(event, scrollDisabler);
        } else {
          elem.removeEventListener(event, scrollDisabler);
        }
      });
    }
  }

  setRef(elemName, refElem) {
    this[elemName] = refElem;
  }

  onDocClick(e) {
    if (this.tooltipRef && !this.tooltipRef.current.contains(e.target)) {
      this.onDismiss();
    }
  }

  onDismiss() {
    const { onDismiss } = this.props;
    if (typeof onDismiss === 'function') {
      onDismiss();
    }
  }

  onKeyUp(e) {
    const { onDismiss } = this.props;
    // dismiss when keying escape
    if (e.keyCode === 27) {
      onDismiss();
    }
  }

  render() {
    const {
      children,
      height,
      left,
      position,
      showClose,
      showOverlay,
      top,
      width,
    } = this.props;

    const jsx = (
      <div>
        <TetherComponent
          isBelowPanel
          attachment={positions[position].attachment}
          targetAttachment={positions[position].targetAttachment}
          constraints={[
            {
              to: 'window',
              attachment: 'together',
            },
          ]}
        >
          <div
            className={classNames(css.Tooltip, {
              [css.Tooltip__pullCenter]: position === 'top',
            })}
            style={{
              top: `${top}px`,
              left: `${left}px`,
              width: `${width}px`,
              height: `${height}px`,
            }}
          />
          <div
            ref={this.tooltipRef}
          >
            <Tip isFlush>
              {showClose
                && (
                <span className={css.Tooltip__close}>
                  <IconButton
                    size="sm"
                    title="close"
                    name="close"
                    onClick={this.onDismiss}
                  />
                </span>
                )}
              <div className={css.Tooltip__inner}>
                {children}
              </div>
            </Tip>
          </div>
        </TetherComponent>
        {showOverlay
          && (
          <div
            onClick={this.onDismiss}
            className={css.Tooltip__overlay}
            aria-hidden="true"
          />
          )}
      </div>
    );
    // render to a portal because we get x and y offsets from the body not the viewport
    return ReactDOM.createPortal(
      jsx,
      document.body,
    );
  }
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.number,
  left: PropTypes.number,
  onDismiss: PropTypes.func.isRequired,
  position: PropTypes.oneOf(Object.keys(positions)),
  showClose: PropTypes.bool,
  showOverlay: PropTypes.bool,
  top: PropTypes.number,
  width: PropTypes.number,
};

Tooltip.defaultProps = {
  height: 0,
  left: 0,
  position: 'top',
  showClose: false,
  showOverlay: false,
  top: 0,
  width: 0,
};

export default Tooltip;
