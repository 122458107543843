import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CSSTransitionGroup } from 'react-transition-group';
import CSSModules from 'react-css-modules';
import classes from './FixedPopover.scss';
import isEqual from 'lodash/isEqual';

export class FixedPopoverTrigger extends Component {
    shouldComponentUpdate(nextProps) {
        return !isEqual(this.props.children.props, nextProps.children.props);
    }
    render() {
        return this.props.children;
    }
}

FixedPopoverTrigger.propTypes = {
    children: PropTypes.element.isRequired,
};

export class FixedPopover extends React.PureComponent {
    static propTypes = {
        showOnEvent: PropTypes.oneOf(['click', 'hover']),
        isOpen: PropTypes.bool,
        onChangeOpen: PropTypes.func,
        offset: PropTypes.shape({
            left: PropTypes.string,
            right: PropTypes.string,
            bottom: PropTypes.string,
        }),
        transitionName: PropTypes.string,
        menuWrapperWidth: PropTypes.string,
    };

    static defaultProps = {
        showOnEvent: 'click',
        isOpen: false,
        offset: {
            right: '0',
        },
        transitionName: 'FixedPopover-animation',
        animationDuration: Number(classes.NavAnimationDuration),
        menuWrapperWidth: 'auto',
    };

    constructor(props) {
        super(props);
        this.state = {
            isOpen: this.props.isOpen,
            arrowLeft: 0,
        };
        this.onDocClick = this.onDocClick.bind(this);
        this.setArrowPosition = this.setArrowPosition.bind(this);

        this.toggleVisibility = this.toggleVisibility.bind(this);
        this.turnOnVisiblity = this.turnOnVisiblity.bind(this);
        this.turnOffVisiblity = this.turnOffVisiblity.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        this.setArrowPosition();
        if (this.state.isOpen != prevState.isOpen && this.props.onChangeOpen) {
            this.props.onChangeOpen(this.state.isOpen);
        }
    }

    componentDidMount() {
        this.setArrowPosition();
        window.addEventListener('resize', this.setArrowPosition);
        document.addEventListener('click', this.onDocClick);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onDocClick);
        window.removeEventListener('resize', this.setArrowPosition);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.isOpen !== this.state.isOpen) {
            this.setState({ isOpen: newProps.isOpen });
        }
    }

    onDocClick(e) {
        const $tooltip = this.popoverTarget;
        const $root = this.popoverTrigger;
        if ($tooltip && $root) {
            if (this.state.isOpen && document.body.contains(e.target) && !$tooltip.contains(e.target) && !$root.contains(e.target)) {
                this.setState({ isOpen: false });
            }
        }
    }
    getElementOffset($element) {
        if ($element) {
            return $element.getBoundingClientRect().left;
        }
    }
    getOffsetMiddle($element) {
        const elOffset = this.getElementOffset($element);
        const elWidth = $element.offsetWidth;
        return (elOffset + (elWidth / 2));
    }
    setArrowPosition() {
        if (this.popoverTrigger) {
            const $el = this.popoverTrigger;
            if (typeof $el.getBoundingClientRect === 'function') {
                const arrowLeft = this.getOffsetMiddle($el);
                if (this.state.arrowLeft !== arrowLeft) {
                    this.setState({ arrowLeft });
                }
            }
        }
    }

    turnOnVisiblity() {
        this.setState({ isOpen: true });
    }

    turnOffVisiblity() {
        this.setState({ isOpen: false });
    }

    toggleVisibility() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    getTopOffset() {
        if (!this.popoverTrigger) {
            return '0px';
        }
        const height = this.popoverTrigger.clientHeight;
        const offsetTop = this.popoverTrigger.getBoundingClientRect().top;
        return `${(height + offsetTop)}px`;
    }

    render() {
        this.trigger = React.Children.map(this.props.children, (c, index) => {
            if (c.type.name === FixedPopoverTrigger.name) {
                let options = {};
                if (this.props.showOnEvent === 'click') {
                    options = {
                        onClick: this.toggleVisibility,
                    };
                } else if (this.props.showOnEvent === 'hover') {
                    options = {
                        onMouseEnter: this.turnOnVisiblity,
                        onMouseLeave: this.turnOffVisiblity,
                    };
                }
                const $child = React.Children.only(c.props.children);
                return React.cloneElement(c, {
                    children: React.cloneElement($child, options),
                });
            }
        });

        this.content = React.Children.map(this.props.children, (c, index) => c.type.name !== FixedPopoverTrigger.name && c);

        const contentStyles = {
            ...FixedPopover.defaultProps.offset,
            ...this.props.offset,
            ...{ top: this.getTopOffset() } };
        const pointerStyles = {
            left: `${this.state.arrowLeft}px`,
            top: contentStyles.top,
        };

        return (<div>
            <div styleName="FixedPopover__Trigger" ref={node => this.popoverTrigger = node}>
                {this.trigger}
            </div>
            <CSSTransitionGroup
                transitionName={this.props.transitionName}
                transitionAppear
                transitionLeave
                transitionAppearTimeout={this.props.animationDuration}
                transitionEnterTimeout={this.props.animationDuration}
                transitionLeaveTimeout={this.props.animationDuration}>
                {this.state.isOpen &&
                    <div ref={node => this.popoverTarget = node} styleName="FixedPopover__Content" style={contentStyles} key={1}>
                        <span styleName="FixedPopover__Trigger__pointer" style={pointerStyles} />
                        <div style={{ width: this.props.menuWrapperWidth }} styleName="FixedPopover__ContentWrapper">
                            {this.content}
                        </div>
                    </div>
                }
            </CSSTransitionGroup>
        </div>);
    }
}

export default CSSModules(FixedPopover, classes);
