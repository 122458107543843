
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import classNames from 'classnames';
import componentClassnames from './styles/StarRating.scss';

export default class StarRating extends Component {
    static propTypes = {
        initialValue: PropTypes.oneOf([0, 1, 2, 3, 4, 5]),
        color: PropTypes.oneOf(['yellow', 'green']),
        size: PropTypes.oneOf(['sm', 'md']),
        onChange: PropTypes.func,
    }

    static defaultProps = {
        initialValue: 5,
        color: 'yellow',
        size: 'sm',
        onChange: () => {},
    }

    constructor(props) {
        super(props);
        this.state = {
            rating: this.props.initialValue,
        };
        this.onRadioChange = this.onRadioChange.bind(this);
    }

    onRadioChange(e) {
        const radioValue = e.target.value;
        if (radioValue !== this.state.rating) {
            this.setState({ rating: radioValue });
            this.props.onChange(radioValue);
        }
    }

    getRadios() {
        const radios = [];
        for (let i = 1; i <= 5; i++) {
            radios.push(<span
                className={classNames([componentClassnames.StarRating__item], {
                    [componentClassnames['StarRating__item--md']]: this.props.size === 'md',
                })}
                key={i}>
                <input className="u-srOnly" onChange={this.onRadioChange} type="radio" id={`star${i}`} name="rating" value={i} />
                <label
                    className={classNames([componentClassnames.StarRating__label],
                        {
                            [componentClassnames['StarRating__label--yellow']]: this.props.color === 'yellow' && this.state.rating >= i,
                            [componentClassnames['StarRating__label--green']]: this.props.color === 'green' && this.state.rating >= i,
                        }
                )}
                    htmlFor={`star${i}`}
                    title={i}>{i} star</label>
            </span>);
        }
        return radios;
    }

    render() {
        return (
            <div className={componentClassnames.StarRating}>
                { this.getRadios() }
            </div>
        );
    }
}
