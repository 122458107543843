import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './ActionableRow/styles/ActionableRow.scss';
import Median from '../../Median';
import MedianAlpha from '../../MedianAlpha';
import MedianOmega from '../../MedianOmega';
import HorizontalList from '../../HorizontalList';
import HorizontalListItem from '../../HorizontalListItem';
import ActionableRowExpander from './ActionableRow/ActionableRowExpander.jsx';
import IconButton from '../../IconButton';
import Tooltip from '../../Tooltip';
import TooltipComponent from '../../TooltipComponent';

export default class ActionableRow extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = { };
    }

    getActions() {
        const spacing = 2;
        const additionalAction = this.props.additionalAction;

        const actions = (
          <HorizontalList spacing={spacing}>
            {this.props.actions.map((action, i) => {
              const icon = action.icon.replace("icon-", "");
              return (
                <HorizontalListItem key={i}>
                  {action.tooltip ? (
                    <Tooltip position={action.tooltipPosition}>
                      <IconButton
                        size="sm"
                        onClick={action.onClick}
                        name={icon}
                        color={action.fade ? "fade" : ""}
                      />
                      <TooltipComponent>{action.tooltip}</TooltipComponent>
                    </Tooltip>
                  ) : (
                    <IconButton
                      size="sm"
                      onClick={action.onClick}
                      name={icon}
                    />
                  )}
                </HorizontalListItem>
              );
            })}
              {/* put empty list item here to make things render correctly when there are no actions before the close action */}
              {this.props.actions.length === 0 &&
              <HorizontalListItem/>
              }
          </HorizontalList>
        );

        if (!this.props.expandable) {
            return actions;
        }

        return (
          <HorizontalList spacing={spacing} divided>
              <HorizontalListItem>{actions}</HorizontalListItem>
              <HorizontalListItem>
                  <IconButton
                    size='sm'
                    onClick={() => this.props.onExpanderClick()}
                    name={this.props.expanded ? 'collapse' : 'expand'}
                  />
              </HorizontalListItem>
          </HorizontalList>
        );
    }

    render() {
        return (
            <div>
                <Median
                    verticalFlush
                    noWrap
                    verticalAlign="top">
                    <MedianAlpha>
                        <div className={styles.ActionableRow__mainAndBody}>
                            {this.props.mainAction &&
                                <div className={styles.ActionableRow__main}>
                                    {this.props.mainAction}
                                </div>
                            }
                            {this.props.body &&
                                <div className={styles.ActionableRow__body}>
                                    {this.props.body}
                                </div>
                            }
                        </div>
                    </MedianAlpha>
                    {(this.props.actions.length > 0 || this.props.expandable) &&
                        <MedianOmega>
                            <div className={styles.ActionableRow__actions}>{this.getActions()}</div>
                        </MedianOmega>
                    }
                </Median>
            </div>
        );
    }
}

ActionableRow.propTypes = {
    actions: PropTypes.array,
    expandable: PropTypes.bool,
    expanded: PropTypes.bool,
    onExpanderClick: PropTypes.func,
};

ActionableRow.defaultProps = {
    actions: [],
    expandable: false,
    expanded: false,
    onExpanderClick: () => {},
};

export {
    ActionableRowExpander
};
