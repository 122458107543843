import React from 'react';
import { onlyUpdateForKeys } from 'recompose';
import componentClassnames from './Indicator/styles/Indicator.scss';
import classNames from 'classnames';

const Indicator = onlyUpdateForKeys(['complete', 'onClick', 'disabled'])(({ complete, onClick, disabled }) => {
    const indicatorClassNames = classNames({
        [componentClassnames.Indicator]: true,
        [componentClassnames['Indicator--complete']]: complete,
        [componentClassnames['Indicator--disabled']]: disabled,
        [componentClassnames['Indicator--clickable']]: onClick && !disabled,
        [componentClassnames['Indicator--hoverable']]: !disabled,
    });

    const iconClassnames = classNames({
        [componentClassnames.Indicator__icon]: true,
    });
    return (
        <div onClick={(disabled ? () => {} : onClick)} className={indicatorClassNames}>
            <span className={iconClassnames} />
        </div>
    );
});

export default Indicator;
