import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './styles.scss';
import {
  IconButton,
  Heading,
  BodyText,
} from '../index';
import Menu from '../Menu';

const GroupCardHeader = ({
  heading,
  subHeading,
  value,
  valueType,
  actions,
  type,
  border,
  hideExpander,
  spacing,
  styleLevel,
}) => (
  <div className={classNames(css.GroupCardHeader__container, {
    [css['GroupCardHeader__container--borderTop']]: border === 'top' || border === 'both',
    [css['GroupCardHeader__container--borderBottom']]: border === 'bottom' || border === 'both',
    [css['GroupCardHeader__container--info']]: type === 'info',
    [css['GroupCardHeader__container--warning']]: type === 'warning',
    [css['GroupCardHeader__container--critical']]: type === 'critical',
  })}
  >
    <div className={classNames(css.GroupCardHeader__content, {
      [css['GroupCardHeader--paddingZero']]: spacing === 'none',
    })}
    >
      <div className={css.GroupCardHeader__labelContent}>
        <div className={css['GroupCardHeader__labelContent--header']}>
          <Heading styleLevel={styleLevel === 'sm' ? 'h6' : 'h3'}>{heading}</Heading>
        </div>
        <div className={css['GroupCardHeader__labelContent--subHeader']}>
          <BodyText styleLevel="xs">
            {subHeading}
          </BodyText>
        </div>
      </div>
      <div className={classNames(css.GroupCardHeader__value, {
        [css['GroupCardHeader__value--positive']]: valueType === 'positive',
        [css['GroupCardHeader__value--negative']]: valueType === 'negative',
        [css['GroupCardHeader__value--strikethrough']]: valueType === 'strikethrough',
      })}
      >
        <Heading styleLevel={styleLevel === 'sm' ? 'h5' : 'h2'}>{value}</Heading>
      </div>
    </div>
    {hideExpander === false && (
      <div className={css.GroupCardHeader__actions}>
        {actions && actions.length > 1
        && (
        <Menu
          disableAutoWidth
          alignToRight
          label="More actions"
          menuItems={actions}
          closeOnSelection
          trigger={(
            <div>
              <IconButton
                name="moreVert"
              />
            </div>
          )}
        />
        )}
        {actions && actions.length === 1
        && (
          <IconButton
            onClick={actions[0].onClick}
            name={actions[0].icon}
          />
        )}
      </div>
    )}
  </div>
);

GroupCardHeader.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  value: PropTypes.string,
  valueType: PropTypes.oneOf(['positive', 'negative', 'default', 'strikethrough']),
  actions: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string,
    onClick: PropTypes.func,
    label: PropTypes.string,
    type: PropTypes.oneOf(['primary', 'secondary', 'critical', 'text']),
    disabled: PropTypes.bool,
    icon: PropTypes.string,
  })),
  type: PropTypes.oneOf(['info', 'warning', 'critical', 'default']),
  border: PropTypes.oneOf(['top', 'bottom', 'both', 'none']),
  hideExpander: PropTypes.bool,
  spacing: PropTypes.oneOf(['none', 'md']),
  styleLevel: PropTypes.oneOf(['default', 'sm']),
};

GroupCardHeader.defaultProps = {
  heading: '',
  subHeading: '',
  value: undefined,
  valueType: 'default',
  actions: undefined,
  type: 'default',
  border: 'none',
  hideExpander: false,
  spacing: 'md',
  styleLevel: 'default',
};

export default GroupCardHeader;
