import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { ContainerQuery } from 'react-container-query';
import remsToPixels from '../utils/remsToPixels';
import queryBuilder from '../utils/queryBuilder';
import classes from './styles.scss';
import getKeyFromChildContent from '../utils/getKeyFromChildContent';

export default class Grid extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getQuery() {
    function sortNumbers(a, b) {
      return a - b;
    }
    const { containerSizes } = this.props;
    const sizes = containerSizes.sort(sortNumbers);
    const query = {};
    sizes.forEach((sz, i) => {
      // if zero not included, add to beginning
      if (i === 0) {
        const offset = sz === 0 ? 1 : 0;
        query[0] = { maxWidth: remsToPixels(`${sizes[i + offset]}rem`) - 1 };
      }
      query[sz] = { minWidth: remsToPixels(`${sz}rem`) };
      if (i !== sizes.length - 1) {
        query[sz].maxWidth = remsToPixels(`${sizes[i + 1]}rem`) - 1;
      }
    });

    if (!sizes.length) {
      return null;
    }

    return query;
  }

  isContainerQuery() {
    const { containerSizes } = this.props;
    if (!containerSizes) {
      return false;
    }
    if (containerSizes.length === 0) {
      return false;
    }
    return true;
  }

  renderChildren(query) {
    const { props } = this;
    const { customChildKey } = props;
    return React.Children.map(props.children, (child) => {
      if (child) {
        return React.cloneElement(child, {
          query,
          key: customChildKey && child.key ? child.key : getKeyFromChildContent(child),
          vSpace: props.vSpace,
          divided: props.divided,
        });
      }

      return null;
    });
  }

  render() {
    let query = null;
    const {
      vSpace,
      divided,
      fillParent,
      containerSizes,
    } = this.props;
    const gridRootClassnames = classNames(classes.Grid__root, {
      [classes['Grid__root--vSpace']]: vSpace,
      [classes['Grid__root--divided']]: divided,
    });
    const gridClasses = classNames(classes.Grid, {
      [classes['Grid--fillParent']]: fillParent,
      [classes['Grid--divided']]: divided,
    });
    if (this.isContainerQuery()) {
      query = queryBuilder(containerSizes);
      return (
        <ContainerQuery query={query} style={{ position: 'static' }}>
          {(params) => (
            <div className={gridClasses}>
              <div className={gridRootClassnames}>
                {this.renderChildren(params)}
              </div>
            </div>
          )}
        </ContainerQuery>
      );
    }
    return (
      <div className={gridClasses}>
        <div className={gridRootClassnames}>{this.renderChildren(null)}</div>
      </div>
    );
  }
}

Grid.propTypes = {
  containerSizes: PropTypes.arrayOf(PropTypes.number),
  divided: PropTypes.bool,
  fillParent: PropTypes.bool,
  vSpace: PropTypes.bool,
  children: PropTypes.node,
  customChildKey: PropTypes.bool,
};

Grid.defaultProps = {
  containerSizes: [],
  divided: false,
  fillParent: false,
  vSpace: true,
  children: undefined,
  customChildKey: false,
};
