import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './styles.scss';
import Icon from '../Icon';

const PricingTable = ({
  titleColumns,
  actionColumns,
  cells,
  activeColumnIndex,
  stretch,
  columnActiveColors,
}) => {
  const getActiveStyle = (column) => {
    const borderRight = column === (activeColumnIndex - 1) ? 0 : undefined;
    const borderLeft = column === (activeColumnIndex + 1) ? 0 : undefined;
    const color = columnActiveColors ? columnActiveColors[column] : css[`activePkg${column}BorderColor`];
    return {
      borderLeft: activeColumnIndex === column ? `${css.borderWidthMd} solid ${color}` : borderLeft,
      borderRight: activeColumnIndex === column ? `${css.borderWidthMd} solid ${color}` : borderRight,
    };
  };

  const titleCells = titleColumns?.map((v, i) => {
    const bgColor = columnActiveColors ? columnActiveColors[i] : css[`activePkg${i}BorderColor`];
    return (
      <th
        key={String(i)}
        className={classNames(css.PricingTable__cell,
          css.PricingTable__header,
          css.PricingTable__headerTitle, {
            [css['PricingTable__cell--center']]: i > 0,
          })}
        style={{
          ...getActiveStyle(i),
          backgroundColor: activeColumnIndex === i ? bgColor : undefined,
          borderBottom: 0,
          width: v?.width ? `${v?.width}%` : undefined,
        }}
      >
        {v?.content}
      </th>
    );
  });

  const actionCells = actionColumns?.map((v, i) => (
    <td
      key={String(i)}
      className={classNames(css.PricingTable__cell,
        css.PricingTable__header,
        css.PricingTable__headerAction, {
          [css['PricingTable__cell--center']]: i > 0,
        })}
      style={getActiveStyle(i)}
    >
      {v}
    </td>
  ));

  const contents = cells?.map((v, i) => (
    <tr key={String(i)}>
      {v?.contents?.map((k, j) => {
        const bgColor = (columnActiveColors && columnActiveColors[j]) ? columnActiveColors[j] : css[`activePkg${j}BorderColor`];
        return (
          <td
            key={String(j)}
            className={classNames(css.PricingTable__cell,
              css['PricingTable__cellContent--background'], {
                [css['PricingTable__cell--center']]: j > 0,
                [css['PricingTable__category--background']]: v?.type === 'category',
                [css['PricingTable__category--font']]: v?.type === 'category',
                [css.PricingTable__categoryItem]: v?.type === 'category_item' && j === 0,
              })}
            style={{
              ...getActiveStyle(j),
              borderBottom: (cells.length - 1) === i && activeColumnIndex === j // last row active column
                ? `${css.borderWidthMd} solid ${bgColor}` : undefined,
            }}
          >
            {['Yes', 'YES', 'yes', 'y'].includes(k) ? (
              <span style={{ margin: '-12px', position: 'absolute' }}>
                <Icon
                  size={24}
                  name="check"
                />
              </span>
            )
              : k}
          </td>
        );
      })}
    </tr>
  ));

  return (
    <div>
      <table className={classNames(css.PricingTable, {
        [css['PricingTable--stretch']]: stretch,
      })}
      >
        <thead>
          <tr>{titleCells}</tr>
        </thead>
        <tbody>
          <tr>{actionCells}</tr>
          {contents}
        </tbody>
      </table>
    </div>
  );
};

PricingTable.propTypes = {
  titleColumns: PropTypes.arrayOf(PropTypes.shape({
    width: PropTypes.number,
    content: PropTypes.node,
  })),
  actionColumns: PropTypes.arrayOf(PropTypes.node),
  cells: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.oneOf(['item', 'category', 'category_item']),
    contents: PropTypes.arrayOf(PropTypes.node),
  })),
  columnActiveColors: PropTypes.arrayOf(PropTypes.node),
  activeColumnIndex: PropTypes.number,
  stretch: PropTypes.bool,
};

PricingTable.defaultProps = {
  titleColumns: [],
  actionColumns: [],
  columnActiveColors: undefined,
  cells: [],
  activeColumnIndex: 1,
  stretch: false,
};

export default PricingTable;
