import React, { Component } from 'react';

function withDeprecation(WrappedComponent, additionalErrorMsg = '') {
  return class extends Component {
    componentDidMount() {
      // eslint-disable-next-line no-console
      console.error(`${WrappedComponent.name} has been deprecated. ${additionalErrorMsg}`);
    }

    render() {
      const { props } = this;

      return (
        <WrappedComponent {...props} />
      );
    }
  };
}

export default withDeprecation;
