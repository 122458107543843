import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import componentClassnames from './styles.scss';

const LoaderIndicator = ({ size }) => {
  const loaderContainerClasses = classNames({
    [componentClassnames.LoaderIndicator]: true,
    [componentClassnames['LoaderIndicator--sm']]: size === 'sm',
  });

  return (
    <div data-testid="Loader-container" className={loaderContainerClasses}>
      <div
        className={classNames({
          [componentClassnames.LoaderIndicator__rect]: true,
          [componentClassnames['LoaderIndicator__rect--1']]: true,
        })}
      />
      <div
        className={classNames({
          [componentClassnames.LoaderIndicator__rect]: true,
          [componentClassnames['LoaderIndicator__rect--2']]: true,
        })}
      />
      <div
        className={classNames({
          [componentClassnames.LoaderIndicator__rect]: true,
          [componentClassnames['LoaderIndicator__rect--3']]: true,
        })}
      />
    </div>
  );
};

export default LoaderIndicator;

LoaderIndicator.propTypes = {
  size: PropTypes.oneOf(['sm', 'md']),
};

LoaderIndicator.defaultProps = {
  size: 'md',
};
