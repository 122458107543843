import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import Expander from '../Expander.jsx';
import ActionableRow from '../ActionableRow';
import componentClassnames from './styles/ActionableRowExpander.scss';

export default class ActionableRowExpander extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = { };
    }

    getHeader() {
        // return this.props.header
        const children = this.props.header;
        const self = this;
        const decorated = [];
        React.Children.forEach(children, (child, i) => {
            if (child && child.type === ActionableRow) {
                decorated.push(React.cloneElement(child, {
                    key: i,
                    expandable: true,
                    expanded: self.props.expanded,
                    onExpanderClick: self.props.onExpanderClick,
                }));
            }
        });
        return decorated;
    }

    render() {
        return (
            <div>
                <div>{this.getHeader()}</div>
                <TransitionGroup component="div">
                    {this.props.expanded &&
                        <Expander>
                            <div className={componentClassnames.ActionableRowExpander}>{this.props.body}</div>
                        </Expander>
                    }
                </TransitionGroup>
            </div>
        );
    }
}

ActionableRowExpander.propTypes = {
    expanded: PropTypes.bool,
    onExpanderClick: PropTypes.func,
};

ActionableRowExpander.defaultProps = {
    expanded: false,
    onExpanderClick: () => {},
};
