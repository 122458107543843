import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import {
  FieldGroup as Group,
  FieldLabel as Label,
  FieldTextArea,
} from '../..';
import HelpText from '../../legacy/Construction/Field/HelpText/HelpText';
import omitProps from '../../HigherOrderComponents/omitProps';

class TextArea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id || uuid(),
      validation: false,
    };
  }

  validation(event) {
    const {
      optional,
      label,
      onValid,
    } = this.props;
    if (!optional && event.target.value === '') {
      return {
        type: 'error',
        message: `${label} is required.`,
      };
    }

    if (onValid) {
      return onValid(event);
    }
    return { type: 'success' };
  }

  onBlur = (event) => {
    const {
      optional,
      onBlur,
    } = this.props;
    event.persist();
    // Exit case if optional and empty value
    if (optional && event.target.value === '') {
      if (onBlur) {
        onBlur(event);
      }
      return;
    }

    const validation = this.validation(event);
    this.setState({ validation }, () => {
      if (onBlur) {
        if ((validation.type && validation.type !== 'error') || !validation.type) {
          onBlur(event);
        }
      }
    });
  };

  render() {
    const {
      id,
      validation: { type: validationType = '', message: validationMessage } = {},
    } = this.state;
    const {
      additionalInfo,
      disabled,
      helpText,
      label,
      onChange,
      onKeyUp,
      optional,
      value,
      hideOptionalLabel,
      ...props
    } = this.props;

    return (
      <Group id={id} validation={validationMessage && validationType}>
        <Label
          optional={optional}
          help={additionalInfo}
          hideOptionalLabel={hideOptionalLabel}
        >
          {label}
        </Label>
        <FieldTextArea
          {...props}
          value={value}
          onKeyUp={onKeyUp}
          disabled={disabled}
          onChange={onChange}
          onBlur={this.onBlur}
        />
        {validationMessage && <HelpText>{validationMessage}</HelpText>}
        {helpText && <HelpText fieldValidation="">{helpText}</HelpText>}
      </Group>
    );
  }
}

TextArea.propTypes = {
  additionalInfo: PropTypes.string,
  disabled: PropTypes.bool,
  helpText: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onKeyUp: PropTypes.func,
  optional: PropTypes.bool,
  hideOptionalLabel: PropTypes.bool,
  onValid: PropTypes.func,
  value: PropTypes.string.isRequired,
};

TextArea.defaultProps = {
  additionalInfo: '',
  disabled: false,
  helpText: '',
  id: undefined,
  onBlur: undefined,
  onKeyUp: undefined,
  onValid: undefined,
  optional: false,
  hideOptionalLabel: false,
};

export default omitProps(['className', 'style'])(TextArea);
