
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import HorizontalList from '../../../../HorizontalList';
import Button from '../../Button.jsx';
import ReactDOM from 'react-dom';
import debounce from 'lodash/debounce';
import ButtonBarMoreMenu from './ButtonBarMoreMenu.jsx';
import GetAcceptableChildren from './GetAcceptableChildren.js';
import Root from './Root';
import classes from '../styles/MultiButton.scss';
import classNames from 'classnames';
import GetButtonsWrappedInItems from './GetButtonsWrappedInItems';

export default class MultiButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            availableButtonsWidth: null,
            haveMeasurements: null,
        };

        this.buttonRefs = [];
        this.buttonProps = [];
        this.handleResize = debounce(this.handleResize.bind(this), 50);
        this.ensureMeasurementsAndButtonProps = this.ensureMeasurementsAndButtonProps.bind(this);
    }

    getAcceptableChildren() {
        return GetAcceptableChildren(this.props.buttons);
    }

    getButtons(buttonList) {
        return GetButtonsWrappedInItems(buttonList);
    }

    getButtonWidths() {
        const buttonWidths = [];
        const buttonRefs = this.buttonRefs;

        buttonRefs.forEach((button) => {
            const $button = ReactDOM.findDOMNode(button);
            // add one for sub-pixels
            const width = Math.ceil($button.offsetWidth) + 1;
            buttonWidths.push(width);
        });

        return buttonWidths;
    }

    getActionsMenuWidth() {
        // add one for sub-pixels
        return Math.ceil(ReactDOM.findDOMNode(this.actionsMenu).offsetWidth) + 1;
    }

    handleResize() {
        // @TODO: In order to get the ButtonBar compatible with MedianAlpha component
        // we need to re-measure the container width on resize, but while the button list
        // is out of the flow. The button list has flex-wrap: nowrap and MedianAlpha has
        // flex-grow: 999. Because of this, we can't get a true measurement on the container
        // because the buttonlist pushes it wider than it's supposed to be.
        //
        // Invoking this.clearMeasurementsAndButtonInfo() is a temporary solution. Originally,
        // this method was just setting the state property `availableButtonsWidth`.
        // We could optimize what we do on resize by caching (or not clearning) the
        // buttons width and actionsmenu width as long as we set state.haveMeasurements to
        // false so that can measure the container with the buttons out of the flow.

        this.clearMeasurementsAndButtonInfo();
    }

    componentDidMount() {
        const self = this;
        // add slight delay on initial measuring to account for container query resizing
        this.didMountTimeout = window.setTimeout(self.ensureMeasurementsAndButtonProps, 300);
        window.addEventListener('resize', self.handleResize);
    }

    clearMeasurementsAndButtonInfo() {
        this.buttonWidths = null;
        this.actionsMenuWidth = null;
        this.setState({ haveMeasurements: false });
    }

    UNSAFE_componentWillReceiveProps() {
        this.clearMeasurementsAndButtonInfo();
    }

    setMeasurements() {
        this.buttonWidths = this.getButtonWidths();
        this.actionsMenuWidth = this.getActionsMenuWidth();
    }

    setButtonProps() {
        const buttons = this.getAcceptableChildren();
        this.buttonProps = buttons &&
            buttons.filter &&
            buttons
                .filter(button => button)
                .map(button => {
                    if (button.props.children && !button.props.label) {
                        return {...button.props, label: button.props.children };
                    }
                    return button.props;
                });
    }

    getAvailableButtonsWidth() {
        const componentWidth = ReactDOM.findDOMNode(this).offsetWidth;

        let buttonWidthsSum = 0;
        this.buttonWidths.forEach((width) => {
            buttonWidthsSum += width;
        });

        let availableButtonsWidth = componentWidth;
        if (buttonWidthsSum > componentWidth) {
            availableButtonsWidth = componentWidth - this.actionsMenuWidth;
        }
        return availableButtonsWidth;
    }

    ensureMeasurementsAndButtonProps() {
        if (!this.state.haveMeasurements) {
            this.setMeasurements();
            this.setButtonProps();
            this.setState({
                haveMeasurements: true,
                availableButtonsWidth: this.getAvailableButtonsWidth(),
            });
        }
    }

    componentDidUpdate() {
        this.ensureMeasurementsAndButtonProps();
    }

    componentWillUnmount() {
        if (this.didMountTimeout) {
            clearTimeout(this.didMountTimeout);
            this.didMountTimeout = null;
        }
        window.removeEventListener('resize', this.handleResize);
    }

    // Returns object with props correctly sorted into
    // `buttons` and `menuItems`. It uses `this.state.availableButtonsWidth`
    // and `this.buttonsWidth` to determine how many props go
    // in buttons attribute vs menuItems.
    getPropsSplit() {
        const children = this.getAcceptableChildren();
        const buttonArray = [];
        const menuArray = [];

        const availableButtonsWidth = this.state.availableButtonsWidth;
        let buttonsWidth = 0;
        const buttonsProps = this.buttonProps;
        React.Children.forEach(children, (child, i) => {
            const buttonProps = buttonsProps[i];
            buttonsWidth += this.buttonWidths[i];
            if (availableButtonsWidth > buttonsWidth) {
                buttonArray.push(buttonProps);
            } else {
                menuArray.push(buttonProps);
            }
        });

        return { buttonArray, menuArray };
    }

    getFinalButtonList(buttonProps) {
        const buttons = [];
        if (!buttonProps) {
            return [];
        }

        Object.keys(buttonProps).forEach((key) => {
            buttons.push(<Button {...buttonProps[key]} />);
        });

        return buttons;
    }

    getFinalActionsMenu(menuProps, buttonsCount) {
        if (menuProps.length === 0) {
            return null;
        }
        const menuItems = [];
        Object.keys(menuProps).forEach((key) => {
            menuItems.push(menuProps[key]);
        });
        return (
            <ButtonBarMoreMenu
                type={this.props?.type}
                label={buttonsCount === 0 ? 'Actions' : 'More'}
                menuItems={menuItems} />
        );
    }

    getButtonAndMenuFromSplitProps(splitProps) {
        const buttons = this.getFinalButtonList(splitProps.buttonArray);
        const actionsMenu = this.getFinalActionsMenu(splitProps.menuArray, buttons.length);
        const children = actionsMenu ? buttons.concat([actionsMenu]) : buttons;
        return children;
    }

    getRootClasses() {
        const inline = this.props.inline;
        return classNames([classes.MultiButton], {
            [classes['MultiButton--inline']]: inline,
        });
    }

    renderMeasured() {
        const finalButtonList = this.getButtons(this.getButtonAndMenuFromSplitProps(this.getPropsSplit()));
        return (
            <Root inline={this.props.inline} minWidth={`${this.actionsMenuWidth}px`}>
                <HorizontalList
                    noWrap>
                    {finalButtonList}
                </HorizontalList>
            </Root>
        );
    }

    renderUnMeasured() {
        const children = this.getButtons(this.getAcceptableChildren());
        this.buttonRefs = [];
        return (
            <Root inline={this.props.inline}>
                <div className={classes.MultiButton__hiddenElement}>
                    <HorizontalList
                        noWrap>
                        {React.Children.map(children, ((child, i) => React.cloneElement(child, { ref: (button) => { if (button) { this.buttonRefs[i] = button; } } })))}
                    </HorizontalList>
                </div>
                <span ref={(menu) => { this.actionsMenu = menu; }}>
                    <HorizontalList
                        noWrap
                        display="inline">
                        {this.getButtons(<ButtonBarMoreMenu />)}
                    </HorizontalList>
                </span>
            </Root>
        );
    }

    render() {
        if (!this.state.haveMeasurements) {
            return this.renderUnMeasured();
        }
        return this.renderMeasured();
    }
}

MultiButton.propTypes = {
    inline: PropTypes.bool,
    buttons: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
    ]),
    type: PropTypes.oneOf(['primary', 'secondary', 'danger', 'link', 'text', 'gray10', 'primary-reverse', 'gray']),
};

MultiButton.defaultProps = {
    inline: false,
};
