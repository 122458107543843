import React, { Component } from 'react';
import PropTypes from 'prop-types';
import css from './styles.scss';

class IdentityCell extends Component {
  getPrimaryText() {
    const { primaryText } = this.props;
    return (
      <div className={css.IdentityCell__header}>
        {primaryText}
      </div>
    );
  }

  getSecondaryText() {
    const {
      secondaryText,
    } = this.props;
    return (
      <div className={css.IdentityCell__subheader}>
        { secondaryText || '' }
      </div>
    );
  }

  getThumbnail() {
    const {
      thumbnail,
    } = this.props;
    if (thumbnail) {
      return (
        <div className={css.IdentityCell__thumbnail} style={{ backgroundImage: `url(${thumbnail})` }} />
      );
    }
    return false;
  }

  getCell() {
    const {
      onClick,
      href,
    } = this.props;
    if (onClick) {
      return (
        <div
          className={[css.IdentityCell, css.IdentityCell__pointer, css.IdentityCell__link].join(' ')}
          onClick={onClick}
          onKeyPress={onClick}
          role="link"
          tabIndex="0"
        >
          {this.getThumbnail()}
          <div>
            {this.getPrimaryText()}
            {this.getSecondaryText()}
          </div>
        </div>
      );
    }
    if (href) {
      return (
        <a href={href} target="_blank" rel="noopener noreferrer">
          <div className={[css.IdentityCell, css.IdentityCell__pointer, css.IdentityCell__link].join(' ')}>
            {this.getThumbnail()}
            <div>
              {this.getPrimaryText()}
              {this.getSecondaryText()}
            </div>
          </div>
        </a>
      );
    }
    return (
      <div className={css.IdentityCell}>
        {this.getThumbnail()}
        <div>
          {this.getPrimaryText()}
          {this.getSecondaryText()}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div data-testid="identity-cell">
        {this.getCell()}
      </div>
    );
  }
}

IdentityCell.propTypes = {
  onClick: PropTypes.func,
  thumbnail: PropTypes.string,
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
  href: PropTypes.string,
};

IdentityCell.defaultProps = {
  thumbnail: '',
  secondaryText: '',
  href: '',
  onClick: null,
};

export default IdentityCell;
