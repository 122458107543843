import React from 'react';
import Select from 'react-select-plus';
import ReactDOM from 'react-dom';
import TetherComponent from './TetherComponent.jsx';
import ActionBar from '../../../../ActionBar';
import styles from './scss/style.scss'

export default class TetherSelectPlusComponent extends Select {
    constructor(props) {
        super(props);

        this.renderOuter = this.renderOuter;
        this.handleTouchOutside = this.handleTouchOutside;
    }

      componentDidMount() {
        super.componentDidMount.call(this);

        this.dropdownFieldNode = ReactDOM.findDOMNode(this);
    }

      // Code sample taken from the issues post below
      // https://github.com/JedWatson/react-select/issues/810#issuecomment-284442852
      handleTouchOutside(event) {
        const { target } = event;
        // The original react-select code is modified to also check if the
        // touch came from inside the tethered container
        const isNotInWrapper = this.wrapper &&
          !this.wrapper.contains(target);

        const isNotInTarget = this.props.target && !this.props.target.contains(target);

        if (isNotInWrapper && isNotInTarget) {
          this.closeMenu();
        }
    }

      renderOuter() {
        const menu = super.renderOuter.apply(this, arguments);

        // Don't return an updated menu render if we don't have one
        if (!menu) {
          return null;
        }

        // Code sample taken from the issues post below
        // https://github.com/JedWatson/react-select/issues/810#issuecomment-284573308
        /** this.wrapper comes from the ref of the main Select component (super.render()) **/
        const selectWidth = this.wrapper ? this.wrapper.offsetWidth : null;

        const { footerActions, tertiaryAction, customFooter, outerClassnames, validation } = this.props;

        if (this.props.validation) {
            outerClassnames.push(`TetherSelect--${validation}`);
        }

        const options = {
            attachment: 'top left',
            targetAttachment: 'bottom left',
            classes: {
                element: outerClassnames.join(' '),
            },
            constraints: [
                {
                    to: 'window',
                    attachment: 'together',
                },
            ],
        };

        const hasActionBar = [
          footerActions?.constructor === Array && footerActions,
          tertiaryAction?.constructor === Array && tertiaryAction,
          tertiaryAction?.constructor === Object && [tertiaryAction],
        ].filter(f => f) || customFooter;

        return (
          <TetherComponent
              hasActionBar={hasActionBar}
              target={this.dropdownFieldNode}
              options={options}
              matchWidth>
              {/* Apply position:static to our menu so that it's parent will get the correct dimensions and we can tether the parent */}
              {React.cloneElement(menu, { style: { position: 'static', width: selectWidth } })}
              {!!hasActionBar && (
                <div className={styles.TetherSelectComponent__actionButtonContainer}>
                  <ActionBar
                    footerActions={this.props?.footerActions}
                    tertiaryAction={this.props?.tertiaryAction}
                    customFooter={this.props?.customFooter}
                  />
                </div>
              )}
          </TetherComponent>
        );
      }
}
