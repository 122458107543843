import React, { Component } from 'react';
import PropTypes from 'prop-types';
import includes from 'lodash/includes';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import Sortable from './Sortable';
import classes from './styles.scss';
import {
  v2,
  Icon,
  Section,
  BodyText,
  DividedList,
  DividedListItem,
  Table,
  TableBanner,
  TableBody,
  TableCell,
  TableColumnHeader,
  TableHead,
  TableRow,
  Tile,
  TileDivision,
  TileSection,
  TileSectionHdg,
  Button,
  ButtonGroup,
  ActionableRow,
  Median,
  MedianAlpha,
  MedianOmega,
} from '..';
import wrapEventHandler, { ARG_ORDER } from '../utils/wrapEventHandler';

const DEFAULT_COLUMN_WIDTH = 100;

export default class ColumnConfig extends Component {
  constructor(props) {
    super(props);
    const { columns } = this.props;
    this.state = {
      columns,
      groupSelectAll: [],
      modified: false,
      companySaved: true,
    };
    this.selectAllOrNone = this.selectAllOrNone.bind(this);
    this.setCompanyColumns = this.setCompanyColumns.bind(this);
    this.setColumns = this.setColumns.bind(this);
    this.remove = this.remove.bind(this);
  }

  isSelected(id) {
    return includes(this.getColumns(), id);
  }

  isSelectedAll(groupName) {
    const { groupSelectAll } = this.state;
    const selectAll = groupSelectAll.find((group) => group.name === groupName);

    return selectAll ? selectAll.selected : '0';
  }

  getColumns() {
    const { columns } = this.state;
    return columns.length ? columns.split(',').map((a) => a.replace(/:[0-9]+$/, '')) : [];
  }

  findColumnById(id) {
    let found = false;
    const { availableColumns } = this.props;

    availableColumns.forEach((category) => category.items.forEach((column) => {
      if (column.id === id) {
        found = column;
        return false;
      }

      return null;
    }));

    return found;
  }

  getHeader() {
    const { title, isExport } = this.props;
    return (
      <div className="row">
        {isExport
                && (
                <div className="col-xs-8">
                  {`${title} CSV Export Column Configuration`}
                </div>
                )}
        {!isExport
                && (
                <div className="col-xs-8">
                  {`${title} Column Configuration`}
                </div>
                )}
      </div>
    );
  }

  sortItem = (order) => {
    const columns = this.getColumns();
    const result = new Array(columns.length);

    columns.forEach((column) => {
      const id = column.replace(/:[0-9]+$/, '');
      result[order.indexOf(id)] = column;
    });

    this.setState({ columns: result.join(','), modified: true, companySaved: false });
  };

  toggle(value, column) {
    const columns = this.getColumns();
    const i = columns.indexOf(column.id);

    if (value.length && i === -1) {
      columns.push(column.id);
    }

    if (!value.length && i > -1) {
      columns.splice(i, 1);
    }

    this.setState({ columns: columns.join(','), modified: true, companySaved: false });
  }

  remove(column) {
    const columns = this.getColumns();

    const i = columns.indexOf(column);
    if (i > -1) {
      columns.splice(i, 1);
    }

    this.setState({ columns: columns.join(','), modified: true, companySaved: false });
  }

  getGroupSelectionLimit(groupName) {
    const { groupSelectionLimits = [] } = this.props;
    if (!groupSelectionLimits.length) {
      return 0;
    }

    const { limit = 0 } = groupSelectionLimits?.find?.(({ group }) => group === groupName) || {};

    return limit;
  }

  getGroupSelectionCount(groupName) {
    const { availableColumns = [] } = this?.props;
    const columns = this?.getColumns();
    const selectionGroup = availableColumns?.find?.(({ group }) => group === groupName) || {};

    if (!selectionGroup?.items || !columns.length) {
      return 0;
    }

    return (selectionGroup?.items?.filter?.(({ id }) => columns?.includes?.(id)) || []).length;
  }

  canSelectMore(groupName) {
    const limit = this.getGroupSelectionLimit(groupName) || 0;

    if (!limit) {
      return true;
    }

    return this.getGroupSelectionCount(groupName) < limit;
  }

  selectAll(index) {
    const columns = this.getColumns();
    const { availableColumns } = this.props;

    availableColumns.forEach((category, ci) => {
      if (ci === index) {
        const limit = this.getGroupSelectionLimit(category?.group) || category?.items?.length || 0;
        const alreadySelectedCount = this.getGroupSelectionCount(category?.group) || 0;
        let itemsAdded = 0;
        category.items.forEach((column) => {
          if (!this.isSelected(column.id) && (itemsAdded + alreadySelectedCount) < limit) {
            itemsAdded += 1;
            columns.push(column.id);
          }
        });
      }
    });

    this.setState({ columns: columns.join(','), modified: true, companySaved: false });
  }

  selectNone(index) {
    const columns = this.getColumns();
    const { availableColumns } = this.props;
    availableColumns.forEach((category, ci) => {
      if (ci === index) {
        category.items.forEach((column) => {
          const i = columns.indexOf(column.id);

          if (i !== -1) {
            columns.splice(i, 1);
          }
        });
      }
    });

    this.setState({ columns: columns.join(','), modified: true, companySaved: false });
  }

  selectAllOrNone(category, index) {
    const { groupSelectAll } = this.state;
    let currentGroupSelected = groupSelectAll.find((group) => (
      group.name === category.group
    ));
    currentGroupSelected = currentGroupSelected ? currentGroupSelected.selected : '0';
    const groupSelect = {
      name: category.group,
      selected: (currentGroupSelected === '1') ? '0' : '1',
    };
    this.setState({
      groupSelectAll: [
        ...groupSelectAll.filter((group) => group.name !== category.group), groupSelect,
      ],
    });
    if (groupSelect.selected === '1') {
      this.selectAll(index);
    } else {
      this.selectNone(index);
    }
  }

  getFooter() {
    const { modified } = this.state;
    const {
      columns,
      isExport,
      onCancel,
      onDone,
      onRestoreSystemDefaults,
    } = this.props;
    const buttons = [
      {
        label: 'Cancel',
        type: 'secondary',
        onClick: onCancel,
      },
      {
        label: 'Done',
        type: 'primary',
        disabled: !modified && !isExport,
        onClick: () => {
          const changedColumns = this.getColumns()
            .reduce((acc, column) => ({ ...acc, [column]: DEFAULT_COLUMN_WIDTH }), {});
          columns.split(',').map((column) => {
            const parts = column.split(':');
            if (includes(Object.keys(changedColumns), parts[0])) {
              changedColumns[parts[0]] = (Number(parts[1])) > 0
                ? Number(parts[1])
                : DEFAULT_COLUMN_WIDTH;
            }

            return '';
          });
          const columnsWithSize = Object.keys(changedColumns).map((column) => `${column}:${changedColumns[column]}`).join();
          onDone(columnsWithSize);
        },
      },
    ];

    if (!isExport) {
      buttons.unshift({
        label: 'Restore to System Defaults',
        type: 'secondary',
        onClick: onRestoreSystemDefaults,
      });
    }

    return buttons;
  }

  setCompanyColumns() {
    const {
      columns: columnsProp,
      onSaveCompanyDefaults,
    } = this.props;

    const changedColumns = this.getColumns()
      .reduce((acc, column) => ({ ...acc, [column]: 0 }), {});

    columnsProp.split(',').map((column) => {
      const parts = column.split(':');

      if (includes(Object.keys(changedColumns), parts[0])) {
        changedColumns[parts[0]] = (Number(parts[1])) > 0
          ? Number(parts[1]) : 0;
      }
      return null;
    });

    const columnsWithSize = Object.keys(changedColumns).map((column) => `${column}:${changedColumns[column]}`).join();
    onSaveCompanyDefaults(columnsWithSize);
    this.setState({ companySaved: true });
  }

  setColumns(columnSetting) {
    this.setState({
      columns: columnSetting, modified: true,
    });
  }

  render() {
    const {
      title,
      availableColumns,
      companyColumns,
      isExport,
      onCancel,
    } = this.props;
    const { actions: companyActions = [] } = companyColumns || {};
    const groups = [];
    const { companySaved } = this.state;
    const { Panel, Checkbox } = v2;

    let columnsUpdatedBy = {};
    if (companyColumns?.updated_by || companyColumns?.updatedBy) {
      const {
        updated_at: updatedAtCc,
        updatedAt: ccUpdatedAt,
        updated_by: updatedByCc,
        updatedBy: ccUpdatedBy,
      } = companyColumns || {};
      const updatedAt = updatedAtCc || ccUpdatedAt;
      const updatedBy = updatedByCc || ccUpdatedBy;
      const {
        first_name: firstNameUb,
        firstName: ubFirstName,
        last_name: lastNameUb,
        lastName: ubLastName,
      } = updatedBy || {};
      const firstName = firstNameUb || ubFirstName;
      const lastName = lastNameUb || ubLastName;
      columnsUpdatedBy = {
        updatedAt: moment(updatedAt).format('MM/DD/YYYY'),
        name: `${firstName} ${lastName}`,
      };
    }
    availableColumns.forEach((category, index) => {
      const columns = [];
      let categorySelectedCount = 0;
      const groupSelectionLimit = this.getGroupSelectionLimit(category.group) || 0;
      const proxyHandler = wrapEventHandler(this.selectAllOrNone, [category, index], ARG_ORDER.OMIT, this);
      const checker = (
        <Checkbox
          legend=""
          key={uuid()}
          options={[{ value: '1', label: '' }]}
          value={this.isSelectedAll(category.group) === '0' ? ['0'] : ['1']}
          onChange={proxyHandler}
        />
      );

      const checkboxCellWidth = 49;
      const tableHeaders = [{ id: 2, label: checker, width: checkboxCellWidth }, { id: 3, label: 'Label', width: 300 }, { id: 4, label: 'Group', width: 250 }];
      category.items.sort((a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
        return 0;
      }).map((column) => {
        const canSelectMore = this.isSelected(column.id) || this.canSelectMore(category.group);
        const proxyToggle = wrapEventHandler(this.toggle, [column], ARG_ORDER.ARGS_FIRST, this);
        columns.push(
          <TableRow key={uuid()}>
            <TableCell width={checkboxCellWidth}>
              <Checkbox
                legend=""
                options={[
                  {
                    value: '1',
                    label: '',
                    disabled: !canSelectMore,
                  },
                ]}
                value={this.isSelected(column.id) ? ['1'] : ['0']}
                onChange={proxyToggle}
              />
            </TableCell>
            <TableCell width={300}>
              { canSelectMore
                ? column.label
                : (
                  <span style={{ color: '#636b88' }}>
                    {column.label}
                  </span>
                )}
            </TableCell>
            <TableCell width={250}>
              { canSelectMore
                ? column.group
                : (
                  <span style={{ color: '#636b88' }}>
                    {column.group}
                  </span>
                )}
            </TableCell>
          </TableRow>,
        );

        if (this.isSelected(column.id)) {
          categorySelectedCount += 1;
        }

        return null;
      });

      groups.push(
        <div key={uuid()} className={classes.ColumnsDisplay__Tiles}>
          <Section>
            <Tile>
              <TileDivision>
                <TileSection>
                  <TileSectionHdg>
                    <div style={{ paddingBottom: '1rem' }}>
                      {category.group}
                      {groupSelectionLimit > 0
                        && (
                          <span style={{ fontSize: '1.2rem' }}>
                            {' '}
                            (Choose up to
                            {' '}
                            {groupSelectionLimit}
                            {' '}
                            fields)
                          </span>
                        )}
                    </div>
                  </TileSectionHdg>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {tableHeaders.map((col) => (
                          <TableColumnHeader
                            key={col.id}
                            id={String(col.id)}
                            label={col.label}
                            width={col.width}
                          />
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {columns}
                      <TableBanner>{`${categorySelectedCount} of total ${columns.length} selected`}</TableBanner>
                    </TableBody>
                  </Table>
                </TileSection>
              </TileDivision>
            </Tile>
          </Section>
        </div>,
      );
    });

    const selectedColumns = [];
    this.getColumns().forEach((id) => {
      const column = this.findColumnById(id, '');
      if (column) {
        selectedColumns.push(
          <div
            key={uuid()}
            data-id={column.id}
            className={classes.ColumnsDisplay__selectionsRow}
          >
            <div className={classes.ColumnsDisplay__dividedList__item}>
              <ActionableRow
                mainAction={<span className={classes.ColumnsDisplay__sortIcon}><Icon name="drag" size={24} /></span>}
                body={column.label}
                actions={[
                  {
                    icon: 'removeHollow',
                    onClick: () => this.remove(column.id),
                  },
                ]}
              />
            </div>
          </div>,
        );
      }
    });

    return (
      <Panel
        isOpen
        header={this.getHeader()}
        footerActions={this.getFooter()}
        size="large"
        onClose={onCancel}
      >
        <div className={classes.ColumnsDisplay__columnsPicker}>
          <div className={classes.ColumnsDisplay__columns}>
            <div className={classes.ColumnsDisplay__columnsInner}>
              {groups}
            </div>
          </div>
          <div className={classes.ColumnsDisplay__selections}>
            <div className={classes.ColumnsDisplay__selectionsHd}>
              <div className={classes.ColumnsDisplay__selectionsHd__intro}>
                <div className="u-padding-bottom--md"><strong>{`${title} Set & Order`}</strong></div>
                <BodyText
                  tag="div"
                  styleLevel="sm"
                >
                  {`As you select the items on the left they will display here in the order you have selected them.
                  You can change the order by selecting and dragging the individual item.
                  The selection and order you have made here will display on the ${title} listing.`}
                </BodyText>
              </div>
              {(companyColumns && !isExport)
                && (
                <DividedList>
                  <DividedListItem dividedListStyles={{ padding: '1rem 0' }}>
                    <Median noWrap>
                      <MedianAlpha>
                        <ButtonGroup>
                          <Button
                            disableTitleCasing
                            type="secondary"
                            disabled={!(companyColumns?.updated_at || companyColumns?.updatedAt || false)}
                            onClick={wrapEventHandler(this.setColumns, [companyColumns.columns], ARG_ORDER.EVENT_LAST, this)}
                          >
                            Restore
                          </Button>
                          {companyActions?.includes?.('add') && (
                            <Button
                              disableTitleCasing
                              type="secondary"
                              disabled={companySaved}
                              onClick={this.setCompanyColumns}
                            >
                              Save as My Company Default
                            </Button>
                          )}
                        </ButtonGroup>
                      </MedianAlpha>
                      <MedianOmega>
                        {columnsUpdatedBy?.name && (
                          <div className={classes.ColumnsDisplay__updatedBy}>
                            <BodyText
                              styleLevel="sm"
                            >
                              <small>{`Last Save: ${columnsUpdatedBy?.updatedAt} by ${columnsUpdatedBy?.name}`}</small>
                            </BodyText>
                          </div>
                        )}
                      </MedianOmega>
                    </Median>
                  </DividedListItem>
                </DividedList>
                )}
            </div>
            <div className={classes.ColumnsDisplay__selectionsBd}>
              <Sortable
                options={[]}
                tag="div"
                onChange={this.sortItem}
                style={[]}
              >
                {selectedColumns}
              </Sortable>
            </div>
          </div>
        </div>
      </Panel>
    );
  }
}

export const ColumnConfigPropTypes = {
  title: PropTypes.string,
  columns: PropTypes.string.isRequired,
  availableColumns: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object,
  ])).isRequired,
  companyColumns: PropTypes.object, // eslint-disable-line
  isExport: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  onRestoreSystemDefaults: PropTypes.func.isRequired,
  onSaveCompanyDefaults: PropTypes.func,
  groupSelectionLimits: PropTypes.arrayOf(PropTypes.shape({
    group: PropTypes.string,
    limit: PropTypes.number,
  })),
};

ColumnConfig.propTypes = ColumnConfigPropTypes;

ColumnConfig.defaultProps = {
  title: '',
  isExport: false,
  onSaveCompanyDefaults: () => {},
  groupSelectionLimits: [],
};
