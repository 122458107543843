import React from 'react';
import PropTypes from 'prop-types';
import Select from '../v2/Select';
import TextInput from '../v2/TextInput';

const FilterInput = (props) => {
  const { onApplyFilters, ...remainingProps } = props;
  if (remainingProps.type === 'select') {
    return (<Select {...remainingProps} />);
  }
  function applyFilterCheck(event) {
    if (event.which === 13) {
      onApplyFilters();
    }
  }
  return (
    <TextInput
      {...remainingProps}
      onKeyUp={applyFilterCheck}
    />
  );
};

FilterInput.propTypes = {
  onApplyFilters: PropTypes.func,
};

FilterInput.defaultProps = {
  onApplyFilters: undefined,
};

export default FilterInput;
