
import React, { Component } from 'react';

import PanelBox from './PanelBox.jsx';
import PanelBoxHeader from './PanelBoxHeader.jsx';
import PanelHeading from './PanelHeading.jsx';
import PanelBoxClose from './PanelBoxClose.jsx';
import PanelBoxBody from './PanelBoxBody.jsx';
import PanelBoxSidebar from './PanelBoxSidebar.jsx';
import PanelBoxMain from './PanelBoxMain.jsx';

export default class PanelDrawer extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = { };
    }

    render() {
        return (
            <PanelBox position="absolute" isDrawer>
                <PanelBoxHeader>
                    {this.props.header}
                    {this.props.onClickClose &&
                        <PanelBoxClose onClick={this.props.onClickClose} />
                    }
                </PanelBoxHeader>
                <PanelBoxBody>
                    <PanelBoxMain footer={this.props.footer}>
                        {this.props.children}
                    </PanelBoxMain>
                </PanelBoxBody>
            </PanelBox>
        );
    }
}

PanelDrawer.propTypes = {

};

PanelDrawer.defaultProps = {

};
