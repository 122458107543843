import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import PhoneInputField from 'react-phone-number-input';
import {
  FieldGroup as Group,
  FieldLabel as Label,
} from '../..';
import { parseNumber } from './custom-libphonenumber';
import HelpText from '../../legacy/Construction/Field/HelpText/HelpText';
import defaultCountries from './countries';
import './styles.global.scss';

const SYMBOL_STRIPPING_REGEX = /[\ \(\)\-\.]/g; // eslint-disable-line no-useless-escape

/**
 * DEPRECATED: Instead use: /src/legacy/Construction/Field/PhoneInput
 */
class PhoneInput extends Component {
  static convertToEvent(value) {
    return { target: { value: !value ? '' : value.replace(SYMBOL_STRIPPING_REGEX, '') } };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.errorMessage !== prevState.errorMessage) {
      if (!prevState.value && !nextProps.optional) {
        return {
          errorMessage: nextProps.errorMessage,
          validation: {
            type: 'error',
            message: nextProps.errorMessage || nextProps.requiredText,
          },
        };
      }
      const parsedNumber = parseNumber(prevState.value, { defaultCountry: 'US', extended: true });
      if (!parsedNumber.possible || !parsedNumber.valid) {
        return {
          errorMessage: nextProps.errorMessage,
          validation: {
            type: 'error',
            message: nextProps.errorMessage || nextProps.invalidText,
          },
        };
      }
    }
    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      validation: false,
      id: props.id || uuid(),
      errorMessage: props.errorMessage || '',
      value: props.value || '',
    };
  }

  internalValidation = (event) => {
    const { optional, requiredText, invalidText } = this.props;
    if (!event.target.value && !optional) {
      return { type: 'error', message: requiredText };
    }
    const {
      possible = true,
      valid = true,
    } = parseNumber(event.target.value, { defaultCountry: 'US', extended: true });
    if (!possible || !valid) {
      return { type: 'error', message: invalidText };
    }
    return {};
  };

  validation(event) {
    const initialValidation = this.internalValidation(event);
    const { onValid } = this.props;

    if (initialValidation.type && initialValidation.type !== 'error') {
      if (onValid) {
        return onValid(event);
      }
    }
    return initialValidation;
  }

  onBlur = (event) => {
    const {
      optional,
      onBlur,
    } = this.props;
    // Exit case if optional and empty value
    if (optional && event.target.value === '') {
      if (onBlur) {
        onBlur(event);
      }
      return;
    }

    const validation = this.validation(event);
    this.setState({ validation }, () => {
      const { type = 'success' } = validation;
      if ((type === 'success') && onBlur) {
        onBlur(event);
      }
    });
  };

  onChange = (event) => {
    const value = this.constructor.convertToEvent(event);
    const validation = this.validation(value);
    this.setState({ value: value.target.value, validation }, () => {
      const { onChange } = this.props;
      onChange(value, validation);
    });
  };

  render() {
    const {
      id,
      validation: { type: validationType = '', message: validationMessage },
    } = this.state;
    const {
      additionalInfo,
      allowInternational,
      disabled,
      helpText,
      label,
      onKeyUp,
      value,
      optional,
      country,
      countries,
      showCountrySelect,
      onCountryChange,
    } = this.props;

    return (
      <Group id={id} validation={validationType}>
        {label && (
          <Label
            optional={optional}
            help={additionalInfo}
          >
            {label}
          </Label>
        )}

        <PhoneInputField
          showCountrySelect={showCountrySelect}
          displayInitialValueAsLocalNumber
          international={allowInternational}
          countries={countries}
          onCountryChange={onCountryChange}
          defaultCountry="US"
          country={country}
          limitMaxLength
          id={id}
          disabled={disabled}
          onKeyUp={onKeyUp}
          value={value}
          onBlur={this.onBlur}
          onChange={this.onChange}
        />
        {validationMessage && <HelpText>{validationMessage}</HelpText>}
        {helpText && <HelpText fieldValidation="">{helpText}</HelpText>}
      </Group>
    );
  }
}

PhoneInput.propTypes = {
  additionalInfo: PropTypes.string,
  allowInternational: PropTypes.bool,
  disabled: PropTypes.bool,
  helpText: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onKeyUp: PropTypes.func,
  optional: PropTypes.bool,
  requiredText: PropTypes.string,
  invalidText: PropTypes.string,
  onValid: PropTypes.func,
  value: PropTypes.string.isRequired,
  country: PropTypes.oneOf(defaultCountries),
  countries: PropTypes.arrayOf(PropTypes.string),
  showCountrySelect: PropTypes.bool,
  onCountryChange: PropTypes.func,
  errorMessage: PropTypes.string,
};

PhoneInput.defaultProps = {
  additionalInfo: '',
  allowInternational: true,
  disabled: false,
  helpText: '',
  id: undefined,
  label: '',
  optional: false,
  requiredText: 'Phone number is required',
  invalidText: 'Phone number is invalid',
  country: 'US',
  countries: defaultCountries,
  showCountrySelect: true,
  onCountryChange: () => { },
  onBlur: () => { },
  onKeyUp: () => { },
  onValid: () => { },
  errorMessage: '',
};

export default PhoneInput;
