import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import classes from './styles.scss';
import Menu from '../Menu';
import {
  ButtonGroup,
  Button,
  IconButton,
} from '../index';

const renderIconButton = () => (
  <div>
    <IconButton
      size="lg"
      name="moreHoriz"
    />
  </div>
);

const ActionBar = (props) => {
  const {
    footerActions,
    customFooter,
    size,
    align,
    tertiaryAction,
    tertiaryText,
  } = props;

  const renderFooter = () => {
    if (footerActions) {
      return (
        <ButtonGroup textAlign="right">
          {footerActions.map((action) => {
            if (action.type === 'custom') {
              return action.customComponent || null;
            }
            return (
              <Button
                key={`footerAction-${action.label || 'default'}`}
                {...action}
              />
            );
          })}
        </ButtonGroup>
      );
    }
    return null;
  };

  const renderTertiaryFooter = () => {
    const tertiaryActions = [
      ...(tertiaryAction?.constructor === Array ? tertiaryAction : []),
      ...(tertiaryAction?.constructor === Object ? [tertiaryAction] : []),
    ].filter((f) => f);
    if (tertiaryActions.length || tertiaryText) {
      return (
        <div className={classes.ActionBar__tertiary}>
          <div className={classes.ActionBar__dividedList}>
            {tertiaryActions.map((ta) => (
              <div
                className={classNames({
                  [classes.ActionBar__dividedListItem]: tertiaryActions.length === 1,
                })}
                key={ta.label}
              >
                {size === 'sm'
                  ? (
                    <IconButton
                      disabled={ta.disabled}
                      name={ta.icon}
                      onClick={ta.onClick}
                    />
                  ) : (
                    <Button
                      {...ta}
                      type={ta.type || 'link'}
                    />
                  )}
              </div>
            ))}
            {tertiaryText && (
              <div className={classes.ActionBar__dividedListItem}>
                <span>{tertiaryText}</span>
              </div>
            )}
          </div>
        </div>
      );
    }
    return null;
  };

  const renderSmallFooter = () => {
    let $footerActions;
    let buttons;
    let menuItems;
    if (footerActions && Array.isArray(footerActions)) {
      $footerActions = footerActions.map((a) => {
        const $a = { ...a };
        if (typeof $a.menuItem === 'undefined') {
          // backward compatibility for menu items
          $a.menuItem = true;
        }
        return $a;
      });
      const lastAction = $footerActions.pop();
      // backward compatibility for last button
      lastAction.menuItem = false;
      $footerActions.push(lastAction);

      buttons = $footerActions.filter((a) => !a.menuItem);
      menuItems = $footerActions.filter((a) => a.menuItem);
    }

    const alignText = align || 'right';
    const alignClass = `ActionBar--align-${alignText}`;
    return (
      <div className={classNames({
        [classes.ActionBar]: true,
        [classes[alignClass]]: true,
      })}
      >
        {renderTertiaryFooter()}
        {$footerActions
          && (
            <div className={classes.ActionBar__primary}>
              <ButtonGroup textAlign="right">
                {menuItems
                  && menuItems.length > 0
                  && (
                    <Menu
                      disableAutoWidth
                      label="More actions"
                      menuItems={menuItems}
                      trigger={renderIconButton}
                    />
                  )}
                {buttons
                  && buttons.length > 0
                  && buttons.map((b) => (
                    <Button
                      key={`footerAction-${b.label}`}
                      href={b.href}
                      onClick={b.onClick}
                      label={b.label}
                      type={b.type}
                      disabled={b.disabled}
                      disableTitleCasing={b.disableTitleCasing}
                    />
                  ))}
              </ButtonGroup>
            </div>
          )}
      </div>
    );
  };

  if (size === 'sm') {
    return renderSmallFooter();
  }

  if (customFooter) {
    return (
      <div>
        {customFooter}
      </div>
    );
  }

  const alignText = align || 'right';
  const alignClass = `ActionBar--align-${alignText}`;
  return (
    <div className={classNames({
      [classes.ActionBar]: true,
      [classes[alignClass]]: true,
    })}
    >
      {renderTertiaryFooter()}
      <div>
        {footerActions && (
          <div className={classes.ActionBar__primary}>
            {renderFooter(footerActions)}
          </div>
        )}
      </div>
    </div>
  );
};

const actionPropTypes = PropTypes.shape({
  className: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  menuItem: PropTypes.bool,
  onClick: PropTypes.func,
  customComponent: PropTypes.element,
  type: PropTypes.oneOf(['primary', 'secondary', 'danger', 'text', 'link', 'custom']),
});

ActionBar.propTypes = {
  footerActions: PropTypes.arrayOf(actionPropTypes),
  tertiaryAction: PropTypes.oneOfType([
    PropTypes.arrayOf(actionPropTypes),
    actionPropTypes,
  ]),
  tertiaryText: PropTypes.string,
  align: PropTypes.oneOf(['center', 'left', 'right']),
  size: PropTypes.oneOf(['sm', 'lg']),
  customFooter: PropTypes.element,
};

ActionBar.defaultProps = {
  footerActions: undefined,
  tertiaryAction: undefined,
  tertiaryText: '',
  size: 'lg',
  align: undefined,
  customFooter: null,
};

export default ActionBar;
