import React from 'react';
import PropTypes from 'prop-types';
import Group from '../Group/Group';
import Label from '../Label/Label';
import PhoneInput from '../PhoneInput';
import withDeprecation from '../../../../utils/hoc/withDeprecation';

/**
 * @deprecated PhoneField is deprectated, use PhoneInput directly and include Label component.
 */
const PhoneField = ({ label, value, onChange }) => {
  return (
    <Group>
      <Label>{label}</Label>
      <PhoneInput value={value} onChange={onChange} />
    </Group>
  );
};

PhoneField.defaultProps = {
  label: 'Phone Number',
};

PhoneField.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default withDeprecation(
  PhoneField,
  'Use PhoneInput directly',
);
