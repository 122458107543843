/* eslint no-underscore-dangle: 0, react/no-find-dom-node: 0 */
/* eslint prefer-rest-params: 0, react/require-default-props: 0, react/forbid-prop-types: 0 */
// based on https://raw.githubusercontent.com/souporserious/react-tether/master/src/TetherComponent.jsx or react-tether
// patched for React-16 with portals
import { Component, Children } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import Tether from 'tether';
import classNames from 'classnames';
import css from './styles.scss';

const renderElementToPropTypes = [
  PropTypes.string,
  PropTypes.shape({
    appendChild: PropTypes.func.isRequired,
  }),
];

const attachmentPositions = [
  'auto auto',
  'top left',
  'top center',
  'top right',
  'middle left',
  'middle center',
  'middle right',
  'bottom left',
  'bottom center',
  'bottom right',
];

export default class TetherComponent extends Component {
  constructor(props) {
    super(props);
    this._targetNode = null;
    this._elementParentNode = null;
    this._tether = false;

    this.state = {
      timerId: null,
    };
  }

  componentDidMount() {
    this._targetNode = ReactDOM.findDOMNode(this);
    this._update();
  }

  componentDidUpdate() {
    this._targetNode = ReactDOM.findDOMNode(this);
    this._update();
  }

  componentWillUnmount() {
    this._destroy();
  }

  get _renderNode() {
    const { renderElementTo } = this.props;
    if (typeof renderElementTo === 'string') {
      return document.querySelector(renderElementTo);
    }
    return renderElementTo || document.body;
  }

  getTetherInstance() {
    return this._tether;
  }

  disable() {
    this._tether.disable();
  }

  enable() {
    this._tether.enable();
  }

  on(event, handler, ctx) {
    this._tether.on(event, handler, ctx);
  }

  off(event, handler) {
    this._tether.off(event, handler);
  }

  position() {
    this._tether.position();
  }

  _registerEventListeners() {
    const {
      onUpdate,
      onRepositioned,
    } = this.props;
    this.on('update', () => onUpdate && onUpdate.apply(this, arguments));
    this.on('repositioned', () => onRepositioned && onRepositioned.apply(this, arguments));
  }

  _destroy() {
    if (this._elementParentNode) {
      ReactDOM.unmountComponentAtNode(this._elementParentNode);
      this._elementParentNode.parentNode.removeChild(this._elementParentNode);
    }

    if (this._tether) {
      this._tether.destroy();
    }

    this._elementParentNode = null;
    this._tether = null;
  }

  _update() {
    const {
      children, renderElementTag, hoverTimerId, toggleVisibility,
    } = this.props;
    const { timerId } = this.state;
    const elementComponent = Children.toArray(children)[1];

    // if no element component provided, bail out
    if (!elementComponent) {
      // destroy Tether element if it has been created
      if (this._tether) {
        this._destroy();
      }
      return;
    }

    // create element node container if it hasn't been yet
    if (!this._elementParentNode) {
      // create a node that we can stick our content Component in
      this._elementParentNode = document.createElement(renderElementTag);

      // append node to the render node
      this._renderNode.appendChild(this._elementParentNode);
    }

    this._elementParentNode.onmouseover = () => {
      if (timerId) {
        clearTimeout(timerId);
      }
      if (hoverTimerId) {
        clearTimeout(hoverTimerId);
      }
    };
    this._elementParentNode.onmouseout = () => {
      if (hoverTimerId) {
        const _timerId = setTimeout(() => {
          if (toggleVisibility) {
            toggleVisibility();
          }
        }, 50);
        this.setState({ timerId: _timerId });
      }
    };

    // render element component into the DOM
    ReactDOM.render(elementComponent, this._elementParentNode, this._updateTether.bind(this));
  }

  _updateTether() {
    const {
      children, isBelowPanel, renderElementTag, renderElementTo, id, className, style, ...options
    } = this.props;
    const tetherOptions = {
      target: this._targetNode,
      element: this._elementParentNode,
      ...options,
    };

    if (id) {
      this._elementParentNode.id = id;
    }

    const tetherClassNames = classNames(className, {
      [css['TetherComponent--belowPanel']]: isBelowPanel,
    });

    if (tetherClassNames) {
      this._elementParentNode.className = tetherClassNames;
    }

    if (style) {
      Object.keys(style).forEach((key) => {
        this._elementParentNode.style[key] = style[key];
      });
    }

    if (!this._tether) {
      this._tether = new Tether(tetherOptions);
      this._registerEventListeners();
    } else {
      this._tether.setOptions(tetherOptions);
    }

    this._tether.position();
  }

  once(event, handler, ctx) {
    this._tether.once(event, handler, ctx);
  }

  render() {
    const { children } = this.props;
    return Children.toArray(children)[0];
  }
}

TetherComponent.propTypes = {
  renderElementTag: PropTypes.string,
  renderElementTo: PropTypes.oneOfType(renderElementToPropTypes),
  attachment: PropTypes.oneOf(attachmentPositions).isRequired,
  targetAttachment: PropTypes.oneOf(attachmentPositions),
  offset: PropTypes.string,
  targetOffset: PropTypes.string,
  targetModifier: PropTypes.string,
  enabled: PropTypes.bool,
  classes: PropTypes.object,
  classPrefix: PropTypes.string,
  optimizations: PropTypes.object,
  constraints: PropTypes.array,
  id: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  onUpdate: PropTypes.func,
  onRepositioned: PropTypes.func,
  children: PropTypes.node,
  isBelowPanel: PropTypes.bool,
  hoverTimerId: PropTypes.number,
  toggleVisibility: PropTypes.func,
};

TetherComponent.defaultProps = {
  renderElementTag: 'div',
  renderElementTo: null,
};
