import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { iconList } from '@fieldnation/font/components';
import css from './styles.scss';
import { Icon } from '../../index';

const Item = ({
  label,
  href,
  icon,
  onClick,
  target,
  disabled,
  hasExtraSpacing,
}) => {
  const isAnchor = href && !disabled;
  const Tag = `${isAnchor ? 'a' : 'button'}`;

  const itemClasses = classNames(
    css.Item,
    {
      [css['Item--hasExtraSpacing']]: hasExtraSpacing,
    },
  );

  const itemInnerClasses = classNames(
    css['Item__inner--icon'],
    {
      [css.Item__inner]: !hasExtraSpacing,
      [css['Item__inner--hasExtraSpacing']]: hasExtraSpacing,
    },
  );

  return (
    <Tag
      disabled={disabled}
      target={target}
      href={href}
      onClick={onClick}
      className={itemClasses}
      type={isAnchor ? 'button' : undefined}
    >
      {icon
        && (
        <span className={itemInnerClasses}>
          <Icon
            block
            name={icon}
          />
        </span>
        )}
      <span className={hasExtraSpacing ? css['Item__inner--hasExtraSpacing'] : css.Item__inner}>{label}</span>
    </Tag>
  );
};

Item.propTypes = {
  label: PropTypes.node.isRequired,
  href: PropTypes.string,
  icon: PropTypes.oneOf(iconList),
  onClick: PropTypes.func,
  target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
  disabled: PropTypes.bool,
  hasExtraSpacing: PropTypes.bool,
};

Item.defaultProps = {
  href: undefined,
  icon: undefined,
  onClick: undefined,
  target: undefined,
  disabled: false,
  hasExtraSpacing: false,
};

export default Item;
