import { selector } from 'recoil';
import prefsState from './prefs.state';
import { TabPreference } from './preferences.factory';
import activeTabQuery from './active-tab.query';

/**
 * Returns, for the actively selected tab, the TabPreference from the preference factory.
 *
 * @return {TabPreference | null}
 */
const tabPrefsQuery = selector<TabPreference | null>({
  key: 'tabPrefs',
  get: ({ get }) => {
    const tab = get(activeTabQuery);

    const preferences = get(prefsState);
    if (!preferences || !tab) {
      return null;
    }

    const tabPreferences = (preferences?.tabs || []).find(
      ({ id }) => id === tab.id,
    );

    if (!tabPreferences) {
      return null;
    }

    return tabPreferences;
  },
});

export default tabPrefsQuery;
