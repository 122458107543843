import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import classes from './BodyText.scss';

const BodyText = ({
  tag: Tag,
  styleLevel,
  children,
}) => (
  <Tag className={
      classNames({
        [classes['BodyText--xs']]: styleLevel === 'xs',
        [classes['BodyText--sm']]: styleLevel === 'sm',
        [classes['BodyText--md']]: styleLevel === 'md',
        [classes['BodyText--mdSemiBold']]: styleLevel === 'mdSemiBold',
        [classes['BodyText--mdBold']]: styleLevel === 'mdBold',
      })
}
  >
    {children}
  </Tag>
);

BodyText.propTypes = {
  tag: PropTypes.oneOf(['p', 'span', 'div', 'small']),
  styleLevel: PropTypes.oneOf(['xs', 'sm', 'md', 'mdSemiBold', 'mdBold']),
  children: PropTypes.node.isRequired,
};

BodyText.defaultProps = {
  tag: 'span',
  styleLevel: 'xs',
};

export default BodyText;
