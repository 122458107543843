import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './styles.scss';
import StatusBubble from '../legacy/Construction/StatusBubble/StatusBubble';

const TabList = ({ items }) => (
  <div className={css.TabList}>
    {
      items.map((item) => (
        <button
          className={classNames(css.TabList__item, {
            [css['TabList__item--active']]: item.active,
          })}
          type="button"
          onClick={item.onClick}
          key={item.label}
        >
          {item.label}
          {!!item?.count && <span className={css.TabList_Count}><StatusBubble>{item?.count}</StatusBubble></span>}
        </button>
      ))
    }
  </div>
);

TabList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    active: PropTypes.bool,
    count: PropTypes.number,
  })).isRequired,
};

export default TabList;
