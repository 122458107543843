import PropTypes from 'prop-types';

const filterPropTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  pattern: PropTypes.instanceOf(RegExp),
  placeholder: PropTypes.string,
  section: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })),
  helpText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      startHelp: PropTypes.string,
      endHelp: PropTypes.string,
    }),
  ]),
  // Lazy loading expects a function that returns promise
  getLazyLoadOptions: PropTypes.func,
  units: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }),
  ]),
  label: PropTypes.string,
  transform: PropTypes.func,
});

const filtersPropTypes = PropTypes.arrayOf(filterPropTypes);

const savedFilterPropTypes = {
  action: PropTypes.string,
  filters: filtersPropTypes,
  name: PropTypes.string,
};

const actionButtonPropTypes = PropTypes.shape({
  id: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'secondary', 'danger', 'link', 'text', 'gray10']),
  active: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  disableTitleCasing: PropTypes.bool,
});

const filterEditButtonAttrPropTypes = PropTypes.shape({
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['primary', 'secondary', 'danger', 'text', 'link']),
});

const filterEditButtonsAttrPropTypes = PropTypes.shape({
  primary: filterEditButtonAttrPropTypes,
  secondary: filterEditButtonAttrPropTypes,
});

export {
  filterPropTypes,
  filtersPropTypes,
  savedFilterPropTypes,
  actionButtonPropTypes,
  filterEditButtonAttrPropTypes,
  filterEditButtonsAttrPropTypes,
};
