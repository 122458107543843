import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Button, ButtonGroup, Tooltip } from '..';
import TooltipContainer from '../TooltipComponent';
import { actionType, slideType } from './types';
import wrapEventHandler, { ARG_ORDER } from '../utils/wrapEventHandler';
import Css from './styles.scss';

export default class Slide extends PureComponent {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    actions: PropTypes.arrayOf(actionType),
    onSlideSelect: PropTypes.func,
    perPage: PropTypes.oneOf([1, 4, 16]),
    selectedSlide: slideType,
    slide: slideType.isRequired,
  }

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    actions: [],
    onSlideSelect: () => {},
    perPage: 1,
    selectedSlide: {},
  }

  constructor(props) {
    super(props);
    this.img = React.createRef();
    const {
      slide = {},
    } = props;
    this.state = {
      isShow: !slide.src,
    };
  }

  renderActions = ({ actions, perPage, slide }) => actions.length > 0 && (perPage === 1 ? (
    <div className={Css.Carousel__Actions}>
      <ButtonGroup>
        {actions.map((action) => (
          <Button
            disabled={action.disabled}
            icon={action.icon}
            // eslint-disable-next-line react/no-array-index-key
            key={action.label}
            onClick={wrapEventHandler(action.onClick, [{ slide }], ARG_ORDER.EVENT_LAST)}
            shouldUseIconComponent
            size="sm"
            type="gray10"
          />
        ))}
      </ButtonGroup>
    </div>
  ) : (
    <Tooltip showOnEvent="click">
      <button
        className={Css.Carousel__Actions__Opener}
        type="button"
      >
        {' '}
      </button>
      <TooltipContainer verticalScrollHeight={20}>
        {actions.map((action) => (
          <div>
            <Button
              block
              disabled={action.disabled}
              icon={action.icon}
              key={action.label}
              label={action.label}
              onClick={wrapEventHandler(action.onClick, [{ slide }], ARG_ORDER.EVENT_LAST)}
              shouldUseIconComponent
              size="sm"
              type="gray10"
            />
          </div>
        ))}
      </TooltipContainer>
    </Tooltip>
  ))

  renderIcon = ({ perPage, slide }) => !slide.src && slide.icon && (
    <span
      className={ClassNames({
        [Css.Carousel__Slides__Slide__Icon]: true,
        [Css[`Carousel__Slides__Slide--${perPage}__Icon`]]: true,
        [slide.icon]: true,
      })}
    />
  )

  renderImage = ({ slide }) => (
    <>
      <img
        alt=""
        className={Css.Carousel__Slides__Slide__Image}
        onLoad={wrapEventHandler(this.setState, [{ isShow: true }], ARG_ORDER.OMIT, this)}
        ref={this.img}
      />
      <img
        alt=""
        className={Css.Carousel__Slides__Slide__Thumbnail}
        // eslint-disable-next-line react/jsx-no-bind
        onLoad={() => { this.img.current.src = slide.src; }}
        src={slide.thumbnail ? slide.thumbnail : slide.src}
      />
    </>
  )

  renderSlide = ({ perPage, slide }) => perPage !== 1 && (
    <div
      className={ClassNames({
        [Css.Carousel__Slides__Slide__Id]: true,
        [Css[`Carousel__Slides__Slide__Id--BgColor--${slide?.color}`]]:
          !!slide?.color,
      })}
    >
      {slide.index}
    </div>
  )

  render() {
    const {
      actions = [],
      onSlideSelect = () => {},
      perPage = 1,
      selectedSlide = {},
      slide = {},
    } = this.props;
    const {
      isShow = false,
    } = this.state;
    const slideProps = {
      className: ClassNames({
        [Css.Carousel__Slides__Slide]: true,
        [Css[`Carousel__Slides__Slide--${perPage}`]]: true,
        [Css['Carousel__Slides__Slide--Selected']]:
          perPage !== 1 && slide.id === selectedSlide.id,
        [Css['Carousel__Slides__Slide--Overlay']]: actions.length,
      }),
      ...(perPage !== 1 && {
        onClick: () => onSlideSelect({ slide }),
      }),
    };
    return (
      <div {...slideProps}>
        {this.renderActions({ actions, perPage, slide })}
        <div
          className={ClassNames({
            [Css.Carousel__Slides__Slide__Wrap]: true,
            [Css['Carousel__Slides__Slide--Blur']]: !isShow,
          })}
        >
          {this.renderSlide({ perPage, slide })}
          {this.renderIcon({ perPage, slide })}
          {this.renderImage({ slide })}
        </div>
      </div>
    );
  }
}
