
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import classNames from 'classnames';
import IconButton from '../../../IconButton';

export default class PanelBoxClose extends Component {
    constructor(props) {
        super(props);
        this.state = { };
    }

    render() {
        const styles = this.context.panelStyles;

        return (
          <div data-testid="panel-close" className={[styles.Panel__close]}>
              <IconButton
                title="close"
                size="sm"
                name="close"
                onClick={this.props.onClick}/>
          </div>
        );
    }
}

PanelBoxClose.propTypes = {

};

PanelBoxClose.defaultProps = {

};

PanelBoxClose.contextTypes = {
    panelStyles: PropTypes.object,
};
