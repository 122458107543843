import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../../Icon';
import StringToTitleCase from './Button/StringToTitleCase.js';
import ButtonGroup from './Button/ButtonGroup.jsx';
import ButtonBar from './Button/ButtonBar.jsx';
import css from './Button/styles/Button.scss';
import slugifyText from '../../utils/slugifyText';

const DISPLAY_NAME = 'Button';

export default class Button extends React.Component {
    constructor(props) {
        super(props);
        this.displayName = DISPLAY_NAME;
    }

    isRightBorderRadiusDisabled() {
        if (!this.props.isInGroup) {
            return false;
        }
        return (!(this.props.lastInGroup));
    }

    isLeftBorderRadiusDisabled() {
        if (!this.props.isInGroup) {
            return false;
        }
        return (!(this.props.firstInGroup));
    }

    isRightBorderDisabled() {
        if (!this.props.isInGroup) {
            return false;
        }
        return (!(this.props.lastInGroup));
    }

    render() {
        const {
            id,
            type,
            icon,
            shouldUseIconComponent,
            applyFixedHeight,
            size,
            dataTestid,
        } = this.props;

        const classes = classNames([css.Button], {
            [this.props.className]: this.props.className,
            [css['Button--isActive']]: this.props.active,
            [css[`Button--${this.props.type}`]]: this.props.type,
            [css['Button--sm']]: this.context.isInButtonBar || size === 'sm',
            [css['Button--xs']]: size === 'xs',
            [css['Button--block']]: this.props.block,
            [css['Button--rightBorderRadiusDisabled']]: this.isRightBorderRadiusDisabled(),
            [css['Button--leftBorderRadiusDisabled']]: this.isLeftBorderRadiusDisabled(),
            [css['Button--rightBorderDisabled']]: this.isRightBorderDisabled(),
            [css['Button--hasFixedHeight']]: applyFixedHeight,
        });

        const iconClasses = classNames([css.Button__icon], {
            [icon]: icon,
        });

        let label = this.props.label;
        let testId = dataTestid || label;
        if (!label) {
          label = this.props.children;
        }
        if(!testId) {
          testId = slugifyText([id, icon, type, size].join(' '));
        }

        const ButtonTag = `${(this.props.href.length ? 'a' : 'button')}`;

        return (
            <ButtonTag
                id={this.props.id}
                type="button"
                className={classes}
                onClick={this.props.onClick ? this.props.onClick : null}
                disabled={this.props.disabled ? 'disabled' : ''}
                title={this.props.title}
                href={this.props.href}
                target={this.props.target}
                data-testid={testId}>
                {
                    (icon && !shouldUseIconComponent)
                    && <span className={iconClasses} />
                }

                {
                    (icon && shouldUseIconComponent)
                        && <span className={classNames({
                            [css['Button__Icon']]: true,
                            [css['Button_Icon--label']]: (label || '').length,
                        })}>
                            <Icon
                                block
                                size={(size && size != 'xs') ? 24 : 22 }
                                name={`${icon}`} />
                        </span>
                }

                <span className={classNames({
                    [css['Button__txt--vAlignBaseline']]: this.props.vAlign === 'baseline',
                    [css['Button__txt--vAlignMiddle']]: this.props.vAlign === 'middle',
                })}>{!this.props.disableTitleCasing ? StringToTitleCase(label) : label }</span>
            </ButtonTag>
        );
    }
}

Button.propTypes = {
    id: PropTypes.string,
    active: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    type: PropTypes.oneOf(['primary', 'secondary', 'danger', 'link', 'text', 'gray10', 'primary-reverse', 'gray']),
    onClick: PropTypes.func,
    className: PropTypes.string,
    block: PropTypes.bool,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    icon: PropTypes.string,
    shouldUseIconComponent: PropTypes.bool,
    href: PropTypes.string,
    isInGroup: PropTypes.bool,
    firstInGroup: PropTypes.bool,
    lastInGroup: PropTypes.bool,
    disableTitleCasing: PropTypes.bool,
    target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
    vAlign: PropTypes.oneOf(['baseline', 'middle']),
    applyFixedHeight: PropTypes.bool,
    size: PropTypes.oneOf(['lg', 'sm', 'xs']),
    dataTestid: PropTypes.string,
};

Button.defaultProps = {
    id: undefined,
    active: false,
    label: '',
    type: 'secondary',
    onClick: undefined,
    className: '',
    block: false,
    disabled: false,
    title: '',
    icon: '',
    shouldUseIconComponent: false,
    href: '',
    isInGroup: false,
    firstInGroup: false,
    lastInGroup: false,
    disableTitleCasing: false,
    target: '_self',
    vAlign: 'baseline',
    applyFixedHeight: false,
    size: 'lg',
    dataTestid: undefined,
};

Button.contextTypes = {
    isInButtonBar: PropTypes.bool,
};

export {
    ButtonGroup,
    ButtonBar,
    StringToTitleCase,
};
