import isValidDateString from './isValidDateString';

export default function getDateFromString(dateString) {
  const d = {
    hour: '',
    minute: '',
  };

  if (!dateString) {
    return '';
  }

  if (isValidDateString(dateString)) {
    let hour = parseInt(dateString.split(':')[0], 10);
    const minute = dateString.split(':')[1].split(' ')[0];
    const amPm = dateString.split(' ')[1];

    d.minute = minute;

    if (amPm === 'PM' && hour !== 12) {
      hour += 12;
    } else if (amPm === 'AM') {
      if (hour === 12) {
        hour = '00';
      } else if (hour < 10) {
        hour = `0${hour}`;
      }
    }

    d.hour = hour;
  }

  return `${d.hour}:${d.minute}`;
}
