
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import classNames from 'classnames';

export default class PanelBoxHeader extends Component {
    constructor(props) {
        super(props);
        this.state = { };
    }

    render() {
        const styles = this.context.panelStyles;

        const panelHeaderClasses = classNames({
            [styles.Panel__hd]: true,
            [styles['Panel__hd--danger']]: this.props.backgroundColor === 'danger',
            [styles['Panel__hd--success']]: this.props.backgroundColor === 'success',
        });

        return (
            <div className={panelHeaderClasses}>
                {this.props.children}
            </div>
        );
    }
}

PanelBoxHeader.propTypes = {
    backgroundColor: PropTypes.oneOf(['white', 'danger', 'success']),
};

PanelBoxHeader.defaultProps = {
    backgroundColor: 'white',
};

PanelBoxHeader.contextTypes = {
    panelStyles: PropTypes.object,
};
