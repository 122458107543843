
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import classNames from 'classnames';
import CSSModules from 'react-css-modules';
import styles from './styles/Panel.scss';

class PanelBox extends Component {
    constructor(props) {
        super(props);
        this.state = { };
    }

    getChildContext() {
        return {
            panelStyles: styles,
            panelPosition: this.props.position,
        };
    }

    render() {
        const rootStyles = classNames({
            [styles.Panel]: true,
            [styles['u-relative']]: this.props.position === 'static',
            [styles['Panel--drawer']]: this.props.isDrawer,
            'js-Panel--drawer': this.props.isDrawer,
        });
        return (
            <div
                className={rootStyles}
                style={this.props.style}
            >{this.props.children}
            </div>
        );
    }
}

PanelBox.childContextTypes = {
    panelStyles: PropTypes.object,
    panelPosition: PropTypes.oneOf(['static', 'absolute']),
};

PanelBox.propTypes = {
    position: PropTypes.oneOf(['static', 'absolute']),
    isDrawer: PropTypes.bool,
    style: PropTypes.object,
};

PanelBox.defaultProps = {
    position: 'static',
    isDrawer: false,
    style: {},
};

export default CSSModules(PanelBox, styles);
