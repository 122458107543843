import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import classNames from 'classnames';
import classes from './styles/NavigationMenu.scss';
import MenuItem from './MenuItem.jsx';
import PropTypes from 'prop-types';

class NavigationMenu extends Component {
    constructor(props, context) {
        super(props, context);
    }

    static propTypes = {
        id: PropTypes.string,
        title: PropTypes.string,
        strict: PropTypes.bool,
    };

    static defaultProps = {
        id: '',
        title: '',
        strict: true,
    };

    getMenuItems() {
        return React.Children.toArray(this.props.children)
            .filter(child => child && (child.type === MenuItem || !this.props.strict))
            .map(child => ({
                id: child.props.id,
                label: child.props.label,
                isActive: child.props.isActive,
                disabled: child.props.disabled,
                component: child,
                isActiveBackGroundColor: child.props.isActiveBackGroundColor,
                isActiveBorderColor: child.props.isActiveBorderColor,
                isActiveBorderPlacement: child.props.isActiveBorderPlacement,
                applyHoverEffect: child.type === MenuItem || child.props.applyHoverEffect,
                noBorder: child.props.noBorder ?? false,
            }));
    }

    render() {
        const menuItems = this.getMenuItems();
        return (
            <div id={this.props.id} styleName="NavigationMenu">
                {this.props.title && (
                    <div styleName="NavigationMenu__header">
                        <h3 styleName="NavigationMenu__heading">{this.props.title}</h3>
                    </div>
                )}
                <ul styleName="NavigationMenu__list">
                    {menuItems.map((item, index) => (
                        <li
                            key={item.id ? item.id : `${item.label}_${index}`}
                            id={item.id}
                            className={classNames(classes.NavigationMenu__item, {
                                [classes[`NavigationMenu__item--noBorder`]]: item.noBorder === true,
                                [classes['NavigationMenu__item--isActive']]: item.isActive === true,
                                [classes['NavigationMenu__item--isDisabled']]: item.disabled,
                                [classes['NavigationMenu__item--applyHoverEffect']]: item.applyHoverEffect,
                                [classes[`NavigationMenu__item--isActiveBackGroundColor--${item.isActiveBackGroundColor}`]]:
                                    item.isActive === true,
                                [classes[`NavigationMenu__item--isActiveBorderColor--${item.isActiveBorderColor}`]]:
                                    item.isActive === true && item.noBorder !== true,
                                [classes[`NavigationMenu__item--isActiveBorderPlacement--${item.isActiveBorderPlacement}`]]:
                                    item.isActive === true && item.noBorder !== true,
                            })}>
                            {item.component}
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

export default CSSModules(NavigationMenu, classes);
export { MenuItem };
