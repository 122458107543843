import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class FormGridColumn extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = { };
    }

    render() {
        const cssClasses = this.context.formGridClasses;
        const columnStyle = {};
        if (this.props.query && this.props.query.minWidth && this.props.widthAtContainerSize) {
            columnStyle.width = `${this.props.widthAtContainerSize}rem`;
        }
        return (
            <div
                style={columnStyle}
                className={classNames(cssClasses.FormGrid__column, {
                    [cssClasses['FormGrid__column--auto']]: this.props.widthAuto,
                    [cssClasses['FormGrid__column--engaged']]: this.props.query.minWidth,
                    [cssClasses[`FormGrid__column--${this.props.alignment}`]]: this.props.alignment,
                    [cssClasses[`FormGrid__column--${this.props.alignmentEngaged}--engaged`]]: this.props.alignmentEngaged,
                })}>{this.props.children}</div>
        );
    }
}

FormGridColumn.propTypes = {
    widthAuto: PropTypes.bool,
    query: PropTypes.object,
    widthAtContainerSize: PropTypes.number,
    alignment: PropTypes.oneOf(['left', 'right', 'center']),
    alignmentEngaged: PropTypes.oneOf(['left', 'right', 'center']),
};

FormGridColumn.defaultProps = {
    widthAuto: false,
    query: {},
    widthAtContainerSize: null,
    alignment: 'left',
    alignmentEngaged: 'left',
};

FormGridColumn.contextTypes = {
    formGridClasses: PropTypes.object,
};
