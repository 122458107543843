import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TetherSelectComponent from './TetherSelectComponent.jsx';
import TetherSelectPlusComponent from './TetherSelectPlusComponent.jsx';
import GetOptions from './GetOptions.js';
import './scss/default.global.scss';
import classes from './Select.scss';
import SelectedValueDefault from './SelectedValueDefault.jsx';
import SelectedValueMulti from './SelectedValueMulti.jsx';
import Icon from '../../../../Icon/Icon.js';

export default class Select extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        id: PropTypes.string,
        placeholder: PropTypes.string,
        showSearchIconOnFocus: PropTypes.bool
    };

    static defaultProps = {
        disabled: false,
        id: null,
        placeholder: '',
        showSearchIconOnFocus: false,
    };

    static contextTypes = {
        fieldId: PropTypes.string.isRequired,
        fieldValidation: PropTypes.oneOf(['', 'error', 'success']),
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            fieldValidation: '',
        };

        this.getTypeaheadOptions = this.getTypeaheadOptions.bind(this);
    }

    hasCategories(props) {
        const { options = [] } = props || this.props;
        return Boolean(options.find(f => Boolean(f.options)));
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const VALIDATION_KEY = 'fieldValidation';
        if (nextContext[VALIDATION_KEY] !== this.state[VALIDATION_KEY]) {
            this.setState({
                fieldValidation: nextContext[VALIDATION_KEY],
            });
        }
    }

    handleMultiSelectOptions() {
        const { value, multi, options, maxSelect } = this.props;

        if (!multi || !maxSelect) {
            return options;
        }

        const isMaxSelected = maxSelect && value?.length >= maxSelect;
        if (isMaxSelected) {
            return options.map((m) => ({
                ...m,
                options: m.options.map((o) => ({
                    ...o,
                    disabled: !value.some((v) => v?.value === o?.value),
                })),
            }));
        }

        return options;
    }

    getTypeaheadOptions(input, callback) {
        return GetOptions(input, callback, this.props.options);
    }

    render() {
        if (this.context.fieldId) {
            const selectClassNames = classNames({
                [classes.FieldSelect]: true,
                [classes['TetherSelect--error']]: this.state.fieldValidation === 'error',
                [classes['FieldSelect--icon']]: this.props?.showSearchIconOnFocus,
            });

            if (this.hasCategories(this.props)) {
                return (
                    <TetherSelectPlusComponent
                        {...this.props}
                        options={this.handleMultiSelectOptions()}
                        outerClassnames={['FieldSelect__tetherElement']}
                        clearable={false}
                        placeholder={this.props.placeholder}
                        disabled={this.props.disabled}
                        value={this.props.value ? this.props.value : null}
                        valueComponent={this.props.multi ? SelectedValueMulti : SelectedValueDefault}
                        inputProps={
                            { id: this.context.fieldId }
                        }
                        className={selectClassNames} />
                );
            }

            if (this.props.typeahead || this.props.loadOptions) {
                // use loadOptions prop if available,
                // otherwise use standard options returned from typeahead helper method
                const loadOptions = this.props.loadOptions ? this.props.loadOptions : this.getTypeaheadOptions;

                // BELOW: need to set empty value to null so that "x" doesn't show
                return (
                    <TetherSelectComponent.Async loadOptions={loadOptions} {...this.props}>
                        {asyncProps => (
                            <TetherSelectComponent
                                {...asyncProps}
                                filterOptions={this.props.filterOptions ? this.props.filterOptions : null}
                                outerClassnames={['FieldSelect__tetherElement']}
                                clearable={false}
                                placeholder={this.props.placeholder}
                                disabled={this.props.disabled}
                                value={this.props.value ? this.props.value : null}
                                valueComponent={this.props.multi ? SelectedValueMulti : SelectedValueDefault}
                                inputProps={
                                    { id: this.context.fieldId }
                                }
                                className={selectClassNames}
                                onInputChange={input => asyncProps.onInputChange(input)} />
                        )}
                    </TetherSelectComponent.Async>
                );
            }

            return (
                <div className={this.props?.showSearchIconOnFocus ? classes.Wrapper : ''}>
                    {this.props?.showSearchIconOnFocus && <div className={classes.Icon}>
                        <Icon name="search" size={16} />
                    </div>}
                    <TetherSelectComponent
                        {...this.props}
                        outerClassnames={['FieldSelect__tetherElement']}
                        clearable={false}
                        placeholder={this.props.placeholder}
                        disabled={this.props.disabled}
                        value={this.props.value ? this.props.value : null}
                        valueComponent={this.props.multi ? SelectedValueMulti : SelectedValueDefault}
                        inputProps={
                            { id: this.context.fieldId }
                        }
                        className={selectClassNames}
                    />
                </div>
            );
        }

        return (<div />);
    }
}
