
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import classNames from 'classnames';

export default class IconAsideIcon extends Component {
    static contextTypes = {
        iconAsideColor: PropTypes.oneOf(['', 'red', 'green', 'blue', 'grey', 'grey80', 'blue60']),
        iconAsideStyles: PropTypes.object,
        iconAsidePosition: PropTypes.oneOf(['left', 'right']),
        iconAsideIconSize: PropTypes.oneOf(['sm', 'lg']),
    }

    static propTypes = {
        verticalAlign: PropTypes.oneOf(['top', 'middle']),
    }

    static defaultProps = {
        verticalAlign: 'top',
    }

    constructor(props) {
        super(props);
        this.state = { };
    }

    render() {
        const color = this.context.iconAsideColor;
        const styles = this.context.iconAsideStyles;
        const iconContainerClass = classNames({
            [styles.IconAside__iconContainer]: true,
            [styles['IconAside__iconContainer--lg']]: this.context.iconAsideIconSize === 'lg',
            [styles['IconAside__iconContainer--reverse']]: this.context.iconAsidePosition === 'right',
            [styles['IconAside__iconContainer--vMiddle']]: this.props.verticalAlign === 'middle',
            [styles['IconAside__iconContainer--red']]: color === 'red',
            [styles['IconAside__iconContainer--green']]: color === 'green',
            [styles['IconAside__iconContainer--blue']]: color === 'blue',
            [styles['IconAside__iconContainer--grey']]: color === 'grey',
            [styles['IconAside__iconContainer--grey80']]: color === 'grey80',
            [styles['IconAside__iconContainer--blue60']]: color === 'blue60',
        });

        return (
            <div className={iconContainerClass}>{this.props.children}</div>
        );
    }
}
