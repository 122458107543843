import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import messageCSS from './styles.scss';
import ActionBar from '../ActionBar/ActionBar';
import {
  Modal,
  ModalAnimation,
  Icon,
  Heading,
} from '../index';

const iconForType = {
  success: 'completeSolid',
  info: 'infoSolid',
  warning: 'warningSolid',
  danger: 'alertSolid',
};

const MessageModal = ({
  isOpen,
  onClose,
  header,
  bodyCopy,
  children,
  footerActions,
  type,
}) => (
  <TransitionGroup>
    { isOpen
      && (
      <ModalAnimation>
        <Modal
          roundedCorners
          headerDivider={false}
          position="center"
          size="medium"
          isOpen={isOpen}
          footer={(footerActions) ? <ActionBar footerActions={footerActions} align="center" /> : false}
          onCloseClick={onClose}
        >
          <div className={messageCSS.Message__container}>
            {type && (
              <div className={classNames(messageCSS.Message__icon, messageCSS[`Message__${type}`])}>
                <Icon
                  size={96}
                  name={iconForType[type] || 'success'}
                />
              </div>
            )}
            {header && <Heading className={messageCSS.Message__heading} styleLevel="h2">{header}</Heading>}
            {bodyCopy && <div>{bodyCopy}</div>}
            {children}
          </div>
        </Modal>
      </ModalAnimation>
      )}
  </TransitionGroup>
);

MessageModal.propTypes = {
  children: PropTypes.node,
  bodyCopy: PropTypes.string,
  header: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  type: PropTypes.oneOf(['success', 'info', 'warning', 'danger']),
  footerActions: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string,
    target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
    onClick: PropTypes.func,
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['primary', 'secondary', 'danger', 'text']),
    disabled: PropTypes.bool,
  })),
};

MessageModal.defaultProps = {
  header: undefined,
  bodyCopy: undefined,
  type: undefined,
  onClose: () => {},
  children: undefined,
  footerActions: undefined,
};

export default MessageModal;
