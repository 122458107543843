import React from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import css from './DrawerAnimationAndLayout.scss';

const duration = parseInt(css.DrawerAnimationDuration, 10);

/**
 * Establishes the TransitionGroup in the DOM, applies animation
 * styling when isOpen prop is toggled, and applies layout
 * styling for the Drawer
 */
const DrawerAnimationAndLayout = ({
  children,
  isOpen,
}) => (
  <ReactCSSTransitionGroup
    component="div"
    className={css.DrawerAnimationAndLayout}
    transitionName={{
      enter: css['DrawerAnimationAndLayout__content--enter'],
      enterActive: css['DrawerAnimationAndLayout__content--enterActive'],
      leave: css['DrawerAnimationAndLayout__content--leave'],
      leaveActive: css['DrawerAnimationAndLayout__content--leaveActive'],
    }}
    transitionEnterTimeout={duration}
    transitionLeaveTimeout={duration}
  >
    {Boolean(isOpen)
      && (
      <div className={css.DrawerAnimationAndLayout__content}>
        {children}
      </div>
      )}
  </ReactCSSTransitionGroup>
);

DrawerAnimationAndLayout.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
};

DrawerAnimationAndLayout.defaultProps = {
  isOpen: false,
};

export default DrawerAnimationAndLayout;
