/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import TetherComponent from '../TetherComponent';

const ANIMATION_STATES = {
  OPENING_START: 'openingStart',
  OPENING: 'opening',
  OPENED: 'opened',
  CLOSING: 'closing',
  CLOSED: 'closed',
};

export default class SkinlessPopover extends Component {
  constructor(props) {
    super(props);

    const { isOpen, otherChildrenClasses } = this.props;
    this.state = {
      isOpen,
      animationState: isOpen
        ? ANIMATION_STATES.OPENED
        : ANIMATION_STATES.CLOSED,
      isOutOfBoundsRight: false,
      otherChildrenClasses,
      hoverTimerId: null,
    };
    this._isMounted = false;
    this.onDocClick = this.onDocClick.bind(this);
    this.toggleVisibility = this.toggleVisibility.bind(this);
    this.turnOnVisiblity = this.turnOnVisiblity.bind(this);
    this.turnOffVisiblity = this.turnOffVisiblity.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    document.addEventListener('click', this.onDocClick, true);
  }

  componentDidUpdate(prevProps, prevState) {
    const { isOpen: isOpenState } = this.state;
    const {
      onChangeOpen,
      animationDuration,
      isOpen: isOpenProps,
    } = this.props;
    if (isOpenState !== prevState.isOpen && onChangeOpen) {
      onChangeOpen(isOpenState);
    }

    // Programmatic (props) method to open the panel
    if (!isOpenState && !prevState.isOpen && !prevProps.isOpen && isOpenProps) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isOpen: true });
    }

    // Need a way to enforce a close from props -- this will cascade to state and call componentDidUpdate again
    if (isOpenState && prevProps.isOpen && !isOpenProps) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isOpen: false });
    }

    // opening animation sequence
    if (!prevState.isOpen && isOpenState) {
      // TODO refactor or rewrite this component to use React Transition Library, cascading timeOut breaks on unmount
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ animationState: ANIMATION_STATES.OPENING_START }, () => {
        window.setTimeout(() => {
          if (this._isMounted) {
            this.setState({ animationState: ANIMATION_STATES.OPENING }, () => {
              window.setTimeout(() => {
                this.setState({
                  animationState: ANIMATION_STATES.OPENED,
                  isOutOfBoundsRight: this.isOutOfBoundsRight(),
                });
              }, animationDuration);
            });
          }
        }, 1);
      });
      return;
    }

    // closing animation sequence
    if (prevState.isOpen && !isOpenState) {
      this.setState({ animationState: ANIMATION_STATES.CLOSING }, () => { // eslint-disable-line
        // reset `isOutOfBoundsRight` so that browser properly gets left
        // position each time the menu opens
        window.setTimeout(() => {
          if (this._isMounted) {
            this.setState({
              animationState: ANIMATION_STATES.CLOSED,
              isOutOfBoundsRight: false,
            });
          }
        }, animationDuration);
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener('click', this.onDocClick);
  }

  getOtherChildren() {
    const { animationState } = this.state;
    if (animationState !== ANIMATION_STATES.CLOSED) {
      return this.otherChildren;
    }

    return null;
  }

  getElementClasses() {
    let tetherElementClasses = 'tether-element';
    const { isAboveModal } = this.context;
    const { classes } = this.props;
    const { animationState } = this.state;
    if (isAboveModal) {
      tetherElementClasses += ' tether-element-aboveModal';
    }
    if (classes.element) {
      tetherElementClasses += ` ${classes.element}`;
    }
    if (animationState === ANIMATION_STATES.OPENING) {
      tetherElementClasses += ' tether-element-isOpening';
    }
    if (animationState === ANIMATION_STATES.OPENED) {
      tetherElementClasses += ' tether-element-isOpened';
    }
    if (animationState === ANIMATION_STATES.CLOSING) {
      tetherElementClasses += ' tether-element-isClosing';
    }
    if (animationState === ANIMATION_STATES.CLOSED) {
      tetherElementClasses += ' tether-element-isClosed';
    }

    return tetherElementClasses;
  }

  turnOnVisiblity() {
    this.setState({ isOpen: true });
  }

  turnOffVisiblity() {
    this.setState({ isOpen: false });
  }

  toggleVisibility() {
    this.setState((state) => ({ isOpen: !state.isOpen }));
  }

  isOutOfBoundsRight() {
    // eslint-disable-next-line react/no-find-dom-node
    const $tooltip = ReactDOM.findDOMNode(this.popoverTarget);
    if ($tooltip) {
      const windowWidth = window.innerWidth;

      const remaining = windowWidth
        - $tooltip.offsetWidth
        - $tooltip.getBoundingClientRect().left;
      return remaining < 1;
    }

    return null;
  }

  closePopover() {
    this.setState({ isOpen: false });
  }

  checkIfContainsOtherChildren(target) {
    const { otherChildrenClasses } = this.state;
    const classes = otherChildrenClasses.split(' ');

    return classes.filter((className) => className !== '' && target.closest(`.${className}`)).length;
  }

  onDocClick(e) {
    // eslint-disable-next-line react/no-find-dom-node
    const $tooltip = ReactDOM.findDOMNode(this.popoverTarget);
    // eslint-disable-next-line react/no-find-dom-node
    const $root = ReactDOM.findDOMNode(this.popoverTrigger);
    const { isOpen } = this.state;
    if ($tooltip && $root) {
      if (
        isOpen
        && !$tooltip.contains(e.target)
        && !$root.contains(e.target)
        && !this.checkIfContainsOtherChildren(e.target)
      ) {
        this.closePopover();
      }
    }
  }

  render() {
    const {
      children,
      showOnEvent,
      hoverOnChild,
    } = this.props;
    this.firstChild = React.Children.map(children, (c, index) => {
      if (index === 0) {
        let options = {
          key: c.key || uuid(),
        };
        if (showOnEvent === 'click') {
          options = {
            onClick: this.toggleVisibility,
          };
        } else if (showOnEvent === 'hover') {
          const { timeout } = this.props;
          let timer;
          const hoverTimeout = () => {
            timer = setTimeout(() => {
              this.setState({ isOpen: true });
            }, timeout);
          };
          options = {
            onMouseEnter: hoverTimeout,
            onMouseLeave: () => {
              if (hoverOnChild) {
                const hoverTimerId = setTimeout(() => {
                  this.turnOffVisiblity();
                  clearTimeout(timer);
                }, 50);
                this.setState({ hoverTimerId });
              } else {
                this.turnOffVisiblity();
                clearTimeout(timer);
              }
            },
          };
        }
        options.ref = (node) => {
          this.popoverTrigger = node;
        };
        return React.cloneElement(c, options);
      }

      return null;
    });

    this.otherChildren = React.Children.map(children, (c, index) => {
      if (index !== 0) {
        return React.cloneElement(c, {
          key: c.key || uuid(),
          ref: (node) => {
            this.popoverTarget = node;
          },
        });
      }

      return null;
    });

    const tetherElementClasses = this.getElementClasses();
    const { isOutOfBoundsRight, hoverTimerId } = this.state;
    const {
      targetAttachment,
      attachment,
      constrainTo,
      constrainToType,
      isBelowPanel,
      testId,
    } = this.props;

    return (
      <TetherComponent
        key={testId}
        hoverTimerId={hoverTimerId}
        toggleVisibility={this.toggleVisibility}
        isBelowPanel={isBelowPanel}
        classes={{ element: tetherElementClasses }}
        attachment={
          isOutOfBoundsRight
            ? attachment.replace('left', 'right')
            : attachment
        }
        targetAttachment={
          isOutOfBoundsRight
            ? targetAttachment.replace('left', 'right')
            : targetAttachment
        }
        constraints={[
          {
            to: constrainTo,
            attachment: constrainToType,
          },
        ]}
      >
        {this.firstChild}
        {this.getOtherChildren()}
      </TetherComponent>
    );
  }
}

SkinlessPopover.propTypes = {
  testId: PropTypes.string,
  attachment: PropTypes.string,
  targetAttachment: PropTypes.string,
  showOnEvent: PropTypes.oneOf(['click', 'hover']),
  constrainTo: PropTypes.oneOf(['window', 'scrollParent']),
  constrainToType: PropTypes.oneOf(['together', 'none']),
  isOpen: PropTypes.bool,
  onChangeOpen: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  animationDuration: PropTypes.number,
  classes: PropTypes.shape({
    element: PropTypes.string,
  }),
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  isBelowPanel: PropTypes.bool,
  otherChildrenClasses: PropTypes.string,
  timeout: PropTypes.number,
  hoverOnChild: PropTypes.bool,
};

SkinlessPopover.defaultProps = {
  testId: `sp-${uuid()}`,
  attachment: 'bottom left',
  onChangeOpen: false,
  targetAttachment: 'top left',
  showOnEvent: 'click',
  constrainTo: 'window',
  constrainToType: 'together',
  isOpen: false,
  animationDuration: 0,
  classes: {},
  isBelowPanel: false,
  otherChildrenClasses: '',
  timeout: 0,
  hoverOnChild: false,
};

SkinlessPopover.contextTypes = {
  isAboveModal: PropTypes.bool,
};
