import React from 'react';
import PropTypes from 'prop-types';
import {
  Group,
  Label,
  HelpText,
  TimePicker as FieldTimePicker,
} from '../../legacy/Construction/Field/Field';

const TimePicker = ({
  id,
  validationType,
  labelHidden,
  optional,
  label,
  helpText,
  disabled,
  value,
  onChange,
}) => (
  <Group
    id={id}
    validation={validationType}
  >
    <Label
      screenReaderOnly={labelHidden}
      optional={optional}
      help=""
    >
      {label}
    </Label>
    <FieldTimePicker
      name={id}
      disabled={disabled}
      placeholder=""
      value={value}
      onChange={onChange}
    />
    {helpText && <HelpText fieldValidation="">{helpText}</HelpText>}
  </Group>
);

TimePicker.propTypes = {
  id: PropTypes.string.isRequired,
  validationType: PropTypes.oneOf(['', 'error', 'success']),
  labelHidden: PropTypes.bool,
  optional: PropTypes.bool,
  label: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

TimePicker.defaultProps = {
  validationType: '',
  labelHidden: false,
  optional: false,
  helpText: '',
  disabled: false,
  value: '',
  onChange: () => {},
};

export default TimePicker;
