import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ButtonBarMoreMenu from '../ButtonBarMoreMenu';
import GetAcceptableChildren from '../utils/getAcceptableButtonChildren';
import classes from './styles.scss';
import HorizontalList from '../HorizontalList';
import HorizontalListItem from '../HorizontalListItem';
import Button from '../legacy/Construction/Button';

function getButtons(buttonList) {
  const buttons = [];

  React.Children.forEach(buttonList, (child, i) => {
    const firstInGroup = i === 0;
    const lastInGroup = i === React.Children.count(buttonList) - 1;

    const button = React.cloneElement(child, {
      isInGroup: true,
      firstInGroup,
      lastInGroup,
    });

    buttons.push(<HorizontalListItem key={child.label}>{button}</HorizontalListItem>);
  });

  return buttons;
}

function getFinalButtonList(buttonProps) {
  const buttons = [];
  if (!buttonProps) {
    return [];
  }

  Object.keys(buttonProps).forEach((key) => {
    buttons.push(<Button {...buttonProps[key]} />);
  });

  return buttons;
}

function getFinalActionsMenu(menuProps, buttonsCount) {
  if (menuProps.length === 0) {
    return null;
  }
  const menuItems = [];
  Object.keys(menuProps).forEach((key) => {
    menuItems.push(menuProps[key]);
  });
  return (
    <ButtonBarMoreMenu
      label={buttonsCount === 0 ? 'Actions' : 'More'}
      menuItems={menuItems}
    />
  );
}

function getButtonAndMenuFromSplitProps(splitProps) {
  const buttons = getFinalButtonList(splitProps.buttonArray);
  const actionsMenu = getFinalActionsMenu(splitProps.menuArray, buttons.length);
  const children = actionsMenu ? buttons.concat([actionsMenu]) : buttons;
  return children;
}

export default class MultiButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      availableButtonsWidth: null,
      haveMeasurements: null,
    };

    this.node = React.createRef();
    this.actionsMenu = React.createRef();

    this.buttonRefs = [];
    this.buttonProps = [];
    this.handleResize = this.handleResize.bind(this);
    this.ensureMeasurementsAndButtonProps = this.ensureMeasurementsAndButtonProps.bind(this);
  }

  componentDidMount() {
    // add slight delay on initial measuring to account for container query resizing
    this.didMountTimeout = window.setTimeout(
      this.ensureMeasurementsAndButtonProps,
      300,
    );
    window.addEventListener('resize', this.handleResize);
  }

  UNSAFE_componentWillReceiveProps() {
    this.clearMeasurementsAndButtonInfo();
  }

  componentDidUpdate() {
    this.ensureMeasurementsAndButtonProps();
  }

  componentWillUnmount() {
    if (this.didMountTimeout) {
      window.clearTimeout(this.didMountTimeout);
      this.didMountTimeout = null;
    }
    window.removeEventListener('resize', this.handleResize);
  }

  getAvailableButtonsWidth() {
    const componentWidth = this.node.current.offsetWidth;

    let buttonWidthsSum = 0;
    this.buttonWidths.forEach((width) => {
      buttonWidthsSum += width;
    });

    let availableButtonsWidth = componentWidth;
    if (buttonWidthsSum > componentWidth) {
      availableButtonsWidth = componentWidth - this.actionsMenuWidth;
    }
    return availableButtonsWidth;
  }

  setButtonProps() {
    const { buttons } = this.props;
    this.buttonProps = buttons.map((button) => button.props);
  }

  setMeasurements() {
    this.buttonWidths = this.getButtonWidths();
    this.actionsMenuWidth = this.getActionsMenuWidth();
  }

  getActionsMenuWidth() {
    // add one for sub-pixels
    return Math.ceil(this.actionsMenu.current.offsetWidth) + 1;
  }

  getButtonWidths() {
    return this.buttonRefs.map((button) => (
      // add one for sub-pixels
      // eslint-disable-next-line react/no-find-dom-node
      ReactDOM.findDOMNode(button).offsetWidth + 1
    ));
  }

  getAcceptableChildren() {
    const { buttons } = this.props;
    return GetAcceptableChildren(buttons);
  }

  // Returns object with props correctly sorted into
  // `buttons` and `menuItems`. It uses `this.state.availableButtonsWidth`
  // and `this.buttonsWidth` to determine how many props go
  // in buttons attribute vs menuItems.
  getPropsSplit() {
    const children = this.getAcceptableChildren();
    const buttonArray = [];
    const menuArray = [];

    const { availableButtonsWidth } = this.state;
    let buttonsWidth = 0;
    const buttonsProps = this.buttonProps;
    React.Children.forEach(children, (child, i) => {
      const buttonProps = buttonsProps[i];
      buttonsWidth += this.buttonWidths[i];
      if (availableButtonsWidth > buttonsWidth) {
        buttonArray.push(buttonProps);
      } else {
        menuArray.push(buttonProps);
      }
    });

    return { buttonArray, menuArray };
  }

  getRootClasses() {
    const { inline } = this.props;
    return classNames([classes.MultiButton], {
      [classes['MultiButton--inline']]: inline,
    });
  }

  ensureMeasurementsAndButtonProps() {
    const { haveMeasurements } = this.state;
    if (!haveMeasurements) {
      this.setMeasurements();
      this.setButtonProps();
      this.setState({
        haveMeasurements: true,
        availableButtonsWidth: this.getAvailableButtonsWidth(),
      });
    }
  }

  clearMeasurementsAndButtonInfo() {
    this.buttonWidths = null;
    this.actionsMenuWidth = null;
    this.setState({ haveMeasurements: false });
  }

  handleResize() {
    // @TODO: In order to get the ButtonBar compatible with MedianAlpha component
    // we need to re-measure the container width on resize, but while the button list
    // is out of the flow. The button list has flex-wrap: nowrap and MedianAlpha has
    // flex-grow: 999. Because of this, we can't get a true measurement on the container
    // because the buttonlist pushes it wider than it's supposed to be.
    //
    // Invoking this.clearMeasurementsAndButtonInfo() is a temporary solution. Originally,
    // this method was just setting the state property `availableButtonsWidth`.
    // We could optimize what we do on resize by caching (or not clearning) the
    // buttons width and actionsmenu width as long as we set state.haveMeasurements to
    // false so that can measure the container with the buttons out of the flow.

    this.clearMeasurementsAndButtonInfo();
  }

  renderMeasured() {
    const finalButtonList = getButtons(getButtonAndMenuFromSplitProps(this.getPropsSplit()));
    return (
      <div
        ref={this.node}
        className={this.getRootClasses()}
        style={{ minWidth: `${this.actionsMenuWidth}px` }}
      >
        <HorizontalList noWrap>{finalButtonList}</HorizontalList>
      </div>
    );
  }

  renderUnMeasured() {
    const children = getButtons(this.getAcceptableChildren());
    this.buttonRefs = [];
    return (
      <div
        className={this.getRootClasses()}
        ref={this.node}
      >
        <div className={classes.MultiButton__hiddenElement}>
          <HorizontalList noWrap>
            {React.Children.map(children, (child, i) => React.cloneElement(child, {
              ref: (button) => {
                if (button) {
                  this.buttonRefs[i] = button;
                }
              },
            }))}
          </HorizontalList>
        </div>
        <span
          ref={this.actionsMenu}
        >
          <HorizontalList noWrap display="inline">
            {getButtons(<ButtonBarMoreMenu />)}
          </HorizontalList>
        </span>
      </div>
    );
  }

  render() {
    const { haveMeasurements } = this.state;
    if (!haveMeasurements) {
      return this.renderUnMeasured();
    }
    return this.renderMeasured();
  }
}

MultiButton.propTypes = {
  inline: PropTypes.bool,
  buttons: PropTypes.arrayOf(PropTypes.element).isRequired,
};

MultiButton.defaultProps = {
  inline: false,
};
