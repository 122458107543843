
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import classNames from 'classnames';
import CSSModules from 'react-css-modules';
import styles from './Stars/styles/Stars.scss';
import StarRating from './Stars/StarRating.jsx';

const getFloatToHalf = (num) => {
    const calcedFloat = (Math.floor(num * 2) / 2);
    return calcedFloat;
}

class Stars extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const self = this;
        const stars = [];
        for (let i = 0; i < 5; i++) {
            let value = '';
            if (parseFloat(this.props.rating) >= parseFloat(i + 1)) {
                value = 'full';
            } else if (parseFloat(this.props.rating) < parseFloat(i + .5)) {
                value = 'empty';
            } else {
                value = 'half';
            }
            const starClasses = classNames({
                [styles.stars__icon]: true,
                [styles['stars__icon--md']]: this.props.size === 'md',
                [styles['stars__icon--lg']]: this.props.size === 'lg',
                [styles['stars__icon--green']]: this.props.color === 'green' && value !== 'empty',
                [styles['stars__icon--orange']]: this.props.color === 'orange' && value !== 'empty',
                [styles['stars__icon--full']]: value === 'full',
                [styles['stars__icon--empty']]: value === 'empty',
                [styles['stars__icon--half']]: value === 'half',
            });

            stars.push(<span className={starClasses} key={i} />);
        }
        return (
            <span styleName="stars">
                {stars}
            </span>
        );
    }
}

Stars.propTypes = {
    rating: PropTypes.oneOf([0, .5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5]),
    color: PropTypes.oneOf(['yellow', 'green', 'orange']),
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

Stars.defaultProps = {
    rating: 0,
    color: 'yellow',
    size: 'sm',
};

export default CSSModules(Stars, styles);

export {
    StarRating,
    getFloatToHalf,
};
