import React from 'react';

import BaseDay from 'react-big-calendar/lib/Day';
import TimeGrid from './TimeGrid';

export default class Day extends BaseDay {
  render() {
    const { date, ...props } = this.props;
    const range = BaseDay.range(date);

    return <TimeGrid {...props} className="fn-calendar-day" range={range} eventOffset={10} />;
  }
}
