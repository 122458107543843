import * as React from 'react';
import { useState, useEffect } from 'react';
import { useRecoilValue, } from 'recoil';
import Listing from '../v2/Listing';
import usePreferenceColumn from './use-preference-column';
import useCheckboxes from './use-checkboxes';
import usePagination from './use-pagination';
import useFilters from './use-filters';
import useView from './use-view';
import useTabs from './use-tabs';
import useColumns from './use-columns';
import requestQuery from './request.query';
import tabPropsQuery from './tab-props.query';
import selectedColumnIdsQuery from './selected-column-ids.query';
import columnWidthsQuery from './column-widths.query';
import activeColumnsQuery, {
  availableColumnsQuery,
} from './active-columns.query';
import activeResultsQuery from './active-results.query';
import activeFiltersQuery from './active-filters.query';
import tabsQuery from './tabs.query';
import activeViewQuery from './active-view.query';
import activePageQuery from './active-page.query';
import tabPrefsQuery from './tab-prefs.query';
import totalResultsQuery from './total-results.query';
import searchTextQuery from './search-text.query';
import availableFiltersQuery from './available-filters.query';
import savedFiltersQuery from './saved-filters.query';
import { MassAction } from './DataGrid';
import massActionsQuery from './mass-actions.query';
import selectionsQuery from './selections.query';

const defaultItemsPerPage = 100;

const View = () => {
  const apiResponse = useRecoilValue(requestQuery);
  const tabPrefs = useRecoilValue(tabPrefsQuery);
  const tabProps = useRecoilValue(tabPropsQuery);
  const availableFilters = useRecoilValue(availableFiltersQuery);
  const activeFilters = useRecoilValue(activeFiltersQuery);
  const selectedColumnIds = useRecoilValue(selectedColumnIdsQuery);
  const widthsByColumn = useRecoilValue(columnWidthsQuery);
  const searchText = useRecoilValue(searchTextQuery);
  const savedFilters = useRecoilValue(savedFiltersQuery);
  const setView = useView();
  const [search, setSearch] = useState(searchText);
  const [columnConfig, setColumnConfig] = useState(false);
  const { resetActiveColumns, setActiveColumns } = useColumns();
  const {
    setSearchText,
    applyFilter,
    removeFilter,
    clearFilters,
    addSavedFilter,
    replaceFilters,
    deleteSavedFilter,
  } = useFilters();
  const setPreferenceColumn = usePreferenceColumn();
  const { setTab } = useTabs();
  const { setPage } = usePagination();
  const {
    addIdToChecked,
    removeIdFromChecked,
    checkAllIds,
    uncheckAllIds,
  } = useCheckboxes();
  const results = useRecoilValue(activeResultsQuery);
  const totalResults = useRecoilValue(totalResultsQuery);
  const allTabs = useRecoilValue(tabsQuery);
  const massActions = useRecoilValue(massActionsQuery);
  const onSelections = useRecoilValue(selectionsQuery);
  const view = useRecoilValue(activeViewQuery);
  const page = useRecoilValue(activePageQuery);
  const columns = useRecoilValue(activeColumnsQuery);
  const availableColumns = useRecoilValue(availableColumnsQuery);

  if (!tabPrefs || !tabProps || !apiResponse || !allTabs || !results) {
    return null;
  }

  const { selectedItems } = tabPrefs;
  const { withCheckboxes = false, withMapSupport = false } = tabProps;

  useEffect(() => {
    if (typeof onSelections === 'function') {
      onSelections(selectedItems);
    }
  }, [selectedItems]);

  return (
    <div data-testid="listing-container">
      <Listing
        activeView={view}
        selectedIds={selectedItems}
        tableConfig={{
          selectedIds: selectedItems,
          data: results,
          columnConfig: {
            title: '',
            availableColumns: [
              {
              // again, the <ColumnConfig> mutates props which is nasty and recoil
              // only provides locked objects, so we have to clone it
                items: availableColumns.map((f) => ({ ...f })),
              },
            ],
            columns: selectedColumnIds,
            isExport: false,
            onCancel: () => setColumnConfig(false),
            onDone: (columnsWithSize: string) => {
              setColumnConfig(false);
              setActiveColumns(columnsWithSize);
            },
            onRestoreSystemDefaults: () => {
              setColumnConfig(false);
              resetActiveColumns();
            },
          },
          columns: columns.map((column, index) => {
          // not a real column -- this is how the <Listing> handles the ... actions per row
            if (column.id === 'inline_actions') {
              return {
                id: column.id,
                header: '',
                sortable: false,
                render: (row) => (tabProps.rowActions
                  ? tabProps.rowActions(row, results, index)
                  : []),
              };
            }

            // specified in the front end code
            const propColumn = tabProps.columns.find(
              ({ id }) => id === column.id,
            );

            return {
              id: column.id,
              header: column.label || column.id,
              sortable: Boolean(column.withSort),
              // note: sortDirection !== undefined means column is sorted!
              sortDirection:
              column.sorted && column.sortDirection
                ? (column.sortDirection.toUpperCase() as 'ASC' | 'DESC')
                : undefined,
              width: widthsByColumn.get(column.id) || 0,
              truncate: Boolean(propColumn?.withTruncation),
              maxWidth: propColumn?.maxWidth || undefined,
              minWidth: propColumn?.minWidth || undefined,
              render: (row) => (propColumn && propColumn.render
                ? propColumn.render(row, results, index)
                : null),
            };
          }),
          currentPage: page,
          itemsPerPage: defaultItemsPerPage,
          selectable: withCheckboxes,
          onAllRowsChange: (checked) => (checked ? checkAllIds() : uncheckAllIds()),
          onColumnResize: (id, width) => {
            setPreferenceColumn(id, { width });
          },
          onColumnSort: (id, dir) => {
            if (!dir) {
              // removing sort
              setPreferenceColumn(id, {
                sorted: false,
                sortDirection: 'asc',
              });
              return;
            }
            setPreferenceColumn(id, {
              sorted: true,
              sortDirection: dir.toLowerCase() === 'asc' ? 'asc' : 'desc',
            });
          },
          onPageChange: (newPage) => setPage(newPage),
          onRowChange: (id, checked) => (checked ? addIdToChecked(id) : removeIdFromChecked(id)),
          onColumnConfigToggle: () => setColumnConfig(true),
          showColumnConfig: columnConfig,
          totalRecords: totalResults,
          rowHeight: tabProps.rowHeight || 'auto',
        }}
        tabItems={
        allTabs.length > 1
          ? allTabs.map((t) => ({
            label: t.label || '',
            onClick: () => setTab(t.id),
            active: t.id === tabPrefs.id,
            count:
                apiResponse.lists.find(({ id }) => id === t.id)?.count || undefined,
          }))
          : undefined
      }
        toolbarEnabled
        toolbarConfig={{
        // note: the platform-components Toolbar component mutates and
        // deletes from the props you pass it, but recoil freezes them
        // to prevent bad behavior which causes errors, so we have to
        // copy them here to allow it to misbehave
          activeFilters: activeFilters.map((f) => ({ ...f })),
          filters: availableFilters.map((f) => ({ ...f })),
          savedFilters: savedFilters.map((f) => ({
            ...f,
            action: 'create',
            filters: f.filters.map((ff) => ({ ...ff, type: 'text', name: '' })),
          })),

          activeView: view,
          onApplyFilter: (f) => applyFilter(f),
          onClearSavedFilters: () => clearFilters(),
          onClearSearch: () => {
            setSearch('');
            setSearchText('');
          },
          onClickSavedFilter: (f) => replaceFilters(f.filters),
          onDeleteSavedFilter: (sf) => deleteSavedFilter(sf),
          onRemoveFilter: (f) => removeFilter(f),
          onSaveFilters: (sf) => addSavedFilter(sf),
          onSearch: () => setSearchText(search),
          onSearchChange: (e) => setSearch(e.target.value),
          onViewChange: (v) => setView(v),
          savedFilter: undefined,
          searchValue: search,
          views: withMapSupport ? ['list', 'map'] : ['list'],
        }}
        massActionBarEnabled={Boolean(selectedItems?.length)}
        massActionBarConfig={{
            actions: massActions.filter((i: MassAction) => i),
        }}
        views={withMapSupport ? ['list', 'map'] : ['list']}
      />
    </div>
  );
};

export default View;
