import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Addon,
  Group,
  HelpText,
  Label,
  TextInput,
  TextInputWrapper,
} from '../legacy/Construction/Field/Field';
import wrapEventHandler, { ARG_ORDER } from '../utils/wrapEventHandler';

export default class CopyText extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showCopied: false,
    };
    this.button = React.createRef();
    this.timerId = null;
    this.copy = this.copy.bind(this);
    this.setRef = this.setRef.bind(this);
  }

  componentWillUnmount() {
    window.clearTimeout(this.timerId);
  }

  copy(value, callBack) {
    const textArea = document.createElement('textarea');
    textArea.value = value;
    document.body.appendChild(textArea);
    textArea.select();
    /*
    jsdom is used by Jest and it doesn't support "execCommand"
    https://github.com/jsdom/jsdom/issues/1539

    Though with this workaround we can't test core functionality. But we
    are able to test side effects.

    Not using jest default env 'test' because later it will conflict with
    automation test suits.
    */
    if (process.env.NODE_ENV !== 'bypassExecCommadForTest') {
      document.execCommand('copy');
    }
    document.body.removeChild(textArea);

    if (this.button) {
      this.button.focus();
    }

    this.setState({ showCopied: true }, () => {
      this.timerId = setTimeout(() => {
        this.setState({ showCopied: false });
      }, 2500);
    });
    if (callBack) {
      callBack();
    }
  }

  setRef(refNode) {
    this.button = refNode;
  }

  render() {
    const {
      additionalInfo,
      id,
      label,
      value,
      callBack,
    } = this.props;

    const {
      showCopied,
    } = this.state;

    return (
      <Group
        id={id}
        validation="success"
      >
        <Label
          help={additionalInfo}
        >
          {label}
        </Label>
        <TextInputWrapper>
          <TextInput
            disabled
            hasDisableTextColor
            hasAppend
            value={value}
          />
          <Addon
            buttonRef={this.setRef}
            label="Copy Link"
            adjacentToDisabled
            onClick={wrapEventHandler(this.copy, [value, callBack], ARG_ORDER.EVENT_LAST, this)}
            orientation="right"
          />
        </TextInputWrapper>
        {showCopied && <HelpText>Link copied to clipboard</HelpText>}
      </Group>
    );
  }
}

CopyText.propTypes = {
  additionalInfo: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  callBack: PropTypes.func,
};

CopyText.defaultProps = {
  additionalInfo: '',
  callBack: undefined,
};
