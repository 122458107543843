import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './styles.scss';

const Median = ({
  verticalAlign,
  verticalFlush,
  noWrap,
  children,
}) => (
  <div
    className={css.Median}
  >
    <div className={classNames(css.Median__row, {
      [css[`Median__row--${verticalAlign}Align`]]: verticalAlign,
      [css['Median__row--verticalFlush']]: verticalFlush,
      [css['Median__row--noWrap']]: noWrap,
    })}
    >
      {Array.isArray(children)
        ? children.map((child, idx) => child && (
          React.cloneElement(
            child,
            {
              key: idx, // eslint-disable-line react/no-array-index-key
              verticalAlign,
              verticalFlush,
            },
          )
        )) : children }
    </div>
  </div>
);

Median.propTypes = {
  verticalAlign: PropTypes.oneOf(['middle', 'top', 'bottom']),
  verticalFlush: PropTypes.bool,
  noWrap: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
};

Median.defaultProps = {
  verticalAlign: 'middle',
  verticalFlush: false,
  noWrap: false,
};

export default Median;
