
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import Median from '../../../Median';
import MedianAlpha from '../../../MedianAlpha';
import MedianOmega from '../../../MedianOmega';
import TileDivisionHdg from './TileDivisionHdg.jsx';
import TileDivisionAction from './TileDivisionAction.jsx';

export default class TileDivision extends Component {
    constructor(props) {
        super(props);
        this.state = { };
    }

    render() {
        const styles = this.context.tileStyles;
        return (
            <div className={[styles.Tile__division]}>
                {(this.props.description !== undefined || this.props.action !== undefined) ?
                    <Median>
                        {this.props.description ?
                            <MedianAlpha>
                                <TileDivisionHdg data-testid={this.props.description}>
                                    {this.props.description}
                                </TileDivisionHdg>
                            </MedianAlpha>
                            :
                            null
                        }

                        {this.props.action ?
                            <MedianOmega>
                                <TileDivisionAction>
                                    {this.props.action}
                                </TileDivisionAction>
                            </MedianOmega>
                            :
                            null
                        }
                    </Median>
                    :
                    null
                }
                {this.props.children}
            </div>
        );
    }
}

TileDivision.contextTypes = {
    tileStyles: PropTypes.object,
};
