import isEqual from 'lodash/isEqual';

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (key.startsWith('_')) return null; // Don't compare React's internal props.
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) return null;
      seen.add(value);
    }
    return value;
  };
};

export const checkChildren = (currentChildren, nextChildren) => (
  JSON.stringify(nextChildren, getCircularReplacer())
    === JSON.stringify(currentChildren, getCircularReplacer())
);

export const checkProps = (currentProps, nextProps) => isEqual(nextProps, currentProps);
