import React, { PureComponent } from 'react';
import classNames from 'classnames';
import classes from './TextInputWrapper.scss';
import TextInput from '../TextInput/TextInput.jsx';
import DatePicker from 'react-datepicker';

export default class TextInputWrapper extends PureComponent {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        const { children } = this.props;
        return (
            <div className={classes.TextInputWrapper}>
                {React.Children.map(children, (child, i) => {
                    const isTextInput = child && (child.type === TextInput || child.type === DatePicker);

                    return (
                        <div
                            key={i}
                            className={classNames(classes.TextInputWrapper__item, {
                                [classes['TextInputWrapper__item--grow']]: isTextInput,
                            })}>{child}</div>
                    );
                })}
            </div>
        );
    }
}
