import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class PanelDrawerAnimation extends Component {
    constructor(props) {
        super(props);
    }

    componentWillEnter(callback) {
        if (document) {
            const drawer = document.querySelector('.js-Panel--drawer');
            TweenMax.fromTo(drawer, .25, { x: '100%' }, { x: '0%', onComplete: callback });
        }
    }

    componentWillLeave(callback) {
        if (document) {
            const drawer = document.querySelector('.js-Panel--drawer');
            TweenMax.fromTo(drawer, .25, { x: '0%' }, { x: '100%', onComplete: callback });
        }
    }

    render() {
        return (
            this.props.children
        );
    }
}
