import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './styles.scss';

export default class Truncate extends React.PureComponent {
  render() {
    const {
      lineHeight,
      maxLines,
      text,
      wordBreak,
    } = this.props;
    const lhFloat = parseFloat(lineHeight);
    return (
      <div
        className={classNames(['TruncateCrossModule', css.Truncate], {
          [css['Truncate--breakAll']]: wordBreak === 'break-all',
          [css['Truncate--keepAll']]: wordBreak === 'keep-all',
          [css['Truncate--breakWord']]: wordBreak === 'break-word',
        })}
        style={{
          lineHeight: `${lhFloat}em`,
          maxHeight: `${lhFloat * maxLines}em`,
          WebkitLineClamp: maxLines,
          WebkitBoxOrient: 'vertical',
        }}
      >
        {text}
      </div>
    );
  }
}

Truncate.propTypes = {
  text: PropTypes.node.isRequired,
  maxLines: PropTypes.number,
  lineHeight: PropTypes.string,
  wordBreak: PropTypes.oneOf(['normal', 'break-all', 'keep-all', 'break-word']),
};

Truncate.defaultProps = {
  maxLines: 3,
  // lineHeight: '1.5em', in the future this should be 1.5
  lineHeight: '1.166667em',
  wordBreak: 'normal',
};
