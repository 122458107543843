
import React, { Component } from 'react';
import PortalRenderer from '../PortalRenderer/PortalRenderer.jsx';
import classNames from 'classnames';
import styles from './styles/SaveBar.scss';
import elementClass from 'element-class';

const BODY_CLASS = 'SaveBar__Body--isOpen';

export default class SaveBar extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        elementClass(document.body).add(BODY_CLASS);
    }

    componentWillUnmount() {
        elementClass(document.body).remove(BODY_CLASS);
    }

    render() {
        const saveBarClasses = classNames({
            [styles.SaveBar]: true,
            'js-saveBar': true,
        });
        return (
            <PortalRenderer>
                <div className={saveBarClasses}>{this.props.children}</div>
            </PortalRenderer>
        );
    }
}
