import React, { Component } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker/build/DayPicker';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import AbstractInput from '../AbstractInput';
import NavBar from './NavBar';
import styles from './DatePicker.scss';
import { tokens } from '../../index';
import Weekday from './Weekday';
import HelpText from '../../legacy/Construction/Field/HelpText/HelpText';
import wrapEventHandler, { ARG_ORDER } from '../../utils/wrapEventHandler';

const DATE_DISPLAY_VALUE_FORMAT = 'MM/DD/YYYY';
const DATE_SAVE_VALUE_FORMAT = 'YYYY-MM-DD';

const styleMap = {
  body: styles.DayPicker__body,
  caption: styles.DayPicker__caption,
  day: styles.DayPicker__day,
  disabled: styles.DayPicker__disabled,
  footer: styles.DayPicker__footer,
  input: styles.DayPicker__input,
  inputOverlay: styles['DayPicker__input-overlay'],
  inputOverlayWrapper: styles['DayPicker__input-overlay-wrapper'],
  interactionDisabled: styles['DayPicker__disabled-interaction'],
  month: styles.DayPicker__month,
  months: styles.DayPicker__months,
  outside: styles.DayPicker__outside,
  selected: styles.DayPicker__selected,
  selectedRangeEnd: styles['DayPicker__selected-end'],
  selectedRangeStart: styles['DayPicker__selected-start'],
  today: styles.DayPicker__today,
  todayButton: styles['DayPicker__today-button'],
  week: styles.DayPicker__week,
  weekNumber: styles['DayPicker__week-number'],
  weekday: styles.DayPicker__weekday,
  weekdays: styles.DayPicker__weekdays,
  weekdaysRow: styles['DayPicker__weekdays-row'],
  wrapper: styles.DayPicker__wrapper,
};

class DatePicker extends Component {
  constructor(props, context) {
    super(props, context);
    this.typing = false;

    this.state = {
      // first text field
      timeValue: props.value ? moment(props.value) : null,
      textValue: props.value ? moment(props.value).format(DATE_DISPLAY_VALUE_FORMAT) : '',
      // second text field
      endTextValue: props.lastDay ? moment(props.lastDay).format(DATE_DISPLAY_VALUE_FORMAT) : '',
      lastDay: props.lastDay ? moment(props.lastDay) : null,
      hoverRange: null,
      showCalendar: false,
      showSecondaryCalendar: false,
      showTextField: props.calendarOnly !== true,
    };
    this.fromInputRef = React.createRef();
    this.toInputRef = React.createRef();
    this.inputGroup = React.createRef();
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnFocus = this.handleOnFocus.bind(this);
    this.handleOnKeyUp = this.handleOnKeyUp.bind(this);
    this.handleOnMouseEnter = this.handleOnMouseEnter.bind(this);
    this.handleOnMouseExit = this.handleOnMouseExit.bind(this);
    this.handleOnValid = this.handleOnValid.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * This is to update the component when new value/lastDay props are passed in (bec of state here)
   * Use case, for example, selecting a predefined date range (ie. last month) and
   * expecting the component to update
   */
  componentDidUpdate(prevProps) {
    const { value, lastDay } = this.props;
    if (prevProps.value !== value) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        timeValue: moment(value),
        textValue: moment(value).format(DATE_DISPLAY_VALUE_FORMAT),
      });
    }
    if (prevProps.lastDay !== lastDay) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        lastDay: moment(lastDay),
        endTextValue: moment(lastDay).format(DATE_DISPLAY_VALUE_FORMAT),
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleOnChange(event) {
    const {
      onChange,
      range,
    } = this.props;

    const {
      name,
      value,
    } = event.target;

    // return in the case where user updates field and typing hasn't been detected yet
    const tempDate = moment(value).toDate();
    // user has not typed in a field or has exited, hacky way to allow them to type
    if (this.typing === false && tempDate.getFullYear() >= 1900 && moment(value).isValid()) {
      if (tempDate.getFullYear() < 1900 || moment(value).isValid === false) {
        return;
      }

      if (range === true) { // using a date range selection
        const dateRange = this.getRange(moment(value).toDate());
        const { to, from } = dateRange;

        this.setState({
          endTextValue: to ? moment(to).format(DATE_DISPLAY_VALUE_FORMAT) : '',
          lastDay: to ? moment(to) : null,
          timeValue: from ? moment(from) : null,
          textValue: from ? moment(from).format(DATE_DISPLAY_VALUE_FORMAT) : '',
        });

        if (typeof onChange === 'function') {
          this.sendOnChange(dateRange, onChange);
        }
      } else {
        this.setState({
          timeValue: moment(value),
          textValue: moment(value).format(DATE_DISPLAY_VALUE_FORMAT),
        });

        if (typeof onChange === 'function') {
          this.sendOnChange(value, onChange);
        }
      }
    } else if (range === true && name.endsWith('end')) { // typing in second field
      this.setState({
        endTextValue: value,
      });
    } else { // typing in first field
      this.setState({
        textValue: value,
      });
    }
  }

  handleOnBlur(event) {
    const {
      onBlur,
      range,
    } = this.props;

    const {
      showTextField,
      hoverRange,
    } = this.state;

    if (this.typing) { // user has typed in the field
      this.typing = false;
      this.handleOnChange(event);
      if (typeof onBlur === 'function') {
        onBlur(event);
      }

      /**
       * This must be in the previous if statement. If user has not typed and clicks
       * a date in the calendar it will close without selection of a date
       */
      if (showTextField === true) {
        this.setState({ showCalendar: false, showSecondaryCalendar: false });
      }
    }

    if (range === true && hoverRange === true) {
      this.handleOnMouseExit();
    }
  }

  /**
   * do not add this.typing = true here unless you solve the issue with clicking outside and
   * selecting a date if user never typed, otherwise it will just close without selecting a date
   */
  handleOnFocus(event) {
    const {
      onFocus,
      range,
    } = this.props;

    const {
      hoverRange,
    } = this.state;

    if (onFocus === 'function') {
      onFocus(event);
    }

    if (range === true && hoverRange === true) {
      this.handleOnMouseExit();
    }
  }

  handleOnKeyUp(event) {
    const { onKeyUp } = this.props;

    if (this.typing === false) {
      this.typing = true;
      this.handleOnChange(event);
    }

    if (typeof onKeyUp === 'function') {
      onKeyUp(event);
    }
  }

  handleOnMouseEnter(day) {
    const range = this.getRange(moment(day).toDate());

    this.setState({
      hoverRange: range,
    });
  }

  handleOnMouseExit() {
    this.setState({
      hoverRange: null,
    });
  }

  handleOnValid(event) {
    const { onValid } = this.props;

    if (typeof onValid === 'function') {
      return onValid(event);
    }

    return true;
  }

  handleDayClick(day) {
    const {
      disabled,
      onChange,
      range,
    } = this.props;

    const {
      hoverRange,
    } = this.state;

    let {
      timeValue,
      lastDay,
    } = this.state;

    if (this.typing === true) {
      this.typing = false;
    }

    timeValue = timeValue ? timeValue.toDate() : null;
    lastDay = lastDay ? lastDay.toDate() : null;

    if (disabled) {
      return;
    }

    if (range === false) {
      timeValue = day;
      if (typeof onChange === 'function') {
        this.sendOnChange(day, onChange);
      }
    } else {
      const dateRange = this.getRange(moment(day).toDate());

      timeValue = dateRange.from;
      lastDay = dateRange.to;

      if (typeof onChange === 'function') {
        this.sendOnChange(dateRange, onChange);
      }
    }

    if (range === true && timeValue > lastDay) {
      lastDay = timeValue;
    }

    if (hoverRange) {
      this.handleOnMouseExit();
    }

    this.setState({
      lastDay: lastDay ? moment(lastDay) : null,
      endTextValue: lastDay ? moment(lastDay).format(DATE_DISPLAY_VALUE_FORMAT) : '',
      timeValue: timeValue ? moment(timeValue) : null,
      textValue: timeValue ? moment(timeValue).format(DATE_DISPLAY_VALUE_FORMAT) : '',
      showCalendar: false,
      showSecondaryCalendar: false,
    });
  }

  sendOnChange(data, cb) {
    if (this.typing || (typeof data === 'string' && moment(data).isValid()) || typeof data.getDate === 'function') {
      cb(moment(data).format(DATE_SAVE_VALUE_FORMAT));
    } else if (typeof data === 'object') {
      cb({
        from: data.from ? moment(data.from).format(DATE_SAVE_VALUE_FORMAT) : null,
        to: data.to ? moment(data.to).format(DATE_SAVE_VALUE_FORMAT) : null,
      });
    } else {
      cb(data);
    }
  }

  drawDatePicker() {
    const {
      disabled,
      hoverSelection,
      range,
      weekSelection,
    } = this.props;

    const myStyles = range ? styleMap : { ...styleMap, selected: styles['DayPicker__selected-single'] };

    const {
      hoverRange,
      lastDay,
      timeValue,
    } = this.state;

    let selectedDays = timeValue ? timeValue.toDate() : null;
    let lastDayDate = lastDay ? lastDay.toDate() : null;
    const modifiers = { isDisabled: disabled };

    if (range === true && hoverSelection !== true) {
      modifiers.start = selectedDays;
      modifiers.end = lastDayDate;
      selectedDays = [selectedDays, { from: selectedDays, to: lastDayDate }];
    }

    if (weekSelection === true && timeValue && lastDay === null) {
      const dateRange = this.getRange(timeValue.toDate());
      selectedDays = dateRange.from;
      lastDayDate = dateRange.to;
    }

    if (range && hoverSelection === true) {
      modifiers.selectedRange = { from: selectedDays, to: lastDayDate };
      modifiers.selectedRangeStart = selectedDays;
      modifiers.selectedRangeEnd = lastDayDate;
      modifiers.hoverRange = hoverRange;
      modifiers.hoverRangeStart = hoverRange && hoverRange.from;
      modifiers.hoverRangeEnd = hoverRange && hoverRange.to;
      selectedDays = { from: selectedDays, to: lastDayDate };
    }

    return (
      <div>
        <DayPicker
          className={`${styles.DayPicker} Selectable`}
          classNames={myStyles}
          month={timeValue ? timeValue.toDate() : null}
          modifiers={modifiers}
          navbarElement={<NavBar />}
          onDayClick={this.handleDayClick}
          onDayMouseEnter={this.handleOnMouseEnter}
          onDayMouseLeave={this.handleOnMouseExit}
          selectedDays={selectedDays}
          showOutsideDays
          weekdayElement={<Weekday />}
        />
        {/**
          * I do not know why the scss and classNames is not picking this up so I had to add it here
          * in their own examples this is how they added style for the hovering
          */}
        <style>
          {`
           .hoverRange {
            background-color: ${tokens.ColorGray50};
          }

          .selectedRangeStart, .hoverRangeStart, .start {
            border-bottom-left-radius: ${tokens.FnBorderRadiusMd};
            border-top-left-radius: ${tokens.FnBorderRadiusMd};
          }

          .selectedRangeEnd, .hoverRangeEnd, .end {
            border-bottom-right-radius: ${tokens.FnBorderRadiusMd};
            border-top-right-radius: ${tokens.FnBorderRadiusMd};
          }

          .${styles.DayPicker__day}:not(.${styles.DayPicker__disabled}):not(.${styles.DayPicker__selected}):hover {
              background-color: ${tokens.ColorGray50};
              border-radius: ${range === true ? 0 : tokens.FnBorderRadiusMd};
          }
          `}
        </style>
      </div>
    );
  }

  getRange(day) {
    const { range, weekSelection } = this.props;
    const { timeValue, lastDay } = this.state;

    return range === true && weekSelection === true
      ? {
        from: moment(day).startOf('week').toDate(),
        to: moment(day).endOf('week').toDate(),
      }
      : DateUtils.addDayToRange(day, {
        from: timeValue ? timeValue.toDate() : null,
        to: lastDay ? lastDay.toDate() : null,
      });
  }

  /**
   * This is to get the calendar to close when you click away from it
   */
  handleClickOutside(event) {
    const { showCalendar, showSecondaryCalendar } = this.state;

    if (showCalendar) {
      if (this.fromInputRef && !this.fromInputRef.current.contains(event.target)) {
        this.setState({ showCalendar: false });
      }
    } else if (showSecondaryCalendar) {
      if (this.toInputRef && !this.toInputRef.current.contains(event.target)) {
        this.setState({ showSecondaryCalendar: false });
      }
    }
  }

  toggleDateRangePicker(isRange = false, event) {
    const inSt = (isRange)
      ? { showCalendar: false, showSecondaryCalendar: true }
      : { showCalendar: true, showSecondaryCalendar: false };
    this.setState(inSt);
    this.handleOnFocus(event);
  }

  render() {
    const {
      disabled,
      label,
      helpText,
      id,
      name,
      optional,
      range,
      secondaryHelpText,
      secondaryLabel,
      secondaryValidationMessage,
      validationMessage,
      inRow,
      rangeMiddleText,
    } = this.props;

    const {
      endTextValue,
      showCalendar,
      showSecondaryCalendar,
      showTextField,
      textValue,
    } = this.state;

    return (
      <div
        className={inRow ? styles.DayPicker__row : {}}
        ref={this.inputGroup}
      >
        {showTextField === false && this.drawDatePicker()}
        {showTextField === true && (
          <div
            className={styles.DayPicker__holder}
            style={inRow ? { paddingRight: 15 } : {}}
            ref={this.fromInputRef}
          >
            <AbstractInput
              className={styles.DayPicker_input}
              disabled={disabled}
              label={label}
              helpText={helpText}
              id={id}
              name={name}
              optional={optional}
              onChange={this.handleOnChange}
              onBlur={this.handleOnBlur}
              onKeyUp={this.handleOnKeyUp}
              style={{ zIndex: -1 }}
              onFocus={wrapEventHandler(this.toggleDateRangePicker, [false], ARG_ORDER.EVENT_LAST, this)}
              onValid={this.handleOnValid}
              icon="event"
              iconOrientation="right"
              value={textValue}
            />
            {showCalendar === true && (
              <div className={styles['DayPicker__holder-overlay']}>
                {this.drawDatePicker()}
              </div>
            )}
          </div>
        )}
        {showTextField === true && validationMessage && (
          <HelpText fieldValidation="error">{validationMessage}</HelpText>
        )}
        {range && inRow && rangeMiddleText && (
          <div
            className={styles.Range_Middle_Text}
            style={helpText ? { alignItems: 'center' } : {}}
            data-testid="dateRangeMiddleText"
          >
            {rangeMiddleText}
          </div>
        )}
        {showTextField === true && range === true && (
          <div
            className={styles.DayPicker__holder}
            ref={this.toInputRef}
          >
            <AbstractInput
              className={styles.DayPicker_input}
              disabled={disabled}
              label={secondaryLabel}
              helpText={secondaryHelpText}
              id={`${id}_end`}
              name={`${name}_end`}
              optional={optional}
              onChange={this.handleOnChange}
              onBlur={this.handleOnBlur}
              onKeyUp={this.handleOnKeyUp}
              onFocus={wrapEventHandler(this.toggleDateRangePicker, [true], ARG_ORDER.EVENT_LAST, this)}
              onValid={this.handleOnValid}
              icon="event"
              iconOrientation="right"
              value={endTextValue}
            />
            {showSecondaryCalendar === true && (
              <div className={styles['DayPicker__holder-overlay']}>
                {this.drawDatePicker()}
              </div>
            )}
          </div>
        )}
        {showTextField === true && range === true && secondaryValidationMessage && (
          <HelpText fieldValidation="error">{secondaryValidationMessage}</HelpText>
        )}
      </div>
    );
  }
}

DatePicker.propTypes = {
  calendarOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  helpText: PropTypes.string,
  hoverSelection: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  lastDay: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyUp: PropTypes.func,
  optional: PropTypes.bool,
  onValid: PropTypes.func,
  range: PropTypes.bool,
  secondaryHelpText: PropTypes.string,
  secondaryLabel: PropTypes.string,
  secondaryValidationMessage: PropTypes.string,
  value: PropTypes.string,
  validationMessage: PropTypes.string,
  weekSelection: PropTypes.bool,
  // If you want the start/end inputs to appear in one row
  inRow: PropTypes.bool,
  // If you want middle text between start and end inputs, like 'to'
  rangeMiddleText: PropTypes.string,
};

DatePicker.defaultProps = {
  calendarOnly: false,
  disabled: false,
  helpText: undefined,
  hoverSelection: false,
  id: undefined,
  label: '',
  lastDay: '',
  name: undefined,
  onChange: undefined,
  onBlur: undefined,
  onFocus: undefined,
  onKeyUp: undefined,
  optional: false,
  onValid: undefined,
  range: false,
  secondaryHelpText: '',
  secondaryLabel: '',
  secondaryValidationMessage: undefined,
  value: '',
  validationMessage: undefined,
  weekSelection: false,
  inRow: false,
  rangeMiddleText: 'to',
};

DatePicker.contextTypes = {
  fieldValidation: PropTypes.oneOf(['', 'error', 'success']),
};

export default DatePicker;
