import React from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import MassActionBarRenderer from './MassActionBarRenderer';
import maxWidthMediaQuery from '../utils/maxWidthMediaQuery';

const MassActionBar = ({ actions, closeOnSelection }) => (
  <>
    <Media
      query={maxWidthMediaQuery('25em')}
    >
      {(matches) => (
        <MassActionBarRenderer
          key={matches ? 'IconButton' : 'button'}
          actions={actions}
          renderIconButton={matches}
          closeOnSelection={closeOnSelection}
        />
      )}
    </Media>
  </>
);

MassActionBar.propTypes = {
  closeOnSelection: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  })).isRequired,
};

MassActionBar.defaultProps = {
  closeOnSelection: false,
};

export default MassActionBar;
