import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ResizableBox } from 'react-resizable';
import classNames from 'classnames';
import textContent from 'react-addons-text-content';
import Icon from '../Icon';
import BodyText from '../BodyText';
import isSticky from '../TableCell/isSticky';
import getStickyPosition from '../TableCell/getStickyPosition';
import css from './styles.scss';
import InfoIcon from '../legacy/Construction/InfoIcon';

const sortDirections = ['DESC', 'ASC', undefined];

const getNextDirection = (currentDirection) => {
  if (currentDirection === sortDirections[0]) {
    return sortDirections[1];
  }
  if (currentDirection === sortDirections[1]) {
    return sortDirections[2];
  }
  return sortDirections[0];
};

const getSortIcon = (sortDirection) => {
  if (sortDirection === 'ASC') {
    return 'sortReverse';
  }

  if (sortDirection === 'DESC') {
    return 'sort';
  }

  return 'sortColumn';
};

const getInner = ({
  id,
  width,
  label,
  onSort,
  sortDirection,
  isLastColumn,
  lastColumnWidth,
  expandableWidth,
  info = '',
  isTopHeader,
}) => {
  const sortableProps = onSort ? {
    tabIndex: 0,
    role: 'button',
    onClick: () => {
      onSort(id, getNextDirection(sortDirection));
    },
    onKeyUp: (e) => {
      if (e.key === 'Enter') {
        onSort(id, getNextDirection(sortDirection));
      }
    },
  } : {};
  return (
    <div
      style={{ width: `${isLastColumn && expandableWidth ? lastColumnWidth : width}` }}
      className={classNames(css.TableColumnHeader__inner, {
        [css['TableColumnHeader__inner--sortable']]: onSort,
      })}
      title={textContent(label)}
      {...sortableProps}
    >
      <span
        className={css.TableColumnHeader__innerText}
      >
        <BodyText
          styleLevel={isTopHeader ? 'mdSemiBold' : 'md'}
        >
          {label}
        </BodyText>
      </span>
      {info.length > 0
        && (
          <div style={{ marginLeft: 2 }}>
            <InfoIcon showOnEvent="click" position="right">{info}</InfoIcon>
          </div>
        )}
      <span
        className={classNames(
          css.TableColumnHeader__sortIcon,
          {
            [css['TableColumnHeader__sortIcon--hoverDisplay']]: !sortDirection,
            [css['TableColumnHeader__sortIcon--visible']]: sortDirection,
            [css['TableColumnHeader__sortIcon--active']]: sortDirection,
          },
        )}
      >
        <Icon block name={getSortIcon(sortDirection)} />
      </span>
    </div>
  );
};

class TableColumnHeader extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isResizing: false,
    };

    this.onResizeStop = this.onResizeStop.bind(this);
    this.onResizeStart = this.onResizeStart.bind(this);
  }

  onResizeStop(e, data) {
    const { width } = data.size;
    const {
      onResize,
      id,
    } = this.props;
    this.setState((prevState) => ({
      isResizing: !prevState.isResizing,
    }));
    e.preventDefault();
    e.stopPropagation();
    onResize(id, width);
  }

  onResizeStart(e) {
    e.preventDefault();
    this.setState((prevState) => ({
      isResizing: !prevState.isResizing,
    }));
  }

  render() {
    const {
      id,
      label,
      minWidth,
      maxWidth,
      onResize,
      onSort,
      sortDirection,
      sticky,
      width,
      isLastColumn,
      expandableWidth,
      lastColumnWidth,
      info,
      isTopHeader,
      isConfig,
      isOverflow,
    } = this.props;

    const additionalProps = {
      ...minWidth ? {
        minConstraints: [minWidth, 25],
      } : {},
      ...maxWidth ? {
        maxConstraints: [maxWidth, 25],
      } : {},
    };

    const { isResizing } = this.state;

    const resizableBoxClassNames = [
      css.TableColumnHeader__outer,
      isResizing ? 'resizing' : null,
    ];

    const isInlineAction = id === 'inline_actions';
    return (
      <th
        className={classNames(css.TableColumnHeader, {
          [css['TableColumnHeader--sticky']]: isSticky(sticky),
          [css['TableColumnHeader--stickyBorderLeft']]: isSticky(sticky) && sticky.border === 'left',
          [css['TableColumnHeader--stickyBorderRight']]: isSticky(sticky) && sticky.border === 'right',
          [css['TableColumnHeader--isLastColumn']]: isLastColumn,
          [css['TableColumnHeader--sortable']]: onSort,
          [css['TableColumnHeader--config']]: isConfig && !isOverflow,
        })}
        key={id}
        style={{
          ...getStickyPosition(sticky),
          ...{ width: `${isLastColumn && expandableWidth ? lastColumnWidth : width}px` },
        }}
      >
        { onResize
          ? (
            <ResizableBox
              {...additionalProps}
              onResizeStart={this.onResizeStart}
              onResizeStop={this.onResizeStop}
              className={resizableBoxClassNames.join(' ')}
              height={25}
              width={width}
              axis="x"
            >
              {getInner({
                id,
                width,
                label,
                onSort,
                sortDirection,
                info,
                isTopHeader,
              })}
            </ResizableBox>
          )
          : (
            <div
              {...(isInlineAction ? { style: { width } } : {})}
              className={classNames({
                [css.TableColumnHeader__outer]: true,
                [css['TableColumnHeader__outer--horizontalSm']]: isInlineAction,
                [css['TableColumnHeader__outer--horizontalCenter']]: isInlineAction,
              })}
            >
              {getInner({
                id,
                width,
                label,
                onSort,
                sortDirection,
                info,
                isTopHeader,
              })}
            </div>
          )}
      </th>
    );
  }
}

TableColumnHeader.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  maxWidth: PropTypes.number,
  minWidth: PropTypes.number,
  onResize: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
  onSort: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
  sortDirection: PropTypes.oneOf(['ASC', 'DESC', undefined]),
  sticky: PropTypes.shape({
    side: PropTypes.oneOf(['left', 'right']),
    offset: PropTypes.number,
    border: PropTypes.oneOf(['left', 'right', 'none']),
  }),
  width: PropTypes.number.isRequired,
  isLastColumn: PropTypes.bool,
  lastColumnWidth: PropTypes.number,
  expandableWidth: PropTypes.number,
  info: PropTypes.string,
  isTopHeader: PropTypes.bool,
  isConfig: PropTypes.bool,
  isOverflow: PropTypes.bool,
};

TableColumnHeader.defaultProps = {
  maxWidth: undefined,
  minWidth: undefined,
  onResize: false,
  sortDirection: undefined,
  onSort: false,
  sticky: undefined,
  isLastColumn: false,
  lastColumnWidth: 0,
  expandableWidth: 0,
  info: '',
  isTopHeader: false,
  isConfig: false,
  isOverflow: true,
};

TableColumnHeader.displayName = 'TableColumnHeader';

export default TableColumnHeader;
