
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import styles from './DividedList/styles/DividedList.scss';
import DividedListItem from './DividedList/DividedListItem.jsx';

class DividedList extends Component {
    getChildContext() {
        return {
            dividedListStyles: styles,
        };
    }

    render() {
        const { children } = this.props;
        return (
            <div styleName="dividedList">{children}</div>
        );
    }
}

DividedList.childContextTypes = {
    dividedListStyles: PropTypes.object,
};

export default CSSModules(DividedList, styles);
export { DividedListItem };
