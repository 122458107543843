import React from 'react';
import PropTypes from 'prop-types';
import ActiveFilter from './ActiveFilter';
import MoreMenu from './MoreMenu';
import IconButton from '../IconButton';
import css from './ActiveFilters.scss';
import { filtersPropTypes } from './ToolbarPropTypes';
import wrapEventHandler, { ARG_ORDER } from '../utils/wrapEventHandler';

class ActiveFilters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showItems: -1,
      onResize: false,
      onDelete: false,
    };

    this.setOnDelete = this.setOnDelete.bind(this);
    this.activeFiltersRef = React.createRef();
    this.moreMenuRef = React.createRef();
    this.filterRefs = [];
    this.filterAddButtonOffset = 10.48;
    this.widths = [];
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
  }

  componentDidUpdate() {
    this.calculateAvailableSpace();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  setFilterRef(index, node) {
    if (this.filterRefs.length >= index) {
      this.filterRefs[index] = node || '';
    }
  }

  setOnDelete(deleted, cb, event) {
    if (cb && typeof cb === 'function') {
      this.setState({ onDelete: deleted }, cb(event));
    } else {
      this.setState({ onDelete: deleted });
    }
  }

  calculateAvailableSpace = () => {
    const {
      toolbarSectionRef,
      searchSectionWidth,
      bookmarkSectionRef,
      filterAddButtonRef,
      filterSaveButtonRef,
      viewSwitcherSectionRef,
      exportButtonRef,
    } = this.props;
    if (!toolbarSectionRef.current
        || !searchSectionWidth
        || !bookmarkSectionRef.current
        || !filterAddButtonRef.current
        || !viewSwitcherSectionRef.current) {
      return;
    }
    const { showItems } = this.state;
    const initial = toolbarSectionRef.current.offsetWidth
      - (this.moreMenuRef.current ? this.moreMenuRef.current.offsetWidth : 0)
      - searchSectionWidth
      - bookmarkSectionRef.current.offsetWidth
      - (filterAddButtonRef.current.offsetWidth + this.filterAddButtonOffset)
      - (filterSaveButtonRef.current ? filterSaveButtonRef.current.offsetWidth : 0)
      - (exportButtonRef.current ? exportButtonRef.current.offsetWidth : 0)
      - viewSwitcherSectionRef.current.offsetWidth;

    let idx = -1;
    this.filterRefs.reduce((acc, current, i) => {
      const width = (current && current.offsetWidth) || this.widths[i];
      this.widths[i] = width;
      const diff = acc - width;
      if (diff < 0 && idx === -1) {
        idx = i;
      }
      return diff;
    }, initial);

    if (idx !== showItems) {
      this.setState({ showItems: idx });
    }
  };

  onResize = () => this.setState(
    {
      showItems: -1,
      onResize: true,
    },
  );

  render() {
    const {
      onApplyFilter,
      onRemoveFilter,
      filters: filtersProp,
      onFilterOptionsFetched,
      isSingleFilter,
    } = this.props;

    if (!filtersProp.length) {
      return null;
    }

    const {
      showItems,
      onResize,
      // eslint-disable-next-line no-unused-vars
      onDelete,
    } = this.state;

    const filters = showItems >= 0
      ? filtersProp.slice(0, showItems)
      : filtersProp;

    const more = showItems >= 0
      ? filtersProp.slice(showItems)
      : [];

    if (onResize) {
      this.setState({ onResize: false });
    }

    return (
      <div className={css.ActiveFilters} ref={this.activeFiltersRef}>
        {filters.map((filter, i) => (
          <div
            ref={wrapEventHandler(this.setFilterRef, [i], ARG_ORDER.EVENT_LAST, this)}
            key={`activeFilter__${filter.id}`}
          >
            <ActiveFilter
              onMount={wrapEventHandler(this.setOnDelete, [false], ARG_ORDER.EVENT_LAST, this)}
              openImmediately={filter.value === undefined}
              onRemoveFilter={wrapEventHandler(this.setOnDelete, [true, onRemoveFilter], ARG_ORDER.EVENT_LAST, this)}
              filter={filter}
              onSubmit={onApplyFilter}
              onFilterOptionsFetched={onFilterOptionsFetched}
              isSingleFilter={isSingleFilter}
            />
          </div>
        ))}

        {
          more.length > 0 && (
            <div ref={this.moreMenuRef}>
              <MoreMenu
                trigger={(
                  <IconButton
                    name="moreHoriz"
                    color="dark"
                    title="More..."
                  />
                )}
                type="link"
                filters={more}
                onRemoveFilter={onRemoveFilter}
                onApplyFilter={onApplyFilter}
              />
            </div>
          )
        }
      </div>
    );
  }
}

ActiveFilters.propTypes = {
  filters: filtersPropTypes.isRequired,
  onRemoveFilter: PropTypes.func.isRequired,
  onApplyFilter: PropTypes.func.isRequired,
  toolbarSectionRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
  searchSectionWidth: PropTypes.number.isRequired,
  bookmarkSectionRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
  filterAddButtonRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
  filterSaveButtonRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
  viewSwitcherSectionRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
  exportButtonRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
  onFilterOptionsFetched: PropTypes.func,
  isSingleFilter: PropTypes.bool,
};
ActiveFilters.defaultProps = {
  onFilterOptionsFetched: () => {},
  isSingleFilter: false,
};
export default ActiveFilters;
