import React from 'react';
import PropTypes from 'prop-types';
import {
  Median,
  MedianAlpha,
  MedianOmega,
  IconButton,
  IconButtons,
} from '../index';

const MiniDash = ({
  children,
  toggleIcon,
  toggleOnClick,
  toggleTitle,
}) => (
  <Median noWrap verticalAlign="bottom">
    <MedianAlpha>
      {children}
    </MedianAlpha>
    <MedianOmega>
      <IconButtons isFlush>
        <IconButton
          name={toggleIcon}
          onClick={toggleOnClick}
          title={toggleTitle}
        />
      </IconButtons>
    </MedianOmega>
  </Median>
);

MiniDash.propTypes = {
  children: PropTypes.node.isRequired,
  toggleIcon: PropTypes.string.isRequired,
  toggleOnClick: PropTypes.func.isRequired,
  toggleTitle: PropTypes.string,
};

MiniDash.defaultProps = {
  toggleTitle: { title: '' },
};

export default MiniDash;
