/* eslint-disable no-console */
import { useRecoilState, useRecoilCallback, RecoilValue } from 'recoil';
import {
  save,
  Preferences,
  FilterValue,
  SavedFilter,
} from './preferences.factory';
import prefsState from './prefs.state';
import propsState from './props.state';
import { Filter } from './DataGrid';

const useFilters = () => {
  const [, setPrefs] = useRecoilState(prefsState);
  const rcb = useRecoilCallback(({ snapshot }) => (query: RecoilValue<any>) => {
    if (query && snapshot) {
      const { contents } = snapshot.getLoadable(query);
      return contents;
    }
    return null;
  });

  const applyFilter = (f: Filter) => {
    const prefs = rcb(prefsState);
    const props = rcb(propsState);
    if (!prefs || !props) {
      return;
    }

    const filterMap = new Map<string, FilterValue>();
    (prefs.filters || []).forEach(({ id, value }) => {
      filterMap.set(id, value || '');
    });

    filterMap.set(f.id, f.value || '');

    const updatedPrefs: Preferences = {
      ...prefs,
      filters: Array.from(filterMap.entries()).map(([id, value]) => ({
        id,
        value,
      })),
    };

    setPrefs(updatedPrefs);
    save(props, updatedPrefs).catch((err) => console.error(err));
  };

  const removeFilter = (f: Filter) => {
    const prefs = rcb(prefsState);
    const props = rcb(propsState);
    if (!prefs || !props) {
      return;
    }

    const filterMap = new Map<string, FilterValue>();
    (prefs.filters || []).forEach(({ id, value }) => {
      filterMap.set(id, value || '');
    });

    filterMap.delete(f.id);

    const updatedPrefs: Preferences = {
      ...prefs,
      filters: Array.from(filterMap.entries()).map(([id, value]) => ({
        id,
        value,
      })),
    };

    setPrefs(updatedPrefs);
    save(props, updatedPrefs).catch((err) => console.error(err));
  };

  const setSearchText = (text: string) => {
    const prefs = rcb(prefsState);
    const props = rcb(propsState);
    if (!prefs || !props) {
      return;
    }

    const updatedPrefs: Preferences = {
      ...prefs,
      searchText: text,
    };

    setPrefs(updatedPrefs);
    save(props, updatedPrefs).catch((err) => console.error(err));
  };

  const clearFilters = () => {
    const prefs = rcb(prefsState);
    const props = rcb(propsState);
    if (!prefs || !props) {
      return;
    }

    const updatedPrefs: Preferences = {
      ...prefs,
      searchText: '',
      filters: [],
    };

    setPrefs(updatedPrefs);
    save(props, updatedPrefs).catch((err) => console.error(err));
  };

  const addSavedFilter = (f: SavedFilter) => {
    const prefs = rcb(prefsState);
    const props = rcb(propsState);
    if (!prefs || !props) {
      return;
    }

    const existing = new Map<string, SavedFilter>();

    (prefs.savedFilters || []).forEach((sf) => {
      existing.set(sf.name, sf);
    });

    existing.set(f.name, f);

    const updatedPrefs: Preferences = {
      ...prefs,
      savedFilters: Array.from(existing.values()),
    };

    setPrefs(updatedPrefs);
    save(props, updatedPrefs).catch((err) => console.error(err));
  };

  const replaceFilters = (filters: Filter[]) => {
    const prefs = rcb(prefsState);
    const props = rcb(propsState);
    if (!prefs || !props) {
      return;
    }

    const updatedPrefs: Preferences = {
      ...prefs,
      filters: filters.map((f) => ({
        id: f.id,
        value: f.value,
      })),
    };

    setPrefs(updatedPrefs);
    save(props, updatedPrefs).catch((err) => console.error(err));
  };

  const deleteSavedFilter = (sf: SavedFilter) => {
    const prefs = rcb(prefsState);
    const props = rcb(propsState);
    if (!prefs || !props) {
      return;
    }

    const existing = new Map<string, SavedFilter>();

    (prefs.savedFilters || []).forEach((ssf) => {
      existing.set(ssf.name, ssf);
    });

    existing.delete(sf.name);

    const updatedPrefs: Preferences = {
      ...prefs,
      savedFilters: Array.from(existing.values()),
    };

    setPrefs(updatedPrefs);
    save(props, updatedPrefs).catch((err) => console.error(err));
  };

  return {
    setSearchText,
    applyFilter,
    clearFilters,
    removeFilter,
    addSavedFilter,
    replaceFilters,
    deleteSavedFilter,
  };
};

export default useFilters;
