import isFinite from 'lodash/isFinite';

/**
 * Return a locale string for a numeric value
 * @param value {number}    numeric value
 * @returns     {string}    locale string
 */
export default function numericFormatter(value) {
    const parsedValue = parseFloat(value);

    if (!isFinite(parsedValue)) {
        return value;
    }

    return Math.floor(parsedValue).toLocaleString();
}
