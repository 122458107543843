import React from 'react';
import ActionsMenu from '../../../../ActionsMenu';

const ButtonBarMoreMenu = ({ menuItems, isInGroup, firstInGroup, lastInGroup, alignToRight, label, type }) => (
    <ActionsMenu
        alignToRight={alignToRight}
        isInGroup={isInGroup}
        firstInGroup={firstInGroup}
        lastInGroup={lastInGroup}
        menuItems={menuItems}
        label={label || 'More'}
        type={type}
        disableAutoWidth />
);

export default ButtonBarMoreMenu;
