import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import styles from './Box/box.scss';
import BoxHeader from './Box/BoxHeader.jsx';
import BoxHeading from './Box/BoxHeading.jsx';
import BoxGrid from './Box/BoxGrid.jsx';
import BoxGridColumn from './Box/BoxGridColumn.jsx';

class Box extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = { };
    }

    getChildContext() {
        return {
            boxStyles: styles,
        };
    }

    render() {
        return (
            <div styleName="box">
                {this.props.children}
            </div>
        );
    }
}

Box.childContextTypes = {
    boxStyles: PropTypes.object,
};

export default CSSModules(Box, styles);

export {
    BoxHeader,
    BoxHeading,
    BoxGrid,
    BoxGridColumn,
};
