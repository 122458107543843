import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { iconList } from '@fieldnation/font/components';
import SkinlessPopover from '../SkinlessPopover';
import css from './styles.scss';
import IconActions from '../IconActions';

export default class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      buttonWidth: null,
    };
    this.node = React.createRef();
    this.setButtonWidth = this.setButtonWidth.bind(this);
    this.changeOpen = this.changeOpen.bind(this);
    this.renderTrigger = this.renderTrigger.bind(this);
  }

  componentDidMount() {
    this.setButtonWidth();
    // need re-size on scroll to size when button is off-screen
    window.addEventListener('scroll', this.setButtonWidth);
  }

  componentDidUpdate() {
    this.setButtonWidth();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.setButtonWidth);
  }

  changeOpen(isMenuOpen) {
    this.setState({ isOpen: isMenuOpen });
  }

  setButtonWidth() {
    const { disableAutoWidth, minWidth } = this.props;
    if (disableAutoWidth || !this.node.current) {
      return;
    }

    // eslint-disable-next-line react/no-find-dom-node
    const buttonRect = ReactDOM.findDOMNode(this.node.current).getBoundingClientRect();
    const newButtonWidth = Math.ceil(minWidth || buttonRect.width);
    const { buttonWidth } = this.state;
    if (buttonWidth !== newButtonWidth) {
      this.setState({ buttonWidth: newButtonWidth });
    }
  }

  renderTrigger() {
    const { trigger } = this.props;
    if (typeof trigger === 'function') {
      return trigger(this.props, this.state);
    }
    return trigger;
  }

  render() {
    const {
      block,
      disableAutoWidth,
      menuItems,
      title,
      label,
      alignToRight,
      isBelowPanel,
      enableSpacingInDropdown,
      closeOnSelection,
      menuClasses: propsMenuClasses,
    } = this.props;

    const { isOpen, buttonWidth } = this.state;

    const menuClasses = classNames({
      ...propsMenuClasses.reduce((acc, cur) => {
        acc[cur] = true;
        return acc;
      }, {}),
      [css.Menu__menu]: true,
      [css['Menu__menu--noWrap']]: disableAutoWidth,
    });
    const width = buttonWidth ? `${buttonWidth}px` : '20rem';
    const menuStyles = { width };

    const items = menuItems.map((item) => {
      const { onClick: itemOnClick } = item;

      if (closeOnSelection === true) {
        return {
          ...item,
          onClick: () => {
            this.setState({ isOpen: false });
            return itemOnClick ? itemOnClick() : undefined;
          },
        };
      }
      return item;
    });

    return (
      <div
        className={classNames(css.Menu, {
          [css['Menu--block']]: block,
        })}
        title={title ? `${title}` : undefined}
        data-testid={label}
      >
        {menuItems.length ? (
          <SkinlessPopover
            isBelowPanel={isBelowPanel}
            showOnEvent="click"
            attachment={alignToRight ? 'top right' : 'top left'}
            isOpen={isOpen}
            onChangeOpen={this.changeOpen}
            targetAttachment={
              alignToRight ? 'bottom right' : 'bottom left'
            }
            ref={this.node}
          >
            {this.renderTrigger()}
            <div className={menuClasses} style={menuStyles}>
              <IconActions
                actions={items}
                hasSpacing={enableSpacingInDropdown}
              />
            </div>
          </SkinlessPopover>
        )
          : this.renderTrigger()}
      </div>
    );
  }
}

Menu.propTypes = {
  alignToRight: PropTypes.bool,
  block: PropTypes.bool,
  disableAutoWidth: PropTypes.bool,
  minWidth: PropTypes.string,
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    href: PropTypes.string,
    icon: PropTypes.oneOf(iconList),
    target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
    disabled: PropTypes.bool,
  })),
  label: PropTypes.string,
  title: PropTypes.node,
  trigger: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  isBelowPanel: PropTypes.bool,
  enableSpacingInDropdown: PropTypes.bool,
  closeOnSelection: PropTypes.bool,
  menuClasses: PropTypes.arrayOf(PropTypes.string),
};

Menu.defaultProps = {
  alignToRight: false,
  block: false,
  disableAutoWidth: false,
  minWidth: null,
  menuItems: [],
  label: '',
  title: false,
  isBelowPanel: false,
  enableSpacingInDropdown: false,
  closeOnSelection: false,
  menuClasses: [],
};
