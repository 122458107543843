import { selector } from 'recoil';
import { Tab } from './DataGrid';
import propsState from './props.state';
import prefsState from './prefs.state';

/**
 * Returns, for the actively selected tab, the Tab (e.g.: <DataGrid tabs prop entry>
 * from the preference factory.
 *
 * @return {TabPreference | null}
 */
const tabPropsQuery = selector<Tab | null>({
  key: 'tabProps',
  get: ({ get }) => {
    const props = get(propsState);
    const preferences = get(prefsState);

    if (!props) {
      return null;
    }

    const tab = preferences?.defaultTab || (preferences?.tabs || [])?.[0]?.id;
    const tabProps = props.tabs.find(({ id }) => id === tab);

    if (!tabProps) {
      return null;
    }

    return tabProps;
  },
});

export default tabPropsQuery;
