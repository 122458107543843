import { useRecoilState, useRecoilCallback, RecoilValue } from 'recoil';
import { save, Preferences } from './preferences.factory';
import prefsState from './prefs.state';
import propsState from './props.state';
import tabPrefsQuery from './tab-prefs.query';

const usePagination = () => {
  const [, setPrefs] = useRecoilState(prefsState);
  const rcb = useRecoilCallback(({ snapshot }) => (query: RecoilValue<any>) => {
    if (query && snapshot) {
      const { contents } = snapshot.getLoadable(query);
      return contents;
    }
    return null;
  });

  const setPage = (newPageNumber: number) => {
    const prefs = rcb(prefsState);
    const tabPrefs = rcb(tabPrefsQuery);
    const props = rcb(propsState);

    if (!tabPrefs || !prefs || !props) {
      return;
    }

    const updatedPrefs: Preferences = {
      ...prefs,
      tabs: (prefs.tabs || []).map((tab) => {
        if (tab.id === tabPrefs.id) {
          return {
            ...tab,
            page: newPageNumber,
          };
        }

        return tab;
      }),
    };

    setPrefs(updatedPrefs);
    // eslint-disable-next-line no-console
    save(props, updatedPrefs).catch((err) => console.error(err));
  };

  return {
    setPage,
  };
};

export default usePagination;
